import { merge } from 'lodash'

// hard-coded copy goes here



const reviewCounts = {
  home_cleaning: `2,474,627`,
  other_handyman_service: `23,986`
};

const sharedReviewInfo = (link) =>{
  return {
    title: `Customer Recommendations`,
    averageStarRating: '4.5',
    learnMore: {
      text: `Learn More`,
      href: link,
    },
  }
};

function membershipContent(brandingDetails, links) {
  const membershipName = 'Angi Key';
  if (brandingDetails.isAngi === true){
    return `${membershipName} discounts only apply to eligible Fixed Price Services that are purchased and paid for on the ${brandingDetails.displayName} website. Any discounts obtained through ${membershipName} cannot be combined or used with any other discounts, coupons or offers on the Fixed Price Services.`
  } else{
    return `${membershipName} discounts only apply to eligible Fixed Price Services that are purchased and paid for on the ${brandingDetails.displayName} website. To see the list of eligible services, click <a href=${links["membership"]} target="_blank" rel="noreferrer noopener">here</a>. Any discounts obtained through ${membershipName} cannot be combined or used with any other discounts, coupons or offers on the Fixed Price Services.`
  }
}

function injectedReviewContent(reviewCount, taskoidName) {
  return {
    reviewCount,
    aboveTheFoldDescription: getRecommendedRatingsText(reviewCount),
    subtitle: `${reviewCount} Recommendations for Fixed-Price ${taskoidName}.`,
  }
}

function getRecommendedRatingsText(reviewCount) {
  const enableNewRatingsService = document.documentElement.dataset.enableNewRatingsService
  return ( enableNewRatingsService === "true") ? `${reviewCount} customers rated this service` : `Recommended by ${reviewCount} customers`
}

function getPhoneNumber(brandingDetails) {
  if (brandingDetails.isAngi === true) {
    return {
      number: "(855) 975-7133",
      numLink: "855-975-7133"
    };
  }

  return {
    number: "(855) 902-0606",
    numLink: "855-902-0606"
  };
};

function whenPageCopyMapping(serviceMachineName, links, brandingDetails) {
  const membershipNameWithArticle = 'Angi Key';
  const possessiveMembershipName = 'Angi Key';
  const serviceMapping = {
    home_cleaning: {
      mainDescription: 'If you’re in need of a regular maid service or one-time house cleaning, we’re simply the best, most convenient platform for connecting you with a top-rated home cleaning professional to help take the stress out of your life. We know you want an affordable house cleaning while still having the confidence that you will receive a cleaner who is reliable and thorough, with a keen attention to detail. We aim to offer you that and more:',
      faqHeader: 'Frequently Asked Questions',
      faqHeaderClassNames: 'large-margin-left-1',
      accordionContent: [
        {
          'label': 'Instant online booking with 7am-11pm availability',
          'text': 'Book online instantly, and schedule your first cleaning for as early as tomorrow. Get your home cleaned anytime from 7am to 11pm, 7 days a week.',
        },
        {
          'label': 'Friendly, vetted professionals',
          'text': `All professionals on the ${brandingDetails.displayName} platform are screened, <a href=${links["vetted"]} target="_blank" rel="noreferrer noopener">vetted</a>, and are rated by customers like you to ensure quality.`,
        },
        {
          'label': 'No unexpected changes',
          'text': 'In some instances, the scope of the job may be different than expected based on unforeseen complexity or a misrepresented job. If this is the case, we may contact you with a revised price for your approval prior to your pro completing the service.',
        },
        {
          'label': 'Cleaned the way you want',
          'text': 'Professionals bring supplies and work from a comprehensive checklist that you can tailor to your liking.',
        },
        {
          'label': 'Same cleaner every time',
          'text': `${brandingDetails.displayName} strives to match you with the right professional for you and your home every time. We also provide you with a team of professionals to provide backup.`,
        },
        {
          'label': 'Easy and secure online payments',
          'text': 'No more last minute runs to the bank. Pay online and tip at your discretion.',
        },
        {
          'label': 'Fixed-Price Guarantee*',
          'text': `Your happiness is our goal. If you are not happy, we will work to make it right. <a href=${links["guarantee"]} target="_blank" rel="noreferrer noopener">Learn More</a>`,
          'disclaimer': `*The Fixed-Price Guarantee applies only to Fixed-Price services, prepaid and purchased on the ${brandingDetails.displayName} Website`,
        },
        {
          'label': `What is included in ${membershipNameWithArticle}?`,
          'text': membershipContent(brandingDetails, links),
        },
        {
          'label': `How to cancel ${membershipNameWithArticle}?`,
          'text': `You may cancel ${possessiveMembershipName} by going to your account page, calling <a href="tel:${getPhoneNumber(brandingDetails).numLink}">${getPhoneNumber(brandingDetails).number}</a>, or by mailing a notice of cancellation to Attn.: Legal, PO Box #1122, New York, NY 10159.`,
        },
        {
          'label': `Are Project Advisor Services included in ${membershipNameWithArticle}?`,
          'text':  membershipContent(brandingDetails, links)
        },
      ],
    },
    other_handyman_service: {
      mainDescription: 'We know you’ve got a never-ending list of odd jobs that need doing around your home. However, with the demands of work and family, it’s so hard to find the time to tackle them all.  When you book fixed-price Handyman services, you’ll be connected with local handyman professionals who can take care of this work for you. In addition to freeing up your time for the more important things in life, booking a fixed-price handyman service will give you the security of knowing that things are being handled by an expert. If you’d rather leave things to a professional, then we are ready to help.',
      faqHeader: 'Frequently Asked Questions',
      faqHeaderClassNames: 'large-margin-left-1',
      accordionContent: [
        {
          'label': 'Will I need tools or will my handyman professional bring them?',
          'text': `Professionals always come prepared with the proper tools and supplies to get your job done right. However, it's always a good idea to be as clear and descriptive in your job description to ensure that nothing important is missed or forgotten!`,
        },
        {
          'label': 'When should I expect my professional to get in touch with me?',
          'text': 'After your booking is confirmed, we will send you confirmation emails and messages about your booking and professional. You can contact your professional at any time with questions or concerns you might have.',
        },
        {
          'label': 'Instant online booking with 7am-11pm availability',
          'text': 'Book online instantly, and schedule your first booking for as early as tomorrow. Your pro will take care of your job anytime from 7am to 11pm, 7 days a week.',
        },
        {
          'label': 'Friendly, vetted professionals',
          'text': `All professionals on the ${brandingDetails.displayName} platform are screened, <a href=${links["vetted"]} target="_blank rel="noreferrer noopener"">vetted</a>, and are rated by customers like you to ensure quality.`,
        },
        {
          'label': 'No unexpected changes',
          'text': 'In some instances, the scope of the job may be different than expected based on unforeseen complexity or a misrepresented job. If this is the case, we may contact you with a revised price for your approval prior to your pro completing the service.',
        },
        {
          'label': 'Easy and secure online payments',
          'text': 'No more last minute runs to the bank. Pay online and tip at your discretion.',
        },
        {
          'label': 'Fixed-Price Guarantee*',
          'text': `Your happiness is our goal. If you are not happy, we will work to make it right. <a href=${links["guarantee"]} target="_blank" rel="noreferrer noopener">Learn More</a>`,
          'disclaimer': `*The Fixed-Price Guarantee applies only to Fixed-Price services, prepaid and purchased on the ${brandingDetails.displayName} Website`,
        },
        {
          'label': `What is included in ${membershipNameWithArticle}?`,
          'text':  membershipContent(brandingDetails, links),
        },
        {
          'label': `How to cancel ${membershipNameWithArticle}?`,
          'text': `You may cancel ${possessiveMembershipName} by going to your account page, calling <a href="tel:${getPhoneNumber(brandingDetails).numLink}">${getPhoneNumber(brandingDetails).number}</a>, or by mailing a notice of cancellation to Attn.: Legal, PO Box #1122, New York, NY 10159.`,
        },
        {
          'label': `Are Project Advisor Services included in ${membershipNameWithArticle}?`,
          'text':  membershipContent(brandingDetails, links)
        },
      ],
    },
  }
  if (serviceMapping[serviceMachineName]){
    return serviceMapping[serviceMachineName]
  }else{
    return serviceMapping.other_handyman_service
  }
};

const detailsHeaderMapping = {
  home_cleaning: 'Tell us about your place',
  other_handyman_service: 'Tell us about the job',
}

function whenPageCopy(serviceMachineName, links, brandingDetails) {
  return whenPageCopyMapping(serviceMachineName, links, brandingDetails)
}

function getCustomerRecommendations(serviceMachineName, taskoidName, link) {
  const reviewCount = reviewCounts[serviceMachineName]

  return _.merge(
    sharedReviewInfo(link),
    injectedReviewContent(reviewCount, taskoidName)
  )
}

function detailsHeader(serviceMachineName) {
  return detailsHeaderMapping[serviceMachineName]
}

function legalCopy(brandingDetails, brandingLink) {

  const { displayName } = brandingDetails
  return `By clicking Get a Price, you affirm you have read and agree to the <a href=${brandingLink} target="_blank">Terms</a>, and you agree and authorize ${displayName} and its affiliates, and their networks of Service Professionals, to deliver marketing calls or texts using automated technology to the number you provided above regarding your project and other home services offers. Consent is not a condition of purchase.`
}

function capitalizeString(uncapitalizedString) {
  return uncapitalizedString.charAt(0).toUpperCase() + uncapitalizedString.slice(1);
}

export {
  getCustomerRecommendations,
  whenPageCopy,
  detailsHeader,
  legalCopy,
  getRecommendedRatingsText
}
