import { axios } from '../../utils/request';
import BookingInstructions from './BookingInstructions';
import BookingConfirmation from './Index';
import { Omniture } from '../../utils/analytics';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import AdditionalBookingErrorBanner from './additionalBookingErrorBanner';

const cookies = new Cookies();
const omniture = new Omniture();

const enableNewConfirmationPage = document.documentElement.dataset.enableNewConfirmationPage === 'true';
const enableCommissionJunctionPixel = document.documentElement.dataset.enableCommissionJunctionPixel === 'true';

const mapStateToProps = (state) => {
  const bookingCreationErrors = state.bookingRequestErrorReducer;
  return { bookingCreationErrors: bookingCreationErrors.errors }
};

class ConfirmationPage extends React.Component {
  constructor (props) {
    super(props);

    const taskoid = cookies.get('taskoid');
    const cjevent = cookies.get('cjevent');
    const serviceMachineName = cookies.get('serviceMachineName');
    const entryPointId = cookies.get('entryPointId');
    const extFulTrack = cookies.get('extFulTrack');
    const externalProviderId = cookies.get('external_provider_id');

    const pageName = 'FixedPriceHandy: Confirmation';

    omniture.track({ serviceMachineName, pageName, entryPointId, extFulTrack, prop58: pageName, catchall: externalProviderId  });

    this.state = {
      loading: enableNewConfirmationPage,
      isAuthed: false,
      taskoid,
      cjevent
    }

    this.fireBookingConfirmationPixel();
  }

  // THIS IS A WORK AROUND, SIGNAL SHOULD HANDLE THIS
  fireBookingConfirmationPixel = () => {
    if (typeof(gtag) !== "undefined")  {
      // Homeadvisor FPP SEM (Booking Confirmation)
      // ID: 7240704
      gtag('event', 'conversion', {
          'send_to': 'AW-798023605/SxLhCKqN-pcBELW_w_wC',
          'value': 1.0,
          'currency': 'USD',
          'transaction_id': ''
      });
      // Homeadvisor FPP SEM (Booking Confirmation) - Second Conversion Type
      // ID: 7438512
      gtag('event', 'conversion', {
        'send_to': 'AW-998499358/21muCJbXv7EBEJ7Ij9wD'
      });
    }
    // Homeadvisor FPP SEM (Booking Confirmation) - BING
    // ID: 7438511
    window.uetq = window.uetq || [];
    window.uetq.push({ 'ec':'FPP', 'ea':'Booking' });
    
    const[amount] = this.getAmountAfterDiscountAndDiscountAmount()

    // Subdomain Booking Amount Data Layer
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'bookingCompleted',
      'bookingAmount': amount
    });    

  }

  componentDidMount () {
    if (enableNewConfirmationPage) {
      axios.get(`/api/v1/is_authed`).then(response => {
        this.setState({
          isAuthed: response.data.is_authed
        })
      }).finally(() => {
        this.setState({
          loading: false
        })
      })
    }
  }

  getImgUrl = () => {
    const type = '419770';
    const cid = '1506095';
    const { taskoid, cjevent } = this.state;
    const {
      location: {
        state: {
          booking: {
            external_id: externalId,
          },
          couponInfo,
        },
      },
    } = this.props;

    const [amount, discountAmount] = this.getAmountAfterDiscountAndDiscountAmount();
    let url = `https://www.emjcd.com/u?TYPE=${type}&CID=${cid}&ITEM1=${taskoid}&QTY1=1&OID=${externalId}&CURRENCY=USD&METHOD=IMG`;
    if(discountAmount){
      const couponPart = `&DISCOUNT=${discountAmount}&COUPON=${couponInfo.couponCode}`;
      url += couponPart;
    }
    url += `&AMT1=${amount}`
    if (cjevent) {
      url += `&CJEVENT=${cjevent}`;
    }

    return url;
  }

  getAmountAfterDiscountAndDiscountAmount(){
    const { quote , couponInfo } = this.props.location.state;
    let discountAmount, amount = quote.full_price / 100;
    if (couponInfo && couponInfo.couponCode && quote) {
      const { quote_breakdown: quoteBreakdown } = quote;
      const discount = quoteBreakdown.find(q => q.category === 'discount');
      discountAmount = Math.abs(discount.amount) / 100;
      amount += discountAmount;
    } 
    return [amount, discountAmount];
  }

  render () {
    const {
      isAuthed,
      loading
    } = this.state

    const cjUrl = this.getImgUrl();

    return (
      <div>
        {!loading &&
          <div>
            { this.props.bookingCreationErrors.length > 0 && <AdditionalBookingErrorBanner/> }
            {enableCommissionJunctionPixel && <img height='1' width='1' src={cjUrl} style={{ position: 'absolute', zIndex: '-1' }} />}
            {(enableNewConfirmationPage && isAuthed) ? (
              <BookingConfirmation {...this.props}/>
            ) : (
              <BookingInstructions {...this.props}/>
            )}
          </div>
        }
      </div>
    )
  }
}

export default connect(mapStateToProps)(ConfirmationPage);
