import BigBlueCheckbox from '../../images/big-blue-checkbox.svg'

function ThankYouPage() {
    return (
        <div className="text-center" style={{paddingTop: '5em', paddingBottom: '8em'}}>
            <div style={{height: '75px', marginBottom: '1em'}}>
                <img src={BigBlueCheckbox} alt="" style={{maxWidth: '100%', maxHeight: '100%'}} />
            </div>

            <h1>Thanks for Responding!</h1>

            Your response has been recorded.
        </div>
    )
}

export default ThankYouPage;
