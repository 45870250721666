import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Frequency from './Frequency';
import { serviceUpdated } from '#/actions/services';
import { selectServiceById, createSelectIsInCartByServiceId } from '#/selectors';
import { useAddToCartByServiceNameMutation } from '#/hooks';

function FrequencyWithState({ id }) {
  const dispatch = useDispatch();
  const { frequency } = useSelector((state) => selectServiceById(state, id));
  const isInCart = useSelector(createSelectIsInCartByServiceId(id));
  const [addQuote] = useAddToCartByServiceNameMutation(id);

  const options = frequency.options.map(({ machineName, displayName }) => ({
    value: machineName,
    text: displayName,
  }));
  const value = frequency.selected;
  const onChange = (selected) => {
    dispatch(serviceUpdated({
      id,
      changes: {
        frequency: {
          ...frequency,
          selected,
        },
      },
    }));
    if (isInCart) addQuote({ serviceName: id });
  };

  return (
    <Frequency
      id={id}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
}

FrequencyWithState.propTypes = {
  id: PropTypes.string.isRequired,
};

export default FrequencyWithState;
