import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText } from '@material-ui/core';
import { ButtonRadio } from '#/components/shared';
import Label from '#/components/cart/task/taskForm/Label';

export default function Frequency({ id, options, value, onChange }) {
  return (
    <FormControl fullWidth>
      <Label>
        Get your service with automatically scheduled bookings.
        <br />
        Plans are flexible: Skip or reschedule anytime.
      </Label>
      <ButtonRadio
        id={id}
        options={options}
        value={value}
        onChange={onChange}
      />
      <FormHelperText id={`${id}-helper-text`} />
    </FormControl>
  );
}

Frequency.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    text: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
