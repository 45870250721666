import { axios } from './request'

const experiments = {
  fetchIsVariant: (experiment_name, unique_id, zipcode = null) => {
    const params = { experiment_name, unique_id };
    if (zipcode && zipcode?.length) { params.zipcode = zipcode; }
    return axios.post('api/v1/experiments', params);
  },
};

export default experiments;
