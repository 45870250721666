import { createSelector } from '@reduxjs/toolkit';

const selectWhenForm = (state) => state.whenForm;

const selectQuoteWorkflowParams = createSelector(
  selectWhenForm,
  ({ quoteWorkflowParams }) => quoteWorkflowParams,
);

const whenFormServiceNameSelector = createSelector(
  selectQuoteWorkflowParams,
  ({ quoteRequest }) => quoteRequest?.serviceName,
);

const whenFormEmailSelector = createSelector(
  selectWhenForm,
  ({ email }) => email,
);

const selectWhenFormIsHydrated = createSelector(
  selectWhenForm,
  ({ isHydrated }) => isHydrated,
);

export {
  selectQuoteWorkflowParams,
  selectWhenForm,
  selectWhenFormIsHydrated,
  whenFormServiceNameSelector,
  whenFormEmailSelector,
};
