import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import { receiptServiceNamesSelector } from '#/selectors';
import useStyles from './styles';
import ServiceLineItem from './ServiceLineItem';

export default function ReceiptServiceList() {
  const { name: theme } = useTheme();
  const classes = useStyles({ theme });
  const serviceNames = useSelector(receiptServiceNamesSelector);

  return (
    <ul className={classes.list}>
      {serviceNames.map((name) => (
        <ServiceLineItem serviceName={name} key={name} />
      ))}
    </ul>
  );
}
