import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FormControl, FormHelperText, OutlinedInput } from '@material-ui/core';
import { usePostRecommendedDurationByServiceMutation } from '#/apiServices';
import { selectServiceById, createSelectIsInCartByServiceId } from '#/selectors';
import { serviceUpdated } from '#/actions/services';
import { Counter, Select } from '#/components/shared';
import Label from '#/components/cart/task/taskForm/Label';
import { useAddToCartByServiceNameMutation } from '#/hooks';

function QuestionWithState({ serviceMachineName, machineName }) {
  const dispatch = useDispatch();
  const service = useSelector((state) => selectServiceById(state, serviceMachineName));
  const isInCart = useSelector(createSelectIsInCartByServiceId(serviceMachineName));
  const { form: { ids, questions } } = service;
  const question = questions[machineName];
  const { subtype, label, options: unformattedOpts, placeholder, value } = question;
  const options = unformattedOpts.map((opts) => {
    const updatedOptions = { ...opts, text: opts.display };
    const serviceIsHolidayHelp = serviceMachineName === 'holiday_help';
    if (serviceIsHolidayHelp && updatedOptions.display === 'Installation') {
      updatedOptions.value = '1';
    }
    if (serviceIsHolidayHelp && updatedOptions.display === 'Removal') {
      updatedOptions.value = '0';
    }
    return updatedOptions;
  });
  const id = `${serviceMachineName}-${serviceMachineName}`;
  const labelId = `${id}-label`;
  const [postDuration, { isLoading: isLoadingDuration }] = usePostRecommendedDurationByServiceMutation(serviceMachineName);
  const [addQuote, { isLoading: isLoadingQuote }] = useAddToCartByServiceNameMutation(serviceMachineName);

  const disabled = isInCart && (isLoadingDuration || isLoadingQuote);

  const onChange = async (newValue) => {
    dispatch(serviceUpdated({
      id: serviceMachineName,
      changes: {
        form: {
          ids,
          questions: { ...questions, [machineName]: { ...question, value: newValue } },
        },
      },
    }));
    if (question.affectsDuration) {
      if (isInCart) {
        await postDuration(serviceMachineName);
        addQuote({ serviceName: serviceMachineName });
      } else {
        postDuration(serviceMachineName);
      }
    }
  };

  const onTextFieldChange = ({ target: { value: newValue } }) => onChange(newValue);

  let currentValueIdx = options.findIndex((opt) => opt.value === value);
  currentValueIdx = currentValueIdx >= 0 ? currentValueIdx : 0;
  const onIncrement = () => onChange(options[currentValueIdx + 1].value);
  const onDecrement = () => onChange(options[currentValueIdx - 1].value);

  switch (subtype) {
    case 'stepper':
      return (
        <FormControl fullWidth>
          <Label>{label}</Label>
          <Counter
            decrementDisabled={currentValueIdx <= 0 || disabled}
            id={id}
            incrementDisabled={currentValueIdx >= (options.length - 1) || disabled}
            onDecrement={onDecrement}
            onIncrement={onIncrement}
            value={value}
          />
          <FormHelperText id={`${id}-helper-text`} />
        </FormControl>
      );
    case 'dropdown':
      return (
        <FormControl fullWidth>
          <Label id={labelId}>{label}</Label>
          <Select
            id={id}
            labelId={labelId}
            onChange={onChange}
            options={options}
            value={value}
          />
          <FormHelperText id={`${id}-helper-text`} />
        </FormControl>
      );
    case 'textarea':
      return (
        <FormControl fullWidth>
          <Label htmlFor={id}>{label}</Label>
          <OutlinedInput
            aria-describedby={`${id}-helper-text`}
            id={id}
            multiline
            onChange={onTextFieldChange}
            placeholder={placeholder}
            rows={3}
            value={value}
            variant="outlined"
          />
          <FormHelperText id={`${id}-helper-text`} />
        </FormControl>
      );
    default:
      return null;
  }
}

QuestionWithState.propTypes = {
  serviceMachineName: PropTypes.string.isRequired,
  machineName: PropTypes.string.isRequired,
};

export default React.memo(QuestionWithState);
