import React from 'react';
import PropTypes from 'prop-types';
import { useHydratedWhenFormStateFromCookies } from '#/hooks';
import Cart from './Cart';

export default function CartWithState({ brandingDetails }) {
  const isHydrated = useHydratedWhenFormStateFromCookies();

  // eslint-disable-next-line react/jsx-filename-extension
  return isHydrated ? <Cart brandingDetails={brandingDetails} /> : <div />;
}
CartWithState.propTypes = {
  brandingDetails: PropTypes.shape({
    acronym: PropTypes.string,
    displayName: PropTypes.string,
    domainBrand: PropTypes.string,
    isAngi: PropTypes.bool,
    partnerId: PropTypes.string,
    siteBrandKey: PropTypes.string,
  }).isRequired,
};
