import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const DeepCleanWindowCleaning = ({ style }) => {
  // Brand information
  const brandingDetails = (useSelector((state) => state.brandingDetails));
  const { isAngi } = brandingDetails;

  return (
    <div>
      { isAngi
        ? (
          <svg style={style} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 8C12 6.89543 12.8954 6 14 6H34C35.1046 6 36 6.89543 36 8V39H12V8Z" fill="#FF6153" />
            <rect x="14" y="8" width="20" height="30" fill="#A03027" />
            <rect x="16" y="10" width="16" height="10" fill="#FF6153" />
            <rect x="16" y="22" width="16" height="14" fill="#FF6153" />
            <path d="M24.8918 33.9002C24.4592 31.2712 22.7454 29.5408 20.0998 29.1082C19.9667 29.0915 19.9667 28.9085 20.0998 28.8918C22.7288 28.4592 24.4592 26.7454 24.8918 24.0998C24.9085 23.9667 25.0915 23.9667 25.1082 24.0998C25.5408 26.7288 27.2546 28.4592 29.9002 28.8918C30.0333 28.9085 30.0333 29.0915 29.9002 29.1082C27.2712 29.5408 25.5408 31.2546 25.1082 33.9002C25.0749 34.0333 24.9085 34.0333 24.8918 33.9002Z" fill="#A03027" />
            <path d="M20.9351 17.9401C20.6755 16.3627 19.6473 15.3245 18.0599 15.0649C17.98 15.0549 17.98 14.9451 18.0599 14.9351C19.6373 14.6755 20.6755 13.6473 20.9351 12.0599C20.9451 11.98 21.0549 11.98 21.0649 12.0599C21.3245 13.6373 22.3527 14.6755 23.9401 14.9351C24.02 14.9451 24.02 15.0549 23.9401 15.0649C22.3627 15.3245 21.3245 16.3527 21.0649 17.9401C21.0449 18.02 20.9451 18.02 20.9351 17.9401Z" fill="#A03027" />
            <path d="M12 39H36C36 40.6569 34.6569 42 33 42H15C13.3431 42 12 40.6569 12 39Z" fill="#A03027" />
            <path d="M10 38H38V40H10V38Z" fill="#FF6153" />
          </svg>
        )

        : (
          <svg style={style} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.205 36.6951H36.695V11.2061H11.205V36.6951Z" fill="white" />
            <path d="M18.0406 11.6553V25.4273" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M29.859 11.6553V25.4273" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M11.6549 13.6094H36.2459" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M11.6549 16.5645H36.2459" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M11.6549 19.5186H36.2459" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M11.6549 22.4736H36.2459" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M11.6549 25.4277H36.2459" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M6.49902 41.4H41.399V6.50098H6.49902V41.4ZM11.205 36.695H36.695V11.205H11.205V36.695Z" fill="#D5DBF1" />
            <path d="M11.205 36.695H10.955V36.945H11.205V36.695ZM36.695 36.695V36.945H36.945V36.695H36.695ZM36.695 11.206H36.945V10.956H36.695V11.206ZM11.205 11.206V10.956H10.955V11.206H11.205ZM6.5 41.4H6.25V41.65H6.5V41.4ZM41.4 41.4V41.65H41.65V41.4H41.4ZM41.4 6.50098H41.65V6.25098H41.4V6.50098ZM6.5 6.50098V6.25098H6.25V6.50098H6.5ZM11.205 36.945H36.695V36.445H11.205V36.945ZM36.945 36.695V11.206H36.445V36.695H36.945ZM36.695 10.956H11.205V11.456H36.695V10.956ZM10.955 11.206V36.695H11.455V11.206H10.955ZM6.5 41.65H41.4V41.15H6.5V41.65ZM41.65 41.4V6.50098H41.15V41.4H41.65ZM41.4 6.25098H6.5V6.75098H41.4V6.25098ZM6.25 6.50098V41.4H6.75V6.50098H6.25Z" fill="#10103F" />
          </svg>
        )}
    </div>

  );
};
DeepCleanWindowCleaning.propTypes = {
  style: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
};

DeepCleanWindowCleaning.defaultProps = {
  style: {
    height: '22.6px',
    width: '23px',
  },
};

export default DeepCleanWindowCleaning;
