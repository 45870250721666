import classNames from 'classnames'

class CouponBanner extends React.Component {
  constructor(props) {
    super(props)
  }

  refCallback = element => {
    if (element && this.props.getSize) {
      this.props.getSize(element.getBoundingClientRect())
    }
  }

  render() {
    const { header, body, hasCheckMark, error, brandingDetails } = this.props
    const checkMark = hasCheckMark
      ? <i className='fas fa-check-circle bold coupon-symbol coupon-checkmark ' ></i>
      : null

    const errorClassNames = brandingDetails.isAngi ? 'far fa-exclamation-triangle coupon-symbol' : 'fas fa-times-circle bold coupon-symbol'
    const times = error ? <i className={ errorClassNames } ></i> : null

    const couponCss = classNames('coupon-banner-container', 'small-12', 
      { 
        'angi': brandingDetails && brandingDetails.isAngi,
        'error-banner-color':  error
      })

    return (
      <div className={ couponCss } ref={this.refCallback} >
        {checkMark}
        {times}
        <div className='text-center' ><b>{header}</b>{` ${body}`}</div>
      </div>
    )
  }
}

export default CouponBanner
