import React from 'react';

const AngiKeyMinimal = ({ className, height = 8, width = 20 }) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 20 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="13.4266"
      y="4.86719"
      width="3.12112"
      height="2.52674"
      fill="white"
    />
    <path
      d="M6.1045 3.69075C6.12262 3.79133 6.13244 3.89416 6.13244 4C6.13244 4.96378 5.34327 5.74817 4.37361 5.74817C3.40395 5.74817 2.61554 4.96378 2.61554 4C2.61554 3.03622 3.40471 2.25183 4.37436 2.25183C5.17637 2.25183 5.85453 2.78852 6.06523 3.51961C6.07429 3.53012 6.08411 3.54063 6.09392 3.55114C6.07882 3.51961 6.06296 3.48808 6.04786 3.45581L7.6111 1.62807C6.87705 0.641021 5.69972 0 4.37436 0C2.15487 0 0.349976 1.79396 0.349976 4C0.349976 6.20529 2.15487 8 4.37436 8C5.70198 8 6.87932 7.35598 7.61261 6.36742L7.61336 6.36892C7.61336 6.36892 7.6262 6.34941 7.64961 6.31638C7.67 6.28786 7.69114 6.26009 7.71078 6.23156C7.71456 6.22631 7.71833 6.22105 7.72286 6.2158C6.93973 5.54025 6.38467 4.65753 6.1045 3.69075Z"
      fill="white"
    />
    <path
      d="M6.04956 3.48486C6.06704 3.55434 6.08679 3.62382 6.1073 3.69254C6.09667 3.63439 6.08375 3.577 6.06704 3.52036L6.06628 3.5196L6.04956 3.48486Z"
      fill="#DAD8D3"
    />
    <path
      d="M8.23584 5.11882C8.23584 5.11882 6.85687 5.11807 6.09413 3.55155C6.08431 3.54104 6.07449 3.53128 6.06543 3.52002C6.08129 3.57632 6.16096 3.82141 6.11735 4.17431C6.39753 5.1411 6.94069 5.54066 7.72382 6.21471C7.97228 5.88294 8.61267 5.12632 9.2795 5.12632H10.4531L10.4584 5.11882H8.23584Z"
      fill="white"
    />
    <path
      d="M9.27881 2.86677C8.42469 2.86677 7.61362 1.62451 7.61362 1.62451L7.6106 1.62751L6.04736 3.45525C6.06247 3.48828 6.07757 3.51905 6.09343 3.55058C6.85617 5.1171 8.23514 5.11785 8.23514 5.11785H10.4577H17.4039C18.6435 5.11785 19.6483 4.11303 19.6483 2.87353C19.6483 2.87075 19.6489 2.86603 19.6461 2.86604C19.4853 2.86677 10.5122 2.86677 9.27881 2.86677Z"
      fill="white"
    />
  </svg>
);

export default AngiKeyMinimal;
