import { discountablePriceFromQuote } from '#/utils/memberships';
import { formatCurrency } from '#/utils/';
import MembershipSelectionDescriptionV2 from './MembershipSelectionDescriptionV2';
import {
  Member as RadioTilePricingMember,
  Regular as RadioTilePricingRegular,
} from './RadioTilePricing';

import classnames from 'classnames'

class MembershipSelectionV2 extends React.Component {
  componentDidMount() {
    this.props.onMembershipSelectionChange(true)();
  }

  render() {
    const { quote, membershipDiscount, isAngi, membership, onMembershipSelectionChange } = this.props;
    const discountablePrice = discountablePriceFromQuote(quote);
    const radioLabel = classnames(
      'radio-label',
      {
        angi: isAngi
      }
    );

    if (discountablePrice <= 0) {
      return null
    }

    const formattedDiscountablePrice = formatCurrency(membership.currencySymbol, discountablePrice);
    const membershipPrice = formatCurrency(membership.currencySymbol, (discountablePrice - membershipDiscount))

    return (
      <fieldset className='input margin-bottom-1em'>
        <legend>
          <MembershipSelectionDescriptionV2
            discount={formatCurrency(membership.currencySymbol, membershipDiscount)}
            isAngi={isAngi}
          />
        </legend>
        <div className="payment-page-form-row padding-bottom-1em">
          <RadioTilePricingMember
            checked={!!membership.isSelected}
            membership={membership}
            name="membership-opt-in"
            price={membershipPrice}
            onChange={onMembershipSelectionChange(true)} />
        </div>
        <div className="payment-page-form-row padding-bottom-1em">
          <RadioTilePricingRegular
            checked={!membership.isSelected}
            name="membership-opt-in"
            price={formattedDiscountablePrice}
            onChange={onMembershipSelectionChange(false)}
          />
        </div>
      </fieldset>
    )
  }
}

export default MembershipSelectionV2
