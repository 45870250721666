const brandingLinks = {
  "angi": {
    "screened_profesional": "https://vault.pactsafe.io/s/a84ad12b-7245-4a12-9fc5-2011a3bf4d62/legal.html#screening",
    "fix_price_guarantee": "https://vault.pactsafe.io/s/a84ad12b-7245-4a12-9fc5-2011a3bf4d62/legal.html#guarantee",
    "terms": "https://vault.pactsafe.io/s/a84ad12b-7245-4a12-9fc5-2011a3bf4d62/legal.html#contract-bjq4r3d6f",
    "vetted": "https://vault.pactsafe.io/s/a84ad12b-7245-4a12-9fc5-2011a3bf4d62/legal.html#screening",
    "guarantee": "https://vault.pactsafe.io/s/a84ad12b-7245-4a12-9fc5-2011a3bf4d62/legal.html#guarantee",
    "recommendations":"https://vault.pactsafe.io/s/a84ad12b-7245-4a12-9fc5-2011a3bf4d62/legal.html#recommendations",
    "fixed_price_terms":"https://legal.homeadvisor.com/#fixed-price-terms",
    "account": "https://my.angi.com/account/settings",
    "cancellation_policy":"https://homeadvisor.zendesk.com/hc/en-us/articles/360015445834-What-s-the-cancellation-policy-",
    "help_center": "https://angiservices.zendesk.com/hc/en-us",
    "happiness_guarantee":"https://www.angi.com/happiness-guarantee.htm",
    "projects": "https://my.angi.com/myprojects/",
    "project_notes": "https://my.angi.com/account/projectnotes/"
  },
  "homeadvisor": {
    "screened_profesional": "https://www.homeadvisor.com/terms/#screening",
    "fix_price_guarantee": "https://www.homeadvisor.com/terms/#guarantee",
    "terms": "http://embedded.homeadvisor.com/rfs/popup/consumerTandCPop.jsp",
    "vetted": "https://www.homeadvisor.com/terms/#screening",
    "happiness_guarantee": "https://www.homeadvisor.com/r/happiness-guarantee/?v=2",
    "membership": "https://try.handy.com/homeadvisor-membership-services/",
    "recommendations":"https://www.homeadvisor.com/terms/#recommendations",
    "fixed_price_terms":"https://legal.homeadvisor.com/#fixed-price-terms",
    "account": "https://www.homeadvisor.com/myhomeadvisor/settings",
    "cancellation_policy":"https://homeadvisor.zendesk.com/hc/en-us/articles/360015445834-What-s-the-cancellation-policy-",
    "help_center": "https://homeadvisor.zendesk.com/hc/en-us",
    "contact": "https://www.homeadvisor.com/contact/",
    "projects": "https://www.homeadvisor.com/myprojects/",
    "project_notes": "https://www.homeadvisor.com/myhomeadvisor/projectnotes/"
  }
}

const getLink = (brand, link) =>{
  return brandingLinks[brand][link]
}
export default { getLink, brandingLinks}
