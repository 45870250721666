import InsideCabinets from '../images/inside-cabinets';
import InsideFridge from '../images/inside-fridge';
import InsideOven from '../images/inside-oven';
import InsideWindows from '../images/inside-windows';
import Laundry from '../images/laundry';
import InsideCabinetsSelected from '../images/inside-cabinets-selected';
import InsideFridgeSelected from '../images/inside-fridge-selected';
import InsideOvenSelected from '../images/inside-oven-selected';
import InsideWindowsSelected from '../images/inside-windows-selected';
import LaundrySelected from '../images/laundry-selected';

export const extrasMapping = {
  inside_cabinets: {
    asset: InsideCabinets,
    assetSelected: InsideCabinetsSelected,
    text: 'Inside Cabinets',
  },
  inside_fridge: {
    asset: InsideFridge,
    assetSelected: InsideFridgeSelected,
    text: 'Inside Fridge',
  },
  inside_oven: {
    asset: InsideOven,
    assetSelected: InsideOvenSelected,
    text: 'Inside Oven',
  },
  interior_windows: {
    asset: InsideWindows,
    assetSelected: InsideWindowsSelected,
    text: 'Inside Windows',
  },
  laundry: {
    asset: Laundry,
    assetSelected: LaundrySelected,
    text: 'Laundry Wash/Dry',
  },
};
