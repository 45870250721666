export function removeFromCartAsPrimaryService() {
  return {
    type: 'bundles/removeFromCartAsPrimaryService',
  };
}

export function removePendingServiceFromCart(serviceId) {
  return {
    payload: serviceId,
    type: 'bundles/removePendingServiceFromCart',
  };
}

export function addPendingServiceToCart(serviceId) {
  return {
    payload: serviceId,
    type: 'bundles/addPendingServiceToCart',
  };
}

export function addPendingServicesToCart(serviceIds) {
  return {
    payload: serviceIds,
    type: 'bundles/addPendingServicesToCart',
  };
}

export function addToCart(serviceId) {
  return {
    payload: serviceId,
    type: 'ADD_TO_CART',
  };
}

export function removeFromCart(serviceId) {
  return {
    payload: serviceId,
    type: 'REMOVE_FROM_CART',
  };
}
