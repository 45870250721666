import { delay } from 'lodash'
import { withRouter } from 'react-router'

const msDelay = 10

class ScrollToTopOnMount extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      _.delay(() => window.scrollTo(0, 0), msDelay)
    }
  }

  render() {
    return this.props.children
  }
}

export default withRouter(ScrollToTopOnMount)
