import React from 'react';
import PropTypes from 'prop-types';
import { formatDiscountAmount, formatAccessFee } from '../../utils/memberships';

export default function MembershipLearnMore({ membership, brandName }) {
  const displayName = 'Angi Key';

  return (
    <div className='membership-modal' >
      <h1 className='membership-modal-headline'>About <span>{displayName}</span></h1>
      <div className='membership-modal-text-container'>
        <div className='membership-modal-text'>{displayName} is a yearly subscription that provides a discount on all eligible fixed price services booked and purchased on {brandName}.com</div>
        <div className='membership-modal-text'>Get {formatDiscountAmount(membership)} off on services such as home cleaning, lawn care, TV mounting, general handyman, general plumbing, electrical work and more!</div>
        <div className='membership-modal-text bottom-line'>{displayName} is {formatAccessFee(membership)} annually. Cancel membership anytime and keep your benefits through the end of your membership period. </div>
      </div>
    </div>
  );
};

MembershipLearnMore.propTypes = {
  membership: PropTypes.shape({
    display_name: PropTypes.string,
    discountType: PropTypes.string,
    currencySymbol: PropTypes.string,
    discountAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  brandName: PropTypes.string.isRequired,
};
