import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Select, styled } from '@material-ui/core';
import CounterInput from './Counter';

const StyledSelect = styled(Select)({
  cursor: 'default',
  textAlign: 'center',
  width: '100%',
  maxWidth: '512px',
});

export default function Counter({
  id,
  onIncrement,
  onDecrement,
  value,
  textFromValue,
  decrementDisabled,
  incrementDisabled,
}) {
  const text = textFromValue(value);
  return (
    <StyledSelect
      id={id}
      value={value}
      inputComponent={CounterInput}
      inputProps={{ text, onIncrement, onDecrement, decrementDisabled, incrementDisabled }}
      variant="outlined"
    />
  );
}

Counter.propTypes = {
  decrementDisabled: PropTypes.bool,
  incrementDisabled: PropTypes.bool,
  onDecrement: PropTypes.func,
  onIncrement: PropTypes.func,
  // ...CounterInput.propTypes, docgen needs these specified again
  textFromValue: PropTypes.func,
  id: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Counter.defaultProps = {
  decrementDisabled: false,
  incrementDisabled: false,
  onDecrement: noop,
  onIncrement: noop,
  // ...CounterInput.defaultProps, docgen needs these specified again
  textFromValue: (value) => value.toString(),
  value: '',
};
