import { Select, styled } from '@material-ui/core';

const SelectInput = styled(Select)({
  fontSize: '14px',
  width: '100%',
  maxWidth: '512px',
  height: '56px',
});

export default SelectInput;
