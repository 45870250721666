import Spinner from '../shared/Spinner'
import CardSection from "../shared/CardSection"
import { cancelMembership } from "../../utils/memberships"
import { Mixpanel } from "../../utils/analytics"

class MembershipsCancel extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: false,
      cancelled: false
    }

    this.mixpanel = new Mixpanel({ external_id: this.props.match.params.external_user_id })
    this.track = this.mixpanel.tracker({ throttling: false, event_context: 'account_management' })
  }

  componentDidMount () {
    this.track("membership_cancellation_shown")
  }

  onCancelClick = () => {
    this.setState({ loading: true })
    this.track("membership_cancellation_submitted")
    const external_user_id = this.props.match.params.external_user_id

    cancelMembership(external_user_id)
      .then((response) => {
        this.handleSuccess()
      }).catch((e) => {
        this.handleError()
      })
  }

  handleSuccess = () => {
    this.track("membership_cancellation_success")
    this.setState({ cancelled: true })
  }

  handleError = () => {
    window.location.replace("/500")
  }

  onAccountClick = () => {
    window.location.replace("https://www.homeadvisor.com/myhomeadvisor/settings/")
  }

  render () {
    return this.state.cancelled ? (
      <CardSection>
        <h3>Your membership was cancelled. </h3>
        <p>Your membership perks will remain active for 12 months after the original
        purchase date or renewal date</p>

        <button className="button button--full" onClick={this.onAccountClick}>
          Back to My Account
        </button>
      </CardSection>
    ) : (
      <CardSection>
        <h3>Are you sure you want to cancel your membership?</h3>
        <p>We're sorry to see you go</p>

        <button
        onClick={this.onCancelClick}
        className="button button--full"
        disabled={this.state.loading}>
          {<Spinner content="Cancel Now" loading={this.state.loading} />}
        </button>
      </CardSection>
    )
  }
}

export default MembershipsCancel
