import { startCase, findIndex, find } from 'lodash';
import { formatCurrency } from '../../utils'
import Cookies from 'universal-cookie'
import ExtrasList from './ExtrasList'
import classnames from 'classnames'
import CouponCodeInput from './CouponCodeInput';
import { CircularProgress, Typography } from '@material-ui/core'
import ReceiptBreakdown from '#/components/shared/ReceiptBreakdown';
import ReceiptServiceList from '#/components/shared/ReceiptServiceList';
import deepCamelCase from '../../utils/deepCamelCase';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  const { services, cart, whenForm } = state;
  return { services, cart, whenForm };
};

const cookies = new Cookies();

class QuoteBreakdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quoteWorkflowParams: deepCamelCase(cookies.get('quoteWorkflowParams'))
    };
  }

  buildQuotesByPayPeriodMap = (quoteLineItems) => {
    let map = new Map()
    for (const item of quoteLineItems) {
      const key = item.pay_period || item.payPeriod || "due_now"
      if (!map.has(key)) {
        map.set(key, [])
      }
      map.get(key).push(item)
    }
    return map
  }

  formatQuoteBreakDownSection = (quoteLineItems, title, index) => {
    const { currencySymbol } = this.props;
    const total = quoteLineItems.reduce((a, b) => { return { amount: (a.amount + b.amount) }}, { amount: 0 });

    return (
      <div className='quote-card-section' key={index}>
        <div className='quote-card-row'><b>{title}</b></div>
        <ReceiptBreakdown currency={currencySymbol} quoteLineItems={quoteLineItems} />
        <div className='quote-card-row'>
          <div className='grid-x'>
            <div className='cell small-9'>
              <div>Total</div>
            </div>
            <div className={this.totalPriceColor()}>
              <div>
                {formatCurrency(currencySymbol, total.amount)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


  totalPriceColor = (paymentAfterComplete) => {
    let textColor;

    if (this.props.enablePlanEngagement) {
      textColor = { 'black-bold': true }
    } else {
      textColor = {
        'black-bold':  paymentAfterComplete && !this.props.brandingDetails.isAngi,
        'angiColor': this.props.brandingDetails.isAngi
      }
    }

    return classnames(
      'cell',
      'small-3',
      'text-right',
      textColor,
    )
  }

  totalLabelColor = (paymentAfterComplete) => {
    let textColor;

    if (this.props.enablePlanEngagement) {
      textColor = { 'black-bold': true }
    } else {
      textColor = {
        'black-bold':  paymentAfterComplete && !this.props.brandingDetails.isAngi,
        'angiColor': this.props.brandingDetails.isAngi
      }
    }

    return classnames(
      'cell',
      'small-9',
      textColor,
    )
  }
  displayMultipleSections = (quoteByPayPeriodMap) => {
    return quoteByPayPeriodMap.size > 1
  }
  formatQuoteBreakDownSections = (quoteByPayPeriodMap) => {
    const payPeriodTitle = new Map([
      ["due_now","Due Now"],
      ["service_complete", "Due After Job Completion"],
    ])
    if (this.displayMultipleSections(quoteByPayPeriodMap)) {
      let result = []
      let index = 0
      for(const pay_period of payPeriodTitle.keys()) {
        if (quoteByPayPeriodMap.get(pay_period)) {
          const totaledQlis = this.getQliTotals(quoteByPayPeriodMap.get(pay_period));
          result.push(
            this.formatQuoteBreakDownSection(
              totaledQlis,
              payPeriodTitle.get(pay_period),
              index
            )
          )
        }
        index+=1
      }
      return result
    } else {
      const additionalQuoteLineItems = this.additionalQuoteLineItems();
      const mainServiceQuoteLineItems = this.props.quoteBreakdownItems.map((qbi) => ({...qbi}));

      const quoteLineItems = [...mainServiceQuoteLineItems, ...additionalQuoteLineItems];
      const totaledQlis = this.getQliTotals(quoteLineItems);

      return (<div className='quote-card-section'>
        <ReceiptBreakdown currency={this.props.currencySymbol} quoteLineItems={totaledQlis} />
        {this.props.brandingDetails.isAngi && this.renderCouponCodeInput()}
      </div>)
    }
  }

  getQliTotals = (quoteLineItems) => {
    const sumQliAmtsByCategory = (sumQlis, qli) => {
      const idx = findIndex(sumQlis, (q) => q.category === qli.category);
      if (idx === -1) return [...sumQlis, qli];
      const currentAmount = sumQlis[idx].amount;
      const newQlis = [...sumQlis];
      // Do no sum membership fees for display, after a primary booking is made with a membership
      // the rest of the quotes will not be charged a membership fee
      const amount = qli.category === 'membership_fee' ? qli.amount : currentAmount + qli.amount;
      newQlis[idx] = { ...qli, amount };
      return newQlis;
    };
    const totaledQlis = quoteLineItems.reduce(sumQliAmtsByCategory, []);
    return totaledQlis;
  }

  getTotalText  = (quoteByPayPeriodMap, paymentAfterComplete) => {
    if(paymentAfterComplete) {
      return (<b>{this.displayMultipleSections(quoteByPayPeriodMap)? "Combined Total": "Total"}</b>)
    } else {
      return "Total";
    }
  }

  renderPaymentAfterCompleteSection = () => {
    return (<div className="quote-card-row">
      <div className="payment-after-booking margin-top-1em margin-bottom-1em padding-1em">
        <span className="fas fa-check-circle padding-right-0-5em"> </span> <b>Pay nothing for this job today</b>
      </div>
    </div>)
  }

  renderCouponCodeInput = () => {
    return <div>
      <CouponCodeInput
        brandingDetails={this.props.brandingDetails}
        couponCode={this.props.couponCode}
        couponError={this.props.couponError}
        isCouponLoading={this.props.isCouponLoading}
        onCouponCodeChange={this.props.onCouponCodeChange}
      />
    </div>
  }

  additionalQuoteLineItems = () => {
    const { additionalQuotes } = this.props;
    const quoteLineItems = additionalQuotes.map((quote) => quote.quoteBreakdown).flat(1);
    return quoteLineItems;
  }

  render() {
    const {
      currencySymbol,
      enableExtras,
      extrasAdded,
      isFetchingPrice,
      paymentAfterComplete,
      totalPrice,
      services,
      cart,
      additionalQuotes,
      whenForm,
      quoteBreakdownItems,
      mainServiceFrequency,
    } = this.props

    const { quoteWorkflowParams } = this.state;

    const mainServiceQuoteLineItems = quoteBreakdownItems.map((qbi) => ({ ...qbi }));
    const additionalQuoteLineItems = this.additionalQuoteLineItems();
    const quoteLineItems = [...mainServiceQuoteLineItems, ...additionalQuoteLineItems];

    const quoteByPayPeriodMap = this.buildQuotesByPayPeriodMap(quoteLineItems);
    const showExtras = () => (enableExtras && extrasAdded.length > 0)
    const quoteCardHeader = classnames(
      'quote-card-header',
      {'angi': this.props.brandingDetails.isAngi}
    )

    const date = (dateString) => {
      const formattedDateString = dateString ? dateString.replace(/-/g, '/') : null;
      const date = new Date(formattedDateString);
      const opts = {
        year: 'numeric', month: '2-digit', day: '2-digit',
        hour: '2-digit', minute: '2-digit', hour12: true,
      };
      return date.toLocaleTimeString('en-US', opts).split(',').join(' at');
    };

    const currency = currencySymbol;
    const quoteRequest = whenForm.quoteWorkflowParams ? whenForm.quoteWorkflowParams.quoteRequest : quoteWorkflowParams.quoteRequest;

    const mainService = {
      basePrice: quoteBreakdownItems.find((qli) => qli.category === 'base').amount,
      name: quoteRequest.serviceName,
      duration: `${quoteRequest.duration/60} Hours`,
      frequency: startCase(mainServiceFrequency),
      date: date(quoteRequest.startsAt),
      quoteLineItems: quoteBreakdownItems,
    }

    const addOnServices = Object.keys(cart.serviceIds).map((cartItem) => {
      const addOnQuote = find(additionalQuotes, { serviceName: cartItem });
      const serviceForm = services.entities[cartItem];
      const quoteLineItems = addOnQuote.quoteBreakdown.map((item) => ({
        amount: item.amount,
        category: item.category,
        display_name: item.displayName,
      }));

      return ({
        basePrice: quoteLineItems.find((qli) => qli.category === 'base').amount,
        name: addOnQuote.serviceName,
        duration: `${addOnQuote.recommendedDurationMinutes/60} Hours`,
        frequency: startCase(serviceForm.frequency.selected),
        date: date(serviceForm.businessHours.value),
        quoteLineItems: quoteLineItems,
      });
    });

    const allServices = [mainService, ...addOnServices]

    return (
      <div>
        <div className={quoteCardHeader}>
          <Typography className="order-summary">Order Summary</Typography>
          {showExtras() && <ExtrasList extrasAdded={extrasAdded} />}
        </div>
        <ReceiptServiceList currency={currency} services={allServices} />
        {this.formatQuoteBreakDownSections(quoteByPayPeriodMap)}
        <div className="quote-card-section">
          <div className="quote-card-row">
            <div className="grid-x total">
              <Typography>
                {this.getTotalText(quoteByPayPeriodMap, paymentAfterComplete)}
              </Typography>
              <Typography component="div" className="quote-card-row">
                {isFetchingPrice ? <CircularProgress size={15} /> : totalPrice}
              </Typography>
            </div>
          </div>
        </div>
        {paymentAfterComplete && this.renderPaymentAfterCompleteSection()}
      </div>
    );
  }
}

export default connect(mapStateToProps)(QuoteBreakdown);
