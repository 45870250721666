import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Hidden, styled, Typography } from '@material-ui/core';
import { whenPageBackgroundImage } from '#/utils/content';
import { currentBundleSelector } from '#/selectors';
import BundleTasks from './BundleTasks';
import Receipt from './Receipt';
import CartWrapperCard from '../../cart/CartWrapper';
import BoxWrapper from '../../cart/BoxWrapper';
import HappinessGuarantee from '../../shared/HappinessGuarantee';

const StyledBanner = styled('img')({
  position: 'absolute',
  zIndex: '-1',
});

const StyledTypography = styled(Typography)(({ theme: { name } }) => ({
  letterSpacing: '-0.75px',
  fontSize: '32px',
  lineHeight: '120%',
  marginBottom: '28px',
  fontWeight: 'bold',
  fontFamily: name === 'angi' ? 'national2Bold' : 'Montserrat, sans-serif',
}));

function BundleEditPageComponent() {
  const currentBundle = useSelector(currentBundleSelector);
  const brandingDetails = useSelector((state) => state.brandingDetails);
  const { name } = currentBundle;

  return (
    <>
      <StyledBanner
        src={whenPageBackgroundImage(name, brandingDetails)}
        className="when-background show-for-large"
      />
      <BoxWrapper>
        <CartWrapperCard elevation={2}>
          <StyledTypography>
            Build your bundle
          </StyledTypography>
          <BundleTasks />
        </CartWrapperCard>
        <Hidden mdUp>
          <Box maxWidth="632px" bgcolor="background.paper" p="16px" width="100%" display="flex" justifyContent="center">
            <HappinessGuarantee brandingDetails={brandingDetails} mobile />
          </Box>
        </Hidden>
        <Receipt />
      </BoxWrapper>
      <Box margin="auto" width="100%" display="flex" justifyContent="center" marginTop="24px">
        <Hidden smDown>
          <Box paddingLeft="7px" maxWidth="632px">
            <HappinessGuarantee brandingDetails={brandingDetails} mobile />
          </Box>
        </Hidden>
        <Hidden smDown>
          <Box width="100%" maxWidth="332px" />
        </Hidden>
      </Box>
    </>
  );
}

export default React.memo(BundleEditPageComponent);
