// Uncomment wdyr to see why componenets are re-rendering
// import '../utils/wdyr';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import AppRouter from '../components/AppRouter';
import store, { persistor } from '#/store';
import '../styles/application.scss';
import { brightTagId } from '../utils';

document.addEventListener('DOMContentLoaded', () => {
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }

  ReactDOM.render(
    (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppRouter />
        </PersistGate>
      </Provider>
    ),
    document.body.appendChild(document.createElement('div')),
  );

  // load script for Signal
  const btjs = document.createElement('script');
  const s = document.getElementsByTagName('script')[0];
  btjs.async = true;
  btjs.src = `//s.btstatic.com/tag.js#site=${brightTagId}`;
  s.parentNode.insertBefore(btjs, s);
});
