import { axios } from './request'
import { formatCurrency } from './index.js'

export const defaultMembership = {
  planTemplateId: -1000,
  accessFee: 0,
  cancellationFee: 0,
  discountAmount: 0,
  discountType: '',
  isSelected: null,
  currencyCode: '',
  currencySymbol: '',
  display_name: 'Savings Membership',
}

export function setMembershipSelected(isSelected, membership = defaultMembership) {
  return Object.assign({}, membership, { isSelected: isSelected === true })
}

export function fetchMembership (email, zipcode) {
  return axios.post('api/v1/plan_templates/upsell',
    {
      email: email,
      zipcode: zipcode,
    }
  )
}

export function isMember(email, serviceMachineName, siteBrandKey) {
  return axios.get('/api/v1/plans/is_member',
    {
      params: {
        email,
        service_machine_name: serviceMachineName,
        site_brand_key: siteBrandKey,
      },
    });
}

export function cancelMembership(external_id) {
  return axios.post(`/api/v1/memberships/${external_id}/cancel`)
}

export function setMembershipFromApiResponse(response_body, membership = defaultMembership) {
  return typeof response_body === "undefined" ?
    null :
    Object.assign({}, defaultMembership, membership,
      {
        planTemplateId: response_body.plan_template_id,
        accessFee: response_body.access_fee,
        discountAmount: response_body.discount.amount,
        discountType: response_body.discount.type,
        currencyCode: response_body.currency.code,
        currencySymbol: response_body.currency.symbol,
      }
    )
}

export function formatDiscountAmount(membership) {
  if (membership.discountType === "percent_discount") {
    return `${membership.discountAmount}%`
  } else if (membership.discountType === "fixed_discount") {
    return formatCurrency(membership.currencySymbol, membership.discountAmount)
  }
}

// TODO: currency conversions
export function formatAccessFee(membership) {
  return formatCurrency(membership.currencySymbol, membership.accessFee)
}

// TODO: this should really be handled by the quotes/invoices service
export function discountablePriceFromQuote(quote) {
  try{
    let base_price = quote.quote_breakdown.filter(
      quote_item => quote_item.display_name === "Base Price"
    ).pop()

    return base_price.amount
  }
  catch(e) {
    return 0
  }
}

export function discountablePrice(membership, discountable_price) {
  return parseInt(membership.discountAmount * 0.01 * discountable_price, 10)
}
