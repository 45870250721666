class AccordionSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      openClass: 'accordion-section--closed',
      iconClass: 'fa-plus'
    }
  }

  toggleOpen = () => {
    const { isOpen } = this.state;

    if (isOpen) {
      this.setState({
        isOpen: false,
        openClass: 'accordion-section--closed',
        iconClass: 'fa-plus'
      });
    } else {
      this.setState({
        isOpen: true,
        openClass: 'accordion-section--open',
        iconClass: 'fa-minus'
      });
    }
  }

  render() {
    const { label, text, disclaimer } = this.props;
    const { openClass, iconClass } = this.state;

    return (
      <div className={`accordion-section ${openClass}`} onClick={this.toggleOpen}>
        <div className='grid-x grid-margin-x'>
          <div className='cell small-10'>
            <div className='accordion-section-label'>{label}</div>
          </div>

          <div className='cell small-2 text-right accordion-section-icon'>
            <i className={`fas ${iconClass}`}></i>
          </div>
        </div>

        <div className='grid-x grid-margin-x'>
          <div className='cell small-12'>
            <p className='accordion-section-text' dangerouslySetInnerHTML={{__html: text}}></p>
            {disclaimer ? <p className='accordion-section-text accordion-section-disclaimer'>{disclaimer}</p> : null}
          </div>
        </div>
      </div>
    )
  }
}

export default AccordionSection
