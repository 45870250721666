import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography, styled } from '@material-ui/core';
import PlusMinus from '../icons/PlusMinus';

const StyledButton = styled(Button)({
  height: '100%',
});

const StyledTypography = styled(Typography)({
  width: '100%',
});

export default function Counter({
  text,
  onIncrement,
  onDecrement,
  decrementDisabled,
  incrementDisabled,
}) {
  const iconColor = (disabled) => (disabled ? 'disabled' : 'action');
  const minusColor = iconColor(decrementDisabled);
  const plusColor = iconColor(incrementDisabled);
  return (
    <Box width="100%" justifyContent="space-between" display="flex" height="56px" alignItems="center">
      <StyledButton disabled={decrementDisabled} onClick={onDecrement}>
        <PlusMinus color={minusColor} minus />
      </StyledButton>
      <StyledTypography display="block" variant="body2">
        {text}
      </StyledTypography>
      <StyledButton disabled={incrementDisabled} onClick={onIncrement}>
        <PlusMinus color={plusColor} disabled={incrementDisabled} />
      </StyledButton>
    </Box>
  );
}

Counter.propTypes = {
  decrementDisabled: PropTypes.bool.isRequired,
  incrementDisabled: PropTypes.bool.isRequired,
  onDecrement: PropTypes.func.isRequired,
  onIncrement: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};
