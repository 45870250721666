import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import useStyles from './styles';
import { useTheme } from '@material-ui/core/styles';
import { Typography, Link, IconButton } from '@material-ui/core';
import ErrorIcon from '../icons/ErrorIcon';
import CloseIcon from '../icons/CloseIcon';

export default function ErrorBanner({ message }) {
  const { name: theme } = useTheme();
  const classes = useStyles({ theme });
  const [isOpen, toggleDisplay] = useState(true);
  const handleCloseClick = () => {
    toggleDisplay(!isOpen)
  };

  const brandingDetails = useSelector(state => state.brandingDetails);
  // TODO: Replace angi link with actual link. Just a stand in
  const supportLink = brandingDetails.isAngi ? 'www.angi.com' : 'https://homeadvisor.zendesk.com/hc/en-us/requests/new'
  const fullMessage = `${message} Please contact`
  
  const closeIconStyle = {
    color: '#FFFFFF',
    height: '20px',
    width: '20px',
  }

  if (!isOpen) return null;

  return (
    <div className={classes.errorBanner}>
      <div className={classes.errorMessage}>
        <div className={classes.errorIcon}>
          <ErrorIcon />
        </div>
        <Typography variant='body1' component='div'>{fullMessage} <Link href={supportLink} className={classes.errorLink}>customer service</Link> for further assistance.</Typography>
      </div>
      <IconButton className={classes.errorButton} onClick={handleCloseClick}>
        <CloseIcon style={closeIconStyle} />
      </IconButton>
    </div>
  )
}

ErrorBanner.propTypes = {
  message: PropTypes.string.isRequired,
}
