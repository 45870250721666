import React from 'react';
import { extrasMapping } from '../../utils/extras';

class ExtrasList extends React.Component {
  renderExtra = (extraMachineName, index) => {
    const extraFound = extrasMapping[extraMachineName];
    if (!_.isUndefined(extraFound)) {
      return (
        <li key={index} className='quotes-extras-list-item'>
          {extraFound.text}
        </li>
      )
    }
  }

  render() {
    const {
      extrasAdded,
    } = this.props;

    return (
      <ul className='quote-extras-list'>
        {
          extrasAdded.map((extra, index) => {
            return (this.renderExtra(extra.name, index));
          })
        }
      </ul>
    );
  }
}

export default ExtrasList;
