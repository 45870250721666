import { createMuiTheme } from '@material-ui/core';
import baseTheme from './base';
import scssVariables from '#/styles/_vars.scss';

const {
  baseWhite,
  haBorderRadius,
  haButtonBlue,
  haButtonOrange,
  haDarkBlue,
  haGrayDark,
  haGrayLight,
  haGrayText,
  haDarkGrayBackground,
  angiDarkGrayBackground2,
  haFontColor,
  haKeyBackground2,
  haPrimary,
} = scssVariables;

const homeAdvisorTheme = createMuiTheme({
  name: 'homeAdvisor',
  customPalette: {
    angiDarkGrayBackground2,
    haDarkGrayBackground,
    haGrayDark,
    haGrayLight,
    haKeyBackground2,
    haPrimary,
    white: baseWhite,
  },
  palette: {
    primary: {
      light: haButtonBlue,
      main: haButtonBlue,
      dark: haDarkBlue,
      contrastText: baseWhite,
    },
    secondary: {
      light: haButtonOrange,
      main: haButtonOrange,
      dark: haButtonOrange,
      contrastText: baseWhite,
    },
    success: {
      main: '#00C785',
    },
    text: {
      primary: haDarkBlue,
      secondary: haGrayText,
      base: haFontColor,
    },
  },
  shape: {
    borderRadius: haBorderRadius,
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: haBorderRadius,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: haBorderRadius,
      },
    },
    MuiFormLabel: {
      root: {
        color: haDarkBlue,
      },
    },
    MuiButton: {
      root: {
        border: '2px',
      },
      outlined: {
        '&:hover': { border: '2px solid' },
        border: '2px solid',
      },
      outlinedPrimary: {
        '&:hover': { border: '2px solid' },
        border: '2px solid',
      },
    },
  },
},
baseTheme);

export default homeAdvisorTheme;
