import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { get, some, filter, includes, isEqual, sortBy } from 'lodash';
import { servicesSelectors } from '#/reducers/services';
import { currentTasksServiceMachineNamesSelector } from './bundles';

const {
  selectIds: selectServiceIds,
  selectAll: selectServiceAll,
  selectEntities: selectServiceEntities,
  selectTotal: selectServiceTotal,
  selectById: selectServiceById,
} = servicesSelectors;

const createSelectServiceById = (serviceMachineName) => (state) => {
  return (state.services.entities[serviceMachineName]);
};

const selectIsServiceCartError = () => createSelector(
  (state) => state.services.entities,
  (state) => state.cart.serviceIds,
  (services, cartServiceIds) => {
    const isInCart = ({ serviceMachineName }) => includes(cartServiceIds, serviceMachineName);
    const servicesInCart = filter(services, isInCart);
    return some(servicesInCart, (service) => service.errors?.length);
  },
);

const createSelectQuestionsByServiceId = (serviceMachineName) => createSelector(
  (state) => state.services.entities[serviceMachineName].form?.ids || [],
  (ids) => ids.map((machineName) => ({ serviceMachineName, machineName })),
);

const createSelectHasDurationByServiceId = (serviceMachineName) => createSelector(
  (state) => state.services.entities[serviceMachineName],
  (service) => get(service, 'durationOverride.options.length', 0) > 0,
);

const createSelectDurationByServiceId = (serviceMachineName) => createSelector(
  (state) => state.services.entities[serviceMachineName],
  ({ durationOverride }) => durationOverride,
);

const createSelectRecurrenceByServiceId = (serviceMachineName) => createSelector(
  (state) => state.services.entities[serviceMachineName],
  ({ recurrence }) => recurrence,
);

const createSelectHasFrequencyByServiceId = (serviceMachineName) => createSelector(
  (state) => state.services.entities[serviceMachineName],
  (service) => get(service, 'frequency.options.length', 0) > 1,
);

const createSelectBusinessHoursByServiceId = (serviceMachineName) => createSelector(
  (state) => state.services.entities[serviceMachineName],
  (service) => service.businessHours,
);

const createSelectErrorsByServiceId = (serviceMachineName) => createSelector(
  (state) => state.services.entities[serviceMachineName],
  (service) => service.errors || [],
);

const asMoment = (dateTime) => moment(dateTime, 'YYYY-MM-DD HH:mm');
const createSelectBusinessHoursAsMomentByServiceId = (serviceMachineName) => createSelector(
  createSelectBusinessHoursByServiceId(serviceMachineName),
  ({ soonestBookableTime, value }) => ({
    soonestBookableTime: asMoment(soonestBookableTime),
    value: asMoment(value),
  }),
);

const selectSelf = (state) => state.services;

const serviceEntitiesSelector = createSelector(
  selectSelf,
  ({ entities }) => entities,
);

const serviceIdsSelector = createSelector(
  selectSelf,
  ({ ids }) => ids,
);

const isLoadingNewServicesSelector = createSelector(
  serviceIdsSelector,
  currentTasksServiceMachineNamesSelector,
  (loadedServices, servicesToLoad) => {
    const bundleOrder = (a, b) => servicesToLoad.indexOf(a) - servicesToLoad.indexOf(b);
    const currentServices = sortBy(loadedServices, bundleOrder);

    return !isEqual(servicesToLoad, currentServices);
  },
);

export {
  createSelectBusinessHoursAsMomentByServiceId,
  createSelectBusinessHoursByServiceId,
  createSelectDurationByServiceId,
  createSelectErrorsByServiceId,
  createSelectHasDurationByServiceId,
  createSelectHasFrequencyByServiceId,
  createSelectQuestionsByServiceId,
  createSelectRecurrenceByServiceId,
  createSelectServiceById,
  selectIsServiceCartError,
  selectServiceAll,
  selectServiceById,
  selectServiceEntities,
  selectServiceIds,
  selectServiceTotal,
  serviceEntitiesSelector,
  isLoadingNewServicesSelector,
};
