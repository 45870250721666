import TextInput from '../shared/TextInput'
import PhoneInputSection from '../shared/PhoneInputSection'

class YourAddress extends React.Component {
  render() {
    const { h3font, enablePlanEngagement, handleInputChange, getInputPlaceholder, user, brandingDetails } = this.props

    return (
      <div className='payment-page-form-section'>
        <h3 className={h3font}>Your Address</h3>

        <div className='payment-page-form-row'>
          <div className='grid-x grid-margin-x address-form-row'>
            <div className='address-form-input'>
              <TextInput
                name='First Name'
                handler={handleInputChange}
                placeholderHandler={getInputPlaceholder}
                value={user.firstName}
                brandingDetails={brandingDetails}
              />
            </div>
            <div className='address-form-input'>
              <TextInput
                name='Last Name'
                handler={handleInputChange}
                placeholderHandler={getInputPlaceholder}
                value={user.lastName}
                brandingDetails={brandingDetails}
              />
            </div>
          </div>
        </div>

        <div className="payment-page-form-row">
          <div className="grid-x grid-margin-x address-form-row">
            <div className="address-form-input">
              <TextInput
                name="Street Address"
                handler={handleInputChange}
                placeholderHandler={getInputPlaceholder}
                size="full"
                value={user.streetAddress}
                brandingDetails={brandingDetails}
              />
            </div>
            <div className="address-form-input">
              <PhoneInputSection
                name="mobilePhone"
                placeholder="Mobile Phone"
                handleChange={handleInputChange}
                value={user.mobilePhone}
                required={true}
                brandingDetails={brandingDetails}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default YourAddress
