import classnames from 'classnames'

class RadioOptions extends React.Component {
  render() {
    const { selected, options, header, description, subTexts, enablePlanEngagement } = this.props;

    const optionInputs = options.map((option, key) => {
      const isChecked = (selected === option.machine_name);
      const optionClasses = () => {
        if (enablePlanEngagement) {
          return 'cell column-padding small-6 medium-6 large-3';
        }
        if (options.length === 3) {
          return 'cell flex-center small-12 medium-6 large-4';
        }
        return 'cell small-12 medium-6 large-3';
      };
      const optionClassesCss = classnames(
        optionClasses(),
        'radio-subdomain'
      )
      const radioOptionCss = classnames(
        'radio-option',
        {
          'radio-option--checked': isChecked,
          'radio-with-subtitle': enablePlanEngagement,
          'angi': this.props.brandingDetails.isAngi
        }
      )
      return (
        <div key={key} className={optionClassesCss}>
          <label key={key} className={radioOptionCss}>
            {_.capitalize(option.display_name)}
            { subTexts ? (
              <div>{subTexts[key]}</div>
            ): null }
            <input
              id={option.display_name}
              value={option.machine_name}
              type='radio'
              checked={isChecked}
              onChange={this.props.handleChange}
            />
          </label>
        </div>
      );
    })

    const radioSectionContainerGridCss = classnames(
      'grid-x', 'grid-margin-x', 'custom-radio',
      { 'angi': this.props.brandingDetails.isAngi }
    )


    const radioSectionContainer = classnames(
      {'angi': this.props.brandingDetails.isAngi}
    )
    return (
      <div className='payment-page-form-section radio-section-container'>
        <h3 className={radioSectionContainer}>{header}</h3>
        <div className='margin-bottom-1'>{description}</div>
        <div className={radioSectionContainerGridCss}>
          {optionInputs}
        </div>
      </div>
    );
  }
}

export default RadioOptions
