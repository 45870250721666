import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    position: 'relative',
    zIndex: 1,
    boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 8%), 0px 8px 10px 1px rgb(0 0 0 / 4%), 0px 3px 14px 2px rgb(0 0 0 / 2%)',
  },
  media: {
    height: '100%',
    width: '72px',
  },
  drawer: {
    marginTop: '-16px',
    position: 'relative',
    zIndex: 0,
  },
  boldText: {
    fontWeight: '600',
  },
}));

export default useStyles;
