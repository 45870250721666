import { omit, pick } from 'lodash';

const initialState = {
  serviceIds: {},
  pendingServiceIds: {},
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case 'bundles/addPendingServiceToCart':
      return {
        ...state,
        pendingServiceIds: { ...state.pendingServiceIds, [action.payload]: action.payload },
      };
    case 'bundles/clearServicesNotInBundle': {
      const idsToSave = action.payload;
      return {
        ...state,
        serviceIds: pick(state.serviceIds, idsToSave),
        pendingServiceIds: pick(state.pendingServiceIds, idsToSave),
      };
    }
    case 'bundles/removePendingServiceFromCart':
      return {
        ...state,
        pendingServiceIds: omit(state.pendingServiceIds, action.payload),
      };
    case 'bundles/addPendingServicesToCart':
      return {
        ...state,
        pendingServiceIds: action.payload.reduce(
          (serviceIds, id) => ({ ...serviceIds, [id]: id }),
          {},
        ),
      };
    case 'quotes/quoteHasBeenSet':
      return {
        ...state,
        pendingServiceIds: omit(state.pendingServiceIds, action.payload.serviceName),
      };
    case 'ADD_TO_CART':
      return {
        ...state,
        serviceIds: { ...state.serviceIds, [action.payload]: action.payload },
      };
    case 'REMOVE_FROM_CART':
      return {
        ...state,
        serviceIds: omit(state.serviceIds, action.payload),
      };
    case 'CLEAR_SERVICES':
      return { initialState };
    default:
      return state;
  }
}
