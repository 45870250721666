import { Button, styled } from '@material-ui/core';

const CtaButton = styled(Button)(({ theme: { name, breakpoints } }) => ({
  borderRadius: name === 'angi' ? '4px' : '100px',
  width: '100%',
  height: '48px',
  maxWidth: '328px',
  fontSize: '1rem',
  fontWeight: 'bold',
  [breakpoints.down('sm')]: {
    maxWidth: '100%',
  },
}));

export default CtaButton;
