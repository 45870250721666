import ExistingCCImg from '../../images/a-credit_card-Generic'

class PrefilledCardInfo extends React.Component {
  render() {
    const { user, handleEditClick } = this.props
    return (
      <div className="payment-page-form-row padding-bottom-1em">
        <img
          alt="Existing credit card"
          src={ExistingCCImg}
        />
        <span className="padding-left-0-5em">*********** {user.ccLast4}</span>
        <a className="padding-left-2em" onClick={handleEditClick}>Edit</a>
      </div>
    )
  }
}

export default PrefilledCardInfo
