function serviceIncluded(serviceMachineName) {
  const serviceMachineNames = (document.documentElement.dataset.planEngagementServiceMachineNames || '').split(',');
  const allEnabled = serviceMachineNames.length === 1 && serviceMachineNames[0].toLowerCase() === 'all';
  return allEnabled || serviceMachineNames.includes(serviceMachineName);
}

export function enablePlanEngagement(serviceMachineName) {
  const enablePlanEngagementSetting = document.documentElement.dataset.enablePlanEngagement === 'true';

  return (enablePlanEngagementSetting && serviceIncluded(serviceMachineName));
}

export function isExperiment(serviceMachineName, brandingDetails = {}, isMember = false, isVariant = false) {
  return enablePlanEngagement(serviceMachineName) && isMember && !(brandingDetails || {}).isAngi && isVariant;
}
