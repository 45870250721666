import React, {Fragment} from 'react';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useStyles from '../cart/receipt/styles';
import ServiceLineItem from '../cart/receipt/ServiceLineItem';

export default function ReceiptServiceList({ services, currency }) {
  const { name: theme } = useTheme();
  const classes = useStyles({ theme });
  return (
    <ul className={classes.alwaysList}>
      {
        services.map((service, index) => {
          return (
            <Fragment key={index}>
              <ServiceLineItem currency={currency} service={service} />
            </Fragment>
          );
        })
      }
      <div className={classes.alwaysReceiptBreak} />
    </ul>
  );
}

ReceiptServiceList.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape({
    basePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    duration: PropTypes.string,
    frequency: PropTypes.string,
    date: PropTypes.string,
    quoteLineItems: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      category: PropTypes.string,
      display_name: PropTypes.string,
    })),
  })).isRequired,
  currency: PropTypes.string.isRequired,
}
