import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import { selectServiceById } from '#/selectors/services';
import Task from '.';

const priceCentsToUsd = (priceCents) => (priceCents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });

const createSelectPriceByServiceId = (serviceMachineName) => createSelector(
  (state) => selectServiceById(state, serviceMachineName).priceCents,
  priceCentsToUsd,
);

const createSelectTitleByServiceId = (serviceMachineName) => createSelector(
  (state) => selectServiceById(state, serviceMachineName),
  ({ name, shortDisplayName }) => {
    if (name.length > 25) {
      return shortDisplayName;
    }
    return name;
  },
);

function TaskWithState({ id, index, openNextTask, openTask }) {
  const selectPriceByServiceId = createSelectPriceByServiceId(id);
  const selectTitleByServiceId = createSelectTitleByServiceId(id);
  const price = useSelector(selectPriceByServiceId);
  const title = useSelector(selectTitleByServiceId);

  return (
    <Task id={id} title={title} price={price} index={index} openNextTask={openNextTask} openTask={openTask} />
  );
}

TaskWithState.propTypes = {
  id: PropTypes.string.isRequired,
  index: PropTypes.number,
  openNextTask: PropTypes.func,
  openTask: PropTypes.number,
};
TaskWithState.defaultProps = {
  index: null,
  openTask: null,
};

export default React.memo(TaskWithState);
