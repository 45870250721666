import { discountablePriceFromQuote, formatDiscountAmount, formatAccessFee } from '#/utils/memberships';
import { formatCurrency } from '#/utils/'
import Modal from '../shared/Modal';
import MembershipLearnMore from './MembershipLearnMore'
import MembershipSelectionDescription from './MembershipSelectionDescription';
import { Mixpanel } from '../../utils/analytics'
import classnames from 'classnames'

const mixpanel = new Mixpanel()
const track = mixpanel.tracker()

class MembershipSelection extends React.Component {
  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this)

    this.state = {
      isModalVisible: false,
    };
  };

  openModal = () => {
    this.setState({ isModalVisible: true })
    track('membership_learn_more_clicked', {
      event_context: 'booking_funnel',
      checkout_plan_engagement_variant: this.props.enablePlanEngagementVariant,
    });
  };

  render() {
    const { paymentAfterComplete, membership, quote, membershipDiscount } = this.props
    const discountablePrice = discountablePriceFromQuote(quote)
    const isAngi = this.props.brandingDetails.isAngi
    const radioLabel = classnames(
      'radio-label',
      {
        angi: isAngi
      }
    );
    const membershipTextColor = classnames({
      green: !isAngi,
      angiTextColor: isAngi
      }
    )
    if (discountablePrice <= 0) {
      return null
    }

    const membershipText = 'Angi Key membership';
    const upsellYesCopyFragment = (
      <React.Fragment>
        <span className='invalid-field' />
        <span className={radioLabel}>
          <strong> Yes! Sign me up for <span className={membershipTextColor}>{membershipText}</span></strong>
          <ul className="unstyled-list padding-top-0-5em padding-left-2em">
            <li className="padding-bottom-0-5em"><span className='fa fa-check green padding-right-0-5em'></span>Save {formatDiscountAmount(membership)} on 100+ services booked and paid for on {this.props.brandingDetails.displayName}.com </li>
            <li className="padding-bottom-0-5em"><span className='fa fa-check green padding-right-0-5em'></span>{paymentAfterComplete ? `Pay ${formatAccessFee(membership)} today for an` : formatAccessFee(membership)} annual membership </li>
            <li className="padding-bottom-0-5em"><span className='fa fa-check green padding-right-0-5em'></span>Cancel anytime</li>
          </ul>
        </span>
      </React.Fragment>
    )

    const optionDefs = [{
      value: true,
      label: upsellYesCopyFragment
    }, {
      value: false,
      label: (<span className={radioLabel}>No thanks</span>)
    }];

    const purchaseText = paymentAfterComplete? 'this job' : 'today\'s purchase'
    return (
      <fieldset className='input margin-bottom-1em'>
        <legend>
          <MembershipSelectionDescription
            discount={formatCurrency(membership.currencySymbol, membershipDiscount)}
            purchaseText={purchaseText}
            amount={formatDiscountAmount(membership)}
            brand={this.props.brandingDetails.displayName}
            openModal={this.openModal}
          />
          <div>
            <Modal
              isModalVisible={this.state.isModalVisible}
              closeModal={() => this.setState({ isModalVisible: false })}>
              <MembershipLearnMore membership={membership} brandName={this.props.brandingDetails.displayName} />
            </Modal>
          </div>
        </legend>
        {
          optionDefs.map((option) =>
            <label key={option.value} className="radio bb-1 padding-bottom-1em margin-bottom-1em">
              <input
                type='radio'
                name='membership-opt-in'
                className="form-control"
                value={option.value}
                checked={this.props.membership.isSelected === option.value}
                onChange={this.props.onMembershipSelectionChange(option.value)}
                required
              />
              {option.label}
            </label>
          )
        }
      </fieldset>
    )
  }
}

export default MembershipSelection
