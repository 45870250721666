import React from 'react';
import PropTypes from 'prop-types';

class Extra extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: false,
    };
  }

  handleCheck = (extraMachineName) => {
    const { checked } = this.state;
    const { handleAddExtra } = this.props;
    this.setState({ checked: !checked });
    handleAddExtra(extraMachineName);
  }

  render() {
    const {
      extra,
      extraMachineName,
    } = this.props;

    const { checked } = this.state;
    const imageSrc = checked ? extra.assetSelected : extra.asset;

    return (
      <div className={`extra-content ${checked ? 'extra-content-selected': ''}`}>
        <label htmlFor={`checkbox-${extraMachineName}`} className='extra-label'>
          <div className='extra-asset'>
            <img src={imageSrc} />
          </div>
          <div className={`extra-text ${checked ? 'extra-text-selected' : ''}`}>
            {extra.text}
          </div>
        </label>
        <input id={`checkbox-${extraMachineName}`} type='checkbox' className='extra-input' value={checked} onClick={() => {this.handleCheck(extraMachineName)}}/>
      </div>
    );
  }
}

Extra.propTypes = {
  extra: PropTypes.object.isRequired,
  extraMachineName: PropTypes.string.isRequired,
  handleAddExtra: PropTypes.func.isRequired,
};

export default Extra;
