import React from 'react';

export default function CreditCardPayment() {
  return (
    <svg width="39" height="32" viewBox="0 0 39 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4.55713" y="7" width="20" height="13" rx="1" stroke="#282827" strokeWidth="2"/>
      <rect x="7.55713" y="10" width="5" height="3" rx="1" fill="#282827"/>
      <rect x="7.55713" y="15" width="14" height="2" rx="1" fill="#282827"/>
      <path d="M8.55713 21V23C8.55713 24.1046 9.45256 25 10.5571 25H26.5571C27.6617 25 28.5571 24.1046 28.5571 23V14C28.5571 12.8954 27.6617 12 26.5571 12H24.5571" stroke="#282827" strokeWidth="2"/>
    </svg>
  );
}
