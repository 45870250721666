const brands = ['angi', 'homeadvisor'];

const acronym = {
  homeadvisor: 'HA',
  angi: 'Angi',
};

const displayName = {
  homeadvisor: 'HomeAdvisor',
  angi: 'Angi',
};

const partnerId = {
  homeadvisor: 'home_advisor',
  angi: 'angi',
};

const siteBrandKeys = {
  angi: 'ANGI',
  homeadvisor: 'HOME_ADVISOR',
};

const homeAdvisor = 'homeadvisor';

const validSubDomainBrand = (params) => {
  const qs = new URLSearchParams(params);
  const branding = qs.get('branding');
  return brands.includes(branding) ? branding : false;
};

const brandFromUrl = (url) => {
  const subDomain = validSubDomainBrand(url.search);
  if (subDomain) {
    return subDomain;
  }
  // this code could be remove once we can get the host from headers
  let result = brands.find((domain) => url.host.includes(domain));
  if (!result) {
    result = homeAdvisor;
  }
  return result;
};

const isAngi = (brand) => brand === 'angi';

export default {
  validSubDomainBrand,
  brandFromUrl,
  acronym,
  siteBrandKeys,
  isAngi,
  homeAdvisor,
  displayName,
  partnerId,
};
