const sizeLookup = {
  'half': {
    'textWidth': 'large-6',
    'inputWidth': 'large-6',
  },
  'full': {
    'textWidth': 'large-3',
    'inputWidth': 'large-12',
  }
}

TextInput.defaultProps = {
  name: 'Give me a name!',
  // inputTypes: 'color' 'date' 'datetime-local' 'email' 'month' 'number' 'range'
  //             'search' 'tel' 'text' 'time' 'url' 'week'
  inputType: 'text',
  size: 'full',
}

function TextInput(props) {
  const isAngi = props.brandingDetails && props.brandingDetails.isAngi

  return (
      <div>
        <div className={`cell ${sizeLookup[props.size].inputWidth}`}>
          <input
            placeholder={ props.name }
            name={_.camelCase(props.name)}
            className={`form-control form-input form-input--full ${props.classNames} ${isAngi ? 'angi' : ''}`}
            type={props.inputType}
            onChange={props.handler}
            value={props.value}
            required />
          <span className='invalid-field' />
        </div>
      </div>
  )
}

export default TextInput
