import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const DeepCleanHomeCleaning = ({ style }) => {
  // Brand information
  const brandingDetails = (useSelector((state) => state.brandingDetails));
  const { isAngi } = brandingDetails;

  return (
    <div>
      { isAngi
        ? (
          <svg style={style} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28 16.471V13.9414H20V16.471C20 16.9137 19.902 17.3509 19.7131 17.7513L16.2869 25.0115C16.098 25.4118 16 25.8491 16 26.2918V41.0004C16 42.6573 17.3431 44.0004 19 44.0004H29C30.6569 44.0004 32 42.6573 32 41.0004V26.2918C32 25.8491 31.902 25.4118 31.7131 25.0115L28.2869 17.7513C28.098 17.3509 28 16.9137 28 16.471Z" fill="#FF6153" />
            <path d="M19.9995 10.6074H28.0003V16.9406C26.4141 15.0749 24.0887 13.9997 21.6399 13.9997H19.9995V10.6074Z" fill="#A03027" />
            <path d="M26 29H16V37H26V29Z" fill="#A03027" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20 6C20 9.26568 17.2657 12 14 12V14C18.4183 14 22 10.4183 22 6H20Z" fill="#FF6153" />
            <path d="M14.2668 7.33366H15.3335V4.66699H14.2668C14.0945 4.66699 13.9277 4.7274 13.7953 4.83769L13.3841 5.18027C13.1407 5.38308 13 5.68352 13 6.00033C13 6.31715 13.1407 6.6176 13.3841 6.8204L13.7953 7.16297C13.9277 7.27326 14.0945 7.33366 14.2668 7.33366Z" fill="#FF6153" />
            <path d="M32 12V7C32 5.34315 30.6569 4 29 4H15V7.00392C15 8.03829 15.5329 8.9997 16.41 9.54792L19.6037 11.544C20.0805 11.842 20.6315 12 21.1937 12H32Z" fill="#FF6153" />
          </svg>
        )
        : (
          <svg style={style} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M21.506 16.8772L17.993 24.1742C17.765 24.6552 17.646 25.1812 17.646 25.7142V41.8482H30.571V25.7142C30.571 25.1812 30.452 24.6552 30.224 24.1742L26.645 16.8242" fill="#D5DBF1" />
            <path d="M21.506 16.8772L17.993 24.1742C17.765 24.6552 17.646 25.1812 17.646 25.7142V41.8482H30.571V25.7142C30.571 25.1812 30.452 24.6552 30.224 24.1742L26.645 16.8242" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M26.8019 10.8623V17.4013H21.3489V12.1573" fill="#15123F" />
            <path d="M26.8019 10.8623V17.4013H21.3489V12.1573" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M31.2579 9.37198C27.5479 9.37498 22.8909 12.521 18.9359 12.245C18.1459 12.245 17.4999 11.599 17.4999 10.808V7.93598C17.4999 7.14598 18.1459 6.50098 18.9359 6.50098H31.2579V9.37198Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M31.2579 9.37198C27.5479 9.37498 22.8909 12.521 18.9359 12.245C18.1459 12.245 17.4999 11.599 17.4999 10.808V7.93598C17.4999 7.14598 18.1459 6.50098 18.9359 6.50098H31.2579V9.37198Z" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M26.755 10.2725C26.755 10.2725 27.748 13.5555 30.83 14.9555" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
          </svg>
        )}
    </div>

  );
};
DeepCleanHomeCleaning.propTypes = {
  style: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
};

DeepCleanHomeCleaning.defaultProps = {
  style: {
    height: '22.6px',
    width: '23px',
  },
};
export default DeepCleanHomeCleaning;
