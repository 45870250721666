/* eslint-disable */
import mixpanel from 'mixpanel-browser'
import Cookies from 'universal-cookie'
import { getDeviceType } from '.'

const dataset = document.documentElement.dataset
const domain = document.location.href
const device = dataset.device
const browser_uid = dataset.browserUid
const mixpanelToken = dataset.mixpanelToken
const session_id = dataset.sessionId
const transaction_id = dataset.currentTransactionId
const platform = 'web'
const product_type = 'consumer'
const partner_source = 'home_advisor'
const event_context = 'booking_funnel'

const throttling = true
const waitTime = 500

mixpanel.init(mixpanelToken)

class Mixpanel {
  constructor(config = {}) {
    // mixpanel.register sets super properties that should
    // get sent to mixpanel with every event
    mixpanel.register(config)
    this.mixpanel = mixpanel
  }

  register(config = {}) {
    mixpanel.register(config)
  }

  tracker(customConfig = {}) {
    const defaultConfig = {
      domain,
      platform,
      device,
      browser_uid,
      session_id,
      transaction_id,
      product_type,
      partner_source,
      event_context,
      throttling,
    }

    const config = _.assign({}, defaultConfig, customConfig)
    const mixpanelProperties = _.omit(config, 'throttling')

    if (config.throttling) {
      return _.throttle((eventName, instanceProps = {}) => {
        const props = _.assign({}, mixpanelProperties, instanceProps)

        this.mixpanel.track(eventName, props)
      }, waitTime, { trailing: false })
    } else {
      return (eventName, instanceProps = {}) => {
        const props = _.assign({}, mixpanelProperties, instanceProps)

        this.mixpanel.track(eventName, props)
      }
    }
  }
}

/**
 * xxx: wraps all trackers
 */
class Analytics {
  constructor(config = { mixpanelConfig: {}, omnitureConfig: {} }) {
    const { mixpanelConfig, omnitureConfig } = config

    this.trackers = [
      new Mixpanel(mixpanelConfig),
    ]
  }

  track(eventName) {
    this.trackers.forEach(x => x.tracker()(eventName))
  }

}

class Omniture {
  pageNameMappings =  {
    home_cleaning: {
      page_name: "Maid Service",
    },

    other_handyman_service: {
      page_name: "Handyman"
    },
  };

  track({
    serviceMachineName,
    pageName,
    entryPointId,
    extFulTrack,
    events,
    prop58,
    taskoid,
    zipcode,
    catchall,
    utm_source,
    utm_campaign,
    angiKeyMember,
    userTypeLabel
  }) {
    const mappedPageName = _.get(this.pageNameMappings, [`${serviceMachineName}`, 'page_name'], serviceMachineName);
    const servicePageName = `${pageName}: ${mappedPageName}`;

    s.pageName = servicePageName;
    s.prop1 = window.location.href;
    s.prop2 = window.location.href;
    s.prop3 = window.location.host;
    s.prop7 = getDeviceType();
    s.prop8 = `${screen.width}x${screen.height}`;
    s.eVar129 = userTypeLabel;
    if(taskoid) {
       s.prop21 = taskoid
    }
    if(zipcode) {
      s.prop24 = zipcode
    }
    s.prop40 = pageName;
    s.prop47 = new Date().toLocaleDateString('en-US');
    if(catchall) {
      s.prop48 = catchall;
    }
    if(prop58) {
      s.prop58 = prop58
    }
    s.eVar64 = entryPointId;
    if(utm_source) {
      s.eVar69 = utm_source
    }
    if(utm_campaign) {
      s.eVar108 = utm_campaign
    }
    s.eVar111 = extFulTrack;
    if(angiKeyMember)
    {
      s.eVar118 = "Angi Key Member";
    }
    if (events) {
      s.events = events
    }
    s.t();
  };

  track_link(linkText, isImmediate = true, linkType = 'o') {
    s.tl(isImmediate, linkType, linkText);
  };
};

export { Mixpanel, Analytics, Omniture }
