import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import AngiKey from '../shared/icons/AngiKey';
import useStyles from '../cart/receipt/styles';
import classNames from 'classnames';

export default function MembershipSelectionDescription({ discount, purchaseText, amount, brand, openModal }) {
  const { name: theme } = useTheme();
  const classes = useStyles({ theme });
  const paraWithKeyClasses = classNames(
    'margin-0em',
    classes.keyLineItem
  );

  return (
    <>
      <p className={paraWithKeyClasses}><AngiKey style={{ height: '32px', width: '32px' }} /><strong>Angi Key members save {discount} on {purchaseText}</strong></p>
      <p className="margin-top-0em">Join to save {amount} on {purchaseText} and 100+ additional services booked and paid for on {brand}. <a onClick={openModal}>Learn More</a></p>
    </>
  )
}
