import { Card, styled } from '@material-ui/core';
import React from 'react';

const DrawerCard = styled(
  // eslint-disable-next-line react/jsx-props-no-spreading
  ({
    isBlurred,
    ...rest
  }) => <Card {...rest} />,
)(
  ({
    theme,
    isBlurred
  }) => ({
    background: theme.palette.background.default,
    filter: isBlurred ? 'blur(2px)' : 'none',
  }),
);

export default DrawerCard;
