import Modal from '../shared/Modal';
import PriceGuideBreakdown from './PriceGuideBreakdown'
import PriceGuideIcon from '../../images/guide-icon';

class PriceGuide extends React.Component {
  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this)

    this.state = {
      isModalVisible: false,
    };
  };

  openModal = () => {
    this.setState({isModalVisible: true})
  };

  render() {
    return (
      <div className='card trueprice-card'>
        <div className='trueprice-card-section bottom-border'>
          <div className='trueprice-card-heading'>
            <img className='trueprice-card-icon' src={PriceGuideIcon} />
            <span className='trueprice-card-heading-text'>Buy Now!</span>
          </div>
          <div className='trueprice-card-text-green'>Price is {this.props.percent} below national average.</div>
        </div>
        <div className='trueprice-card-section'>
          <div>Based on actual project cost data reported by {this.props.brandingDetails.displayName} members. <a className="modal-open" onClick={this.openModal}>More</a></div>
        </div>
        <Modal
          isModalVisible={this.state.isModalVisible}
          closeModal={() => this.setState({isModalVisible: false})}>
          <PriceGuideBreakdown brandingDetails={this.props.brandingDetails}/>
        </Modal>
      </div>
    )
  };
};

export default PriceGuide;
