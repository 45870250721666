import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import ConfirmationMsg from './ConfirmationMsg';
import { useGetProviderByExternalIdQuery } from '#/apiServices';

const cookies = new Cookies();

function ConfirmationMsgContainer({ quoteDate }) {
  const formattedDate = moment(quoteDate).format('MMM D, YYYY [at] h:mm a');
  const externalId = cookies.get('external_provider_id');
  useGetProviderByExternalIdQuery({ externalId });
  const provider = useSelector(({ providers: { entities } }) => entities[externalId]);

  if (!provider) return null;

  const {
    firstName,
    lastName,
    profilePhotoUrl,
  } = provider;

  const name = `${firstName} ${lastName}`;

  return (
    <ConfirmationMsg
      quoteDate={formattedDate}
      name={name}
      profilePhotoUrl={profilePhotoUrl}
    />
  );
}
ConfirmationMsgContainer.propTypes = {
  quoteDate: PropTypes.string.isRequired,
};

export default ConfirmationMsgContainer;
