import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const providersAdapter = createEntityAdapter({
  selectId: ({ externalId }) => externalId,
});

const providersSlice = createSlice({
  name: 'providers',
  initialState: providersAdapter.getInitialState(),
  reducers: {
    providerHasBeenSet: providersAdapter.setOne,
  },
});

export const providersActions = providersSlice.actions;
export const providersReducer = providersSlice.reducer;
export const providersSelectors = providersAdapter.getSelectors((state) => state.provider);

export default providersReducer;
