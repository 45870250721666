import { CardNumberElement, CardExpiryElement, CardCVCElement, CardElement} from 'react-stripe-elements'
import { mobileMaxWidthSize } from '../../utils'
import classnames from 'classnames'
import PaymentAfterComplete from './PaymentAfterComplete';
import CreditCardPayment from '../shared/icons/CreditCardPayment';

class BillingInfoV2 extends React.Component {
  render() {
    const { membership, style, showCancelEdit, onCancelEditClick, paymentAfterComplete } = this.props
    const paymentPageFormRow = classnames(
      'payment-page-form-row',
      'padding-bottom-1em',
      {angi: this.props.isAngi}
    )

    const paymentPageCardFieldLock = classnames(
      'credit-card-payment',
      {'angi': this.props.isAngi}
    )

    const paymentPageCardField = classnames(
      'form-input',
      'form-input--full',
      'card-field',
      {'angi': this.props.isAngi}
    )

    const isMobile = window.screen.width <= mobileMaxWidthSize;
    return(
      <React.Fragment>
        <div className="payment-page-form-row padding-bottom-0.5em">
          {membership &&
            <h3>Billing</h3>
          }
          {paymentAfterComplete &&
             <PaymentAfterComplete/>
          }
        </div>
        <div className={paymentPageFormRow}>
          <label>
            <div className='grid-x grid-margin-x'>
              <div className='cell small-12'>
                <div className={paymentPageCardField}>
                  <CardNumberElement
                    className='billing-cc-v2'
                    style={style}
                    placeholder={'Credit or Debit Card'}
                  />
                  <div className={paymentPageCardFieldLock}>
                    <CreditCardPayment/>
                  </div>
                </div>
              </div>
            </div>
          </label>
        </div>
        <div className={paymentPageFormRow}>
          <div className='grid-x grid-margin-x'>
            { !isMobile &&
              <div className='cell-container'>
                <div className='cell small-12 medium-6 left'>
                  <label>
                    <div className='cell large-12'>
                      <div className={paymentPageCardField}>
                        <CardExpiryElement
                          className='billing-input'
                          style={style}
                          placeholder={'Expiration'}
                        />
                      </div>
                    </div>
                  </label>
                </div>
                <div className='cell small-12 medium-6 right'>
                  <label>
                    <div className='cell large-12'>
                      <div className={paymentPageCardField}>
                        <CardCVCElement
                          className='billing-input'
                          style={style}
                          placeholder={'CVC'}
                        />
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            }

            { isMobile &&
              <React.Fragment>
                <div className='cell small-6 '>
                  <label>
                    <div className='cell small-10'>
                      <div className={paymentPageCardField}>
                        <CardExpiryElement
                          className='billing-input'
                          style={style}
                          placeholder={'Expiration'}
                        />
                      </div>
                    </div>
                  </label>
                </div>
                <div className='cell small-6 '>
                  <label>
                    <div className='cell small-12'>
                      <div className={paymentPageCardField}>
                        <CardCVCElement
                          className='billing-input'
                          style={style}
                          placeholder={'CVC'}
                        />
                      </div>
                    </div>
                  </label>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
        {showCancelEdit &&
          <div className='payment-page-form-row padding-bottom-1em'>
            <a onClick={onCancelEditClick}>Use my saved card</a>
          </div>
        }
      </React.Fragment>
    )
  }
}

export default BillingInfoV2
