import React from 'react';
import DatePickerBase from 'react-datepicker';
import { styled } from '@material-ui/core';
import Caret from '#/components/shared/icons/Caret';

const StyledDatePickerWrapper = styled('div')({
  '& .react-datepicker-wrapper': {
    width: '100%',
  },
  position: 'relative',
});

const StyledCaret = styled(Caret)({
  top: 'calc(50% - 12px)',
  color: '#666666',
  right: '16px',
  position: 'absolute',
  pointerEvents: 'none',
});

const StyledDatePicker = styled(DatePickerBase)(({
  theme: {
    shape: { borderRadius },
    palette: { primary: { main, dark }, text: { primary } },
  },
}) => ({
  color: primary,
  padding: '18.5px 16px',
  borderRadius,
  border: '1px solid rgba(0, 0, 0, 0.23)',
  width: '100%',
  fontSize: '0.875rem',
  cursor: 'pointer',
  '&:focus-visible': {
    outlineColor: main,
  },
  '&:hover': {
    borderColor: dark,
    cursor: 'pointer',
  },
}));

export default function DatePicker(props) {
  return (
    <StyledDatePickerWrapper>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <StyledDatePicker {...props} />
      <StyledCaret />
    </StyledDatePickerWrapper>
  );
}
