
import PhoneInput from 'react-phone-number-input/basic-input'
import classNames from 'classnames'

class PhoneInputSection extends React.Component {
  handlePhoneInput = value => {
    this.validatePhone()
    this.props.handleChange({ target: { name: this.props.name, value } })
  }

  validatePhone = () => {
    const phone = this.phoneInput()
    if (!phone.value) return this.clearPhoneError(phone)

    const constraint = new RegExp(/^(1?)(-| ?)(\()?([0-9]{3})(\)|-| |\)-|\) )?([0-9]{3})(-| )?([0-9]{4}|[0-9]{4})$/, '')

    if (constraint.test(phone.value)) {
      this.clearPhoneError(phone)
    } else {
      this.setPhoneError(phone)
    }
  }

  setPhoneError = () => {
    this.phoneInput().setCustomValidity('Please enter a valid number')
  }

  clearPhoneError = () => {
    this.phoneInput().setCustomValidity('')
  }

  phoneInput = () => {
    return this.phoneRef.input.input
  }


  render() {
    const { placeholder, value, required, addMargin, brandingDetails } = this.props
    const isAngi = brandingDetails && brandingDetails.isAngi

    const cssClass = classNames(
      {
        'margin-bottom-1': addMargin,
        'custom-form-input':  isAngi
      });

    const phoneInputCss = classNames(
      'form-control',
      'form-input',
      'form-input--full',
      {
        'angi' : isAngi
      });

    return (
      <div className={cssClass}>
        <PhoneInput
          className={phoneInputCss}
          country='US'
          value={value}
          placeholder={placeholder}
          onChange={this.handlePhoneInput}
          ref={phoneRef => this.phoneRef = phoneRef}
          required={required} />
        <span className='invalid-field' />
      </div>
    );
  }
}

export default PhoneInputSection



