import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

function HomeAdvisorGuarantee({ className, style }) {
  return (
    <SvgIcon
      className={className}
      style={style}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.528 21.117a4.704 4.704 0 00-1.795-6.713 4.711 4.711 0 01-2.54-4.43 4.681 4.681 0 00-4.91-4.912 4.711 4.711 0 01-4.395-2.54 4.697 4.697 0 00-6.72-1.765 4.697 4.697 0 01-5.066 0 4.697 4.697 0 00-6.712 1.795 4.711 4.711 0 01-4.431 2.54 4.697 4.697 0 00-4.91 4.91 4.711 4.711 0 01-2.541 4.431 4.704 4.704 0 00-1.765 6.684 4.697 4.697 0 010 5.065 4.704 4.704 0 001.794 6.713 4.711 4.711 0 012.54 4.394 4.682 4.682 0 004.912 4.91 4.712 4.712 0 014.43 2.54 4.704 4.704 0 006.683 1.803 4.696 4.696 0 015.066 0 4.704 4.704 0 006.713-1.795 4.711 4.711 0 014.394-2.54 4.696 4.696 0 004.91-4.91 4.712 4.712 0 012.54-4.395 4.704 4.704 0 001.803-6.72 4.696 4.696 0 010-5.065z"
        fill="#070441"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.434 22.933l-5.654-5.65a1.12 1.12 0 00-.652-.344c-.3-.028-.538.103-.717.267l-4.618 4.647c-.393.48-.491.724-.491 1.455l.012 4.016c0 1.023.845 2.081 2.037 2.081h6.58c.22 0 .43.223.584.38l2.98 3.06c.25.238.61.304.865.211.17-.062.464-.254.464-.753l.007-2.902 2.393.001c1.1 0 1.992-.832 1.992-2.13l.012-3.663-4.19-.004c-.67 0-1.149-.21-1.604-.672z"
        fill="#fff"
      />
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={11}
        y={13}
        width={25}
        height={10}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.266 13.45h23.98v8.69h-23.98v-8.69z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.959 20.635l-6.617-6.705c-.392-.324-.937-.478-1.654-.478l-6.77-.002c-.71 0-1.412.3-1.822.71l-6.498 6.48c-.202.193-.332.395-.332.665 0 .456.383.82.84.82.244 0 .507-.143.755-.394.778-.785 5.446-5.455 5.553-5.548.594-.524 1.089-.702 1.7-.702.648 0 1.148.268 1.612.726l5.713 5.72c.159.172.361.209.59.209l6.381.004c.517 0 .727-.38.777-.5.049-.117.18-.53-.228-1.005z"
          fill="#fff"
        />
      </g>
      <path
        opacity={0.15}
        d="M37.275 5.1a4.711 4.711 0 01-4.394-2.541A4.704 4.704 0 0026.168.757a4.697 4.697 0 01-5.066 0 4.704 4.704 0 00-6.712 1.795 4.711 4.711 0 01-4.431 2.54 4.697 4.697 0 00-4.91 4.91 4.711 4.711 0 01-2.541 4.431 4.704 4.704 0 00-1.765 6.683 4.697 4.697 0 010 5.066 4.704 4.704 0 001.794 6.713 4.711 4.711 0 012.54 4.394 4.704 4.704 0 001.367 3.544L40.819 6.465A4.704 4.704 0 0037.275 5.1z"
        fill="#fff"
      />
    </SvgIcon>
  );
}

HomeAdvisorGuarantee.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.string,
};

HomeAdvisorGuarantee.defaultProps = {
  style: {},
  className: '',
};

export default HomeAdvisorGuarantee;
