import React from 'react';
import { useSelector } from 'react-redux';
import { receiptServiceNamesSelector } from '#/selectors';
import BundleServiceLineItem from './BundleServiceLineItem';

export default function BundlesServicesConfirmation() {
  const serviceNames = useSelector(receiptServiceNamesSelector);
  return (
    <div className="padding-left-medium-down-1-5em padding-right-medium-down-1-5em">
      <h1 className="confirmation-header-text">
        Your bookings are confirmed
      </h1>
      <ul
        className="cell small-12 medium-8 small-centered text-left">
        {serviceNames.map((name) => (
          <BundleServiceLineItem serviceName={name} key={name}/>
        ))}
      </ul>
    </div>
  );
}
