import { axios } from './request'
import qs from 'qs'
import Cookies from 'universal-cookie'
import { setDefaultService } from '.';

const apiCaller = {
  getPaymentAfterCompleteValue: (params) => {
    return axios.post("/api/v1/payment_after_complete", params)
  },

  postQuoteWorkflow: (params) => {
    return axios.post("/api/v1/quote_workflow", params)
  },

  getQuote: (params) => {
    params['partner_id'] = 'home_advisor';
    const encodedParams = qs.stringify(params, {
      arrayFormat: 'brackets'
    });

    const quotesUrl = '/api/v1/quote' + '?' + encodedParams;
    return axios.get(quotesUrl)
  },

  postCouponValidation: (couponCode, serviceMachineName, zipcode, frequency, email) => {
    return axios.post('api/v1/coupon_validation', {
      coupon_code: couponCode,
      service_machine_name: serviceMachineName,
      zipcode,
      frequency,
      email,
      originating_platform: 'home_advisor'
    })
  },

  setTaskoidMapping: (taskoid) => {
    const cookies = new Cookies()
    let result = {}

    return axios.get(`/task_mapping/${taskoid}`).then(response => {
      cookies.set('taskoid', taskoid, { path: '/' });
      const taskMapping = response.data.task_mapping

      if (taskMapping.is_active) {
        const taskoidData = taskMapping.destination

        const taskoidName = taskoidData.display_name
        const serviceMachineName = taskoidData.task_external_id

        cookies.set('taskoidName', taskoidName, { path: '/' })
        cookies.set('serviceMachineName', serviceMachineName, { path: '/' })

        result.taskMappingEnabled = true
      } else {
        setDefaultService(cookies)
        result.taskMappingEnabled = false
      }

      cookies.set('taskMappingEnabled', result.taskMappingEnabled)
      return Promise.resolve(result)
    }).catch(error => {
      setDefaultService(cookies)
      result.taskMappingEnabled = false
      cookies.set('taskMappingEnabled', result.taskMappingEnabled)

      return Promise.resolve(result)
    })
  },

  userSearch: (params) => {
    const usersSearchUrl = '/api/v1/user_search'

    return axios.post(usersSearchUrl, { email: params['email'] })
  },

  getExtras: (serviceMachineName) => {
    const extrasUrl = `/api/v1/services/${serviceMachineName}/extras`;

    return axios.get(extrasUrl);
  },

  getMarketLead: (marketLeadId) => {
    const marketLeadUrl = `/api/v1/market_leads/${marketLeadId}`;

    return axios.get(marketLeadUrl)
  },

  getServiceRequestConfiguration: (serviceMachineName, reasonCode) => {
    const url = `/api/v1/service_request_configuration/${serviceMachineName}/${reasonCode}`
    return axios.get(url).then(response => {
      // eslint-disable-next-line camelcase
      const { data: { service_requests_enabled } } = response
      return Promise.resolve(service_requests_enabled)
    }).catch(err => {
      console.error(err)
      return Promise.resolve(false)
    })
  },

  getBusinessHours: (zipcode, serviceMachineName) => {
    const url = `/api/v1/business_hours/${serviceMachineName}/${zipcode}`
    return axios.get(url).then(response => {
      const {
        data: {
          soonest_bookable_time: soonestBookableTime,
          min_hour: minHour,
          max_hour: maxHour,
        },
      } = response;

      return Promise.resolve({
        soonestBookableTime,
        minHour,
        maxHour,
      });
    });
  },

  getRecommendedDuration: (serviceMachineName, responses) => {
    const url = `/api/v1/recommended_duration`
    const responsesKeys = Object.getOwnPropertyNames(responses)
    const convertedResponses = responsesKeys.map(key => ({ key: key, value: responses[key] }))
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    const params = {
      service_machine_name: serviceMachineName,
      responses: convertedResponses,
    };

    return axios.post(url, params, { headers }).then(response => {
      const { data: { recommended_duration } } = response

      return Promise.resolve({
        recommended_duration,
      })
    })
  },

  getBundleOffering: (taskoid, zipcode, props) => {
    return axios.get(`/api/v1/bundle_offering/${zipcode}/${taskoid}`).then(response => {
      const { data: { services: services } } = response;
      return services
    }).catch(error => {
      console.error(error);
      return []
    });
  }
};

export default apiCaller
