import { bundlesActions } from '#/reducers/bundles';

export function updateWhenFormQuoteWorkflowParams(quoteWorkflowParams) {
  return {
    type: 'bundles/updateWhenFormQuoteWorkflowParams',
    payload: quoteWorkflowParams,
  };
}

export function clearServicesNotInBundle(serviceNames) {
  return {
    type: 'bundles/clearServicesNotInBundle',
    payload: serviceNames,
  };
}

export const {
  setCurrentBundleId,
  bundlesReceived,
} = bundlesActions;
