import Accordion from '../shared/Accordion';
import classnames from 'classnames'

class FaqSection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      header,
      classNames,
      accordionContent,
      brandingDetails
    } = this.props

    const containerClass = classnames(
      'when-row',
      'faq-container',
      {
        'angi': brandingDetails.isAngi
      }
    )

    const angiFontBoldH2 = classnames(
      'how-it-works-header',
      {'faq-header-angi-font': brandingDetails.isAngi}
    )

    return (
      <div className = { containerClass }>
        <div className='grid-container'>
          <div className='grid-x grid-margin-x'>
            <div className='cell small-12 card'>
              <div className='grid-x grid-margin-x'>
                <h2 className={`faq-header ${classNames} ${angiFontBoldH2}`}>{header}</h2>
              </div>
              <div className='top-border'>
                <Accordion items={accordionContent} brandingDetails={brandingDetails}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FaqSection;
