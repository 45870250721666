class PlanEngagementFormReminder extends React.Component {
  constructor(props) {
    super(props)
  }

  refCallback = element => {
    if (element && this.props.getSize) {
      this.props.getSize(element.getBoundingClientRect())
    }
  }

  render() {
    const { header } = this.props

    return (
      <div className='plan-engagement-form-reminder-container' ref={this.refCallback} >
        <div className='text-center plan-engagement-description'>Your <strong>20%</strong> discount will be applied at checkout</div>
      </div>
    )
  }
}

export default PlanEngagementFormReminder
