import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon, styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckIcon from '../shared/icons/CheckIcon';

const StyledList = styled(List)(({ theme: { palette } }) => ({
  '& .MuiListItem-root': {
    padding: '0',
    paddingBottom: '1em',
  },
  '& .MuiListItemIcon-root': {
    minWidth: '16px',
    paddingRight: '16px',
  },
  '& .MuiListItemText-root': {
    color: palette.text.base,
  },
}));

const StyledListItemText = styled(ListItemText)(({ theme: { palette } }) => ({
  '& .MuiTypography-body1': {
    color: palette.text.secondary,
    fontSize: '12px',
    lineHeight: '1.5',
  },
}));

function DescriptionChecklist({ screenedProfessionalLink,
  fixPriceGuaranteeLink, brandingDisplayName }) {
  return (
    <div>
      <StyledList data-testid="description-checklist">
        <ListItem>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText>
            Vetted, rated and&nbsp;
            <a data-testid="screened-professional-link" href={screenedProfessionalLink} rel="noreferrer" target="_blank">
              screened professionals
            </a>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText>
            No time windows, book when you want
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText>
            Friendly 24/7 customer service
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText>
            Affordable, upfront pricing
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText>
            Backed by the &nbsp;
            <a data-testid="fixed-price-guarantee-link" href={fixPriceGuaranteeLink} rel="noreferrer" target="_blank">
              Fixed-Price Guarantee*
            </a>
          </ListItemText>
        </ListItem>
        <ListItem>
          <StyledListItemText data-testid="guarantee-text">
            *The Fixed-Price Guarantee applies only to Fixed-Price services, prepaid and purchased
            on the&nbsp;
            { brandingDisplayName }
            &nbsp;Website
          </StyledListItemText>
        </ListItem>
      </StyledList>
    </div>
  );
}

DescriptionChecklist.propTypes = {
  screenedProfessionalLink: PropTypes.string,
  fixPriceGuaranteeLink: PropTypes.string,
  brandingDisplayName: PropTypes.string,
};

DescriptionChecklist.defaultProps = {
  screenedProfessionalLink: null,
  fixPriceGuaranteeLink: null,
  brandingDisplayName: null,
};
export default DescriptionChecklist;
