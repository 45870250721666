import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state.quotes;
const selectQuoteEntities = createSelector(
  selectSelf,
  ({ entities }) => entities,
);

const createQuoteForServiceSelector = (serviceName) => createSelector(
  selectQuoteEntities,
  ({ [serviceName]: quote }) => quote,
);

export {
  selectQuoteEntities,
  createQuoteForServiceSelector,
};
