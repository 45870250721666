import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useTheme } from "@material-ui/core/styles";
import { formatCurrency } from "../../../utils";
import useStyles from "./styles";
import { useGetServiceInfoByServiceMachineNameQuery } from "#/apiServices/checkout";

const serviceDisplayName = (machineName, classes, textStyle) => {
  const { data, error, isLoading } = useGetServiceInfoByServiceMachineNameQuery(
    machineName
  );
  const displayName = error ? machineName : isLoading ? undefined : data.name;
  if (!displayName) return null;
  return (
    <Typography
      variant="body1"
      component="div"
      className={`${classes.serviceFont} ${textStyle}`}
    >
      {displayName}
    </Typography>
  );
};

export default function ServiceLineItem({ service, currency }) {
  const { name: theme } = useTheme();
  const classes = useStyles({ theme });
  const basePriceQLI = service.quoteLineItems.find((obj) => obj.category === "base");
  const basePrice = formatCurrency(currency, basePriceQLI.amount);

  return (
    <li className={classes.listItem}>
      <div className={`${classes.spacedItems} ${classes.lightFont}`}>
        {serviceDisplayName(service.name, classes, classes.serviceName)}
        <Typography
          variant="body1"
          component="div"
          className={classes.serviceFont}
        >
          {basePrice}
        </Typography>
      </div>
      <Typography
        variant="body1"
        component="div"
        className={`${classes.serviceFont} ${classes.lightFont} ${classes.supportingText}`}
      >
        {service.date}
      </Typography>
      <Typography
        variant="body1"
        component="div"
        className={`${classes.bottomPadding} ${classes.lightFont} ${classes.supportingText}`}
      >
        {`${service.frequency} - ${service.duration}`}
      </Typography>
    </li>
  );
}

ServiceLineItem.propTypes = {
  service: PropTypes.shape({
    basePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    duration: PropTypes.string,
    frequency: PropTypes.string,
    date: PropTypes.string,
    quoteLineItems: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      category: PropTypes.string,
      display_name: PropTypes.string,
    })),
  }).isRequired,
  currency: PropTypes.string.isRequired,
}
