import React from 'react';
import Moment from 'moment';
import { keys } from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import QuoteBreakdown from './QuoteBreakdown';
import QuoteMobileTotal from './QuoteMobileTotal';
import { formatCurrency, formatFrequencyInWeeks } from '../../utils';

const mapStateToProps = (state) => {
  const { quotes: quoteState, cart: { serviceIds } } = state;
  const quotesInCart = keys(serviceIds).map((cartItem) => quoteState.entities[cartItem]);
  return { quotes: quotesInCart };
};

class QuotePreview extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalVisible: false
    }
  }

  formattedTotalPrice = () => {
    const mainQuote = this.props.quote;
    const mainQuoteTotalPrice = mainQuote.amount_due_after_credits
    const additionalQuotes = this.props.quotes;
    const additionalTotals = additionalQuotes.reduce(
      (a, b) => ({ fullPrice: a.fullPrice + b.fullPrice }), { fullPrice: 0 },
    );
    const mainQuoteHasMembershipFee = mainQuote.quote_breakdown.find((item) => item.category === 'membership_fee');
    if (mainQuoteHasMembershipFee) {
      const removeMembershipFee = (quote) => {
        const membershipLineItem = quote.quoteBreakdown.find((lineItem) => lineItem.category === 'membership_fee');
        if (membershipLineItem) { additionalTotals.fullPrice -= (membershipLineItem.amount); }
      };
      additionalQuotes.forEach(removeMembershipFee);
    }
    const totalPrice = mainQuoteTotalPrice + additionalTotals.fullPrice;
    return formatCurrency(this.currencySymbol(), totalPrice);
  }

  formattedOriginalPrice = () => {
    const originalPrice = _.reduce(this.quoteBreakdownItems(), function (sum, item) {
      if(item.amount < 0){
        return sum
      }
      return sum + item.amount;
    }, 0);

    return formatCurrency(this.currencySymbol(), originalPrice)
  }

  currencySymbol = () => {
    return this.props.quote.currency.symbol
  }

  quoteBreakdownItems = () => {
    return this.props.quote.quote_breakdown;
  }

  formattedDateTime = () => {
    return Moment(this.props.quote.starts_at, 'YYYY-MM-DD HH:mm').format('MMM D, YYYY [at] hh:mm a')
  }

  formattedHours = (hasDetails = false) => {
    const recommendedHours = this.props.quote.recommended_duration_minutes / 60

    if (hasDetails) {
      return `(up to ${recommendedHours} hours)`
    } else {
      return `Up to ${recommendedHours} hours`
    }
  }

  frequencyCopy = () => {
    const { frequency } = this.props.quote
    const frequencyInWeeks = formatFrequencyInWeeks(frequency)
    if (!frequencyInWeeks) return

    let copy =  `Every ${frequencyInWeeks} week`
    if (frequencyInWeeks > 1) {
      copy += 's'
    }

    return copy
  }

  onOrderSummaryExpand = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  }

  render() {
    const {
      taskoidName,
      isCouponApplied,
      enableExtras,
      extrasAdded,
      paymentAfterComplete,
      brandingDetails,
      enablePlanEngagement,
      couponCode,
      couponError,
    } = this.props

    const quoteCardCss = classnames("card", "quote-card", "mobile-li-align", {
      angi: brandingDetails.isAngi,
    });

    return (
      <div className={quoteCardCss} ref={this.refCallback}>
        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell small-12">
              <div className="cell show-for-small-only mobile-align-summary">
                {this.state.isModalVisible && (
                  <QuoteBreakdown
                    brandingDetails={this.props.brandingDetails}
                    couponCode={couponCode}
                    couponError={couponError}
                    currencySymbol={this.currencySymbol()}
                    enableExtras={enableExtras}
                    enablePlanEngagement={enablePlanEngagement}
                    extrasAdded={extrasAdded}
                    formattedDateTime={this.formattedDateTime()}
                    formattedSummary={this.formattedHours()}
                    frequencyCopy={this.frequencyCopy()}
                    isCouponLoading={this.props.isCouponLoading}
                    isFetchingPrice={this.props.isFetchingPrice}
                    onCouponCodeChange={this.props.onCouponCodeChange}
                    paymentAfterComplete={paymentAfterComplete}
                    quoteBreakdownItems={this.quoteBreakdownItems()}
                    serviceMachineName={this.props.serviceMachineName}
                    taskoidName={taskoidName}
                    totalPrice={this.formattedTotalPrice()}
                    mainServiceFrequency={this.props.quote.frequency}
                    additionalQuotes={this.props.quotes}
                  />
                )}
                <QuoteMobileTotal
                  formattedDateTime={this.formattedDateTime()}
                  isCouponApplied={isCouponApplied}
                  visible={this.state.isModalVisible}
                  onClickHandler={this.onOrderSummaryExpand}
                  originalPrice={this.formattedOriginalPrice()}
                  paymentAfterComplete={paymentAfterComplete}
                  totalPrice={this.formattedTotalPrice()}
                />
              </div>
              <div className="cell show-for-medium">
                <QuoteBreakdown
                  brandingDetails={this.props.brandingDetails}
                  couponCode={couponCode}
                  couponError={couponError}
                  currencySymbol={this.currencySymbol()}
                  enableExtras={enableExtras}
                  enablePlanEngagement={enablePlanEngagement}
                  extrasAdded={extrasAdded}
                  formattedDateTime={this.formattedDateTime()}
                  formattedSummary={this.formattedHours()}
                  frequencyCopy={this.frequencyCopy()}
                  isCouponLoading={this.props.isCouponLoading}
                  isFetchingPrice={this.props.isFetchingPrice}
                  onCouponCodeChange={this.props.onCouponCodeChange}
                  paymentAfterComplete={paymentAfterComplete}
                  quoteBreakdownItems={this.quoteBreakdownItems()}
                  serviceMachineName={this.props.serviceMachineName}
                  totalPrice={this.formattedTotalPrice()}
                  taskoidName={taskoidName}
                  mainServiceFrequency={this.props.quote.frequency}
                  additionalQuotes={this.props.quotes}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(QuotePreview);
