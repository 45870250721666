import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function PlusMinus({ minus, color, style }) {
  return (
    <SvgIcon
      style={style}
      color={color}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {
        !minus
        && (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11 5v14a1 1 0 102 0V5a1 1 0 10-2 0z"
          />
        )
      }
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 13h14a1 1 0 100-2H5a1 1 0 100 2z"
      />
    </SvgIcon>
  );
}

PlusMinus.propTypes = {
  /** [Material SvgIcon Color Options](https://material-ui.com/api/svg-icon/#props) */
  color: PropTypes.oneOf(['action', 'disabled', 'error', 'inherit', 'primary', 'secondary']),
  minus: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object]),
};

PlusMinus.defaultProps = {
  color: 'primary',
  minus: false,
  style: {},
};
