const Axios = require('axios')
const _ = require('lodash')
const routePrefix = process.env.USE_MOCK_API === 'true' ? 'mock-api/' : ''
const partner_id_override = (partner_id) =>
  { return { partner_id: partner_id ? partner_id : 'home_advisor' } }
const { getAuthenticityToken } = require('./csrf')

const axios = Axios.create({
  // unwrap axios response body nested under `data` object for our api's that
  // follow JSON:API spec: https://jsonapi.org/format/
  transformResponse: (resp, headers) => {
    const data = JSON.parse(resp)

    return _.get(data, 'data', data)
  },

  // pass authenticity_token with every POST
  data: { authenticity_token: getAuthenticityToken() }
})

module.exports.axios = {
  get: (route, queryObj = {}, partner_id = null) =>
    axios.get(`${routePrefix}${route}`, _.merge(queryObj, { params: partner_id_override(partner_id) })),

  post: (route, body = {}, config = {}, partner_id = null) =>
    axios.post(`${routePrefix}${route}`, _.merge(body, partner_id_override(partner_id)), config)
}
