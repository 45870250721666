class PlanEngagementBanner extends React.Component {
  constructor(props) {
    super(props)
  }

  refCallback = element => {
    if (element && this.props.getSize) {
      this.props.getSize(element.getBoundingClientRect())
    }
  }

  render() {
    const { header, body, hasCheckMark } = this.props
    const checkMark = hasCheckMark
      ? <i className='fas fa-check-circle bold plan-engagement-symbol plan-engagement-checkmark ' ></i>
      : null

    return (
      <div className={`plan-engagement-banner-container`} ref={this.refCallback} >
        {checkMark}
        <div className='text-center plan-engagement-description' ><b>{header}</b>{` ${body}`}</div>
      </div>
    )
  }
}

export default PlanEngagementBanner
