import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const servicesAdapter = createEntityAdapter({
  selectId: ({ serviceMachineName }) => serviceMachineName,
  sortComparer: (a, b) => a.priceCents - b.priceCents,
});

const servicesSlice = createSlice({
  name: 'services',
  initialState: servicesAdapter.getInitialState(),
  reducers: {
    serviceAdded: servicesAdapter.addOne,
    servicesReceived(state, action) {
      servicesAdapter.setAll(state, action.payload);
    },
    serviceUpdated: servicesAdapter.updateOne,
    serviceRemoved: servicesAdapter.removeOne,
  },
});

export const servicesActions = servicesSlice.actions;
export const servicesReducer = servicesSlice.reducer;
export const servicesSelectors = servicesAdapter.getSelectors((state) => state.services);

export default servicesReducer;
