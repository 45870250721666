import { Omniture } from '#/utils/analytics';

const OMNITURE_LINK_TYPES = {
  CUSTOM: 'o',
  DOWNLOAD: 'd',
  EXIT: 'e',
};

const useOmniture = () => {
  const omniture = new Omniture();

  return omniture;
};

const useTrackPageView = (omniProps) => {
  const omniture = useOmniture();

  return (events) => {
    omniture.track({ ...omniProps, events });
  };
};

const useTrackLink = (eventName, isImmediate = true, linkType = OMNITURE_LINK_TYPES.CUSTOM) => {
  const { track_link: trackLink } = useOmniture();

  return () => {
    trackLink(eventName, isImmediate, linkType);
  };
};

export {
  OMNITURE_LINK_TYPES,
  useTrackPageView,
  useTrackLink,
};
