import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const DeepCleanCarpetCleaning = ({ style }) => {
  // Brand information
  const brandingDetails = useSelector((state) => state.brandingDetails);
  const { isAngi } = brandingDetails;

  return (
    <div>
      {isAngi ? (
        <svg
          style={style}
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 24H12.0544C18.0345 24 23.5154 27.3349 26.2641 32.6458L28 36H8C6.34315 36 5 34.6569 5 33V24Z"
            fill="#FF6153"
          />
          <circle cx="13.5" cy="35.5" r="4.5" fill="#A03027" />
          <circle cx="13.5" cy="35.5" r="2.5" fill="#FF6153" />
          <path
            d="M32.4097 37.7657L31.6797 35H37.7569C38.5525 35 39.3156 35.3161 39.8782 35.8787L41.7066 37.7071C41.8942 37.8946 41.9995 38.149 41.9995 38.4142V40H35.3104C33.9484 40 32.7573 39.0825 32.4097 37.7657Z"
            fill="#FF6153"
          />
          <path
            d="M33.44 37.4527L26.187 12.7465C25.1869 9.33994 22.0613 7 18.5109 7H14.2819C11.3648 7 9 9.36477 9 12.2819V12.2819C9 13.9876 9.82379 15.5884 11.2118 16.5799L12.6499 17.6071C14.7523 19.1088 16 21.5333 16 24.1169V27.5"
            stroke="#FF6153"
            strokeWidth="2"
          />
          <path
            d="M4 40H44V42C44 43.6569 42.6569 45 41 45H7C5.34315 45 4 43.6569 4 42V40Z"
            fill="#A03027"
          />
        </svg>
      ) : (
        <svg
          style={style}
          viewBox="0 0 38 37"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.6892 34.7324V2.12844C32.1772 -1.53556 27.9942 2.12844 27.9942 2.12844V34.7324C27.9942 34.7324 32.2842 30.7494 37.6892 34.7324Z"
            fill="#D5DBF1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.6892 34.7324V2.12844C32.1772 -1.53556 27.9942 2.12844 27.9942 2.12844V34.7324C27.9942 34.7324 32.2842 30.7494 37.6892 34.7324Z"
            stroke="#10103F"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.21399 32.8737H27.993V3.7627H3.21399V32.8737Z"
            fill="#D5DBF1"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.21399 32.8737H27.993V3.7627H3.21399V32.8737Z"
            stroke="#10103F"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
          <path
            d="M3.21357 3.76172H-0.000427246"
            stroke="#10103F"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
          <path
            d="M3.21357 8.76953H-0.000427246"
            stroke="#10103F"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
          <path
            d="M3.21357 13.457H-0.000427246"
            stroke="#10103F"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
          <path
            d="M3.21357 18.4648H-0.000427246"
            stroke="#10103F"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
          <path
            d="M3.21357 23.2598H-0.000427246"
            stroke="#10103F"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
          <path
            d="M3.21357 27.9482H-0.000427246"
            stroke="#10103F"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
          <path
            d="M3.21357 32.876H-0.000427246"
            stroke="#10103F"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
          <path
            d="M27.9939 34.7324C27.9939 34.7324 32.8169 38.5274 37.6889 34.7324"
            stroke="#10103F"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.1785 34.897C37.1785 35.513 35.4685 36.2 32.9005 36.2C30.3305 36.2 28.7135 35.513 28.7135 34.897C28.7135 34.281 30.3305 33.501 32.9005 33.501C35.4685 33.501 37.1785 34.281 37.1785 34.897Z"
            fill="#16123F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.1785 34.897C37.1785 35.513 35.4685 36.2 32.9005 36.2C30.3305 36.2 28.7135 35.513 28.7135 34.897C28.7135 34.281 30.3305 33.501 32.9005 33.501C35.4685 33.501 37.1785 34.281 37.1785 34.897Z"
            stroke="#10103F"
            strokeWidth="0.5"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );
};
DeepCleanCarpetCleaning.propTypes = {
  style: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
};

DeepCleanCarpetCleaning.defaultProps = {
  style: {
    height: '22.6px',
    width: '23px',
  },
};
export default DeepCleanCarpetCleaning;
