import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state.bundles;
export const currentBundleIdSelector = createSelector(
  selectSelf,
  (bundles) => bundles.currentBundleId,
);
export const currentBundleSelector = createSelector(
  selectSelf,
  ({ entities, currentBundleId }) => entities[currentBundleId],
);
export const bundlesByIdSelector = createSelector(
  selectSelf,
  ({ entities }) => entities,
);
export const currentTasksSelector = createSelector(
  currentBundleSelector,
  ({ tasks }) => tasks,
);
export const currentTasksServiceMachineNamesSelector = createSelector(
  currentTasksSelector,
  (tasks) => tasks.map(({ serviceMachineName }) => serviceMachineName),
);
