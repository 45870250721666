import React from 'react';
import { useSelector } from 'react-redux';
import brandingContent from '#/components/shared/BrandingContent';

function LegalText() {
  const { domainBrand, displayName } = (useSelector((state) => state.brandingDetails));
  const termsLink = brandingContent.getLink(domainBrand, 'terms');
  return (
    <div className="legal-text">
      <p>
        By clicking Continue, you affirm you have read and agree to the
        <a href={termsLink} target="_blank" rel="noreferrer"> Terms</a>
        , and you agree and authorize
        {' '}
        {displayName}
        {' '}
        and its affiliates, and their networks of Service Professionals, to deliver marketing calls or texts using automated technology to the number you provided above regarding your project and other home services offers. Consent is not a condition of purchase.
      </p>
    </div>
  );
}

export default LegalText;
