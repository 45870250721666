import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import every from 'lodash/every';
import PropTypes from 'prop-types';
import { addPendingServicesToCart } from '#/actions';
import { isLoadingNewServicesSelector, selectIsServiceCartError, receiptLineItemsSelector, receiptPendingLineItemsSelector, selectServiceIds, serviceEntitiesSelector } from '#/selectors';
import { formatCurrency } from '#/utils';
import Receipt from './Receipt';

function ReceiptWithState({ history }) {
  const pendingServiceIds = useSelector((state) => selectServiceIds(state));
  const dispatch = useDispatch();
  const isDisabled = useSelector(selectIsServiceCartError());
  const isLoadingNewServices = useSelector(isLoadingNewServicesSelector);

  const serviceEntities = useSelector(serviceEntitiesSelector);
  const hasPrice = (service) => service?.priceCents > 0;
  const havePendingPrices = every(serviceEntities, hasPrice);
  const receiptLineItems = useSelector(receiptLineItemsSelector);
  const pendingServiceLineItems = useSelector(receiptPendingLineItemsSelector);
  const isEmptyCartWithPendingPrices = havePendingPrices && !isLoadingNewServices
    && receiptLineItems.length === 0 && pendingServiceLineItems.length === 0;

  useEffect(() => {
    if (isEmptyCartWithPendingPrices) {
      dispatch(addPendingServicesToCart(pendingServiceIds));
    }
  }, [dispatch, isEmptyCartWithPendingPrices, pendingServiceIds]);

  const sum = (amtKey, items) => items.reduce(
    (total, { [amtKey]: amount }) => total + amount,
    0,
  );

  const membershipItems = ({ category }) => category !== 'membership_fee' && category !== 'membership_discount';
  const nonMembershipItems = receiptLineItems.filter(membershipItems);
  const cartTotal = sum('amount', nonMembershipItems);
  const pendingTotal = sum('priceCents', pendingServiceLineItems);

  const formattedTotal = formatCurrency('$', cartTotal + pendingTotal);

  const onClick = () => history.push('/payment');

  return (
    <Receipt
      onClick={onClick}
      isDisabled={isDisabled}
      total={formattedTotal}
    />
  );
}

ReceiptWithState.propTypes = {
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withRouter(ReceiptWithState);
