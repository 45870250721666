import React from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { useTheme } from '@material-ui/core/styles';
import YourProPresenter from './YourPro';
import { useGetProviderByExternalIdQuery } from '#/apiServices';

const cookies = new Cookies();

function YourPro() {
  const { name: themeName } = useTheme();
  const h3Font = themeName === 'angi' ? 'angi' : '';
  const externalId = cookies.get('external_provider_id');
  useGetProviderByExternalIdQuery({ externalId });
  const provider = useSelector(({ providers: { entities } }) => entities[externalId]);

  if (!provider) return null;

  const {
    firstName,
    lastName,
    profilePhotoUrl: proAvatar,
  } = provider;

  const name = `${firstName} ${lastName}`;

  return (
    <YourProPresenter
      name={name}
      h3Font={h3Font}
      proAvatar={proAvatar}
    />
  );
}

export default YourPro;
