import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Typography } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import {
  createQuoteForServiceSelector,
  createSelectServiceById,
} from "#/selectors";
import { formatCurrency, formatDate } from "#/utils";
import useStyles from "./styles";
export default function ServiceLineItem({ serviceName }) {
  const { name: theme } = useTheme();
  const classes = useStyles({ theme });
  const quote = useSelector(createQuoteForServiceSelector(serviceName));
  const service = useSelector(createSelectServiceById(serviceName));

  if (!quote || !service.businessHours) return null;

  const {
    currency: { symbol: currencySymbol },
    quoteBreakdown,
    recommendedDurationMinutes,
  } = quote;
  const {
    name,
    shortDisplayName,
    frequency: { selected: selectedFrequency, options },
    businessHours: { value: startsAt },
  } = service;

  const title = name.length > 25 ? shortDisplayName : name;

  const centPrice = quoteBreakdown.find(({ category }) => category === "base").amount;
  const basePrice = formatCurrency(currencySymbol, centPrice);

  const findSelectedFreq = ({ machineName }) => machineName === selectedFrequency;
  const { displayName: frequency } = options.find(findSelectedFreq);

  const duration = `${recommendedDurationMinutes / 60} Hours`;
  const date = formatDate(startsAt);

  return (
    <li className={classes.listItem}>
      <div
        className={`${classes.spacedItems} ${classes.lightFont} ${classes.minimizePadding}`}
      >
        <Typography
          variant="body1"
          component="div"
          className={`${classes.serviceFont} ${classes.serviceName}`}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          component="div"
          className={classes.serviceFont}
        >
          {basePrice}
        </Typography>
      </div>
      <Typography
        variant="body1"
        component="div"
        className={`${classes.serviceFont} ${classes.lightFont} ${classes.supportingText}`}
      >
        {date}
      </Typography>

      <Typography
        variant="body1"
        component="div"
        className={`${classes.serviceTimeFont} ${classes.bottomPadding} ${classes.lightFont} ${classes.supportingText}`}
      >
        {`${frequency} - ${duration}`}
      </Typography>
    </li>
  );
}

ServiceLineItem.propTypes = {
  serviceName: PropTypes.string.isRequired,
};
