/* eslint-disable max-len */
import React from 'react';
import { Grid, styled, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import AvatarName from '../../shared/AvatarName/AvatarName';

const StyledTypographyDescription = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.text.base,
  fontSize: '14px',
  lineHeight: '20px',
}));

function YourPro({ name, proAvatar, h3font }) {
  return (
    <div data-testid="yourpro">
      <Grid
        container
        direction="column"
        justifycontent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item>
          <h3 data-testid="yourpro-title" className={h3font}>Your Pro</h3>
        </Grid>
        <Grid item>
          <AvatarName
            name={name}
            srcImg={proAvatar}
          />
        </Grid>
        <Grid item>
          <StyledTypographyDescription data-testid="yourpro-footer" component="div">
            If your pro isn’t available at the requested time, we will let you know and send another top-rated pro.
          </StyledTypographyDescription>
        </Grid>
      </Grid>
    </div>
  );
}
YourPro.propTypes = {
  name: PropTypes.string.isRequired,
  h3font: PropTypes.string,
  proAvatar: PropTypes.string,
};

YourPro.defaultProps = {
  proAvatar: null,
  h3font: '',
};

export default YourPro;
