import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { useGetCartServicesByTaskoidAndZipcodeQuery } from '#/apiServices/checkout';
import { selectServiceIds } from '#/selectors/services';
import TaskLoading from '#/components/cart/skeletons/Task';
import images from '#/images/recommendations';
import Task from '../task/Task';

export default function Tasks() {
  const [openTask, setOpentask] = useState(0);
  const { taskoid, zipcode } = useSelector(({ whenForm }) => (whenForm));

  const { error, isLoading } = useGetCartServicesByTaskoidAndZipcodeQuery({ taskoid, zipcode });
  const serviceIds = useSelector((state) => selectServiceIds(state));
  const openNextTask = (indexToOpen) => {
    setOpentask(indexToOpen);
  };
  if (error) return <Redirect to={{ pathname: '/payment' }} />;

  const loadingImages = ['home_cleaning', 'exterior_door_installation', 'faucets_replacement_ha', 'furniture_cleaning', 'gutter_help'];

  if (isLoading) {
    return loadingImages.map((machineName) => (
      <TaskLoading key={machineName} image={images[machineName]} />
    ));
  }

  return serviceIds.map((machineName, index) => (
    <Task
      key={machineName}
      id={machineName}
      index={index}
      openNextTask={openNextTask}
      openTask={openTask}
    />
  ));
}
