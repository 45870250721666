import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';

export default function AngiKeyIcon({ style }) {
  const { name: themeName } = useTheme();
  let fill = themeName === 'angi' ? '#7B123C' : '#070441';
  if (style.color) { fill = style.color; }
  return (
    <svg role="svg" style={style} viewBox="0 0 44 18" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M39.3038 12.6933H32.2017V15.9226H39.3038V12.6933Z" fill={fill}/>
      <path
        d="M43.961 6.28955H20.2773C18.7634 6.28955 17.2612 4.53039 16.7249 3.83406C16.6576 3.73962 16.589 3.64518 16.5174 3.55355C14.8456 1.39549 12.1772 0 9.16982 0C4.10486 0 0 3.95951 0 8.84512C0 13.7307 4.10486 17.6902 9.16982 17.6902C12.3789 17.6902 15.2022 16.0974 16.8403 13.6884C14.7682 12.0167 13.5728 9.47239 13.2279 8.66188C13.2308 8.72249 13.2323 8.7831 13.2323 8.84371C13.2323 11.0088 11.4129 12.7623 9.16982 12.7623C6.92668 12.7623 5.10733 11.0074 5.10733 8.84371C5.10733 6.68001 6.92668 4.92508 9.16982 4.92508C11.0944 4.92508 12.7062 6.21766 13.1256 7.95145C15.1992 11.9462 20.1283 11.2851 20.1283 11.2851C20.1283 11.2851 20.1254 11.2978 20.121 11.3218C20.1312 11.3203 20.1414 11.3189 20.1531 11.3175H32.1492H32.2032H39.3053C41.9225 11.0497 43.961 8.88459 43.961 6.28955Z"
        fill={fill}/>
      <path
        d="M13.1257 7.95148C13.1812 8.18124 13.2163 8.41946 13.228 8.66191C13.5714 9.47242 14.7667 12.0167 16.8404 13.6885C16.9178 13.5743 16.9938 13.4601 17.0654 13.3431C17.7318 12.565 18.9125 11.412 20.121 11.3218C20.1254 11.2992 20.1283 11.2851 20.1283 11.2851C20.1283 11.2851 15.1993 11.9462 13.1257 7.95148Z"
        fill="#11101A"/>
      <path d="M39.3038 11.3175H32.2017V12.6918H39.3038V11.3175Z" fill="#11101A"/>
    </svg>
  );
}

AngiKeyIcon.propTypes = {
  style: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
    color: PropTypes.string,
  }),
};

AngiKeyIcon.defaultProps = {
  style: {
    height: '20px',
    width: '20px',
  },
};
