import WarningText from './WarningText';
import classnames from 'classnames'

class Stepper extends React.Component {
  constructor(props) {
    super(props)
  }

  handleStepDown = (e) => {
    e.preventDefault()
    if (this.props.value > Math.min(...this.valueRange())) {
      const newValue = parseInt(this.props.value) - 1
      this.updateStepperState(newValue)
    }
  }

  handleStepUp = (e) => {
    e.preventDefault()
    if (this.props.value < Math.max(...this.valueRange())) {
      const newValue = parseInt(this.props.value) + 1
      this.updateStepperState(newValue)
    }
  }

  valueRange = () => {
    return this.props.question.options.map((option) => {
      return option.value;
    })
  }

  stepperDisplay = () => {
    const currentValue = String(this.props.value)
    let display

    this.props.question.options.forEach(option => {
      if (option.value === currentValue) {
        display = option.display
      }
    });

    return display
  }

  updateStepperState = (value) => {
    const event = {
      target: {
        type: 'stepper',
        name: this.props.name,
        value: value,
      }
    }

    this.props.onChange(event)
  }

  render() {
    const {
      question,
      value,
      onChange,
      className,
      required,
      warningTextValue,
      isAngi,
    } = this.props

    const questionLabel = classnames(
      'question-label',
      {angi: isAngi}
    )

    return(
      <div className='form-row'>
        <div className={questionLabel}>{!!question.label  && question.label}</div>
        <div className='stepper-wrapper'>
          <button className='stepper-button-down stepper-button' type='button' onClick={this.handleStepDown} />
          <input
            name={question.machine_name}
            required={required}
            className={className + ' ' + 'stepper-input'}
            readOnly={true}
            value={this.stepperDisplay()}
          />
          <button className='stepper-button-up stepper-button' type='button' onClick={this.handleStepUp} />
        </div>
        <WarningText value={warningTextValue} />
      </div>
    )
  }
}

export default Stepper
