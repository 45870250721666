import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
import URLSearchParams from '@ungap/url-search-params';
import HowItWorks from '../../when/HowItWorks';
import FaqSection from '../../when/FaqSection';
import { whenPageCopy } from '../../../utils/copy';
import brandingContent from '../../shared/BrandingContent';
import { whenPageBackgroundImage } from '../../../utils/content';
import DescriptionChecklist from '../../when/DescriptionChecklist';
import DescriptionSection from '../../when/DescriptionSection';
import WhatsIncluded from './WhatsIncluded';
import KeyBanner from '../../shared/KeyBanner/KeyBanner';
import BundleContactInfo from './BundleContactInfo';
import { isMember } from '../../../utils/memberships';

const cookies = new Cookies();

class BundleShowPageComponent extends React.Component {
  constructor(props) {
    super(props);
    const qs = new URLSearchParams(props.location.search);
    const initialEmail = cookies.get('formAttribute')?.email;
    this.email = qs.get('email') || initialEmail || cookies.get('email') || '';

    this.state = {
      activeMember: false,
    };
  }

  componentDidMount = () => {
    if (this.email) {
      this.setMembershipFromState();
    }
  };

  setMembershipFromState() {
    const { brandingDetails, currentBundle } = this.props;
    isMember(this.email, currentBundle.name, brandingDetails.siteBrandKey)
      .then((result) => {
        this.setState({
          activeMember: result.data.is_member,
        });
      });
  }

  render() {
    const { activeMember } = this.state;
    const {
      brandingDetails,
      currentBundle,
    } = this.props;
    const {
      domainBrand,
      isAngi,
      displayName,
    } = brandingDetails;

    const {
      name,
      description,
      title,
      tasks,
    } = currentBundle;

    const angiFontBold = classnames(
      { 'h1angi-bold-font': brandingDetails.isAngi },
    );
    const whyHeadine = brandingDetails.isAngi ? 'Why Angi' : 'Why HomeAdvisor';
    const faqBrandingLinks = brandingContent.brandingLinks[domainBrand];
    const whenCopy = whenPageCopy(name, faqBrandingLinks, brandingDetails);
    const screenedProfesionalLink = brandingContent.getLink(domainBrand, 'screened_profesional');
    const fixPriceGuaranteeLink = brandingContent.getLink(domainBrand, 'fix_price_guarantee');
    const whenPageContainerCSS = classnames('when-page-container', { 'angi': isAngi }, 'bundle-show-page');

    return (
      <div className={whenPageContainerCSS}>
        <img alt="when page background" src={whenPageBackgroundImage(name, brandingDetails)}
             className="when-background show-for-medium" />
        <div className="when-row">
          <div className="grid-container">
            <h1 className={angiFontBold}>{title}</h1>
            <div className="bundles-when-row">
              <div className="grid-container bundles-contact">
                <WhatsIncluded currentBundle={currentBundle}/>
                {!activeMember && <div className="key-banner"><KeyBanner/></div>}
                <BundleContactInfo />
              </div>
              <div className="how-to-book cell medium-8 small-12 medium-order-1 small-order-2">
                <div className="how-to-book-section">
                  <h1 className={angiFontBold}>{title}</h1>
                  <DescriptionSection description={description}/>
                  <WhatsIncluded currentBundle={currentBundle}/>
                </div>
                {!activeMember && <div className="key-banner"><KeyBanner/></div>}
                <h3>{whyHeadine}</h3>
                <DescriptionChecklist
                  brandingDisplayName={displayName}
                  screenedProfessionalLink={screenedProfesionalLink}
                  fixPriceGuaranteeLink={fixPriceGuaranteeLink}
                />
              </div>
            </div>
          </div>
        </div>
        <HowItWorks brandingDetails={brandingDetails} serviceMachineName={name}/>
        <FaqSection
          header={whenCopy.faqHeader}
          accordionContent={whenCopy.accordionContent}
          classNames={whenCopy.faqHeaderClassNames}
          brandingDetails={brandingDetails}
        />
      </div>
    );
  }
}

BundleShowPageComponent.propTypes = {
  brandingDetails: PropTypes.shape({
    domainBrand: PropTypes.string,
    acronym: PropTypes.string,
    isAngi: PropTypes.bool,
    displayName: PropTypes.string,
    partnerId: PropTypes.string,
    siteBrandKey: PropTypes.string,
  }).isRequired,
  currentBundle: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  }).isRequired,
};

export default React.memo(BundleShowPageComponent);
