import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(({ name, customPalette }) => {
  const isAngi = name === 'angi';

  const {
    angiGray200,
    angiGray500,
    angiPurple500,
    haGrayDark,
    haGrayLight,
    haPrimary,
    white,
  } = customPalette;

  const borderRadius = isAngi ? '4px' : '8px';
  const borderColorChecked = isAngi ? angiGray500 : haGrayDark;
  const borderColorUnchecked = isAngi ? angiGray200 : haGrayLight;
  const inputColor = isAngi ? angiGray500 : haPrimary;

  return {
    container: {
      border: ({ checked }) => (
        checked
          ? `2px solid ${borderColorChecked}`
          : `1px solid ${borderColorUnchecked}`
      ),
      paddingLeft: '4px',
      position: 'relative',
      minHeight: '72px',
      borderRadius,
      display: 'flex',
      alignItems: 'center',
      transition: '120ms border ease-in-out',
      overflow: 'hidden',
      cursor: 'pointer',
    },
    containerInner: {
      padding: '14px 16px 14px 0',
      width: '100%',
    },
    containerInput: {
      padding: `0 ${isAngi ? 14 : 12}px`,
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      fontSize: isAngi ? '20px' : '24px',
      color: inputColor,
      cursor: 'pointer',
    },
    label: {
      cursor: 'pointer',
    },
    labelRow: {
      display: 'flex',
      justifyContent: 'space-between',
      flex: 'flex-grow',
      alignItems: 'center',
    },
    labelSecondary: {
      fontSize: '12px',
    },
    price: {
      paddingLeft: '24px',
    },
    tag: {
      backgroundColor: isAngi ? angiPurple500 : haPrimary,
      color: white,
      display: 'block',
      fontSize: '12px',
      padding: '4px 8px',
      position: 'absolute',
      top: 0,
      right: 0,
      textTransform: isAngi ? 'uppercase' : 'none',
      fontWeight: 'bold',
      borderBottomLeftRadius: borderRadius,
      overflow: 'hidden',
    },
  };
});

const getIsEnterKey = (e = {}) => (e.key && e.key.toLowerCase() === 'enter')
  || e.keyCode === 13
  || e.which === 13;

const RadioTile = ({
  checked,
  label,
  labelSecondary,
  name,
  onChange,
  price,
  tagContent,
  value,
}) => {
  const classes = useStyles({ checked });
  const id = `radio-tile-${name}-${value}`;
  return (
    <div
      className={classes.container}
      onClick={onChange}
      role="radio"
      aria-checked={checked}
      tabIndex={0}
      onKeyDown={(e) => getIsEnterKey(e) && onChange()}
    >
      <div
        className={classes.containerInput}
      >
        <input
          id={id}
          type="radio"
          name={name}
          className={`form-control radio-custom ${classes.input}`}
          value={value}
          checked={checked}
          onChange={onChange}
          required
        />
      </div>
      <div className={classes.containerInner}>
        <div className={classes.labelRow}>
          <label
            className={classes.label}
            htmlFor={id}
          >
            {label}
          </label>
          {!labelSecondary && <span className={classes.price}>{price}</span>}
        </div>
        {labelSecondary && (
          <div className={classes.labelRow}>
            <span className={classes.labelSecondary}>{labelSecondary}</span>
            <span className={classes.price}>{price}</span>
          </div>
        )}
      </div>
      {tagContent && <span className={classes.tag}>{tagContent}</span>}
    </div>
  );
};

RadioTile.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  labelSecondary: PropTypes.node,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tagContent: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
};

RadioTile.defaultProps = {
  checked: false,
  label: undefined,
  labelSecondary: undefined,
  price: undefined,
  tagContent: undefined,
};

export default RadioTile;
