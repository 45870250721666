import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import _ from 'lodash'

class MobileWebDatePicker extends React.Component {
  handleMobileDateChange = event => {
    const { value } = event.target
    const dateString = Date.parse(value)
    const formattedDate = new Date(dateString)
    this.props.handleDateChange(formattedDate)
  }

  render () {
    const { dateStart, minDaysInFuture } = this.props
    let dates = []
    const dateRange = _.range(_.isNil(minDaysInFuture) ? 0 : minDaysInFuture, 45)
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    dateRange.map(daysInFuture => {
      let dupedDay = new Date(today.getTime()) // add i to dupedDay object to not mutate `today`
      const date = new Date(dupedDay.setDate(dupedDay.getDate() + daysInFuture))
      dates.push(date)
    })

    let formattedDateStart = dateStart
    if (!_.isNil(dateStart)) {
      const dateString = Date.parse(dateStart)
      formattedDateStart = new Date(dateString)
      formattedDateStart.setHours(0, 0, 0, 0)
    }

    return (
      <div className='select-wrapper caret-small-only show-for-small-only' >
        <select
          name='dateStart'
          value={formattedDateStart}
          onChange={this.handleMobileDateChange}
          className='form-input show-for-small-only'
        >
          {dates.map((date, i) => {
            return <option key={i} value={date}>
              {Moment(date).format('ddd MMM DD')}
            </option>
          })}
        </select>
      </div>
    )
  }
}

MobileWebDatePicker.propTypes = {
  dateStart: PropTypes.instanceOf(Date).isRequired,
  handleDateChange: PropTypes.func.isRequired,
  minDaysInFuture: PropTypes.number
}

export default MobileWebDatePicker
