import React from 'react';
import PropTypes from 'prop-types';
import useStyles from '../cart/receipt/styles';
import ReceiptBreakdownItem from './ReceiptBreakdownItem';
import { makeStyles } from '@material-ui/core/styles';

const useComponentStyles = makeStyles(({ breakpoints }) => ({
  mobileSpacing: {
    [breakpoints.down("sm")]: {
      paddingTop: "16px",
    },
  },
}));

export default function ReceiptBreakdown({ quoteLineItems, currency }) {
  const classes = useStyles();
  const componentClasses = useComponentStyles();

  quoteLineItems.sort(function (a, b) {
    if (a.category === "trust_fee") {
      return 1;
    }
    else if (b.category === "trust_fee") {
      return -1
    }
    else {
      return 0;
    }
  });
  
  return (
    <div className={`${classes.listItem} ${componentClasses.mobileSpacing}`}>
      {quoteLineItems.map((element, i) => {
        return (
          <ReceiptBreakdownItem
            quoteItem={element}
            currency={currency}
            key={i}
          />
        );
      })}
    </div>
  );
}

ReceiptBreakdown.propTypes = {
  quoteLineItems: PropTypes.arrayOf(PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    category: PropTypes.string,
    display_name: PropTypes.string,
  })).isRequired,
  currency: PropTypes.string.isRequired,
};
