import React from "react";
import classnames from "classnames";

const CheckboxSection = (props) => {
  const marginRight = props.marginRight ? 'margin-right-1' : ''
  const checkMarkCss = classnames(
    'checkmark',
    marginRight,
    { 'angi': props.brandingDetails.isAngi }
  )

  return (<div className='form-row hi'>
    <label className='checkbox-container'>
      <input name='isSubscribe' type='checkbox' checked={props.isChecked} onChange={props.handleChange} className='form-control form-input' />
      <span className={checkMarkCss} />
      {props.content}
    </label>
  </div>)
}

export default CheckboxSection;
