import PropTypes from 'prop-types'

const WarningText = ({ value }) => {
  return value ? (
    <div className='margin-top-1 red-text warning'>
      {value}
    </div>
  ) : null
}

WarningText.propTypes = {
  value: PropTypes.string.isRequired,
};

export default WarningText;
