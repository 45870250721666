class PriceGuideBreakdown extends React.Component {
  constructor(props) {
    super(props);
  };

  render(){
    return (
      <div className='trueprice-modal'>
        <h1 className='trueprice-modal-headline'>How do we get this data?</h1>
        <div className='trueprice-modal-text-container'>
    <div className='trueprice-modal-text'>1. Homeowners visit {this.props.brandingDetails.displayName}.com to find a top-rated pro to complete their home improvement project or repair.</div>
          <div className='trueprice-modal-text'>2. Once their projects are completed, the members log in to their accounts and complete a short cost survey.</div>
          <div className='trueprice-modal-text bottom-line'>3. After compiling and organizing the data, we report it back to you.</div>
        </div>
      </div>
    )
  }
};

export default PriceGuideBreakdown;
