import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import get from 'lodash/get';

const bundles = Object.freeze({
  entities: {
    holiday_glow_up: {
      id: 'holiday_glow_up', // ids are names for now, in the future this will be a db_col id
      name: 'holiday_glow_up',
      title: 'The Holiday Glow-Up',
      description: 'Make the season bright this winter—and get the lights down before spring. Book the Holiday Lighting bundle and get more done for less.',
      tasks: [
        {
          taskoid: 46334,
          serviceMachineName: 'holiday_materials',
        },
        {
          taskoid: 61520,
          serviceMachineName: 'holiday_help',
        },
      ],
    },
    tune_up: {
      id: 'tune_up',
      name: 'tune_up',
      title: 'The Tune Up',
      description: 'Anything that might need the swing of the hammer or the twist of a screwdriver, we’ve got you covered. Book the Tune Up and get more done for less.',
      tasks: [
        {
          taskoid: 39794,
          serviceMachineName: 'other_handyman_service',
        },
        {
          taskoid: 60320,
          serviceMachineName: 'outlets_ha',
        },
        {
          taskoid: 40113,
          serviceMachineName: 'faucets_replacement_ha',
        },
      ],
    },
    deep_clean: {
      id: 'deep_clean',
      name: 'deep_clean',
      title: 'The Deep Clean',
      description: 'Give your home the clean sweep it deserves. Book the Deep Clean and get more done for less.',
      tasks: [
        {
          taskoid: 40006,
          serviceMachineName: 'home_cleaning',
        },
        {
          taskoid: 40010,
          serviceMachineName: 'window_cleaning',
        },
        {
          taskoid: 40005,
          serviceMachineName: 'carpet_cleaning',
        },
      ],
    },
  },
  ids: ['holiday_glow_up', 'tune_up', 'deep_clean'],
});

export const bundlesAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const defaultBundleId = bundles.ids[0];
const bundlesSlice = createSlice({
  name: 'bundles',
  initialState: bundlesAdapter.getInitialState({
    ...bundles,
    currentBundleId: defaultBundleId,
  }),
  reducers: {
    setCurrentBundleId(state, { payload: newBundleId }) {
      const newOrDefaultBundle = get(state, ['entities', newBundleId, 'id'], defaultBundleId);
      state.currentBundleId = newOrDefaultBundle;
    },
    bundlesReceived(state, action) {
      bundlesAdapter.setAll(state, action.payload);
    },
  },
});

export const bundlesActions = bundlesSlice.actions;
export const bundlesReducer = bundlesSlice.reducer;

export default bundlesReducer;
