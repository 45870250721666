import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useStyles from '../cart/receipt/styles';
import AngiKey from './icons/AngiKey';

export default function MemberDiscountLineItem({ amount }) {
  const { name: theme } = useTheme();
  const classes = useStyles({ theme });
  return (
    <div className={classes.breakdownSpacedItems}>
      <div className={classes.keyLineItem}>
        <AngiKey style={{ height: '24px', width: '24px' }} />
        <Typography variant='body1' component='div' className={classes.biggerFont}>Key Discount (20%)</Typography>
      </div>
      <Typography variant='body1' component='div' className={classes.biggerFont}>{amount}</Typography>
    </div>
  );
}

MemberDiscountLineItem.propTypes = {
  amount: PropTypes.string.isRequired,
}
