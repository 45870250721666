import { Card, styled } from '@material-ui/core';

const StyledCard = styled(Card)(({ theme: { breakpoints } }) => ({
  padding: '40px',
  maxWidth: '632px',
  width: '100%',
  [breakpoints.down('sm')]: {
    padding: '20px 20px 0',
    marginBottom: '-8px',
    boxShadow: 'none',
    borderRadius: '0px',
  },
}));

export default StyledCard;
