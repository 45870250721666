const initialState = {
  domainBrand: 'homeadvisor',
  acronym: 'HA',
  isAngi: false,
  displayName: 'HomeAdvisor',
  partnerId: 'home_advisor',
};

export default function brandingDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_BRANDING_DETAILS':
      return { ...action.payload };
    default:
      return state;
  }
}
