import PropTypes from 'prop-types'
import Question from './Question'

import { question } from './prop-types'

const Questions = props => {
  const { questions, responses, handleChange, isAngi } = props
  return questions.map((question, key) => {
    const value = responses[question.machine_name];

    return (
      <Question
        question={question}
        value={value}
        handleChange={handleChange}
        key={key}
        isAngi={isAngi}
      />
    )
  })
}

Questions.propTypes = {
  questions: PropTypes.arrayOf(question),
  responses: PropTypes.object,
  handleChange: PropTypes.func.isRequired
}

Questions.defaultProps = {
  questions: [],
  responses: {}
}

export default Questions
