import React from 'react';
import classNames from 'classnames';
import Cookies from 'universal-cookie';
import { axios } from '../../utils/request';
import ServiceLinkSection from './ServiceLinkSection';
import { trackNoThrottle } from '../../utils/mixpanel';
import ConfirmationMsg from './ConfirmationMsg';
import BundlesServicesConfirmation from '../bundle/BundlesServicesConfirmation';

const cookies = new Cookies();
class BookingInstructions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      booking: this.props.location.state.booking,
      quote: this.props.location.state.quote,
      redirectUrl: this.props.location.state.redirectUrl,
    }

    this.trackConfirmationPage();
  }

  handleEntryChange = ({ target }) => {
    this.setState({ selectedEntryOption: target.value })
  }

  updateDetails = ({target}) => {
    this.setState({ [target.id]: target.value });
  }

  currentEntryOption = () => {
    let selected = null

    this.state.entryOptions.forEach(element => {
      if (element.machine_name === this.state.selectedEntryOption) {
        selected = element
      }
    });

    return selected
  }

  trackConfirmationPage() {
    const params = {
      methods: [],
      booking_id: this.state.booking.external_id,
      collects_entry_info: false,
    };

    trackNoThrottle('post_booking_entry_methods_shown', params)
  }

  getAdditionalEntryInstructions = () => {
    if (!this.state.entryOptions) return
    if (this.state.selectedEntryOption === 'at_home') return
    if (this.currentEntryOption() && this.currentEntryOption().requires_description) {
      return (
        <div className='entry-details'>
          <span>Add Entry Instructions</span>
          <textarea
            id='entryDetails'
            value={this.state.entryDetails}
            placeholder='req'
            onChange={this.updateDetails}
          />
        </div>
      )
    }
    return <div className='entry-details'>
        <span>Add Entry Instructions</span>
        <textarea
          id='entryDetails'
          value={this.state.entryDetails}
          placeholder='not req'
          onChange={this.updateDetails}
        />
      </div>;
  }

  postBookingInstructions = () => {
    axios
      .post(
        `/api/v1/bookings/${this.state.booking.external_id}/instructions`,
        this.instructionsParams()
      )
      .then(response => {
        window.location.href = 'https://www.homeadvisor.com'
      })
      .catch(() => {
        this.setState({ loading: false })
      })
  }

  instructionsParams = () => {
    return (
      {
        entry_method: {
          type: this.state.selectedEntryOption,
          description: this.state.entryDetails
        },
        note_to_pro: {
          description: this.state.noteToPro
        }
      }
    )
  }

  redirectToHA = () => {
    window.location = this.state.redirectUrl
  }

  render () {
    const brandingName = this.props.brandingDetails.displayName
    const confirmationButtonSmallCss = classNames('button', 'button--full', 'button--tall',
      { 'angi': this.props.brandingDetails.isAngi }
    )
    const confirmationButtonMediumCss = classNames('button', 'button--full', 'button--tall',
      { 'angi': this.props.brandingDetails.isAngi,
        'custom_angi_btn_width': this.props.brandingDetails.isAngi
      }
    )
    const paymentAndConfirmationContainerCss = classNames('payment-container confirmation-container',
      { 'angi': this.props.brandingDetails.isAngi,
      'angi-font': this.props.brandingDetails.isAngi}
    )
    const isSeasonalBundleFeatureEnabled = document.documentElement.dataset.enableSeasonalBundles === 'true';
    const externalProviderId = cookies.get('external_provider_id');
    const hasProId = externalProviderId && externalProviderId.length > 0;
    const displayEnableRebookMyProUI = !!hasProId;
    const {
      quote,
    } = this.state;
    return (
      <div className={paymentAndConfirmationContainerCss}>
        <div className='grid-container'>
        {displayEnableRebookMyProUI
          && <ConfirmationMsg quoteDate={quote.starts_at}/>}
          <div className='grid-x grid-margin-x payment-header-section text-center align-center'>
          {!displayEnableRebookMyProUI
          &&<div className='cell small-12 medium-8 small-centered'>
            {isSeasonalBundleFeatureEnabled ? <BundlesServicesConfirmation />
              :
              <div>
                <h1 className="confirmation-header-text">
                  Your booking is confirmed!
                </h1>
                <p className="confirmation-content-text">
                  Thanks for booking your Fixed-Price service with {brandingName}.
                  We've sent you a confirmation email with full booking details.
                </p>
              </div>}
              <p className='confirmation-content-text'>
                If you need support, call us at <a href="tel:1(855) 902-0606">1(855) 902-0606</a> or email us <a href='https://homeadvisor.zendesk.com/hc/en-us/requests/new'>here</a>.
              </p>
            </div>}

            <div className='show-for-medium confirmation-ha-homepage-btn-container cell small-12 medium-6'>
                <button className={confirmationButtonMediumCss} onClick={this.redirectToHA}>
                  Go to {brandingName} Home Page
                </button>
            </div>

            <div className='show-for-small-only confirmation-ha-homepage-btn-container cell small-12 medium-6'>
              <button className={confirmationButtonSmallCss} onClick={this.redirectToHA}>
                Go to {brandingName} Home Page
              </button>
            </div>

            <ServiceLinkSection />

          </div>
        </div>
      </div>
    )
  }
}

export default BookingInstructions;
