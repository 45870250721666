import React from 'react';
import { connect } from 'react-redux'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core';
import { setBrandingDetails } from '#/actions/brandingDetails'
import NavBar from './NavBar'
import Cart from './cart'
import ConfirmationPage from './confirmation/ConfirmationPage'
import PaymentPage from './payment/Index.jsx'
import WhenPage from './when/Index.jsx'
import ThankYouPage from './thank_you/Index.jsx'
import ScrollToTopOnMount from './shared/ScrollToTopOnMount'
import apiCaller from '../utils/apiCaller'
import Cookies from 'universal-cookie'
import MembershipsCancel from './memberships/Cancel'
import URLSearchParams from '@ungap/url-search-params'
import Branding from './Branding.jsx'
import { homeAdvisorTheme, angiTheme } from '#/styles/material';
import Footer from './Footer.jsx'
import SeasonalBundleRoutes from './SeasonalBundleRouter';
import { Spinner } from './shared';

if (document.documentElement.dataset.environment === 'production') {
  require('../static/newRelic.min.js')
}

const NotFound = () => <h1>Not found</h1>

class AppRouter extends React.Component {
  constructor(props) {
    super(props)

    this.navbarContainer = React.createRef()
    this.state = {
      navbarDetails: {},
      brandingDetails: {}
    }
  }

  setBrand = () => {
    const featureFlag = document.documentElement.dataset.enableSubdomainBranding;
    const domainBrand = Branding.brandFromUrl(location);
    if (featureFlag === 'true' && domainBrand) {
      this.state.brandingDetails = {
        domainBrand,
        acronym: Branding.acronym[domainBrand],
        isAngi: Branding.isAngi(domainBrand),
        displayName: Branding.displayName[domainBrand],
        partnerId: Branding.partnerId[domainBrand],
        siteBrandKey: Branding.siteBrandKeys[domainBrand],
      };
    } else {
      this.state.brandingDetails = {
        domainBrand: Branding.homeAdvisor,
        acronym: Branding.acronym[Branding.homeAdvisor],
        isAngi: false,
        displayName: Branding.displayName[Branding.homeAdvisor],
        partnerId: Branding.partnerId[Branding.homeAdvisor],
        siteBrandKey: Branding.siteBrandKeys[Branding.homeAdvisor],
      };
    }

    this.props.setBrandingDetails(this.state.brandingDetails);
  }

  setTaskoidData = () => {
    const qs = new URLSearchParams(location.search)
    const isNoTaskoidDataNeeded = location.pathname.includes('/bundles');

    if (isNoTaskoidDataNeeded) {
      this.setState({ loaded: true, taskMappingEnabled: true });
      return;
    }

    const cookies = new Cookies()
    const cookieTaskoidName = cookies.get('taskoidName')
    const taskoid = qs.get('taskoid')

    // if taskoidName exists in cookie and is not superceded by
    // qs param, default to cookie instead of requerying
    if (cookieTaskoidName && !taskoid) {
      this.setState({
        loaded: true,
        taskMappingEnabled: cookies.get('taskMappingEnabled')
      })

      return
    }
    apiCaller.setTaskoidMapping(taskoid).then(response => {
      this.setState({
        loaded: true,
        taskMappingEnabled: response.taskMappingEnabled
      })
    })
  }

  componentDidMount() {
    this.setBrand()
    this.setTaskoidData()
  }

  render () {
    const { navbarDetails, brandingDetails: { isAngi } } = this.state;
    const { isFormLoading } = this.props;
    const theme = isAngi ? angiTheme : homeAdvisorTheme;

    if (!this.state.loaded) {
      return null
    }

    return (
      <BrowserRouter>
        <ScrollToTopOnMount>
          <div>
            <ThemeProvider theme={theme}>
              <NavBar navbarContainer={this.navbarContainer} getSize={navbarDetails => this.setState({ navbarDetails })} brandingDetails={this.state.brandingDetails}/>
              { isFormLoading &&
                  (
                      <div className="grid-container">
                        <div className="grid-x grid-margin-x">
                          <div className="medium-offset-5 small-offset-4">
                            <Spinner
                                color="grey"
                                height="128"
                                width="128"
                                loading={isFormLoading}
                            />
                          </div>
                        </div>
                      </div>
                  )
              }
              <Switch>
                <Route
                  exact path='/'
                  component={({ location }) => (
                    <Redirect to={{...location, pathname: '/when'}}/>
                  )}
                />
                <Route path='/when'
                    // React Router way of passing prop to a Component.
                    // This allows us to pass url params to WhenPage as props
                    render={(props) => <WhenPage {...props} taskMappingEnabled={this.state.taskMappingEnabled} brandingDetails={this.state.brandingDetails}/>}
                />

                <Route path='/bundles' component={SeasonalBundleRoutes} />

                <Route path='/cart'
                  render={(props) => <Cart {...props} navbarContainer={this.navbarContainer} navbarDetails={navbarDetails} brandingDetails={this.state.brandingDetails}/>}
                />
                <Route path='/payment'
                    render={(props) => <PaymentPage {...props} navbarContainer={this.navbarContainer} navbarDetails={navbarDetails} brandingDetails={this.state.brandingDetails}/>}
                />

                <Route path='/confirmation?booking_id=:booking_id'
                  render={(props) => <ConfirmationPage {...props} brandingDetails={this.state.brandingDetails}/>}
                />

                <Route path='/thankyou'
                  render={(props) => <ThankYouPage {...props} brandingDetails={this.state.brandingDetails}/>}
                />

                <Route path='/memberships/:external_user_id/cancel'
                  render={(props) => <MembershipsCancel {...props} brandingDetails={this.state.brandingDetails}/>}
                />
                <Route component={NotFound} />

              </Switch>
            </ThemeProvider>
          </div>
          <Footer />
        </ScrollToTopOnMount>
      </BrowserRouter>
    )
  }
}

const mapDispatchToProps = { setBrandingDetails };
const mapStateToProps = (state) => {
  const { isFormLoading } = state.whenForm;
  return {
    isFormLoading,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
