import classnames from 'classnames'
import { mobileMaxWidthSize } from '../../utils'

class CardSection extends React.Component {
  render () {
    const defaultClasses = 'card padding-2em padding-medium-down-1-5em'
    const isMobile = window.screen.width <= mobileMaxWidthSize;

    const cardSectionCss = classnames(
      defaultClasses,
      { 'angi': this.props.brandingDetails ? this.props.brandingDetails.isAngi : false  },
      { 'margin-bottom-1em': !isMobile }
    )
    return (
      <div className={cardSectionCss}>
        {this.props.children}
      </div>
    )
  }
}

export default CardSection
