import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const TuneUpOutlesHa = ({ style }) => {
  // Brand information
  const brandingDetails = (useSelector((state) => state.brandingDetails));
  const { isAngi } = brandingDetails;

  return (
    <div>
      { isAngi
        ? (
          <svg style={style} viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M28.5 45H43.5V54.5C43.5 55.0523 43.0523 55.5 42.5 55.5C41.3954 55.5 40.5 56.3954 40.5 57.5V58.5H31.5C31.5 56.8431 30.1569 55.5 28.5 55.5V45Z" fill="#FF6153" />
            <path d="M31.5 61.5H40.5C40.5 63.9853 38.4853 66 36 66C33.5147 66 31.5 63.9853 31.5 61.5Z" fill="#FF6153" />
            <mask id="mask0_524_117378" maskUnits="userSpaceOnUse" x="28" y="45" width="16" height="15">
              <path d="M29.9061 46.4061H42.0939V54.3757C42.0939 56.7054 40.2054 58.5939 37.8757 58.5939H34.1243C31.7946 58.5939 29.9061 56.7054 29.9061 54.3757V46.4061Z" fill="#EC665F" stroke="#EC665F" strokeWidth="2.81213" />
            </mask>
            <g mask="url(#mask0_524_117378)">
              <path d="M43.5 45H27V56.625C28.97 53.1486 32.6571 51 36.6529 51H43.5V45Z" fill="#A03027" />
            </g>
            <circle cx="36" cy="27" r="21" fill="#FF6153" />
            <path d="M27.3388 28.7756L38.25 13.5L37.1497 24.5026C37.1365 24.6351 37.2405 24.75 37.3736 24.75H44.3828C44.6331 24.75 44.7733 25.0385 44.6187 25.2353L32.625 40.5L34.8212 29.5191C34.849 29.3799 34.7425 29.25 34.6005 29.25H27.583C27.3389 29.25 27.197 28.9742 27.3388 28.7756Z" fill="#A03027" />
          </svg>
        )

        : (
          <svg style={style} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5 41.698H34.828V7.5H13.5V41.698Z" fill="#D5DBF1" />
            <path fillRule="evenodd" clipRule="evenodd" d="M13.5 41.697H34.828V7.49902H13.5V41.697Z" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M18.696 22.0471H29.278V12.7881H18.696V22.0471Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M18.696 22.0471H29.278V12.7881H18.696V22.0471Z" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M22.2229 15.165V16.929" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M25.7502 15.165V16.929" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M23.9963 17.9199V19.6839" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M18.696 36.4543H29.278V27.1953H18.696V36.4543Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M18.696 36.4543H29.278V27.1953H18.696V36.4543Z" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M22.2229 29.5713V31.3353" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M25.7502 29.5713V31.3353" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M23.9963 32.3271V34.0911" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
          </svg>
        )}
    </div>

  );
};
TuneUpOutlesHa.propTypes = {
  style: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
};

TuneUpOutlesHa.defaultProps = {
  style: {
    height: '22.6px',
    width: '23px',
  },
};

export default TuneUpOutlesHa;
