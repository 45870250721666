import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { Typography } from "@material-ui/core";

const QuoteMobileTotal = (props) => {
  const visible = props.visible;
  const totalPrice = props.totalPrice;
  const isCouponApplied = props.isCouponApplied;
  const originalPrice = props.originalPrice;
  const onClickHandler = props.onClickHandler;

  return (
    <div>
      <div className="quote-card-section">
        {!visible && (
          <div className="quote-card-row mobile-line-break">
            <div className="grid-x mobile-bright-font">
              <div className="cell small-6 nowrap">
                <Typography>Total</Typography>
              </div>
              <div className="cell small-6 text-right">
                <Typography>
                  {isCouponApplied && (
                    <span className="quote-original-price">
                      {originalPrice}
                    </span>
                  )}
                  {totalPrice}
                </Typography>
              </div>
            </div>
          </div>
        )}
        <div className="quote-card-row mobile-line-break">
          <div className="grid-x">
            <div className="cell small-6">
              <a
                onClick={onClickHandler}
                className="mobile-order-summary order-summary"
              >
                View order summary{" "}
                {visible ? (
                  <RemoveIcon className="mobile-expand-icon" />
                ) : (
                  <AddIcon className="mobile-expand-icon" />
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteMobileTotal;
