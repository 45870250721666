import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

function AngiGuarantee({ className, style }) {
  return (
    <SvgIcon
      className={className}
      style={style}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={32} cy={32} r={28} fill="#FF6153" />
      <path
        d="M17.564 25.086l13.218-10.15a2 2 0 012.436 0l13.218 10.15A4 4 0 0148 28.26V44a2 2 0 01-2 2H18a2 2 0 01-2-2V28.259a4 4 0 011.564-3.173z"
        fill="#fff"
      />
      <path
        d="M32.721 28.437L32 29.52l-.721-1.082a3.973 3.973 0 00-5.083-1.35l-.228.115A3.56 3.56 0 0024 30.386c0 1.997.838 3.901 2.31 5.25l5.014 4.597a1 1 0 001.352 0l5.015-4.597A7.121 7.121 0 0040 30.386a3.56 3.56 0 00-1.968-3.184l-.228-.114a3.973 3.973 0 00-5.083 1.35z"
        fill="#A03027"
      />
    </SvgIcon>
  );
}

AngiGuarantee.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object]),
  className: PropTypes.string,
};

AngiGuarantee.defaultProps = {
  style: {},
  className: '',
};

export default AngiGuarantee;
