import applianceCleaningImage from './appliance_cleaning.jpg';
import carpetCleaningImage from './carpet_cleaning.jpg';
import chimneyCleaningImage from './chimney_cleaning.jpg';
import curtainCleaningImage from './curtain_cleaning.jpg';
import doorRepairImage from './door_repair.jpg';
import dryerVentCleaningImage from './dryer_vent_cleaning.jpg';
import exteriorDoorInstallationImage from './exterior_door_installation.jpg';
import faucetsReplacementHaImage from './faucets_replacement_ha.jpg';
import furnitureCleaningImage from './furniture_cleaning.jpg';
import gutterHelpImage from './gutter_help.jpg';
import haCeilingFanImage from './ha_ceilingfan.jpg';
import holidayHelpImage from './holiday_help.jpg';
import holidayIndoorImage from './holiday_indoor.jpg';
import holidayRemoveImage from './holiday_remove.jpg';
import homeCleaningImage from './home_cleaning.jpg';
import lawnFertilizationImage from './lawn_fertilization.jpg';
import mountTvImage from './mount_tv.jpg';
import otherHandymanServiceImage from './other_handyman_service.jpg';
import outdoorCleaningImage from './outdoor_cleaning.jpg';
import outletsHaImage from './outlets_ha.jpg';
import paintingImage from './painting.jpg';
import powerWashingImage from './power_washing.jpg';
import roofCleaningImage from './roof_cleaning.jpg';
import roofRepairImage from './roof_repair.jpg';
import shrubTrimmingImage from './shrub_trimming.jpg';
import sprinklerRepairImage from './sprinkler_repair.jpg';
import tileRepairImage from './tile_repair.jpg';
import unclogDrainsHaImage from './unclog_drains_ha.jpg';
import wallRepairsImage from './wall_repairs.jpg';
import windowCleaningImage from './window_cleaning.jpg';
import yardWorkHelpImage from './yardwork_help.jpg';

export default {
  appliance_cleaning: applianceCleaningImage,
  carpet_cleaning: carpetCleaningImage,
  chimney_cleaning: chimneyCleaningImage,
  curtain_cleaning: curtainCleaningImage,
  door_repair: doorRepairImage,
  dryer_vent_cleaning: dryerVentCleaningImage,
  exterior_door_installation: exteriorDoorInstallationImage,
  faucets_replacement_ha: faucetsReplacementHaImage,
  furniture_cleaning: furnitureCleaningImage,
  gutter_help: gutterHelpImage,
  ha_ceilingfan: haCeilingFanImage,
  holiday_help: holidayRemoveImage,
  holiday_indoor: holidayIndoorImage,
  holiday_remove: holidayRemoveImage,
  holiday_materials: holidayRemoveImage,
  home_cleaning: homeCleaningImage,
  lawn_fertilization: lawnFertilizationImage,
  mount_tv: mountTvImage,
  other_handyman_service: otherHandymanServiceImage,
  outdoor_cleaning: outdoorCleaningImage,
  outlets_ha: outletsHaImage,
  painting: paintingImage,
  power_washing: powerWashingImage,
  roof_cleaning: roofCleaningImage,
  roof_repair: roofRepairImage,
  shrub_trimming: shrubTrimmingImage,
  sprinkler_repair: sprinklerRepairImage,
  tile_repair: tileRepairImage,
  unclog_drains_ha: unclogDrainsHaImage,
  wall_repairs: wallRepairsImage,
  window_cleaning: windowCleaningImage,
  yardwork_help: yardWorkHelpImage,
};
