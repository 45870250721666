class PaymentAfterComplete extends React.Component {
  constructor(props) {
    super(props);

  };

  render() {
    return (
      <div className="payment-after-booking margin-top-1em margin-bottom-1em padding-1em">
        <p className="margin-0em"><span className="fas fa-check-circle padding-right-0-5em"> </span><b>Pay nothing for this job today</b> </p>
        <p className="margin-left-1-5em margin-top-0-5em margin-bottom-0em"> Your card won't be charged for the job until after the job is completed</p>
      </div>
    )
  }
}

export default PaymentAfterComplete
