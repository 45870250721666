import React from 'react';
import { Box, styled, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import AngiKeyIcon from '../icons/AngiKeyIcon';

function KeyBanner() {
  const { name: themeName } = useTheme();
  const fill = themeName === 'angi' ? '#FFF' : '#070441';
  const iconStyle = {
    width: '48px',
    height: '19px',
    color: fill,
  };

  const StyledKeyBanner = styled(Box)(({ theme: { customPalette, name, palette } }) => {
    const bgColor = name === 'angi' ? customPalette.angiKeyLogoColor : customPalette.haKeyBackground2;
    const textColor = name === 'angi' ? palette.primary.contrastText : palette.primary.dark;
    return ({
      background: bgColor,
      color: textColor,
      fontSize: '16px',
      lineHeight: '28px',
      maxWidth: '519px',
      maxHeight: '112px',
      borderRadius: '4px',
      margin: '0px',
      padding: '16px',
      display: 'flex',
    });
  });
  const StyledTypographyCopy = styled(Typography)(() => ({
    padding: '0px',
    margin: '0px',
    fontSize: '14px',
    lineHeight: '20px',
  }));

  const Wraper = styled('div')({
    marginRight: '16px',
    display: 'flex',
    alignItems: 'center',
  });

  return (
    <StyledKeyBanner
      sx={{
        display: 'flex',
        flexDirection: 'row',
      }}
      data-testid="keyBanner"
    >
      <Wraper>
        <AngiKeyIcon style={iconStyle} />
      </Wraper>
      <StyledTypographyCopy data-testid="keyBanner-copy" component="p">
        <strong>Save 20%</strong>
        {' '}
        on this bundle when you become an Angi Key member. Membership available at checkout.
      </StyledTypographyCopy>
    </StyledKeyBanner>
  );
}

export default KeyBanner;
