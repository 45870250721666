import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function CloseIcon({ style }) {
  return (
    <SvgIcon style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"/>
    </SvgIcon>
  );
}

CloseIcon.propTypes = {
  style: PropTypes.shape({
    color: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
  }),
};

CloseIcon.defaultProps = {
  style: {
    color: '#000000',
    height: '24px',
    width: '24px',
  }
};
