import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const HolidayGlowUpLightning = ({ style }) => {
  // Brand information
  const brandingDetails = (useSelector((state) => state.brandingDetails));
  const { isAngi } = brandingDetails;

  return (
    <div>
      { isAngi

        ? (
          <svg style={style} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line x1="9.80005" y1="10" x2="9.80005" y2="20" stroke="#FF6153" strokeWidth="2" />
            <path d="M13.8 21L14.8571 23.2837C16.0766 25.9184 15.9502 28.9796 14.5177 31.5048L12.0911 35.7824C11.6642 36.535 10.8658 37 10.0006 37V37C9.16594 37 8.39105 36.567 7.95361 35.8562L5.33155 31.5952C3.75054 29.0261 3.56184 25.8356 4.82897 23.098L5.80005 21L13.8 21Z" fill="#FF6153" />
            <path d="M13.8 21L5.80005 21L7.5486 16.6286C7.70047 16.249 8.06818 16 8.47708 16L11.123 16C11.5319 16 11.8996 16.249 12.0515 16.6286L13.8 21Z" fill="#FF6153" />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.7258 22.9995L13.8001 20.9995L5.8001 20.9995L4.82903 23.0975C4.12389 24.6209 3.86959 26.2845 4.05516 27.9033C5.96188 24.862 9.30624 22.9995 12.9191 22.9995L14.7258 22.9995Z" fill="#A03027" />
            <line x1="24" y1="15" x2="24" y2="25" stroke="#FF6153" strokeWidth="2" />
            <path d="M28 26L29.057 28.2837C30.2765 30.9184 30.1502 33.9796 28.7177 36.5048L26.2911 40.7824C25.8642 41.535 25.0657 42 24.2005 42V42C23.3659 42 22.591 41.567 22.1536 40.8562L19.5315 36.5952C17.9505 34.0261 17.7618 30.8356 19.0289 28.098L20 26L28 26Z" fill="#FF6153" />
            <path d="M32.9351 43.9401C32.6755 42.3627 31.6473 41.3245 30.0599 41.0649C29.98 41.0549 29.98 40.9451 30.0599 40.9351C31.6373 40.6755 32.6755 39.6473 32.9351 38.0599C32.9451 37.98 33.0549 37.98 33.0649 38.0599C33.3245 39.6373 34.3527 40.6755 35.9401 40.9351C36.02 40.9451 36.02 41.0549 35.9401 41.0649C34.3627 41.3245 33.3245 42.3527 33.0649 43.9401C33.0449 44.02 32.9451 44.02 32.9351 43.9401Z" fill="#A03027" />
            <path d="M14.9351 40.9401C14.6755 39.3627 13.6473 38.3245 12.0599 38.0649C11.98 38.0549 11.98 37.9451 12.0599 37.9351C13.6373 37.6755 14.6755 36.6473 14.9351 35.0599C14.9451 34.98 15.0549 34.98 15.0649 35.0599C15.3245 36.6373 16.3527 37.6755 17.9401 37.9351C18.02 37.9451 18.02 38.0549 17.9401 38.0649C16.3627 38.3245 15.3245 39.3527 15.0649 40.9401C15.0449 41.02 14.9451 41.02 14.9351 40.9401Z" fill="#A03027" />
            <path d="M28 26L20 26L21.7486 21.6286C21.9004 21.249 22.2681 21 22.677 21L25.323 21C25.7319 21 26.0996 21.249 26.2514 21.6286L28 26Z" fill="#FF6153" />
            <path fillRule="evenodd" clipRule="evenodd" d="M28.9258 27.9995L28.0001 25.9995L20.0001 25.9995L19.029 28.0975C18.3238 29.6209 18.0695 31.2845 18.2551 32.9033C20.1618 29.862 23.5062 27.9995 27.119 27.9995L28.9258 27.9995Z" fill="#A03027" />
            <line x1="38" y1="10" x2="38" y2="20" stroke="#FF6153" strokeWidth="2" />
            <path d="M42 21L43.057 23.2837C44.2765 25.9184 44.1502 28.9796 42.7177 31.5048L40.2911 35.7824C39.8642 36.535 39.0657 37 38.2005 37V37C37.3659 37 36.591 36.567 36.1536 35.8562L33.5315 31.5952C31.9505 29.0261 31.7618 25.8356 33.0289 23.098L34 21L42 21Z" fill="#FF6153" />
            <path d="M42 21L34 21L35.7486 16.6286C35.9004 16.249 36.2681 16 36.677 16L39.323 16C39.7319 16 40.0996 16.249 40.2514 16.6286L42 21Z" fill="#FF6153" />
            <path fillRule="evenodd" clipRule="evenodd" d="M42.9258 22.9995L42.0001 20.9995L34.0001 20.9995L33.029 23.0975C32.3238 24.6209 32.0695 26.2845 32.2551 27.9033C34.1618 24.862 37.5062 22.9995 41.119 22.9995L42.9258 22.9995Z" fill="#A03027" />
            <path d="M5 7.21777C10.1736 11.4566 16.7898 14.0002 24 14.0002C31.2102 14.0002 37.8264 11.4566 43 7.21777" stroke="#FF6153" strokeWidth="2" />
          </svg>
        )

        : (
          <svg style={style} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M24.4897 38.5C22.2103 38.5 19.066 37.3726 17.1746 34.8515C15.9153 33.1724 14.7175 30.2001 16.1546 25.5662C17.3137 21.8332 20.112 17.6027 22.1553 14.5124L22.9167 13.3547C23.2659 12.8205 23.8576 12.5 24.501 12.5H24.5026C25.1428 12.5016 25.736 12.8205 26.0836 13.3547L26.8483 14.5172C28.8916 17.6043 31.6883 21.8348 32.8458 25.5678C34.2829 30.2017 33.0834 33.174 31.8257 34.8515C29.9359 37.3726 26.7901 38.5 24.5123 38.5H24.4897Z" fill="#D5DBF1" />
            <path fillRule="evenodd" clipRule="evenodd" d="M24.4897 38.5C22.2103 38.5 19.066 37.3726 17.1746 34.8515C15.9153 33.1724 14.7175 30.2001 16.1546 25.5662C17.3137 21.8332 20.112 17.6027 22.1553 14.5124L22.9167 13.3547C23.2659 12.8205 23.8576 12.5 24.501 12.5H24.5026C25.1428 12.5016 25.736 12.8205 26.0836 13.3547L26.8483 14.5172C28.8916 17.6043 31.6883 21.8348 32.8458 25.5678C34.2829 30.2017 33.0834 33.174 31.8257 34.8515C29.9359 37.3726 26.7901 38.5 24.5123 38.5H24.4897Z" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M20.5 34.5664C18.996 32.9389 17.5654 30.0579 19.2818 25.5664" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M24.5 10V4" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M17.1213 13.1215L12.8787 8.87891" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M31.8787 13.1215L36.1213 8.87891" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M14 20H8" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M35 20H41" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.5 44.5H28.5V37.5H20.5V44.5Z" fill="#070441" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
          </svg>
        )}
    </div>

  );
};
HolidayGlowUpLightning.propTypes = {
  style: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
};

HolidayGlowUpLightning.defaultProps = {
  style: {
    height: '22.6px',
    width: '23px',
  },
};

export default HolidayGlowUpLightning;
