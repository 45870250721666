import React from 'react';
import BundleContactInfoPresenter from './BundleContactInfo';

function BundleContactInfo() {
  return (
    <BundleContactInfoPresenter />
  );
}

export default BundleContactInfo;
