import 'isomorphic-fetch';
import moment from 'moment';
import qs from 'qs';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { get, includes, map, takeRight, flatten } from 'lodash';
import Cookies from 'universal-cookie';
import deepCamelCase, { deepSnakeCase } from '#/utils/deepCamelCase';
import { servicesReceived, serviceUpdated } from '#/actions/services';
import { addPendingServicesToCart, providerHasBeenSet, quoteHasBeenSet, updateServiceFormErrors, addToCart, removeFromCart, updateWhenFormQuoteWorkflowParams } from '#/actions';
import { getAuthenticityToken } from '#/utils/csrf';

// These are constant for queries regardless of branding
const defaultHaPartnerIdParam = 'partner_id=home_advisor';
const defaultHapartnerId = 'home_advisor';

const subQuery = async (query, endpoint, queryArg) => {
  const queryString = endpoint.query(queryArg);
  const result = await query(queryString);
  const data = result.error ? result.error : endpoint.transformResponse(result);
  return [result, data];
};

const createDurationFormFromService = (service) => {
  const responses = map(service.form.questions, ({ machineName: key, value }) => ({ key, value }));
  const { serviceMachineName } = service;
  return deepSnakeCase({
    partnerId: defaultHapartnerId,
    responses,
    serviceMachineName,
  });
};

export const createQuoteFormFromService = (service, whenForm) => {
  const responses = map(service.form.questions, ({ machineName: key, value }) => ({ key, value }));
  const duration = service.durationOverride.value
                   || service.durationOverride.recommendedDuration
                   || service.recurrence.defaultDurationMinutes;
  const { zipcode, email, isSubscribe, commitment } = whenForm;
  const { url, quoteRequest: { purchasePlanTemplateId } } = whenForm.quoteWorkflowParams;

  return deepSnakeCase({
    quoteRequest: {
      commitment, // this is hard-coded in whenForm
      duration,
      frequency: service.frequency.selected,
      payAfterBookingComplete: true,
      responses,
      serviceName: service.serviceMachineName,
      startsAt: service.businessHours.value,
      zipcode,
      email,
      purchasePlanTemplateId,
      baseDurationMinutes: duration,
    },
    email,
    isSubscribe,
    partnerId: defaultHapartnerId,
    url,
  });
};

const transformToForm = (questionSet) => {
  const removeDefaultKey = ({ default: _unused, ...option }) => option;
  const getDefaultValue = (options, subtype) => {
    if (subtype === 'textarea') return '';
    const defaultOpt = options.find((opt) => opt.default) || options[0];
    return get(defaultOpt, 'value', null);
  };

  const questions = questionSet.map((question) => {
    const { options: unformattedOpts, subtype } = question;
    const value = getDefaultValue(unformattedOpts, subtype);
    const options = unformattedOpts.map(removeDefaultKey);
    return { ...question, options, value };
  });

  return questions.reduce((formAccum, question) => ({
    ...formAccum,
    ids: [...formAccum.ids, question.machineName],
    questions: {
      ...formAccum.questions,
      [question.machineName]: { ...question },
    },
  }), { ids: [], questions: {} });
};

const recommendedDurationEndpoint = {
  query: (form) => ({
    url: 'recommended_duration',
    method: 'POST',
    body: {
      authenticity_token: getAuthenticityToken(),
      ...form,
    },
  }),
  transformResponse: ({ data }) => deepCamelCase(data),
};

const bundleOfferingEndpoint = {
  query: ({ taskoid, zipcode }) => `bundle_offering/${zipcode}/${taskoid}`,
  transformResponse: ({ data: { services } }) => deepCamelCase(services),
};

const seasonalBundleOfferingEndpoint = {
  query: ({ zipcode, serviceMachineNames }) => ({
    url: `bundle_offering/${zipcode}`,
    params: qs.stringify({ service_machine_names: serviceMachineNames }, { arrayFormat: 'brackets' }),
  }),
  transformResponse: ({ data: { services } }) => deepCamelCase(services),
};

const questionsEndpoint = {
  query: (serviceName) => `services/${serviceName}/questions?${defaultHaPartnerIdParam}`,
  transformResponse: ({ data: { data } }) => deepCamelCase(data),
};

const formatDateTime = (dateTime) => moment(dateTime, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm');
const businessHoursEndpoint = {
  query: ({ serviceName, zipcode }) => `business_hours/${serviceName}/${zipcode}`,
  transformResponse: ({ data }) => {
    const formattedData = deepCamelCase(data);
    const soonestBookableTime = formatDateTime(formattedData.soonestBookableTime);
    return {
      ...formattedData,
      soonestBookableTime,
      value: soonestBookableTime,
    };
  },
};

const recommendedDurationQueryFn = {
  async queryFn(serviceName, api, _extraOptions, query) {
    const state = api.getState();
    const { services: { entities: { [serviceName]: service } } } = state;
    const { durationOverride } = service;
    const form = createDurationFormFromService(service);

    const [result, duration] = await subQuery(query, recommendedDurationEndpoint, form);
    const { recommendedDuration } = duration;

    const isOverrideable = get(durationOverride, 'options.length', 0) > 0;
    const maxValue = Math.max(recommendedDuration, (durationOverride.value || 0));
    const value = isOverrideable ? maxValue : recommendedDuration;
    api.dispatch(serviceUpdated({
      id: serviceName,
      changes: {
        durationOverride: {
          ...durationOverride,
          recommendedDuration,
          value,
        },
      },
    }));

    return result;
  },
};

const recurrenceEndpoint = {
  query: (serviceName) => `services/${serviceName}?${defaultHaPartnerIdParam}`,
  transformResponse: ({ data }) => deepCamelCase(data),
};

const serviceInfoEndpoint = {
  query: (serviceName) => `service_info/${serviceName}?${defaultHaPartnerIdParam}`,
  transformResponse: (service) => {
    const responseData = service?.data || service;
    const serviceInfo = deepCamelCase(responseData);
    if (serviceInfo.serviceMachineName === 'holiday_materials') {
      serviceInfo.name = 'Holiday Light Hanging';
      serviceInfo.shortDisplayName = 'Holiday Light Hanging';
      return serviceInfo;
    }
    if (serviceInfo.serviceMachineName === 'holiday_help') {
      serviceInfo.name = 'Holiday Light Removal';
      serviceInfo.shortDisplayName = 'Holiday Light Removal';
      return serviceInfo;
    }
    return serviceInfo;
  },
};

const quoteEndpoint = {
  query: (form) => ({
    url: 'quote',
    params: qs.stringify(form, { arrayFormat: 'brackets' }),
  }),
  transformResponse: ({ data }) => deepCamelCase(data.quote),
};

const transactionIdEndpoint = {
  query: (taskoid) => `transaction_id?taskoid=${taskoid}`,
  transformResponse: (data) => deepCamelCase(data),
};

const providerExternalEndpoint = {
  query: (externalProviderId) => ({ url: `providers/${externalProviderId}` }),
  transformResponse: (data) => deepCamelCase(data),
};

export const checkoutApi = createApi({
  reducerPath: 'checkoutApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/v1', fetchFn: fetch }),
  endpoints: (builder) => ({
    getIsMemberByEmailAndBrand: builder.query({
      query: ({ email, siteBrandKey }) => ({
        url: 'plans/is_member',
        params: qs.stringify(deepSnakeCase({ email, siteBrandKey })),
      }),
      transformResponse: (data) => deepCamelCase(data),
    }),
    getCartServicesByTaskoidAndZipcode: builder.query({
      async queryFn({ taskoid, zipcode }, api, _extraOptions, query) {
        const args = { taskoid, zipcode };
        const [result, services] = await subQuery(query, bundleOfferingEndpoint, args);
        if (result.error) return result;
        if (!services || services.length === 0) return { error: 'No services received' };

        const getServiceInfo = ({ serviceMachineName }) => (
          subQuery(query, serviceInfoEndpoint, serviceMachineName)
        );
        const serviceInfoResultsAndData = await Promise.all(services.map(getServiceInfo));

        const serviceData = flatten(map(serviceInfoResultsAndData, takeRight));
        const formatted = services.map((service) => {
          const byMachineName = (data) => data.serviceMachineName === service.serviceMachineName;
          const infoAttrs = serviceData.find(byMachineName);
          return ({ ...service, ...infoAttrs });
        });

        api.dispatch(servicesReceived(formatted));
        return result;
      },
    }),
    getBundleServicesByZipcode: builder.query({
      async queryFn({ zipcode, serviceMachineNames }, api, _extraOptions, query) {
        const args = { zipcode, serviceMachineNames };
        const [result, services] = await subQuery(query, seasonalBundleOfferingEndpoint, args);
        if (result.error) return result;
        if (!services || services.length === 0) return { error: 'No services received' };

        const getServiceInfo = ({ serviceMachineName }) => (
          subQuery(query, serviceInfoEndpoint, serviceMachineName)
        );
        const serviceInfoResultsAndData = await Promise.all(services.map(getServiceInfo));

        const serviceData = flatten(map(serviceInfoResultsAndData, takeRight));
        const formatted = services.map((service) => {
          const byMachineName = (data) => data.serviceMachineName === service.serviceMachineName;
          const infoAttrs = serviceData.find(byMachineName);
          return ({ ...service, ...infoAttrs });
        });

        api.dispatch(servicesReceived(formatted));
        const pendingServiceNames = formatted.map(({ serviceMachineName }) => serviceMachineName);
        api.dispatch(addPendingServicesToCart(pendingServiceNames));
        return result;
      },
    }),
    getCartServiceFormByServiceName: builder.query({
      async queryFn({ serviceName, isBundle = false }, api, _extraOptions, query) {
        const state = api.getState();
        const { whenForm: { zipcode } } = state;
        let startsAt;

        if (!isBundle) {
          const { whenForm: { quoteWorkflowParams: { quoteRequest } } } = state;
          startsAt = quoteRequest.startsAt;
        }

        const [
          [questionsResult, questionsResponse],
          [, soonestBusinessHours],
          [, recurrence],
        ] = await Promise.all([
          subQuery(query, questionsEndpoint, serviceName),
          subQuery(query, businessHoursEndpoint, { serviceName, zipcode }),
          subQuery(query, recurrenceEndpoint, serviceName),
        ]);

        const { questions, durationOverride: durationOverrideOptions } = questionsResponse;

        const form = transformToForm(questions);

        let durationOverride = {};
        if (durationOverrideOptions?.length) {
          const defaultDuration = includes(map(durationOverrideOptions, 'value'), 180) ? 180 : durationOverrideOptions[0];
          durationOverride = { options: durationOverrideOptions, value: defaultDuration };
        }

        const startValue = startsAt || formatDateTime(soonestBusinessHours.soonestBookableTime);
        const businessHours = { ...soonestBusinessHours, value: startValue };

        api.dispatch(serviceUpdated({
          id: serviceName,
          changes: { form, recurrence, businessHours, durationOverride },
        }));

        return questionsResult;
      },
    }),
    postPrimaryBundleServiceByName: builder.mutation({
      async queryFn({ serviceName: givenServiceName }, api, _extraOptions, query) {
        const {
          services: { entities },
          bundles: { currentBundleId, entities: { [currentBundleId]: { tasks } } },
          whenForm: { isSubscribe, email, zipcode },
        } = api.getState();

        const givenTask = tasks.find((task) => task.serviceMachineName === givenServiceName);

        if (givenServiceName && !givenTask) {
          return {
            error: `No task found for the requested service: ${givenServiceName}`,
          };
        }

        const task = givenTask || tasks[0];
        const { taskoid, serviceMachineName } = task;

        const {
          [serviceMachineName]: {
            name: displayName,
            businessHours,
            durationOverride: { value: selectedDuration, recommendedDuration },
            recurrence,
            form,
            frequency: frequencyOpts,
          },
        } = entities;

        const { defaultDurationMinutes } = recurrence;

        const responses = form.ids.reduce((arr, questionId) => {
          const question = form.questions[questionId];
          const { machineName: key, value } = question;
          return [...arr, { key, value }];
        }, []);

        const frequency = frequencyOpts.selected;
        const duration = selectedDuration || recommendedDuration || defaultDurationMinutes;

        const quoteWorkflowParams = {
          email,
          isSubscribe,
          url: window.location.href,
          quoteRequest: {
            commitment: 'no_commitment',
            couponCode: null,
            duration,
            frequency,
            planTemplateId: null,
            purchasePlanTemplateId: null,
            responses,
            serviceName: serviceMachineName,
            startsAt: businessHours.value,
            zipcode,
          },
        };

        // Match legacy booking flow
        const snakeCasedParams = deepSnakeCase(quoteWorkflowParams);
        const snakeCasedRecurrence = deepSnakeCase(recurrence);
        const cookies = new Cookies();
        cookies.set('frequency', frequency, { path: '/' });
        cookies.set('serviceMachineName', serviceMachineName, { path: '/' });
        cookies.set('quoteWorkflowParams', snakeCasedParams, { path: '/' });
        cookies.set('taskoidName', displayName, { path: '/' });
        cookies.set('taskoid', taskoid, { path: '/' });
        cookies.set('serviceInfo', snakeCasedRecurrence, { path: '/' });
        query(transactionIdEndpoint.query(taskoid));

        api.dispatch(updateWhenFormQuoteWorkflowParams(quoteWorkflowParams));
        api.dispatch(updateServiceFormErrors(serviceMachineName, []));

        const queryParams = deepSnakeCase({
          ...quoteWorkflowParams,
          partnerId: defaultHapartnerId,
        });
        const result = await query(quoteEndpoint.query(queryParams));
        const { error, data } = result;
        if (error) {
          const { data: { errors } } = error;
          api.dispatch(updateServiceFormErrors(serviceMachineName, errors));
        } else {
          const quote = quoteEndpoint.transformResponse(data);
          api.dispatch(quoteHasBeenSet({ serviceName: serviceMachineName, ...quote }));
          // primary tasks should not be in cart
          api.dispatch(removeFromCart(serviceMachineName));
        }

        return result;
      },
    }),
    getQuoteByServiceName: builder.mutation({
      async queryFn({ serviceName, shouldAddToCart = false }, api, _extraOptions, query) {
        const {
          whenForm,
          services: { entities: { [serviceName]: service } },
        } = api.getState();
        api.dispatch(updateServiceFormErrors(serviceName, []));
        const form = createQuoteFormFromService(service, whenForm);

        const result = await query(quoteEndpoint.query(form));
        const { error, data } = result;
        if (error) {
          const { data: { errors } } = error;
          api.dispatch(updateServiceFormErrors(serviceName, errors));
        } else {
          const quote = quoteEndpoint.transformResponse(data);
          api.dispatch(quoteHasBeenSet({ serviceName, ...quote }));
          if (shouldAddToCart) api.dispatch(addToCart(serviceName));
        }

        return result;
      },
    }),
    getProviderByExternalId: builder.query({
      async queryFn({ externalId }, api, _extraOptions, query) {
        const result = await query(providerExternalEndpoint.query(externalId));
        const { error, data } = result;
        if (error) {
          const { data: { errors } } = error;
          console.error(errors);
        } else {
          const providerInfo = providerExternalEndpoint.transformResponse(data);
          api.dispatch(providerHasBeenSet(providerInfo));
        }

        return result;
      },
    }),
    getRecommendedDurationByService: builder.query(recommendedDurationQueryFn),
    postRecommendedDurationByService: builder.mutation(recommendedDurationQueryFn),
    getQuoteByServiceForm: builder.mutation(quoteEndpoint),
    getBusinessHoursByServiceNameAndZipcode: builder.query(businessHoursEndpoint),
    getRecurrenceByServiceName: builder.query(recurrenceEndpoint),
    getBundleOfferingByTaskoidAndZipcode: builder.query(bundleOfferingEndpoint),
    getQuestionsByServiceNameAndPartnerId: builder.query(questionsEndpoint),
    getRecommendedDurationByServiceForm: builder.mutation(recommendedDurationEndpoint),
    getServiceByTaskoid: builder.query({
      query: (taskoid) => `transaction/${taskoid}`,
      transformResponse: ({ data }) => deepCamelCase(data),
    }),
    getServiceInfoByServiceMachineName: builder.query(serviceInfoEndpoint),
    postRedirectByEmailZipcodeAndPath: builder.mutation({
      query: ({ email, zipcode, path, location }) => ({
        url: 'redirect',
        method: 'POST',
        body: {
          authenticity_token: getAuthenticityToken(),
          email,
          zipcode,
          path,
          location
        },
      }),
      transformResponse: (data) => deepCamelCase(data),
    }),
    getTransactionIdByTaskoid: builder.query(transactionIdEndpoint),
  }),
});

export const {
  endpoints,
  useGetQuestionsByServiceQuery,
  useGetBundleOfferingByTaskoidAndZipcodeQuery,
  useGetQuoteByServiceFormMutation,
  useGetBusinessHoursByServiceQuery,
  useGetCartServicesByTaskoidAndZipcodeQuery,
  useGetCartServiceFormByServiceNameQuery,
  useGetRecurrenceByServiceNameQuery,
  useGetQuoteByServiceNameMutation,
  useGetRecommendedDurationByServiceQuery,
  usePostRecommendedDurationByServiceMutation,
  usePostPrimaryBundleServiceByNameMutation,
  useGetServiceInfoByServiceMachineNameQuery,
  useGetProviderByExternalIdQuery,
  useGetBundleServicesByZipcodeQuery,
  usePostRedirectByEmailZipcodeAndPathMutation,
  useGetIsMemberByEmailAndBrandQuery,
} = checkoutApi;
