import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class CouponCodeInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      applyRemoveToggle: props.couponCode === null || props.couponCode === '' ? 'Apply' : 'Remove',
      couponCode: props.couponCode,
      isCouponLoading: props.isCouponLoading
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    let value = event.target.value
    value = _.isString(value) ? value.toUpperCase().trim() : value

    this.setState({ couponCode: value })
  };

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.applyRemoveToggle === 'Apply' && this.state.couponCode) {
      this.setState({ applyRemoveToggle: '' })
      setTimeout(() => { this.setState({ applyRemoveToggle: 'Remove', isCouponLoading: false }) }, 1500);
      this.props.onCouponCodeChange(this.state.couponCode)
    } else if (this.state.applyRemoveToggle === 'Remove') {
      this.setState({ applyRemoveToggle: 'Apply', couponCode: '', isCouponLoading: false })
      this.props.onCouponCodeChange('')
    }
  }

  componentDidMount() {
    this.setState({isCouponLoading: false})
  }

  render(){
    const couponButton = classnames(
      'button',
      'coupon-button',
      {'angi': this.props.brandingDetails.isAngi},
      {'coupon-button-darker': this.props.brandingDetails.isAngi && this.state.couponCode.length > 0},
      {'coupon-loading fa fa-check': this.props.isCouponLoading}
    )

    const couponInput = classnames(
      'form-control',
      'form-input',
      'form-input--full',
      'coupon-input',
      {'angi': this.props.brandingDetails.isAngi},
    )

    return (
      <div className='quote-card-row'>
        <div className='grid-x'>
          <form className='coupon-input-form' onSubmit={this.handleSubmit}>
            <div className='cell small-8'>
              <input
                name='coupon_code'
                type='text'
                value={this.state.couponCode}
                onChange={this.handleChange}
                className={couponInput}
                placeholder='Enter promo code'
              />
            </div>
            <div className='cell auto coupon-button'>
              <button className={couponButton}>{this.state.applyRemoveToggle}</button>
            </div>
          </form>
        </div>
        <div className='error invalid-field'>{this.props.couponError}</div>
        <span className='invalid-field' />
      </div>
    )
  }
}

CouponCodeInput.propTypes = {
  couponCode: PropTypes.string,
  couponError: PropTypes.string,
  isCouponLoading: PropTypes.bool,
  onCouponCodeChange: PropTypes.func.isRequired,
  brandingDetails: PropTypes.object.isRequired,
};

CouponCodeInput.defaultProps = {
  couponCode: '',
  couponError: '',
  isCouponLoading: false,
};

export default CouponCodeInput;
