import AccordionSection from './AccordionSection';

class Accordion extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let {items} = this.props;

    if (!_.isEmpty(items)) {
      return (
        <div className='accordion'>
          {items.map((item, i) => (
            <AccordionSection key={i} label={item.label} text={item.text} disclaimer={item.disclaimer} brandingDetails={this.props.brandingDetails}/>
          ))}
        </div>
      )
    }

    return null;
  }
}

export default Accordion
