import React from "react";
import { useSelector } from "react-redux";
import { Typography, Box } from "@material-ui/core";
import {
  receiptLineItemsSelector,
  receiptPendingLineItemsSelector,
  whenFormEmailSelector,
  siteBrandKeySelector,
} from "#/selectors";
import { useGetIsMemberByEmailAndBrandQuery } from "#/apiServices";
import { useTheme } from "@material-ui/core/styles";
import AngiKeyLogo from "../../../../images/angi-branded-key.png";
import AngiMemberKeyLogo from "../../../../images/KeyBadge_Angi.png";
import HaKeyLogo from "../../../../images/ha-branded-key.png";
import HaMemberKeyLogo from "../../../../images/KeyBadge_HA.png";
import useStyles from "./styles";

// https://homeadvisor.services.handy.com/docs/api#operation/memberships.planTemplate
// Should be updated to user the new endpoint for fetching discounts
// https://gitlab.com/angi1/engineering/service-checkout-backend/-/merge_requests/90#note_751029857
const DISCOUNT_PERCENTAGE = 0.2;

export default function AngiKeyReceiptPromo() {
  const receiptLineItems = useSelector(receiptLineItemsSelector);
  const pendingServiceLineItems = useSelector(receiptPendingLineItemsSelector);
  const email = useSelector(whenFormEmailSelector);
  const siteBrandKey = useSelector(siteBrandKeySelector);
  const { data } = useGetIsMemberByEmailAndBrandQuery({ email, siteBrandKey });
  const { name: theme } = useTheme();
  const classes = useStyles({ theme });
  const isMember = data?.isMember;

  const sum = (amtKey, items) =>
    items.reduce((total, { [amtKey]: amount }) => total + amount, 0);

  const cartTotal = sum(
    "amount",
    receiptLineItems.filter((item) => item.category === "base")
  );
  const pendingTotal = sum("priceCents", pendingServiceLineItems);
  const total = cartTotal + pendingTotal;

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const discountDisplay = formatter.format((total * DISCOUNT_PERCENTAGE) / 100);

  const nonMemberView = () => (
    <Box pt={2} className={classes.nonMemberContainer}>
      {name === "angi" ? (
        <img
          className={classes.keyLogo}
          src={AngiKeyLogo}
          alt="Angi key logo"
        />
      ) : (
        <img className={classes.keyLogo} src={HaKeyLogo} alt="HA key logo" />
      )}
      <Typography className={classes.nonMember}>
        Become an Angi Key member at checkout and save <b>{discountDisplay}</b>
      </Typography>
    </Box>
  );

  const memberView = () => (
    <Box pt={2} className={classes.memberContainer}>
      <Typography className={classes.member}>
        {name === "angi" ? (
          <img
            className={classes.keyLogo}
            src={AngiMemberKeyLogo}
            alt="Angi member key logo"
          />
        ) : (
          <img
            className={classes.keyLogo}
            src={HaMemberKeyLogo}
            alt="HA member key logo"
          />
        )}
        <span className={classes.memberText}>Key member discount</span>
      </Typography>
      <Typography className={classes.price}>{discountDisplay}</Typography>
    </Box>
  );

  return isMember ? memberView() : nonMemberView();
}
