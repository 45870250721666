import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { setCurrentBundleId } from '#/actions/bundles';
import BundleEditPage from './BundleEditPage';
import { Omniture } from '../../../utils/analytics';
import { fetchCurrentUserType } from '../../../utils/user';
import { useHydratedWhenFormStateFromCookies } from '#/hooks';

const cookies = new Cookies();
const omniture = new Omniture();
export default function HydratedBundleEditPage(props) {
  const { match: { params: { id: bundleId } } } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentBundleId(bundleId));
  }, [dispatch]);

  const isHydrated = useHydratedWhenFormStateFromCookies();
  const entryPointId = cookies.get('entryPointId');
  const extFulTrack = cookies.get('extFulTrack');
  const pageName = 'FixedPriceHandy: Bundles';
  const trackBundleEditPageLoaded = (angiKeyMember, userTypeLabel) => {
    omniture.track({
      pageName,
      entryPointId,
      extFulTrack,
      catchall: bundleId,
      prop58: 'FixedPriceHandy: Bundles Edit',
      angiKeyMember,
      userTypeLabel,
    });
  };

  useEffect(() => {
    fetchCurrentUserType().then((user) => {
      trackBundleEditPageLoaded(user.angiKeyMember, user.userTypeLabel);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isHydrated) return <div />;

  return (
    <BundleEditPage />
  );
}

HydratedBundleEditPage.propTypes = PropTypes.shape({
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}).isRequired;
