import PropTypes from 'prop-types'

// form wrapper class with built in validation
// assume input elements are wrapped in <div>'s and that all fields requiring
// validation have `form-control` className
class ValidForm extends React.Component {
  state = {
    isValidated: false
  }

  validate = () => {
    const inputFields = []
    const invalidFields = []

    this.formElem.childNodes.forEach(div => {
      const input = _.compact(div.querySelectorAll('.form-control'))

      if (!_.isEmpty(input)) inputFields.push(...input)
    })

    if (!this.formElem.checkValidity()) {
      inputFields.forEach(elem => {
        const errorLabel = elem.parentNode.querySelector('.invalid-field')

        if (!elem.validity.valid) {
          if (errorLabel) {
            errorLabel.textContent = this.getValidationErrorMessage(elem)
          }
          elem.classList.add('highlight-red')
          invalidFields.push(elem)
        } else {
          resetValidation(elem)
        }
      })

      // scroll to first invalid input
      try {
        invalidFields[0].scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      } catch (error) {
        //fallback to prevent browser crashing
        invalidFields[0].scrollIntoView(false);
      }

      return false
    } else {
      inputFields.forEach(resetValidation)

      return true
    }
  }

  submitHandler = event => {
    event.preventDefault()

    if (this.validate()) {
      this.props.submit()
    }

    this.setState({isValidated: true})
  }

  getValidationErrorMessage = (element) => {
    const {
      validity: {
        patternMismatch,
        typeMismatch
      },
      validationMessage,
      type,
      value,
      name
    } = element

    if(name === 'zipcode' && patternMismatch) {
      return 'Please enter a valid zip code'
    }

    if (type === 'email' && patternMismatch && !typeMismatch) {
      const endsWithFirstLevelDomain = /\.[\w]+$/
      if (endsWithFirstLevelDomain.test(value)) {
        return "Email should not contain special characters like '!'. '$', '%', etc. Please enter a valid email."
      } else {
        return 'Please include a valid domain (like ".com" or ".gov") in the email address.'
      }
    } else {
      return validationMessage
    }
  }

  render() {
    const validatedClass = this.state.isValidated ? 'was-validated' : ''
    const inheritedProps = _.omit(this.props, ['className', 'submit'])

    return (
      <form
        {...inheritedProps}
        className={`${this.props.className} ${validatedClass}`}
        ref={form => this.formElem = form}
        onSubmit={this.submitHandler}
        noValidate>

        {/* form contents rendered below */}
        { this.props.children }

      </form>
    )
  }
}

function resetValidation(elem) {
  const errorLabel = elem.parentNode.querySelector('.invalid-field')

  if (errorLabel && elem.nodeName.toLowerCase() !== 'button') {
    errorLabel.textContent = ''
  }

  elem.classList.remove('highlight-red')
}

ValidForm.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  submit: PropTypes.func.isRequired
}

export default ValidForm
