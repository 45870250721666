/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import Cookies from 'universal-cookie';
import URLSearchParams from '@ungap/url-search-params';
import { usePostRedirectByEmailZipcodeAndPathMutation } from '#/apiServices/checkout';
import { axios } from '#/utils/request';
import PhoneInputSection from '#/components/shared/PhoneInputSection';
import ValidForm from '#/components/shared/ValidForm';
import Spinner from '#/components/shared/Spinner';
import CheckboxSection from '#/components/shared/CheckboxSection';
import { currentBundleSelector } from '#/selectors/bundles';
import { setContactInfo } from '#/actions/whenForm';
import LegalText from './LegalText';
import { deepSnakeCase } from '#/utils/deepCamelCase';

const cookies = new Cookies();
const formAttr = cookies.get('formAttribute');
const location = window.location;
const windowLocation = location.search;

function BundleContactInfo({ history }) {
  const dispatch = useDispatch();
  const currentBundle = useSelector(currentBundleSelector);
  // Brand information
  const brandingDetails = (useSelector((state) => state.brandingDetails));
  const { domainBrand, isAngi } = brandingDetails;
  // State hooks
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    zipcode: formAttr?.zipcode || '',
    firstName: formAttr?.firstName || '',
    lastName: formAttr?.lastName || '',
    phone: formAttr?.phone || '',
    email: formAttr?.email || '',
    isSubscribe: formAttr?.isSubscribe || false,
  });
  const [postRedirectInfo] = usePostRedirectByEmailZipcodeAndPathMutation();

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    const newValue = name === 'isSubscribe' ? checked : value;
    setForm({ ...form, [name]: newValue });
  };

  const saveToCookies = () => {
    cookies.set('formAttribute', form, { path: '/' });
  };

  const createEmailLead = async (email, zipcode, phone) => {
    const path = { pathname: `/bundles/${currentBundle.id}/edit` };
    //   Query params
    const qs = new URLSearchParams(windowLocation);

    const leadProperties = {
      email_address: email,
      context: 'fpp_checkout_flow',
      collecting_url: window.location.href,
      capturing_brand: domainBrand,
      created_through: 'live_submission',
      zip: zipcode,
      phone_number: phone,
      // platform: platform,
      first_name: form.firstName,
      last_name: form.lastName,
      home_advisor_opt_in: true,
      utm_source: qs.get('utm_source'),
      utm_medium: qs.get('utm_medium'),
      utm_campaign: qs.get('utm_campaign'),
      utm_content: qs.get('utm_content'),
      utm_term: qs.get('utm_term'),
    };
    const { data } = await postRedirectInfo({ email, path: path.pathname, location: location.href, zipcode });
    const redirectUrl = data?.redirectUrl || path;

    if (form.isSubscribe) { axios.post('/api/v1/email_lead', leadProperties); }

    if (data?.isExternalRedirect) {
      window.location = redirectUrl;
      return null;
    }

    history.push(redirectUrl);
  };

  const handleSubmit = () => {
    setLoading(true);
    createEmailLead(form.email, form.zipcode, form.phone);
    saveToCookies();
    dispatch(setContactInfo(form));
  };

  const containerClass = classnames(
    'bundle-contact-info-container',
    {
      angi: isAngi,
    },
  );

  const btn = classnames(
    'button',
    {
      'button--full': !isAngi,
      'button-angi': isAngi,
    },
  );

  const bundleContactHeader = classnames(
    'bundle-contact-info',
    { angi: isAngi },
  );
  return (
    <div>
      <div className={containerClass}>
        <ValidForm className={bundleContactHeader} submit={handleSubmit}>
          <div className="bundle-contact-info-header-container">
            <h3>{currentBundle.title}</h3>
          </div>
          <div className="bundle-contact-info-row">
            <input
              required
              name="zipcode"
              type="text"
              pattern="[0-9]{5}"
              value={form.zipcode}
              onChange={handleChange}
              className="form-control form-input form-input--full"
              placeholder="Zip Code"
            />
            <span className="invalid-field" />
          </div>
          <p>Contact Info</p>
          <div className="bundle-contact-info-row">
            <input
              required
              name="firstName"
              type="text"
              value={form.firstName}
              onChange={handleChange}
              className="form-control form-input form-input--full"
              placeholder="First Name"
            />
            <span className="invalid-field" />
          </div>
          <div className="bundle-contact-info-row">
            <input
              required
              name="lastName"
              type="text"
              value={form.lastName}
              onChange={handleChange}
              className="form-control form-input form-input--full"
              placeholder="Last Name"
            />
            <span className="invalid-field" />
          </div>
          <PhoneInputSection
            name="phone"
            placeholder="Phone"
            handleChange={handleChange}
            value={form.phone}
            required
            addMargin
            brandingDetails={brandingDetails}
          />
          <div className="bundle-contact-info-row">
            <input
              className="form-control form-input form-input--full"
              required
              name="email"
              type="email"
              pattern="^[\w.-]+@[\w.-]+\.[\w]+$"
              value={form.email}
              onChange={handleChange}
              placeholder="Email"
            />
            <span className="invalid-field" />
          </div>
          <CheckboxSection
            isChecked={form.isSubscribe}
            value={form.isSubscribe}
            handleChange={handleChange}
            content="Sign me up to receive advice and deals in my inbox."
            marginRight
            brandingDetails={brandingDetails}
          />
          <button type="submit" className={btn} disabled={loading}>
            <Spinner content="Continue" loading={loading} />
          </button>
        </ValidForm>
      </div>
      <LegalText />
    </div>
  );
}

BundleContactInfo.propTypes = {
  history: PropTypes.instanceOf(Object),
};
BundleContactInfo.defaultProps = {
  history: {},
};

export default withRouter(BundleContactInfo);
