import React from 'react';
import { Avatar, Grid, styled, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const StyledAvatarName = styled(Avatar)(({ theme: { customPalette, name, palette } }) => {
  const bgColor = name === 'angi' ? customPalette.angiDarkGrayBackground3 : customPalette.haDarkGrayBackground;
  return ({
    background: bgColor,
    color: palette.text.base,
    fontSize: '16px',
    lineHeight: '28px',
  });
});

const StyledTypographyName = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.text.base,
  margin: 0,
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: '700',
}));
const StyledTypographyDescription = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.text.secondary,
  margin: 0,
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: '300',
}));

const getInitials = (string) => {
  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
};

function AvatarName({ name, description, vertical, srcImg }) {
  const config = vertical ? { direction: 'column', justifyContent: 'left', alignItems: 'flex-start' } : { direction: 'row', justifyContent: 'center', alignItems: 'center' };
  return (
    <Grid
      container
      direction={config.direction}
      justifycontent={config.justifyContent}
      alignItems={config.alignItems}
      spacing={1}
      data-testid="avatarName"
    >
      <Grid item>
        <StyledAvatarName data-testid="avatarName-img" alt={name} src={srcImg}>{getInitials(name)}</StyledAvatarName>
      </Grid>
      <Grid item>
        <StyledTypographyName data-testid="avatarName-title" component="p">
          { name }
        </StyledTypographyName>
        {description
          && (
          <StyledTypographyDescription data-testid="avatarName-description" component="div">
            { description }
          </StyledTypographyDescription>
          )}
      </Grid>
    </Grid>

  );
}
AvatarName.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  srcImg: PropTypes.string,
  vertical: PropTypes.bool,
};

AvatarName.defaultProps = {
  description: null,
  srcImg: null,
  vertical: false,
};
export default AvatarName;
