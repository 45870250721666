import classnames from 'classnames'

function WhatsIncluded(props) {
  const isAngi = props.brandingDetails.isAngi
    const buttonClass = classnames(
      'button',
      'get-started',
      {
        'angi': isAngi
      }
    )

    const whatsIncludedContainer = classnames(
      'when-row',
      'whats-included-container',
      {'angi': isAngi}
    )
    
  return (
    <div className={whatsIncludedContainer}>
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell small-12 card">
            <div className="grid-x grid-margin-x">
              <h2 className="whats-included-header">What's included in a Home Cleaning?</h2>
            </div>
            <div className="grid-x grid-margin-x">

              <div className="cell small-12 medium-6 whats-included-cell">
                <h4>Bedroom, Living Room & Common Areas</h4>
                <ul className="whats-included-list">
                  <li>Dust all accessible surfaces</li>
                  <li>Wipe down all mirrors and glass fixtures</li>
                  <li>Clean all floor surfaces</li>
                  <li>Take out garbage and recycling</li>
                </ul>
              </div>

              <div className="cell small-12 medium-6 whats-included-cell">
                <h4>Kitchen</h4>
                <ul className="whats-included-list">
                  <li>Dust all accessible surfaces</li>
                  <li>Empty sink and load up dishwasher with dirty dishes</li>
                  <li>Wipe down exterior of stove, oven and fridge</li>
                  <li>Clean all floor surfaces</li>
                  <li>Take out garbage and recycling</li>
                </ul>
              </div>

              <div className="cell small-12 medium-6 whats-included-cell">
                <h4>Bathrooms</h4>
                <ul className="whats-included-list">
                  <li>Wash and sanitize the toilet, shower, tub and sink</li>
                  <li>Dust all accessible surfaces</li>
                  <li>Wipe down all mirrors and glass fixtures</li>
                  <li>Clean all floor surfaces</li>
                  <li>Take out garbage and recycling</li>
                </ul>
              </div>

              <div className="cell small-12 medium-6 whats-included-cell">
                <h4>Extras</h4>
                <p>Talk with your pro about other specific cleaning needs, such as:</p>
                <ul className="whats-included-list">
                  <li>Inside cabinets</li>
                  <li>Inside fridge</li>
                  <li>Inside oven</li>
                  <li>Laundry wash & dry</li>
                  <li>Interior windows</li>
                </ul>
              </div>
              <button className={buttonClass} onClick={props.scrollToWhenFormRef}>
                Get Started
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhatsIncluded
