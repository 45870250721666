import React from 'react';
import classNames from 'classnames';

export default function MembershipSelectionDescriptionV2({ discount, isAngi }) {
  const paraWithKeyClasses = classNames(
    'margin-top-0em',
    {'color: #282827': isAngi}
  );

  return (
    <>
      <p className={paraWithKeyClasses}>
        <strong>Save {discount}</strong> on today's service when you become an Angi Key member plus get 20% off all Angi Services every time.
      </p>
    </>
  )
}
