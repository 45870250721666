export function setContactInfo(formData) {
  return {
    type: 'bundles/setContactInfo',
    payload: formData,
  };
}

export function setWhenFormData(formData) {
  return {
    type: 'SET_WHEN_FORM_DATA',
    payload: formData,
  };
}

export function updateMembershipPlan(planId) {
  return {
    type: 'UPDATE_MEMBERSHIP_PLAN',
    payload: planId,
  };
}

export function setExternalProviderId(externalProviderId) {
  return {
    type: 'SET_EXTERNAL_PROVIDER_ID',
    payload: externalProviderId,
  };
}

export function clearWhenFormData() {
  return {
    type: 'CLEAR_WHEN_FORM_DATA',
  };
}

export function whenFormIsHydrated() {
  return {
    type: 'WHEN_FORM_IS_HYDRATED',
  };
}

export function setFormLoadState(isFormLoading) {
  return {
    type: 'FORM_IS_LOADING',
    payload: isFormLoading,
  };
}
