import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const TuneUpHandymanService = ({ style }) => {
  // Brand information
  const brandingDetails = (useSelector((state) => state.brandingDetails));
  const { isAngi } = brandingDetails;

  return (
    <div>
      { isAngi

        ? (
          <svg style={style} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 17C4 15.3431 5.34315 14 7 14H41C42.6569 14 44 15.3431 44 17V24H4V17Z" fill="#FF6153" />
            <path d="M7 24H41V37C41 38.6569 39.6569 40 38 40H10C8.34315 40 7 38.6569 7 37V24Z" fill="#FF6153" />
            <path d="M41 24H7V27.2V32C8.05444 28.4413 11.324 26 15.0356 26H40.9993L41 24Z" fill="#A03027" />
            <path d="M29 24L29 27C29 28.1046 28.1046 29 27 29L21 29C19.8954 29 19 28.1046 19 27L19 24L29 24Z" fill="#A03027" />
            <path d="M18.5 14V12C18.5 10.3431 19.8431 9 21.5 9L26.5 9C28.1569 9 29.5 10.3431 29.5 12V14" stroke="#FF6153" strokeWidth="2" />
          </svg>
        )

        : (
          <svg style={style} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M36.2045 24.4268H12.1755" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M40.5131 24.423H41.8791V19.555C41.8791 18.19 40.7721 17.082 39.4071 17.082H8.97212C7.60712 17.082 6.50012 18.19 6.50012 19.555V24.423H7.83812" fill="#D5DBF1" />
            <path d="M40.5131 24.423H41.8791V19.555C41.8791 18.19 40.7721 17.082 39.4071 17.082H8.97212C7.60712 17.082 6.50012 18.19 6.50012 19.555V24.423H7.83812" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M18.5336 17.096V13.5H29.8266V17.096" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.83502 35.4398H40.544V24.4268H7.83502V35.4398Z" fill="#D5DBF1" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.83502 35.4398H40.544V24.4268H7.83502V35.4398Z" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.868 27.0164H17.534V21.7334H15.868V27.0164Z" fill="#10103F" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.868 27.0164H17.534V21.7334H15.868V27.0164Z" stroke="#10103F" strokeWidth="0.5" />
            <path fillRule="evenodd" clipRule="evenodd" d="M30.868 27.0164H32.534V21.7334H30.868V27.0164Z" fill="#10103F" />
            <path fillRule="evenodd" clipRule="evenodd" d="M30.868 27.0164H32.534V21.7334H30.868V27.0164Z" stroke="#10103F" strokeWidth="0.5" />
          </svg>
        )}
    </div>

  );
};
TuneUpHandymanService.propTypes = {
  style: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
};

TuneUpHandymanService.defaultProps = {
  style: {
    height: '22.6px',
    width: '23px',
  },
};

export default TuneUpHandymanService;
