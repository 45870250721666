import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import DeepCleanHomeCleaning from '../../shared/icons/DeepCleanHomeCleaning';
import DeepCleanWindowCleaning from '../../shared/icons/DeepCleanWindowCleaning';
import DeepCleaningCarpetCleaning from '../../shared/icons/DeepCleanCarpetCleaning';
import TuneUpHandymanService from '../../shared/icons/TuneUpHandymanService';
import TuneUpOutletsHa from '../../shared/icons/TuneUpOutletsHa';
import TuneUpFaucetsReplacement from '../../shared/icons/TuneUpFaucetsReplacement';
import HolidayGlowUpLightning from '../../shared/icons/HolidayGlowUpLightning';
import { useGetServiceInfoByServiceMachineNameQuery } from '#/apiServices/checkout';
// import { property } from 'lodash';

const ServiceDisplayName = ({ machineName }) => {
  const { data, error, isLoading } = useGetServiceInfoByServiceMachineNameQuery(machineName);

  let displayName;
  if (error) {
    displayName = machineName;
  } else if (isLoading) {
    displayName = undefined;
  } else {
    displayName = data.name;
  }

  if (!displayName) return null;

  return (
    <span>{displayName}</span>
  );
};

const WhatsIncluded = ({ currentBundle }) => {
  const { tasks } = currentBundle;

  const brandingDetails = (useSelector((state) => state.brandingDetails));

  const { isAngi } = brandingDetails;
  const whatsIncludedHeaderClass = classnames(
    'whats-included-header',
    {
      angi: isAngi,
    },
  );
  const whatsIncludedServiceNameClass = classnames(
    'bundle-whats-included-service-name',
    {
      angi: isAngi,
    },
  );
  const whatsIncludedServiceRow = classnames(
    'bundle-whats-included-service-row',
    {
      angi: isAngi,
    },
  );

  const getIcon = (taskoId) => {
    switch (taskoId) {
      case 40006:
        return <DeepCleanHomeCleaning />;
      case 40010:
        return <DeepCleanWindowCleaning />;
      case 40005:
        return <DeepCleaningCarpetCleaning />;
      case 39794:
        return <TuneUpHandymanService />;
      case 60320:
        return <TuneUpOutletsHa />;
      case 40113:
        return <TuneUpFaucetsReplacement />;
      case 46334:
      case 61520:
        return <HolidayGlowUpLightning />;
      default:
        return null;
    }
  };

  return (

    <div className="bundle-whats-included-container" data-testid="whats-included">
      <span className={whatsIncludedHeaderClass} data-testid="whats-included-title" id="service-names">What's included</span>
      <ul className="bundle-whats-included-services-container" aria-labelledby="service-names">
        {
                    tasks && tasks.length > 0 ? tasks.map((task) => (
                      <li className={whatsIncludedServiceRow} key={`${task.taskoid}`}>
                        <div className="bundle-whats-included-service-icon">
                          {getIcon(task.taskoid)}
                        </div>
                        <div className={whatsIncludedServiceNameClass}>
                          <ServiceDisplayName machineName={task.serviceMachineName} />
                        </div>
                      </li>

                    ))
                      : null

                }
      </ul>
    </div>

  );
};
ServiceDisplayName.propTypes = {
  machineName: PropTypes.string.isRequired,
};

WhatsIncluded.propTypes = {
  currentBundle: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    tasks: PropTypes.arrayOf(PropTypes.shape({
      taskoid: PropTypes.number,
      serviceMachineName: PropTypes.string,
    }).isRequired),
  }).isRequired,
};
export default WhatsIncluded;
