import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Hidden, Box } from '@material-ui/core';
import { range, isInteger } from 'lodash';
import { updateBookingDate } from '#/actions/services';
import { useAddToCartByServiceNameMutation } from '#/hooks';
import { createSelectIsInCartByServiceId, createSelectRecurrenceByServiceId, createSelectHasDurationByServiceId, createSelectBusinessHoursAsMomentByServiceId, createSelectBusinessHoursByServiceId, createSelectDurationByServiceId } from '#/selectors';
import Select from '#/components/shared/Select';
import Label from '#/components/cart/task/taskForm/Label';
import DatePicker from './DatePicker';

const createOptions = ({ start, stop, interval, cb }) => (
  range(start, stop, interval).map((time) => {
    const optionValue = cb(time);

    return {
      value: optionValue,
      text: optionValue,
    };
  })
);

const startOfDay = (momentDate) => momentDate.clone().startOf('day');
const asDate = (momentDate) => startOfDay(momentDate).toDate();

export default function DateTimePicker({ id }) {
  const dispatch = useDispatch();
  const duration = useSelector(createSelectDurationByServiceId(id));
  const isInCart = useSelector(createSelectIsInCartByServiceId(id));
  const businessHoursMoments = useSelector(createSelectBusinessHoursAsMomentByServiceId(id));
  const businessHoursState = useSelector(createSelectBusinessHoursByServiceId(id));
  const hasDurationOverride = useSelector(createSelectHasDurationByServiceId(id));
  const { minimumDurationMinutes } = useSelector(createSelectRecurrenceByServiceId(id));
  const [addQuote] = useAddToCartByServiceNameMutation(id);

  let bookingLength = minimumDurationMinutes;
  if (hasDurationOverride) {
    bookingLength = duration?.value || duration.recommendedDuration;
  }
  const bookingLengthHours = bookingLength / 60;

  const dayDisplayFormat = 'ddd MMM DD';
  const timeDisplayFormat = 'h:mm A';

  const { minHour, maxHour } = businessHoursState;
  const { soonestBookableTime, value } = businessHoursMoments;

  const handleMobileDayChange = (day) => {
    const date = moment(day, dayDisplayFormat);
    dispatch(updateBookingDate(id, date, { setHour: false }));
    if (isInCart) addQuote({ serviceName: id });
  };
  const handleDatePickerDayChange = (jsDate) => {
    const date = moment(jsDate);
    if (isInCart) addQuote({ serviceName: id });
    dispatch(updateBookingDate(id, date, { setHour: false }));
  };
  const handleTimeChange = (time) => {
    const date = moment(time, timeDisplayFormat);
    if (isInCart) addQuote({ serviceName: id });
    dispatch(updateBookingDate(id, date, { setDay: false }));
  };

  const currentDay = startOfDay(value).format(dayDisplayFormat);
  const currentTime = value.clone().format(timeDisplayFormat);

  const createDayOption = (daysInFuture) => {
    const day = startOfDay(soonestBookableTime);
    day.add(daysInFuture, 'days');
    return day.format(dayDisplayFormat);
  };
  const dayMobileOptions = createOptions({ start: 0, stop: 45, interval: 1, cb: createDayOption });

  const createTimeOption = (hour) => moment({
    hour, minute: isInteger(hour) ? 0 : 30,
  }).format(timeDisplayFormat);
  const stop = (maxHour - bookingLengthHours) + 0.5;
  const timeOptions = createOptions({ start: minHour, stop, interval: 0.5, cb: createTimeOption });

  return (
    <>
      <Box mb="16px">
        <Label id={`${id}-date-time`}>
          When would you like us to come?
        </Label>
        <Hidden mdUp>
          <Select
            name="day-start-mobile"
            onChange={handleMobileDayChange}
            options={dayMobileOptions}
            value={currentDay}
          />
        </Hidden>
        <Hidden smDown>
          <DatePicker
            dateFormat="EEE MMM dd"
            minDate={asDate(soonestBookableTime)}
            onChange={handleDatePickerDayChange}
            selected={asDate(value)}
          />
        </Hidden>
      </Box>
      <Select
        name="time-start"
        onChange={handleTimeChange}
        options={timeOptions}
        value={currentTime}
      />
    </>
  );
}

DateTimePicker.propTypes = {
  id: PropTypes.string.isRequired,
};
