import React from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import debounce from 'lodash/debounce';
import URLSearchParams from '@ungap/url-search-params';
import classnames from 'classnames';
import { setWhenFormData, setFormLoadState } from '#/actions/whenForm';
import deepCamelCase from '#/utils/deepCamelCase';
import { axios } from '../../utils/request';
import { serverErrorHandler } from '../../utils';
import { frequencyMachineName, defaultResponses, responsesMatchQuestions } from '../../utils/content.js'
import { isMember } from '../../utils/memberships';
import apiCaller from '../../utils/apiCaller';
import { Mixpanel } from '../../utils/analytics';
import Questions from './Questions';
import DurationOverride from './DurationOverride';
import DateTimePicker from './DateTimePicker';
import CheckboxSection from '../shared/CheckboxSection';
import ValidForm from '../shared/ValidForm';
import Spinner from '../shared/Spinner';
import Stars from '../shared/Stars';
import { detailsHeader, legalCopy } from '../../utils/copy';
import PhoneInputSection from '../shared/PhoneInputSection';
import ContactInformation from './ContactInformation';
import PlanEngagementFormReminder from '../shared/PlanEngagementFormReminder';
import brandingContent from '../shared/BrandingContent.jsx';
import experiments from '../../utils/experiments';
import { isExperiment } from '../../utils/config';
import { getRecommendedRatingsText } from '../../utils/copy';
import ProDetails from './ProDetails';
import { initialDate } from '../../utils/content';

const cookies = new Cookies();
const enableCoupons = document.documentElement.dataset.enableCoupons === 'true';
const enablePhoneLeads = document.documentElement.dataset.enablePhoneLeads === 'true';
const enableWhenFormPrefill = document.documentElement.dataset.enableWhenFormPrefill === 'true';
const enableDynamicDatePicker = document.documentElement.dataset.enableDynamicDatePicker === 'true';
const enableRecommendedDuration = document.documentElement.dataset.enableRecommendedDuration === 'true';

class WhenForm extends React.Component {
  constructor(props) {
    super(props);

    const formAttribute = cookies.get('formAttribute');
    const prefilledAttribute = _.pick(
      formAttribute,
      [
        'zipcode',
        'dateStart',
        'timeStart',
        'email',
        'responses',
        'phone',
        'firstName',
        'lastName',
      ]
    )
    const startDate = initialDate();
    const timeStart = _.isUndefined(prefilledAttribute.timeStart) ? new Date(startDate.getTime()) : new Date(prefilledAttribute.timeStart);
    const dateStart = _.isUndefined(prefilledAttribute.dateStart) ? startDate : new Date(prefilledAttribute.dateStart);

    let zipcode = this.initialZipcode(prefilledAttribute) || this.props.zipcode
    let phone = prefilledAttribute.phone || ''
    let email = this.props.email || this.initialEmail(prefilledAttribute) || cookies.get('email')
    let firstName = this.initialValue(prefilledAttribute, 'firstName')
    let lastName = this.initialValue(prefilledAttribute, 'lastName')
    let soonestDate  = new Date
    let minDateWithProduct  = soonestDate

    zipcode = _.trim(zipcode)
    email = _.trim(email)

    const couponCode = this.props.couponCode
    const serviceMachineName = this.props.serviceMachineName
    const reason = this.props.reason
    const { taskoid, utm_source, utm_medium, utm_campaign, utm_content, utm_term } = this.props

    this.state = {
      zipcode,
      service_name: serviceMachineName,
      dateStart,
      timeStart,
      soonestDate,
      minDateWithProduct,
      starts_at: '',
      email,
      questions: [],
      responses: prefilledAttribute.responses,
      isSubscribe: true,
      duration: '',
      phone,
      first_name: firstName,
      last_name: lastName,
      enableXmlLeads: false,
      taskoid: taskoid,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      utm_content: utm_content,
      utm_term: utm_term,
      couponCode,
      isMember: this.props.isMember,
      enablePlanEngagement: this.props.enablePlanEngagement,
      isDynamicDate: false,
    }

    let mixpanelConfigs = { service: serviceMachineName }
    if (reason !== '') {
      Object.assign(mixpanelConfigs, { reason: reason })
    }

    const mixpanel = new Mixpanel(mixpanelConfigs)

    this.mixpanel = mixpanel
    this.track = mixpanel.tracker()
    this.errorTracker = mixpanel.tracker({ throttling: false })
    this.errorRef = React.createRef();
  }

  initialZipcode = prefilledAttribute => {
    return prefilledAttribute.zipcode || ''
  }

  initialEmail = prefilledAttribute => {
    return _.get(prefilledAttribute, 'email', '')
  }

  initialValue = (prefilledAttribute, key) => (_.get(prefilledAttribute, key, ''));

  handleChange = event => {
    let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
    const { name } = event.target
    value = _.isString(value) ? value.trim() : value

    this.setState({ [name]: value })
  };

  handleBlur = event => {
    let value = event.target.value
    const { name } = event.target
    value = _.isString(value) ? value.trim() : value

    if (name === 'email') {
      if (value.length > 0) {
        this.setState({ loading: true });
        isMember(value, this.props.serviceMachineName, this.props.brandingDetails.siteBrandKey)
          .then((response) => {
            cookies.set('isMember', response.data.is_member, { path: '/' });
            experiments.fetchIsVariant('checkout_plan_engagement', this.state.email)
              .then(({ data }) => {
                this.props.setParentState({
                  email: value,
                  isMember: response.data.is_member,
                  enablePlanEngagement: isExperiment(
                    this.props.serviceMachineName,
                    this.props.brandingDetails,
                    response.data.is_member,
                    data.variant
                  )
                });
              });
            this.setState({ loading: false });
          });
      } else {
        cookies.set('isMember', 'false', { path: '/' });
        this.props.setParentState({email: value, enablePlanEngagement: false, isMember: false});
      }
    }
  };

  handleDateChange = date => {
    // set hour to 00:00:00 to ensure that selected values will match
    date.setHours(0,0,0,0)
    this.setState({ dateStart : date })
  };

  handleTimeChange = event => {
    const times = event.target.value.split(':')
    const hours = times[0]
    const minutes = times[1]

    let updatedTimeStart = new Date()
    updatedTimeStart.setHours(hours)
    updatedTimeStart.setMinutes(minutes)
    this.setState({ timeStart: updatedTimeStart })
  };

  componentDidMount() {
    const {
      service_name: serviceName,
      zipcode,
      dateStart,
      timeStart,
    } = this.state
    const { reason, setErrors, setFormLoadState } = this.props;
    this.track('zip_shown', {
      checkout_plan_engagement_variant: this.props.enablePlanEngagementVariant,
    })
    axios
      .get(`/api/v1/services/${serviceName}/questions`)
      .then(({data}) => {
        let { responses } = this.state
        let { questions } = data
        if (!responses || !responsesMatchQuestions(responses, questions)) {
          responses = defaultResponses(questions)
        }

        const questionsAffectDuration = questions.some(q => (q.affects_duration))

        this.setState({
          questions,
          responses,
          durationOverrideOptions: data.duration_override,
          questionsAffectDuration,
        });

        this.fetchRecommendedDuration();
      })
      .catch(serverErrorHandler)
      .then((errors = []) => setErrors(errors))
      .finally(() => {
        setFormLoadState(false);
      });

    this.prefillMarketLeadAttributes()

    if (serviceName && reason) {
      const result = apiCaller.getServiceRequestConfiguration(serviceName, reason)
      result.then(enableXmlLeads => {
        this.setState({
          enableXmlLeads
        })
      })
    }

    if (enableDynamicDatePicker) {
      this.setSoonestDateTime(zipcode, serviceName, dateStart, false);
    } else {
      this.setSoonestBookableTime(dateStart);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { errors } = this.props;
    const { isDynamicDate, zipcode } = this.state;

    if (!_.isEmpty(errors) && !_.isEqual(errors, prevProps.errors)) {
      this.scrollToError();
    }
    if (isDynamicDate && zipcode.length !== 5) {
      this.setState({ isDynamicDate: false });
    }
  }

  // Current component is not stateless (pure), therefore the re-render needs to be triggered
  static getDerivedStateFromProps(props, state) {
    if (!state.email && props.email !== state.email) {
      return {
        email: props.email,
        isMember: props.isMember,
        enablePlanEngagement: props.enablePlanEngagement,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  setSoonestDateTime(zipcode, serviceName, dateStart, isDynamicDate) {
    if (!isDynamicDate && serviceName && zipcode && zipcode.length === 5) {
      apiCaller.getBusinessHours(zipcode, serviceName)
        .then((result) => {
          const {
            soonestBookableTime,
            minHour,
            maxHour,
          } = result;
          const dateTime = Moment(soonestBookableTime, 'YYYY-MM-DDTHH:mm:ss').toDate();
          this.setSoonestBookableTime(dateTime, minHour, maxHour);
          this.setState({ isDynamicDate: true, soonestDate: dateTime, minDateWithProduct: dateTime });
        }).catch(() => {
          this.setSoonestBookableTime(dateStart);
        });
    } else if (!isDynamicDate && !zipcode) {
      this.setSoonestBookableTime(dateStart);
    }
  }

  setSoonestBookableTime = (dateStart, minHour = 7, maxHour = 23) => {
    this.setState({
      dateStart: dateStart,
      timeStart: dateStart,
      minHour,
      maxHour,
    });
  };

  inputValues = () => {
    const includedKeys = [
      'dateStart',
      'email',
      'isSubscribe',
      'responses',
      'service_name',
      'timeStart',
      'zipcode',
    ];

    const values = {}

    for (let key in this.state) {
      if (includedKeys.includes(key)) {
        values[key] = typeof this.state[key] === 'string'
          ? this.state[key].trim()
          : this.state[key]
      }
    }

    return values;
  };

  getFrequency = () => {
    const defaultFrequencyMachineName = frequencyMachineName(this.state.service_name, this.state.enablePlanEngagement)
    let lastUsedFrequency
    if (this.state.service_name === "home_cleaning") {
      lastUsedFrequency = cookies.get('frequency')
    }
    return lastUsedFrequency || defaultFrequencyMachineName
  }

  quoteWorkflowParams = () => {
    const defaultFrequencyMachineName = frequencyMachineName(this.state.service_name, this.state.enablePlanEngagement)
    const frequency = this.getFrequency()

    let params = this.inputValues()
    const email = params.email
    const is_subscribe = params.isSubscribe
    const url = window.location.href // important for contextual branding
    params.starts_at = this.formatTime()
    params.responses = this.formatResponses(params.responses)
    params.frequency = frequency
    params.commitment = 'no_commitment'
    params.duration = this.state.duration

    if (enableCoupons) {
      params.coupon_code = this.state.couponCode
    }

    delete params.email;
    delete params.isSubscribe;
    delete params.dateStart;
    delete params.timeStart;
    return { quote_request: params, email, is_subscribe, url }
  };

  formatTime = () => {
    const dateTime = Moment(this.state.dateStart).format('YYYY-MM-DD') + Moment(this.state.timeStart).format('HH:mm Z');
    const formattedDateTime = Moment(dateTime, 'YYYY-MM-DDHH:mm').format('YYYY-MM-DD HH:mm');

    this.setState({starts_at: formattedDateTime})
    return formattedDateTime;
  }

  formatResponses = responses => {
    const formattedResponses = [];
    for(let key in responses) {
      formattedResponses.push({ key: key, value: responses[key] });
    }
    return formattedResponses;
  }

  isPhoneSubmitted = () => {
    if (enablePhoneLeads) {
      return !!this.state.phone
    }
  }

  prefillMarketLeadAttributes = () => {
    const qs = new URLSearchParams(window.location.search)
    const marketLeadId = qs.get('ml_id')

    if(!enableWhenFormPrefill || !marketLeadId) return

    const { email, zipcode, phone } = this.state

    if (email && zipcode && phone) return //dont bother to make call if everythings already prefilled


    apiCaller.getMarketLead(marketLeadId)
      .then((response) => {
        const { market_lead } = response.data

        this.setState({
          email: email || _.get(market_lead, 'user.email_address', ""),
          zipcode: zipcode || _.get(market_lead, 'address.zipcode', ""),
          phone: phone || _.get(market_lead, 'user.phone_number', ""),
        }, this.saveToCookies())
      })
  }

  isResponseAffectsDuration = (responseName) => {
    const { questions } = this.state
    return questions.find(q => q.machine_name === responseName && q.affects_duration)
  }

  isNumber = str => (/^\d+$/).test(str)

  handleServiceProductQuestions = (questionMachineName,value) => {
    if (questionMachineName == "BN_TV_Mount_Type") {
      if (value >= 1) {
        const new_date = Moment(this.state.soonestDate).add(7, 'days').toDate()
        this.setSoonestBookableTime(new_date, this.state.minHour, this.state.maxHour);
        this.setState({minDateWithProduct: new_date})
      }
      else
      {
        this.setSoonestBookableTime(this.state.soonestDate, this.state.minHour, this.state.maxHour);
        this.setState({minDateWithProduct: this.state.soonestDate})
      }
    }
  }
  updateResponseState = event => {
    const { name, value } = event.target
    const formattedValue = this.isNumber(value) ? parseInt(value) : value
    const responses = _.assign({}, this.state.responses, { [name]: formattedValue });

    const state = { responses }

    if (this.isResponseAffectsDuration(name)) {
      let { recommendedDurationRequest } = this.state
      if (recommendedDurationRequest == null) {
        recommendedDurationRequest = debounce(this.fetchRecommendedDuration, 300)
      }
      state["recommendedDurationRequest"] = recommendedDurationRequest
      recommendedDurationRequest();
    }

    this.handleServiceProductQuestions(name, value)

    this.setState({
      ...state,
    });
  }

  fetchRecommendedDuration = () => {
    const { responses, service_name } = this.state;
    if (enableRecommendedDuration) {
      apiCaller.getRecommendedDuration(service_name, responses)
        .then(result => {
          const { recommended_duration } = result
          this.setState({
            recommended_duration,
            duration: recommended_duration,
          })
        })
        .catch(err => {
          console.error(err)
        })
    }
  }

  saveToCookies = () => {
    const { phone, first_name, last_name } = this.state
    const frequency = this.getFrequency()
    const formAttributes = (_.merge({}, this.inputValues(), { phone: phone, firstName: first_name, lastName: last_name }))
    cookies.set('frequency', frequency)
    cookies.set('formAttribute', formAttributes, { path: '/' })
  }

  createEmailLead = (email, zipcode, phone) => {
    const { isSubscribe, enableXmlLeads, first_name, last_name, taskoid, utm_source, utm_medium, utm_campaign, utm_content, utm_term } = this.state
    const leadProperties = {
      email_address: email,
      context: 'fpp_checkout_flow',
      collecting_url: window.location.href,
      created_through: 'live_submission',
      zip: zipcode,
      phone_number: phone,
      service_machine_name: this.state.service_name,
      utm_source: utm_source,
      utm_medium: utm_medium,
      utm_campaign: utm_campaign,
      utm_content: utm_content,
      utm_term: utm_term,
      capturing_brand: this.props.brandingDetails.domainBrand
    }

    if (enableXmlLeads) {
      const xmlLeadProperties = {
        first_name: first_name,
        last_name: last_name,
        home_advisor_opt_in: true,
        taskoid: taskoid,
      }

      Object.assign(leadProperties, xmlLeadProperties)
    }

    if (isSubscribe || enableXmlLeads) {
      axios.post('/api/v1/email_lead', { ...leadProperties })
    }
  }

  isSchedulingError = (exception) => {
    const errors = _.get(exception, 'response.data.errors', [])
    return _.find(errors, { code: 'quote_validation_error', source: { field: 'starts_at' } })
  }

  handleSubmit = () => {
    const { email, zipcode, isSubscribe, phone, couponCode, taskoid } = this.state
    const { updateWhenForm } = this.props
    const quoteWorkflowParams = this.quoteWorkflowParams()
    this.mixpanel.register({ zipcode })
    updateWhenForm({ quoteWorkflowParams, email, zipcode, isSubscribe, phone, couponCode, taskoid })
    this.track('zip_submitted', {
      email,
      couponCode,
      unsubscribed: !isSubscribe,
      phone_submitted: this.isPhoneSubmitted(),
      checkout_plan_engagement_variant: this.props.enablePlanEngagementVariant,
    })
    this.setState({ loading: true })

    apiCaller.postQuoteWorkflow(quoteWorkflowParams)
      .then(({data}) => {
        this.createEmailLead(email, zipcode, phone)

        const { is_existing_ha_user, is_new_user, quote, is_external_redirect } = data
        this.saveToCookies()
        if (is_external_redirect) {
          this.track('redirect_to_login', {
            checkout_plan_engagement_variant: this.props.enablePlanEngagementVariant,
          })
        }
        this.mixpanel.register({ is_existing_ha_user, is_new_user })
        this.props.updateWhenPage(data.redirect_url, is_external_redirect, quoteWorkflowParams);
      })
      .catch(e => {
        if (this.isSchedulingError(e)) {
          this.createEmailLead(email, zipcode, phone)
        }

        const errors = serverErrorHandler(e)
        errors.forEach(x => {
          this.errorTracker('quote_request_error', {
            error_info: x.message,
            checkout_plan_engagement_variant: this.props.enablePlanEngagementVariant,
          })
        })

        this.setState({ loading: false })
        this.props.setErrors(errors);
      })
  }

  bannerDisplay = () => {
    if (this.props.enablePlanEngagement) {
      return <PlanEngagementFormReminder/>
    }
  }

  scrollToError = () => {
    if (this.errorRef && this.errorRef.current) {
      this.errorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  makeError = (errorMessage, errorKey, ref) => (
    <div
      className="error invalid-field"
      key={errorKey}
      ref={ref}
    >
      { errorMessage }
    </div>
  );

  render() {
    const {
      serviceMetadata,
      title,
      brandingDetails,
      customerRecommendationsEnabled,
      externalProviderId,
      errors,
    } = this.props;

    const {
      dateStart,
      minDateWithProduct,
      enableXmlLeads,
      first_name,
      isDynamicDate,
      last_name,
      minHour,
      maxHour,
      questionsAffectDuration,
      recommended_duration,
      service_name,
      timeStart,
      zipcode
    } = this.state;

    const whenFormErrors = [];
    let availabilityError;
    if (!_.isEmpty(errors)) {
      errors.forEach((error, i) => {
        const flatError = _.isUndefined(error.message) ? error : error.message;
        const errorMessage = _.isString(flatError)
          ? error.message
          : `Unexpected error occurred (Code 237): ${JSON.stringify(flatError)}`;

        // separate the availability errors from other errors
        if (errorMessage.includes('we do not have availability at that time')) {
          availabilityError = this.makeError(errorMessage, i, this.errorRef);
          this.isAvailabilityError = true;
        } else {
          whenFormErrors.push(this.makeError(errorMessage, i));
        }
      });
    }
    const averageStarRating = serviceMetadata.average_rating;

    const phoneFieldPlaceholder = enableXmlLeads ? 'Phone*' : 'Phone (optional)'

    const termsLink = brandingContent.getLink(this.props.brandingDetails.domainBrand, "terms")
    const isAngi = brandingDetails.isAngi
    const containerClass = classnames(
      'when-form-container',
      {
        'angi': isAngi
      }
    )

    const btn = classnames(
      'button',
      {
        'button--full': !isAngi,
        'button-angi': isAngi
      }
    )

    const whenFormHeaderContainer= classnames(
      'when-form',
      {'angi': isAngi}
    )
    const recommendedRatingsText = getRecommendedRatingsText(this.props.reviewCount);
    const hasProId = externalProviderId && externalProviderId.length > 0;
    const displayEnableRebookMyProUI = !!hasProId;

    return (
      <div className="cell medium-4 small-12 medium-order-2 small-order-1">
        <div className={containerClass}>
          <ValidForm className={whenFormHeaderContainer} submit={this.handleSubmit}>
            <div className="when-form-header-container">
              <h3>{title}</h3>
            </div>
            { this.props.reviewCount ?
              (<div className="average-star-rating-container show-for-small-only">
                <Stars starRating={averageStarRating} brandingDetails={brandingDetails} />
                {
                  customerRecommendationsEnabled ?
                    (<a className="average-star-rating-description" onClick={this.props.scrollToRecommendationRef}>
                      {recommendedRatingsText}
                    </a>)
                  : (<div className="average-star-rating-description">
                      {recommendedRatingsText}
                    </div>)
                }
              </div>)
              : null
            }
            {displayEnableRebookMyProUI && <ProDetails />}
            <div className="when-form-row">
              <input
                required
                name="zipcode"
                type="text"
                value={zipcode}
                onChange={this.handleChange}
                onBlur={() => this.setSoonestDateTime(zipcode, service_name, dateStart, isDynamicDate)}
                className="form-control form-input form-input--full"
                placeholder = { isAngi ? "Zip Code" : "Zip Code*"}
              />
              <span className="invalid-field" />
            </div>
            <h3 className="subheader" >{detailsHeader(service_name)}</h3>
            <Questions
              questions={this.state.questions}
              responses={this.state.responses}
              handleChange={this.updateResponseState}
              isAngi={this.props.brandingDetails.isAngi}
            />
            {this.state.durationOverrideOptions && (
              <DurationOverride
                overrideOptions={this.state.durationOverrideOptions}
                serviceMachineName={this.state.service_name}
                handleChange={this.handleChange}
                recommendedDuration={recommended_duration}
                enableRecommendedDuration={enableRecommendedDuration && questionsAffectDuration}
              />
            )}

            <h3 className="subheader question-label" >When would you like us to come?</h3>
            <DateTimePicker
              dateStart={dateStart}
              timeStart={timeStart}
              minDateWithProduct={minDateWithProduct}
              minHour={minHour}
              maxHour={maxHour}
              handleDateChange={this.handleDateChange}
              handleTimeChange={this.handleTimeChange}
              errorField={availabilityError}
              isDynamicDate={isDynamicDate}
            />
            {enableXmlLeads &&
              <ContactInformation
                firstName={first_name}
                lastName={last_name}
                handleChange={this.handleChange}
              />
            }
            <div className="form-row">
              <input
                ref={emailElem => this.emailElem = emailElem}
                className="form-control form-input form-input--full"
                required
                name="email"
                type="email"
                pattern="^[\w.-]+@[\w.-]+\.[\w]+$"
                value={this.state.email}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                placeholder="Email*"
              />
              <span className="invalid-field" />
            </div>
            {(enableXmlLeads || enablePhoneLeads) &&
              <PhoneInputSection
                name="phone"
                placeholder={phoneFieldPlaceholder}
                handleChange={this.handleChange}
                value={this.state.phone}
                required={enableXmlLeads}
                addMargin={true}
                brandingDetails={brandingDetails}
              />}
            <CheckboxSection
                isChecked={this.state.isSubscribe}
                handleChange={this.handleChange}
                content="Sign me up to receive advice and deals in my inbox."
                marginRight={true}
                brandingDetails={brandingDetails}
            />
            <button type="submit" className={btn} disabled={this.state.loading}>
              {<Spinner content="Get a Price" loading={this.state.loading} />}
            </button>
            { whenFormErrors }
          </ValidForm>
          {this.bannerDisplay()}
        </div>
        <div className="legal-text" dangerouslySetInnerHTML={{__html: legalCopy(brandingDetails, termsLink)}}></div>
      </div>);
  }
}

const updateWhenForm = (form) => setWhenFormData(deepCamelCase(form));
const mapDispatchToProps = { updateWhenForm, setFormLoadState };
const mapStateToProps = (state) => {
  const { whenForm: { externalProviderId } } = state;
  return {
    externalProviderId,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WhenForm);
