import { makeStyles } from '@material-ui/core';
import scssVariables from '#/styles/_vars.scss';
import Branding from '../../Branding.jsx';

const { errorBannerRed, cWhite } = scssVariables;

const useStyles = makeStyles(({ name, breakpoints }) => {
  const angi = Branding.isAngi(name);

  return ({
    errorBanner: {
      display: 'flex',
      justifyContent: 'space-between',
      background: errorBannerRed,
      color: cWhite,
      padding: '0.75em 10em',
      alignItems: 'center',
      [breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        padding: '0.75em 2em',
      }
    },
    errorIcon: {
      marginRight: '0.5em',
      display: 'flex',
    },
    errorLink: {
      textDecoration: 'underline',
      color: cWhite,
    },
    errorMessage: {
      display: 'flex',
      alignItems: 'center',
      [breakpoints.down('sm')]: {
        alignItems: 'flex-start',
      }
    },
    errorButton: {
      padding: '0px',
    },
  })
})

export default useStyles;
