import Stars from '../shared/Stars'
import classnames from 'classnames'

function CustomerRecommendations(props) {
  if (_.isEmpty(props.recommendations)) return null

  const { recommendations, reviews, serviceMetadata, taskoidName, brandingDetails } = props
  const buttonClass = classnames(
    'button',
    'get-started',
    'end-of-page',
    {
      'angi': brandingDetails.isAngi
    }
  )

  const CustomerRecommendations = classnames(
    'customer-recommendation-container',
    {
      'angi': brandingDetails.isAngi
    }
  )

  return (
    <div className={CustomerRecommendations}>
      <h2 className='customer-recommendation-header' id='customer-recommendations'>{recommendations.title}</h2>
      <h3 className='customer-recommendation-sub-header'>
        {`${props.reviewCount} Recommendations for Fixed-Price ${taskoidName}. `}<a href={recommendations.learnMore.href} target='_blank'>{recommendations.learnMore.text}</a>
      </h3>
      <Reviews reviews={reviews} brandingDetails={brandingDetails}/>
      <br/>
      <button className={ buttonClass } onClick={props.scrollToWhenFormRef}>
        Get Started
      </button>
    </div>
  )
}

function Reviews(props) {
  const reviews = Array.isArray(props.reviews) ? props.reviews : []
  const { brandingDetails } = props
  const reviewerName = classnames(
    'reviewer-name',
    {
      'angi': brandingDetails.isAngi
    }
  )
  return reviews.map((review, i) => (
    <div key={i} className='customer-recommendation-review'>
      <div className='customer-recommendation-reviewer'>
         <Stars starRating={review.rating} brandingDetails={brandingDetails} />
         <div className={reviewerName}> {shortenName(review.user.name)} </div>
      </div>
      <div className='customer-recommendation-note'>
        {review.body}
      </div>
    </div>
    )
  )
}

function shortenName(name) {
  const nameArray = name.split(" ")
  return `${nameArray[0]} ${nameArray[1][0]}.`
}

export default CustomerRecommendations
