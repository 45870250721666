class Modal extends React.Component {
  constructor(props) {
    super(props)
    this.handleModalVisibility = this.handleModalVisibility.bind(this)
    this.handleModalOutsideClick = this.handleModalOutsideClick.bind(this)
  }

  handleModalVisibility = (showModal) => {
    if (showModal) {
      document.addEventListener('click', this.handleModalOutsideClick, false)
    } else {
      document.removeEventListener('click', this.handleModalOutsideClick, false)
      this.props.closeModal()
    }
  }

  handleModalOutsideClick = (event) => {
    if (!this.node || this.node.contains(event.target)) return
    this.handleModalVisibility(false)
  }

  render() {
    const isModalVisible = this.props.isModalVisible
    if (isModalVisible) this.handleModalVisibility(true)

    return (
      <div>
        {isModalVisible && (
           <div className="modal">
              <div className="modal-content" ref={node => {this.node = node}}>
                <div className="modal-close" onClick={() => this.handleModalVisibility(false)}>&times;</div>
                <div>
                  {this.props.children}
                </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Modal
