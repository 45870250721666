import { useSelector } from 'react-redux';
import { cartServicesNamesSelector, whenFormServiceNameSelector, cartPendingServicesSelector } from '#/selectors';
import { removeFromCart, removePendingServiceFromCart, removeFromCartAsPrimaryService } from '#/actions/cart';
import { useGetQuoteByServiceNameMutation, usePostPrimaryBundleServiceByNameMutation } from '#/apiServices';

function useIsBundleAndPrimaryService(serviceMachineName) {
  const cartServicesNames = useSelector(cartServicesNamesSelector);
  const primaryCartServiceId = useSelector(whenFormServiceNameSelector);
  const isCartEmpty = cartServicesNames.length === 0 && !primaryCartServiceId;
  const isPrimaryCartService = primaryCartServiceId === serviceMachineName;
  const isBundlePath = window.location.pathname.includes('bundles'); // update to react-router hooks when upgraded to router v5

  return isBundlePath && (isCartEmpty || isPrimaryCartService);
}

function useAddToCartByServiceNameMutation(serviceMachineName) {
  const isBundleAndPrimaryService = useIsBundleAndPrimaryService(serviceMachineName);

  let api = useGetQuoteByServiceNameMutation;
  if (isBundleAndPrimaryService) api = usePostPrimaryBundleServiceByNameMutation;
  return api();
}

function useRemovePrimaryService() {
  const servicesInCart = useSelector(cartServicesNamesSelector);
  const maybeNewPrimaryId = servicesInCart[0];
  const [replaceLegacyWithBundleServicePost] = usePostPrimaryBundleServiceByNameMutation();
  const primaryCartServiceId = useSelector(whenFormServiceNameSelector);

  if (maybeNewPrimaryId) {
    return () => {
      replaceLegacyWithBundleServicePost({
        serviceName: maybeNewPrimaryId,
        removeServiceName: primaryCartServiceId,
      });
      return { type: 'noop' }; // dispatch a noop ^ the above will fetch a quote/replace the service
    };
  }
  return removeFromCartAsPrimaryService;
}

function useRemoveFromCart(serviceMachineName) {
  const pendingServices = useSelector(cartPendingServicesSelector);
  const isPendingService = pendingServices[serviceMachineName] || false;
  const isBundleAndPrimaryService = useIsBundleAndPrimaryService(serviceMachineName);
  const removePrimaryService = useRemovePrimaryService();

  if (isPendingService) return removePendingServiceFromCart;
  if (isBundleAndPrimaryService) return removePrimaryService;
  return removeFromCart;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  useAddToCartByServiceNameMutation,
  useRemoveFromCart,
};
