import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardMedia, Collapse, CardContent, Typography, Box, CardActionArea, styled } from '@material-ui/core';
import { PlusMinus } from '#/components/shared/icons';
import useStyles from './styles';
import TaskForm from './taskForm';
import images from '#/images/recommendations';

const StyledActionArea = styled(CardActionArea)({
  '&': {
    display: 'flex',
    justifyContent: 'flex-start',
  },
});

function Task({ title, price, id, index, openNextTask, openTask }) {
  const [regularTasks, setRegularTask] = useState(null);
  const isBundlePage = window.location.pathname.includes('/bundles/');
  let shouldBeExpanded = isBundlePage && index === openTask;
  const classes = useStyles();

  const handleTaskFormActions = (param) => {
    if (!isBundlePage) {
      regularTasks === null ? setRegularTask(index) : setRegularTask(null);
    }
    if (param === 'plusMinus' && isBundlePage) {
      !shouldBeExpanded ? openNextTask(index) : openNextTask(-1);
    } else {
      openNextTask(index + 1);
    }
  };

  const image = images[id] || images.home_cleaning;
  const expandCondition = (!isBundlePage && regularTasks !== null) || (isBundlePage && shouldBeExpanded);
  return (
    <Box mb="32px">
      <Card className={classes.root}>
        <StyledActionArea onClick={() => handleTaskFormActions('plusMinus')}>
          <CardMedia className={classes.media} image={image} title={title} />
          <CardContent>
            <Typography variant="h6" component="h3" className={classes.boldText}>
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              from&nbsp;
              <Typography variant="body2" color="textPrimary" component="span" className={classes.boldText}>
                {price}
              </Typography>
            </Typography>
          </CardContent>
          <Box ml="auto" mr="16px">
            <PlusMinus minus={expandCondition} />
          </Box>
        </StyledActionArea>
      </Card>
      <Collapse mountOnEnter={!isBundlePage} className={classes.drawer} in={expandCondition} timeout="auto">
        {id && <TaskForm id={id} handleTaskFormActions={handleTaskFormActions} />}
      </Collapse>
    </Box>
  );
}

Task.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  id: PropTypes.string,
  index: PropTypes.any,
  openNextTask: PropTypes.func,
  openTask: PropTypes.number,
};

Task.defaultProps = {
  id: null,
  index: null,
  openTask: -1,
  openNextTask: () => {},
};

export default React.memo(Task);
