import React from 'react'
import { connect } from 'react-redux'
import { Box } from '@material-ui/core'
import { get, isEmpty, isEqual } from 'lodash'
import { Elements, StripeProvider } from 'react-stripe-elements'
import Cookies from 'universal-cookie'
import apiCaller from '#/utils/apiCaller'
import { formatCurrency, getUpdatedBookingInfo } from '#/utils'
import { whenPageCopy } from '#/utils/copy'
import { frequencyOptions, frequencyMachineName, setFrequencyOptions } from '#/utils/content.js'
import { Omniture } from '#/utils/analytics'
import { track } from '#/utils/mixpanel'
import { fetchCurrentUserType } from '#/utils/user';
import PaymentForm from './Form'
import CouponBanner from '../shared/CouponBanner'
import PlanEngagementBanner from '../shared/PlanEngagementBanner'
import RadioOptions from '../shared/RadioOptions'
import QuotePreview from './QuotePreview'
import HappinessGuarantee from '#/components/shared/HappinessGuarantee';
import Accordion from '../shared/Accordion'
import PriceGuide from './PriceGuide'
import CardSection from '../shared/CardSection'
import { setMembershipSelected, fetchMembership, setMembershipFromApiResponse, discountablePriceFromQuote, formatSavedMoney } from '#/utils/memberships'
import { isExperiment } from '#/utils/config'
import { Redirect } from 'react-router'
import classnames from 'classnames'
import brandingContent from '../shared/BrandingContent.jsx'
import experiments from '#/utils/experiments'
import { updateMembershipPlan, setFormLoadState } from '#/actions/whenForm';
import { endpoints } from '#/apiServices';
import { isMember } from '../../utils/memberships';

const omniture = new Omniture()

const stripeKey = document.documentElement.dataset.stripeKey
const enableCoupons = document.documentElement.dataset.enableCoupons === 'true'
const showPriceGuide = document.documentElement.dataset.showPriceGuide === 'true'
const enableMemberCheck = document.documentElement.dataset.enableMemberCheck === 'true'
const truePriceMapping = JSON.parse(document.documentElement.dataset.truePriceMapping)
const enableExtras = document.documentElement.dataset.enableExtras === 'true';
const enableCouponStacking = document.documentElement.dataset.enableCouponStacking === 'true';
const enableMembershipDecoupledPricing = document.documentElement.dataset.enableMembershipDecoupledPricing === 'true';
const isHomeSpecialistSmsTermsEnabled = document.documentElement.dataset.homeSpecialistSmsTermsEnabled === 'true';

// in anticipation of loading in copy from some sort of admin service,
// storing copy in an object
const copy = {
  head: {
    title: 'Complete your booking',
    subtitle: 'Great! We can book your appointment at that time. A few more details and we can complete your booking with a top-rated professional.',
  },
  info: {
    frequency: 'Choose a Cleaning Frequency',
  },
}

const oneTimeBookingFrequency = 'one_time'
const cookies = new Cookies()

class PaymentPage extends React.Component {
  constructor(props) {
    super(props)

    const { setFormLoadState } = this.props;
    setFormLoadState(true);
    const quoteWorkflowParams = cookies.get('quoteWorkflowParams')
    const starts_at = quoteWorkflowParams && quoteWorkflowParams.quote_request.starts_at
    const serviceMachineName = cookies.get('serviceMachineName')
    const couponInfo = cookies.get('couponInfo')
    const isMember = cookies.get('isMember') === 'true'
    const planEngagement = isExperiment(serviceMachineName, props.brandingDetails, isMember);
    const defaultFrequencyMachineName = frequencyMachineName(serviceMachineName, planEngagement);
    const frequency = cookies.get('frequency') || defaultFrequencyMachineName;
    let defaultFrequencyQuotes = [];

    if (planEngagement) {
      // move once frequency option to the end
      frequencyOptions.push(frequencyOptions.shift());
      defaultFrequencyQuotes = frequencyOptions.map(() => '-');
    }

    this.state = {
      brandingDetails: this.props.brandingDetails,
      couponBannerDetails: {},
      couponInfo,
      enableCouponStacking,
      enablePlanEngagement: planEngagement,
      enablePlanEngagementVariant: "default",
      extrasAdded: [],
      frequency: frequency,
      frequencyOptions,
      frequencyQuotes: defaultFrequencyQuotes,
      isCouponApplied: false,
      isCouponLoading: false,
      isFetchingPrice: false,
      isMember,
      isModalVisible: false,
      membership: null,
      membershipDiscount: null,
      paymentAfterComplete: false,
      percentDifference: 0,
      quoteCardDetails: {},
      quoteWorkflowParams: quoteWorkflowParams,
      serviceInfo: cookies.get('serviceInfo'),
      serviceMachineName,
      starts_at: starts_at,
      style: { isStickyQuote: false, },
      taskoidName: cookies.get('taskoidName'),
      userExists: false,
      showMembershipDecoupledPricing: false,
      showHomeSpecialistSmsTerms: false,
    }

    this.fireLeadConversionPixel()
  }

  componentDidMount = async () => {
    const params = this.state.quoteWorkflowParams

    if (!params) return;

    if(this.state.isMember !== 'true' && enableMemberCheck) {
      this.checkIsMember(params)
    }

    experiments.fetchIsVariant('checkout_plan_engagement', params.email).then(({ data }) => {
      this.setState({
        enablePlanEngagement: isExperiment(this.state.serviceMachineName, this.props.brandingDetails, this.state.isMember, data.variant),
      });
      this.setState({
        enablePlanEngagementVariant: this.state.enablePlanEngagement ? "experiment" : "default",
      });
    }).catch(error => {
      console.error('Error fetching experiment variant', error);
    });

    if (enableMembershipDecoupledPricing) {
      experiments.fetchIsVariant('membership_decoupled_pricing_on_subdomain', params.email).then(({ data }) => {
        this.setState({ showMembershipDecoupledPricing: data.variant});
      }).catch(error => {
        console.error('Error fetching membership_decoupled_pricing_on_subdomain variant', error);
      });
    }

    if (isHomeSpecialistSmsTermsEnabled) {
      experiments.fetchIsVariant('display_home_specialist_sms_terms', params.email).then(({ data }) => {
        this.setState({ showHomeSpecialistSmsTerms: data.variant });
      }).catch((error) => {
        console.error('Error fetching display_home_specialist_sms_terms variant', error);
      });
    }

    try {
      await this.getPaymentAfterCompleteValue({
        unique_id: params.email,
        zipcode: params.quote_request.zipcode,
      })
    } catch (e) {
      //do nothing, continue with the flow
    }
    this.isUserExisting({ email: params.email })
    this.validateCoupon()
    this.updateQuote(params)
    this.getPercentDifference()
    window.addEventListener('scroll', this.handleScroll)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { setFormLoadState, checkoutApiQueries } = this.props;
    if (!isEmpty(checkoutApiQueries) && !isEqual(checkoutApiQueries, prevProps.checkoutApiQueries)) {
      const checkoutQueries = Object.values(checkoutApiQueries);
      const noCurrentPending = isEmpty(checkoutQueries.find((value) => value !== undefined && value.status === 'pending'));
      if (noCurrentPending) {
        setFormLoadState(false);
      }
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll)
  }

  conditionallyFetchMembership = (isMember) => {
    if (!isMember && this.allowStackingForCoupon(this.state.couponInfo)) {
      const email = this.state.quoteWorkflowParams.email
      const zipcode = this.state.quoteWorkflowParams.quote_request.zipcode
      fetchMembership(email, zipcode).then((response) => {
        this.setState({
          membership: setMembershipFromApiResponse(response.data.plan_template, this.state.membership),
          variant: response.data.variant
        })
        this.fetchDiscountedMembership(response.data.plan_template.plan_template_id)
        this.trackPageView()
      }).catch(() => {
        this.trackPageView()
      })
    } else {
      this.trackPageView()
    }
  }

	allowStackingForCoupon = (couponInfo) => {
    if (!couponInfo) {
      return true;
    }
    return this.isCouponStacking() && get(couponInfo, 'stackable', false);
  }

  trackPageView = () => {
    track('payment_shown', {
      existing_fpp_user: this.state.userExists,
      variant: this.state.variant,
      checkout_plan_engagement_variant: this.state.enablePlanEngagementVariant,
    })
    const entryPointId = cookies.get('entryPointId')
    const extFulTrack = cookies.get('extFulTrack')
    const serviceMachineName = cookies.get('serviceMachineName')
    const externalProviderId = cookies.get('external_provider_id')
    const pageName = 'FixedPriceHandy: Contact Info Submit'
    const payAfterBookingCompleteEvent = this.state.paymentAfterComplete ? 'event328' : null
    fetchCurrentUserType().then((user) => {
      omniture.track({ serviceMachineName,
        pageName,
        entryPointId,
        extFulTrack,
        angiKeyMember: user.angiKeyMember,
        userTypeLabel: user.userTypeLabel,
        events: payAfterBookingCompleteEvent,
        prop58: externalProviderId });
    });
    omniture.track_link(`FPP Sub: Payment Sub Shown - ${this.state.variant}`);
  }

  isUserExisting = params => {
    apiCaller.userSearch(params)
      .then((response) => {
        const userExists = response.data.user_exists
        this.setState({ userExists })
        this.conditionallyFetchMembership(this.state.isMember)
      }).catch(e => {
        this.conditionallyFetchMembership(false)
      })
  }

  fireLeadConversionPixel = () => {
    if (typeof (gtag) !== "undefined") {
      gtag('event', 'conversion', {
        'send_to': 'AW-798023605/i68DCPyF-pcBELW_w_wC',
        'value': 1.0,
        'currency': 'USD'
      });
    }
  }

  isCouponStacking = () => {
    // if needed Angi validation ( && !this.state.brandingDetails.isAngi )
    return this.state.enableCouponStacking;
  }

  handleScroll = event => {
    const navbarHeight = this.props.navbarDetails.height
    const { couponBannerDetails } = this.state
    const couponBannerHeight = couponBannerDetails.height || 0
    const breakPoint = navbarHeight + couponBannerHeight

    if (!event) { return }
    const scrollElement = _.get(event, 'target.scrollingElement')

    if (scrollElement) {
      const scrollTop = scrollElement.scrollTop
      const isStickyQuote = scrollTop > breakPoint

      const stickyQuoteState = _.merge({}, this.state, {
        style: { isStickyQuote },
      })

      this.setState(stickyQuoteState)
    }
  }

  getPaymentAfterCompleteValue = async (params) => {
    let paymentAfterComplete = false
    try {
      const response = await apiCaller.getPaymentAfterCompleteValue(params)
      paymentAfterComplete = response.data.variant === 'true'
    } catch (e) {
      //do nothing, treat paymentAfterComplete as false
    }
    this.setState({ paymentAfterComplete: paymentAfterComplete })
  }

  checkIsMember = (params) => {
    isMember(params.email, this.state.serviceMachineName, this.props.brandingDetails.siteBrandKey)
      .then((response) => {
        this.setState({ isMember: response.data.is_member });
        cookies.set('isMember', response.data.is_member, { path: '/' });
      });
  }

  fetchQuote = (params) => {
    this.setState({isFetchingPrice: true})
    return apiCaller.getQuote(params)
      .then(({ data }) => data.quote)
      .finally(() => this.setState({ isFetchingPrice: false })
    )
  }

  fetchDiscountedMembership = (plan_template_id) => {
    let params = JSON.parse(JSON.stringify(this.state.quoteWorkflowParams));
    params.quote_request.pay_after_booking_complete = this.state.paymentAfterComplete;
    params.quote_request.purchase_plan_template_id = plan_template_id;

    this.fetchQuote(params).then(quote => {
      let membershipDiscountQuote = quote.quote_breakdown.find(i => i.category === 'membership_discount')
      let membershipDiscount = null

      if (membershipDiscountQuote) {
        membershipDiscount = Math.abs(membershipDiscountQuote.amount);
      } else {
        membershipDiscount = discountablePrice(this.state.membership, discountablePriceFromQuote(quote));
      }

      this.setState({ membershipDiscount });
    });
  }

  updateFrequencyQuotes = () => {
    if (!this.state.enablePlanEngagement) return;

    let params = JSON.parse(JSON.stringify(this.state.quoteWorkflowParams));
    params.quote_request.pay_after_booking_complete = this.state.paymentAfterComplete;

    const quoteRequests = frequencyOptions.map((frequency) => {
      params.quote_request.frequency = frequency.machine_name;
      return this.fetchQuote(params);
    });

    Promise.all(quoteRequests).then(values => {
      const formattedQuotes = values.map(value => {
        return formatCurrency(value.currency.symbol, value.amount_due_after_credits);
      })
      this.setState({ frequencyQuotes: formattedQuotes });
    })
  }

  updateQuote = (params) => {
    this.setState({ couponError: null, isFetchingPrice: true })
    params.quote_request.frequency = this.state.frequency
    params.quote_request.pay_after_booking_complete = this.state.paymentAfterComplete
    apiCaller.getQuote(params)
      .then(({ data }) => {
        const { duration, starts_at, frequency, zipcode } = params.quote_request
        const { email } = params

        const additionalData = { duration, starts_at, frequency, zipcode, email }
        const oldQuote = _.merge({}, this.state.quote, additionalData)
        const newQuote = _.assign({}, oldQuote, data.quote)

        this.setState({ quote: newQuote });
        if (newQuote.coupon_validation && !newQuote.coupon_validation.is_valid) {
          this.setCouponError(newQuote.coupon_validation.reason)
        }
      })
      .finally(() => this.setState({ isFetchingPrice: false }))

    // initial fetch
    this.updateFrequencyQuotes();
  }

  validateCoupon = () => {
    this.setState({ couponError: null })
    if (!enableCoupons) { return }
    const couponInfo = this.state.couponInfo
    if (!couponInfo) { return }
    const { couponCode } = couponInfo
    const serviceMachineName = this.state.serviceMachineName
    const frequency = this.state.frequency
    const zipcode = this.state.quoteWorkflowParams.quote_request.zipcode
    const email = this.state.quoteWorkflowParams.email

    apiCaller.postCouponValidation(couponCode, serviceMachineName, zipcode, frequency, email)
      .then(response => {
        const couponValidation = response.data.coupon_validation
        if (couponValidation.is_valid) {
          const { coupon, banner } = couponValidation
          this.addCoupon(coupon, banner, coupon.stackable);
        } else {
          const { reason } = couponValidation
          this.removeCoupon(couponCode)
          this.setCouponError(reason)
        }
      })
      .catch(error => {
        const error_reason = error.response.data.errors[0].detail
        this.removeCoupon(couponCode)
        this.setCouponError(error_reason)
      })
  }

  removeCoupon = (couponCode) => {
    track('coupon_error', {
      coupon_code: couponCode,
      step: 'quote_submitted',
      checkout_plan_engagement_variant: this.state.enablePlanEngagementVariant,
    })
    let params = this.state.quoteWorkflowParams
    delete params.quote_request.coupon_code
    this.setState({ quoteWorkflowParams: params, isCouponApplied: false, couponInfo: null }, this.updateQuote(params))
  }

  setCouponError = (reason='Coupon could not be applied') => {
    this.setState({ couponError: reason })
    cookies.remove('couponInfo')
  }

  addCoupon = (coupon, banner, stackable) => {
    const couponInfo = {
      couponCode: coupon.code,
      showBanner: banner.is_visible,
      bannerDescription: banner.description,
      stackable,
    };

    let params = this.state.quoteWorkflowParams
    params.quote_request.coupon_code = couponInfo.couponCode
    this.setState({ quoteWorkflowParams: params, isCouponApplied: true, isCouponLoading: true, couponInfo: couponInfo }, this.updateQuote(params))
    setTimeout(() => { this.setState({ isCouponLoading: false }) }, 1500);
  }

  addCouponCode = (couponCode) => {
    const couponInfo = {
      couponCode: couponCode,
    }

    let params = this.state.quoteWorkflowParams
    params.quote_request.coupon_code = couponCode
    this.setState({ quoteWorkflowParams: params, isCouponApplied: true, isCouponLoading: true, couponInfo: couponInfo }, this.updateQuote(params))
    setTimeout(() => { this.setState({ isCouponLoading: false }) }, 1500);
  }

  handleTermError = () => {
    this.setState({ termsStyle: 'red-text' })
  }

  handleFrequencyChange = ({ target }) => {
    const { value } = target
    const params = this.state.quoteWorkflowParams
    params.quote_request.frequency = value
    const updatedBookingInfo = getUpdatedBookingInfo(this.state.starts_at, params)
    const { coupon_code } = params.quote_request

    this.setState({
      quote: _.assign({}, this.state.quote, { frequency: value }),
      termsStyle: '',
      frequency: value,
    })
    this.onChangeGetQuote(params, updatedBookingInfo, coupon_code)
    if ((this.state.membership || {}).planTemplateId) {
      this.fetchDiscountedMembership((this.state.membership || {}).planTemplateId);
    }
  }

  bannerDisplay = () => {
    if (this.state.enablePlanEngagement) {
      return <PlanEngagementBanner body='Your 20% member discount is reflected in your price' hasCheckMark='true' />
    } else {
      return this.couponDisplay();
    }
  }

  couponDisplay = () => {
    const couponInfo = this.state.couponInfo
    const couponApplied = enableCoupons && couponInfo && couponInfo.showBanner

    if (this.state.couponError) {
      return <CouponBanner body={this.state.couponError} error={true} brandingDetails={this.props.brandingDetails} />
    } else if (couponApplied) {
      return <CouponBanner header='Offer applied to your first booking!' body='Offer is reflected in your price.' hasCheckMark={true} brandingDetails={this.props.brandingDetails} getSize={couponBannerDetails => this.setState({ couponBannerDetails })} />
    }
  }

  priceGuideDisplay = () => {
    const { style } = this.state
    if (showPriceGuide && this.priceBelowNationalAvg()) {
      return <PriceGuide percent={this.formatPercentDifference()} brandingDetails={this.props.brandingDetails} />
    }
  }

  priceBelowNationalAvg = () => {
    if (this.state.percentDifference < -10) {
      return true
    }

    return false
  }

  getPercentDifference = () => {
    const avgPrice = this.nationalAvgPrice()
    const quote = this.state.quote

    if (quote && avgPrice) {
      const quotedPrice = quote.full_price
      const percentDifference = ((quotedPrice - avgPrice) / avgPrice) * 100
      this.setState({ percentDifference: percentDifference })
    }
  }

  formatPercentDifference = () => {
    const percent = Math.floor(this.state.percentDifference * -1)
    return `${percent}%`
  }

  nationalAvgPrice = () => {
    const service = truePriceMapping[this.state.serviceMachineName]
    if (service) {
      return service.nationalAvgPrice
    } else {
      return 0
    }
  }

  getTermsContent = (HomeAdvisorTermsLink) => {
    const { paymentAfterComplete, membership, showMembershipDecoupledPricing, showHomeSpecialistSmsTerms } = this.state
    const { frequency } = this.state.quote
    const isOneTimeBooking = frequency === oneTimeBookingFrequency
    // scully experiment logic will replace the "true"/"false" here
    const termsLinkStyle = classnames(
      {'text-decoration: underline' : !showMembershipDecoupledPricing}, // existing style
      {'$ha-header-color' : !showMembershipDecoupledPricing}, // existing style
      {'font-color: #00819E': this.props.brandingDetails.isAngi && showMembershipDecoupledPricing}, // scully test style
      {'font-color: #3F6EED' : !this.props.brandingDetails.isAngi && showMembershipDecoupledPricing} // scully test style
    );

    const serviceText = isOneTimeBooking ? 'service' : 'recurring service'

    const membershipSuffixBeginning = (membership && membership.isSelected) ? ' I further understand that I am purchasing an'  : ''
    // scully experiment logic will replace the "true" here
    const membershipSuffixMiddle = (membership && membership.isSelected) ? ((showMembershipDecoupledPricing) ? ' annually renewing membership,' : ' automatically renewing annual membership,') : ''
    const membershipSuffixEnd = (membership && membership.isSelected) ? ' for which I will be charged annually.' : ''

    const homeSpecialistSMSTerms = (membership && membership.isSelected && showHomeSpecialistSmsTerms) ? ' I also understand with my purchase I am opting in to receive SMS messages, which may be sent using automated technology, regarding my Angi Key membership and its benefits. Consent is not a condition of purchase.' : ''

    let paymentText;

    if (isOneTimeBooking) {
      if (paymentAfterComplete) {
        paymentText = 'after booking completion'
      } else {
        paymentText = 'before the service'
      }
    } else {
      if (paymentAfterComplete) {
        paymentText = 'after completion of my first booking and prior to each additional booking'
      } else {
        paymentText = 'before each booking'
      }
    }

    return (
      <span className={`terms-copy ${this.state.termsStyle}`}>
          I understand that I am purchasing a {serviceText} subject to
            the <a className={termsLinkStyle} href={HomeAdvisorTermsLink} target="_blank" rel="noreferrer noopener">Fixed Price Terms and Conditions</a> and
            my payment method will be charged {paymentText}.{membershipSuffixBeginning}{membershipSuffixMiddle}{membershipSuffixEnd}{homeSpecialistSMSTerms}
      </span>
    )
  }

  updateExtrasParams = (updatedExtras) => {
    const { quoteWorkflowParams } = this.state;
    let params = quoteWorkflowParams;
    params.quote_request.extras = updatedExtras;
    this.setState({ quoteWorkflowParams: params }, this.updateQuote(params))
  }

  handleAddExtra = (extraMachineName) => {
    const { extrasAdded } = this.state;
    let newExtras;
    const extraInList = _.filter(extrasAdded, (extra) => (extra.name === extraMachineName));

    if (extraInList.length > 0) {
      newExtras = _.filter(extrasAdded, (extra) => (extra.name !== extraMachineName));
      this.setState({ extrasAdded: newExtras });
    } else {
      newExtras = [...extrasAdded, { name: extraMachineName }];
      this.setState({ extrasAdded: newExtras });
    }
    this.updateExtrasParams(newExtras);

    if ((this.state.membership || {}).planTemplateId) {
      this.fetchDiscountedMembership((this.state.membership || {}).planTemplateId);
    }
  }

  adjustMargin = () => {
    const isSticky = this.state.style.isStickyQuote

    const { quoteCardDetails } = this.state
    const quoteCardHeight = quoteCardDetails.height

    const fillerStyle = {
      marginTop: `${quoteCardHeight}px`,
      height: '1em',
    }

    const noFillerStyle = {
      marginTop: 0,
      height: 0,
    }

    if (isSticky) {
      return (<div className='mobile-scroll-filler' style={fillerStyle}></div>)
    } else {
      return (<div className='mobile-scroll-filler' style={noFillerStyle}></div>)
    }
  }

  onMembershipSelectionChange = (membershipSelected) => {
    return () => {
      const { updateMembershipPlan, refetchCartQuotes, cartServiceIds } = this.props;
      const { quoteWorkflowParams, membership, starts_at, showMembershipDecoupledPricing } = this.state;
      const params = quoteWorkflowParams
      const planTemplateId = membershipSelected ? membership.planTemplateId : null
      params.quote_request.purchase_plan_template_id = planTemplateId

      // Redux store actions
      updateMembershipPlan(planTemplateId);
      refetchCartQuotes(cartServiceIds);

      const updatedBookingInfo = getUpdatedBookingInfo(starts_at, params)

      this.setState({
        membership: setMembershipSelected(membershipSelected, membership)
      })

      if (!membershipSelected) {
        omniture.track_link(`Regular Price clicked: membership decoupled pricing experiment is ${showMembershipDecoupledPricing}`);
      }

      this.onChangeGetQuote(params, updatedBookingInfo, null)

      // refetch frequencies quotes
      this.updateFrequencyQuotes();
    }
  }

  onChangeGetQuote = (params, updatedBookingInfo, coupon_code) => {
    apiCaller.getQuote(params)
      .then(response => {
        const { quote } = response.data
        const updatedQuote = _.assign({}, quote, updatedBookingInfo)
        this.setState({ quote: updatedQuote })
        this.getPercentDifference()

        this.setState({ couponError: null })
        if (quote.coupon_validation && !quote.coupon_validation.is_valid && coupon_code) {
          this.setCouponError(quote.coupon_validation.reason)
        }
      })
  }

  onCouponCodeChange = (couponCode) => {
    this.setState({ couponError: null })
    if (!_.isEmpty(couponCode) ) {
      this.addCouponCode(couponCode)
    } else if (_.isEmpty(couponCode) && this.state.couponInfo) {
      this.removeCoupon(this.state.couponInfo.couponCode)
    }
  }

  render() {
    const {
      quote,
      taskoidName,
      isCouponApplied,
      style,
      userExists,
      extrasAdded,
      serviceMachineName,
      paymentAfterComplete,
      frequencyQuotes,
      membershipDiscount,
      showMembershipDecoupledPricing,
    } = this.state

    const { brandingDetails, isFormLoading } = this.props;

    if (!this.state.quoteWorkflowParams) {
      const errors = [{
        message: 'Oops something went wrong. Please try again.'
      }]

      return (
        <Redirect
          to={{
            pathname: '/when',
            state: { errors: errors }
          }}
        />
      )
    }

    if (!quote) { return null }

    const { responses } = this.state.quoteWorkflowParams.quote_request
    const fonts = [{ cssSrc: "https://fonts.googleapis.com/css?family=Montserrat:300,500,700" }]
    const frequencyOptions = this.state.serviceInfo.can_recur
      ? (<RadioOptions
        handleChange={this.handleFrequencyChange}
        selected={quote.frequency}
        header={this.state.enablePlanEngagement ? 'Choose frequency' : `Choose a ${taskoidName} Frequency`}
        options={this.state.frequencyOptions}
        description={this.state.enablePlanEngagement ? 'Schedule recurring bookings. Plans are flexible and you can skip or reschedule anytime.' : 'Get your service with automatically scheduled bookings. Plans are flexible: Skip or reschedule anytime.'}
        brandingDetails={brandingDetails}
        subTexts={frequencyQuotes}
        enablePlanEngagement={this.state.enablePlanEngagement}
      />)
      : null

    const faqBrandingLinks = brandingContent.brandingLinks[this.props.brandingDetails.domainBrand]
    const whenCopy = whenPageCopy(serviceMachineName, faqBrandingLinks, this.props.brandingDetails)
    const HomeAdvisorTermsLink = faqBrandingLinks["fixed_price_terms"]
    const isSticky = style.isStickyQuote

    const stickyQuoteClass = isSticky ? 'mobile-sticky-quote' : ''
    const isInvisibleClass = isFormLoading ? 'invisible' : '';

    const guaranteeMobileClass = classnames(
      'grid-x',
      'happiness-guarantee-section',
      'show-for-small-only'
    )
    const paymentPageFormHeader = classnames(
      {'angi': this.props.brandingDetails.isAngi}
    )

    const paymentContainterCss = classnames(
      'payment-container', isInvisibleClass,
      { 'angi': this.props.brandingDetails.isAngi }
    )
    const paymentCompleteContainerCss = classnames(
      'grid-x', 'grid-margin-x', 'payment-page-form', 'payment-complete-container',
      { 'angi': this.props.brandingDetails.isAngi }
    )
    const couponCode = this.state.couponInfo ?  this.state.couponInfo.couponCode : ''

    return (
      <div>
        <div className={paymentContainterCss}>
          <div className="grid-container">
            <div className={paymentCompleteContainerCss}>
              <div className="cell small-12 medium-8 medium-order-1 small-order-2">
                <div
                  className={`payment-page-form-header padding-left-medium-down-1-5em padding-right-medium-down-1-5em ${paymentPageFormHeader}`}
                >
                  <h2>{`${copy.head.title}`}</h2>
                  <p>{`${copy.head.subtitle}`}</p>
                </div>
                <StripeProvider apiKey={stripeKey}>
                  <Elements fonts={fonts}>
                    <PaymentForm
                      brandingDetails={brandingDetails}
                      couponInfo={this.state.couponInfo}
                      enablePlanEngagement={this.state.enablePlanEngagement}
                      enablePlanEngagementVariant={this.state.enablePlanEngagementVariant}
                      enableExtras={enableExtras}
                      extrasAdded={extrasAdded}
                      frequencyOptionsComponent={frequencyOptions}
                      handleAddExtra={this.handleAddExtra}
                      handleTermError={this.handleTermError}
                      isMember={this.state.isMember}
                      membership={this.state.membership}
                      membershipDiscount={membershipDiscount}
                      onMembershipSelectionChange={this.onMembershipSelectionChange}
                      paymentAfterComplete={paymentAfterComplete}
                      quote={quote}
                      removeCoupon={this.removeCoupon}
                      setCouponError={this.setCouponError}
                      taskoidName={taskoidName}
                      termsContent={this.getTermsContent(HomeAdvisorTermsLink)}
                      userExists={userExists}
                      variant={this.state.variant}
                      membershipDecoupledPricing={showMembershipDecoupledPricing}
                    />
                  </Elements>
                </StripeProvider>
                <Box
                  bgcolor="white"
                  p="0 20px 5px"
                  marginTop="-16px"
                  className={guaranteeMobileClass}
                >
                  <HappinessGuarantee />
                </Box>
              </div>

              <div className="cell small-12 medium-4 medium-order-2 small-order-1">
                <div className="sticky">
                  <QuotePreview
                    brandingDetails={this.props.brandingDetails}
                    couponCode={couponCode}
                    couponError={this.state.couponError}
                    enableExtras={enableExtras}
                    enablePlanEngagement={this.state.enablePlanEngagement}
                    extrasAdded={extrasAdded}
                    getSize={(quoteCardDetails) =>
                      this.setState({ quoteCardDetails })
                    }
                    isCouponApplied={isCouponApplied}
                    isCouponLoading={this.state.isCouponLoading}
                    isCouponStacking={this.isCouponStacking()}
                    isFetchingPrice={this.state.isFetchingPrice}
                    onCouponCodeChange={this.onCouponCodeChange}
                    paymentAfterComplete={paymentAfterComplete}
                    quote={quote}
                    responses={responses}
                    serviceMachineName={serviceMachineName}
                    stickyQuoteClass={stickyQuoteClass}
                    taskoidName={taskoidName}
                  />
                  {this.adjustMargin()}
                  {this.priceGuideDisplay()}
                  <Box marginTop="24px" className="show-for-medium">
                    <HappinessGuarantee />
                  </Box>
                </div>
              </div>
            </div>
            <div className="grid-x grid-margin-x">
              <div className="cell small-12 medium-8 medium-order-1 small-order-2">
                <CardSection brandingDetails={this.props.brandingDetails}>
                  <div className="grid-x grid-margin-x">
                    <div className="cell small-12">
                        <div
                          className={`payment-page-form-header ${paymentPageFormHeader}`}
                        >
                          <h2>What makes us different?</h2>
                        </div>

                        <Accordion
                          items={whenCopy.accordionContent}
                          brandingDetails={brandingDetails}
                        />
                    </div>
                  </div>
                </CardSection>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cartServiceIds: Object.keys(state.cart.serviceIds),
  isFormLoading: state.whenForm.isFormLoading,
  checkoutApiQueries: state.checkoutApi.queries,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateMembershipPlan: (planId) => dispatch(updateMembershipPlan(planId)),
    refetchCartQuotes: (cartServiceIds) => {
      const refetchCartQuote = endpoints.getQuoteByServiceName.initiate;
      cartServiceIds.map((serviceName) => dispatch(refetchCartQuote({ serviceName })));
    },
    setFormLoadState: (isFormLoading) => dispatch(setFormLoadState(isFormLoading)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);

// export default PaymentPage
