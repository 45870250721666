import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { transform } from 'lodash';
import PropTypes from 'prop-types';
import Duration from './Duration';
import { serviceUpdated } from '#/actions/services';
import { createSelectBusinessHoursByServiceId, createSelectDurationByServiceId, createSelectBusinessHoursAsMomentByServiceId, createSelectIsInCartByServiceId } from '#/selectors';
import { useGetRecommendedDurationByServiceQuery } from '#/apiServices';
import { useAddToCartByServiceNameMutation } from '#/hooks';

const enableRecommendedDuration = document.documentElement.dataset.enableRecommendedDuration === 'true';

const getRecommendedDurationText = (isLoading, numHrs) => {
  if (!enableRecommendedDuration) return '';
  if (isLoading) return 'Fetching recommended duration...';
  return `We recommend ${numHrs} hours`;
};

function DurationWithState({ id }) {
  const dispatch = useDispatch();
  const { maxHour } = useSelector(createSelectBusinessHoursByServiceId(id));
  const isInCart = useSelector(createSelectIsInCartByServiceId(id));
  const { value: bookingStart } = useSelector(createSelectBusinessHoursAsMomentByServiceId(id));
  const durationOverride = useSelector(createSelectDurationByServiceId(id));
  const { isLoading, isFetching } = useGetRecommendedDurationByServiceQuery(id);
  const [addQuote] = useAddToCartByServiceNameMutation(id);

  const { options: unformattedOpts, value: currentValue, recommendedDuration } = durationOverride;
  const hrs = recommendedDuration / 60;
  const inLoadState = isLoading || isFetching;

  const onChange = (duration) => {
    dispatch(serviceUpdated({
      id,
      changes: {
        durationOverride: {
          ...durationOverride,
          value: duration,
        },
      },
    }));
    if (isInCart) addQuote({ serviceName: id });
  };

  const options = transform(unformattedOpts, (opts, { value, label }) => {
    const tooLateText = 'To book more hours, please select an earlier start time';
    const bookingEnd = bookingStart.clone().add(value, 'minutes');
    const bookingMax = bookingStart.clone().hours(maxHour).startOf('hour');
    let option = { value, text: label };
    if (bookingEnd.isAfter(bookingMax)) option = { value: -1, text: tooLateText, disabled: true };
    opts.push(option);

    const isOutOfValidOptions = !option.disabled;
    return isOutOfValidOptions;
  }, []);

  const recommendedDurationText = getRecommendedDurationText(inLoadState, hrs);

  return (
    <Duration
      id={id}
      onChange={onChange}
      options={options}
      recommendedDurationText={recommendedDurationText}
      value={currentValue}
    />
  );
}

DurationWithState.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DurationWithState;
