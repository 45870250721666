import { injectStripe } from 'react-stripe-elements'
import { Redirect } from 'react-router-dom'
import apiCaller from "../../utils/apiCaller";
import { buildBookingParams, serverErrorHandler } from '../../utils'
import { axios } from '../../utils/request'
import Cookies from 'universal-cookie'
import ValidForm from '../shared/ValidForm'
import CardSection from '../shared/CardSection'
import BillingInfo from './BillingInfo'
import BillingInfoV2 from './BillingInfoV2'
import PrefilledCardInfo from './PrefilledCardInfo'
import Spinner from '../shared/Spinner'
import PhoneInputSection from '../shared/PhoneInputSection'
import CheckboxSection from '../shared/CheckboxSection'
import Extra from './Extra';
import { extrasMapping } from '../../utils/extras';
import MembershipSelection from './MembershipSelection'
import MembershipSelectionV2 from './MembershipSelectionV2'
import PaymentAfterComplete from './PaymentAfterComplete'
import { Omniture } from '../../utils/analytics'
import { track, trackNoThrottle } from './../../utils/mixpanel'
import { buildUserFromApiResponse, defaultUser, setUserCookie } from '../../utils/user'
import classNames from 'classnames'
import YourAddress from './YourAddress'
import HAYourAddress from './ha/HAYourAddress'
import makeBookingRequest from './../../utils/booking';
import { connect } from 'react-redux';
import store from '#/store';
import { addError } from '../../reducers/bookingRequestError';
import { createQuoteFormFromService } from '#/apiServices';
import YourPro from './YourPro/index';

const omniture = new Omniture()
const enableCoupons = document.documentElement.dataset.enableCoupons === 'true'
const enableExtras = document.documentElement.dataset.enableExtras === 'true';
const enableCardEditFix = document.documentElement.dataset.enableCardEditFix === 'true';

const cookies = new Cookies()

const mapStateToProps = (state) => {
  const { whenForm, cart, services } = state;
  return {
    whenForm,
    cart,
    services,
  }
};

class PaymentForm extends React.Component {
  constructor(props) {
    super(props)

    const { couponInfo, quote } = this.props

    this.state = {
      errors: [],
      isFormSubmitted: false,
      loading: false,
      termsChecked: false,
      screenWidth: '',
      extras: [],
      couponInfo,
      user: defaultUser(),
      tokenId: null,
      editingCCInfo: false,
      quote,
    }
  }

  componentDidMount() {
    this.updateScreenWidth()
    window.addEventListener('resize', this.updateScreenWidth)
    if (enableExtras) {
      const serviceMachineName = cookies.get("serviceMachineName");
      this.retrieveExtras(serviceMachineName);
    }
    this.setCurrentUser()
  }

  setCurrentUser = () => {
    buildUserFromApiResponse().then((user) => {
      this.setState({
        editingCCInfo: _.isNull(user.paymentInformation),
        user: user
      })
    })
  }

  componentWillUnmount() {
    setUserCookie(this.state.user)
  }

  updateUserState = (user = {}) => (
    this.setState(_.merge({}, this.state, { user }))
  )

  handleInputChange = ({ target }) => {
    const { name, value } = target
    this.updateUserState({ [name]: value })
  }

  toggleTermsCheckbox = () => {
    const { termsChecked } = this.state;
    this.setState({ termsChecked: !termsChecked });
  }

  retrieveExtras = (serviceMachineName) => {
    apiCaller.getExtras(serviceMachineName).then((response) => {
      this.setState({ extras: response.data.extras });
    });
  }

  showFullCardForm = () => {
    const { editingCCInfo, user } = this.state
    return editingCCInfo || _.isNull(user.ccLast4) || user.ccLast4 === '0';
  }

  shouldCreateStripeToken = () => {
    if (enableCardEditFix) {
      return this.showFullCardForm();
    }
    const { user } = this.state;
    return _.isNull(user.ccLast4) || user.ccLast4 === '0';
  }

  handleSubmit = () => {
    const coupon_code = _.get(this.props.couponInfo, 'couponCode', null)
    track('payment_submitted', {
      coupon_code,
      existing_fpp_user: this.props.userExists,
      checkout_plan_engagement_variant: this.props.enablePlanEngagementVariant,
    })

    if (!this.state.termsChecked) {
      trackNoThrottle('payment_error', {
        error_info: 'terms of service not checked',
        existing_fpp_user: this.props.userExists,
        checkout_plan_engagement_variant: this.props.enablePlanEngagementVariant,
      })
      this.props.handleTermError()
      return
    }

    this.setState({ loading: true, termsStyle: '' });

    const couponInfo = this.props.couponInfo

    if (enableCoupons && couponInfo) {
      this.validateCouponAndCreateBooking()
    } else {
      this.createBooking()
    }
  }

  validateCouponAndCreateBooking = () => {
    const { couponCode } = this.props.couponInfo
    const serviceMachineName = cookies.get("serviceMachineName");
    const {
      email,
      frequency,
      zipcode
    } = this.state

    return apiCaller.postCouponValidation(couponCode, serviceMachineName, email, frequency, zipcode)
      .then(response => {
        const couponValidation = response.data.coupon_validation
        if (couponValidation.is_valid) {
          this.createBooking()
        } else {
          this.handleInvalidCoupon(couponCode)
        }
      })
      .catch(() => {
        this.handleInvalidCoupon(couponCode)
      })
  }

  createBooking = () => {
    const couponCode = _.get(this.props.couponInfo, 'couponCode', null)
    const serviceMachineName = cookies.get("serviceMachineName")
    const { quote, membership, paymentAfterComplete, brandingDetails, cart, services, whenForm } = this.props
    const baseItems = quote.quote_line_items
      ? quote.quote_line_items.filter(item => item.category == "base")
      : quote.quote_breakdown.filter(item => item.category == "base")
    const params = {
      coupon_code: couponCode,
      membership_bought: membership && membership.isSelected,
      membership_discount_applied_to_booking: (membership && membership.isSelected) ? membership.discountAmount : 0,
      base_price_shown: baseItems[0] ? baseItems[0].amount : null,
      total_price: quote.amount_due_after_credits
    }

    const { user } = this.state;
    const { extrasAdded } = this.props;
    const quoteWorkFlowParams = cookies.get('quoteWorkflowParams');
    const responses = _.get(quoteWorkFlowParams, `quote_request.responses`, "");
    const additionalParams = this.additionalParams(responses);
    const additionalServices = Object.values(cart.serviceIds);

    makeBookingRequest(
      user,
      quote,
      membership,
      serviceMachineName,
      this.props.stripe,
      brandingDetails.partnerId,
      extrasAdded,
      additionalParams,
      paymentAfterComplete,
      couponCode,
      null,
      this.shouldCreateStripeToken(),
    ).then(({ data }) => {
      if (data.is_external_redirect) {
        const redirectError = new Error();
        redirectError.name = 'Redirection';
        window.location = data.redirect_url;
        throw redirectError;
        return null;
      }
      track('booking_made', Object.assign(params, {
        variant: this.props.variant,
        checkout_plan_engagement_variant: this.props.enablePlanEngagementVariant,
      }))
      if (params.membership_bought) {
        omniture.track_link(`FPP Sub: Subscribed via Booking - ${this.props.variant}`);
      }
      return data;
    })
      .then((data) => {
        const primaryBookingExternalId = data.booking.external_id;
        const bookingPromises = additionalServices.map((service) => {
          const form = services.entities[service];
          const quoteForm = createQuoteFormFromService(form, whenForm);
          const nullCouponCode = null; // coupons cannot apply to added services (coupon will already be used by primary)

          return makeBookingRequest(
            user,
            quoteForm.quote_request,
            membership,
            service,
            this.props.stripe,
            brandingDetails.partnerId,
            [],
            this.additionalParams(quoteForm.quote_request.responses),
            paymentAfterComplete,
            nullCouponCode,
            primaryBookingExternalId,
          );
        });
        const promisesToSettle = [Promise.resolve({ data })].concat(bookingPromises);
        return Promise.allSettled(promisesToSettle);
      })
      .then((results) => {
        const { value } = results[0];
        const { data } = value;
        const errors = results.map((result) => {
          if (result.status === 'rejected') {
            const formattedErrors = serverErrorHandler(result);
            if (result.reason.config) {
              const jsonData = JSON.parse(result.reason.config.data)
              const serviceWithError = jsonData.booking.service
              store.dispatch(addError(serviceWithError, formattedErrors));
            }
            return formattedErrors;
          }
        }).filter((el) => {
          return el != undefined;
        }).flat(1);
        this.setState({ isFormSubmitted: true, booking: data.booking, redirectUrl: data.redirect_url, quote });
        return errors;
      })
      .catch((error) => {
        if (error.name === 'Redirection') {
          return [];
        } else {
          return [serverErrorHandler(error)].flat(1);
        }
      })
      .then((errors) => {
        this.setState({ errors, loading: false })
        errors.forEach((error) => {
          const errorMessage = _.isArray(error) ? error[0].message : error.message;
          trackNoThrottle('payment_error', {
            error_info: errorMessage,
            existing_fpp_user: this.props.userExists,
            checkout_plan_engagement_variant: this.props.enablePlanEngagementVariant,
          })
          if (errorMessage.includes('coupon')) {
            this.handleInvalidCoupon(couponCode);
          }
        });
      });
  }

  createTokenResult = async () => {
    if (this.showFullCardForm()) {
      return await this.props.stripe.createToken({ name: 'handy-checkout-flow' })
    } else {
      return { token: { id: null } }
    }
  }

  handleInvalidCoupon = (couponCode) => {
    this.setState({ loading: false });
    this.props.removeCoupon(couponCode, 'payment_submitted')
    this.props.setCouponError()
  }

  updateScreenWidth = () => {
    const w = window
    const width = w.Foundation.MediaQuery.current;

    this.setState({ screenWidth: width });
    return;
  }

  getInputPlaceholder = (placeholder) => {
    if ((this.state.screenWidth == 'small') || (this.state.screenWidth == 'medium')) {
      return placeholder;
    } else {
      return '';
    }
  }

  additionalParams = (responses) => {
    const descriptionResponse = responses.find(function (response) {
      return response.key === 'description';
    });

    let description = '';
    if (descriptionResponse) { description = descriptionResponse.value };

    return {
      description,
      responses,
    };
  }

  toggleEditCCInfo = () => {
    const { editingCCInfo } = this.state
    this.setState({ editingCCInfo: !editingCCInfo })
  }

  render() {
    const style = {
      base: {
        fontFamily: 'Montserrat',
        fontSize: '16px',
      },
    }

    const angiBillingStyle = {
      iconStyle: "solid",
      base:{
        fontFamily: 'national2Regular, sans-serif',
        fontSize: '16px',
      },
      invalid:{
        iconColor: "#E50000",
        color: "#E50000"
      },
      complete: {
        color: "#005F99"
      }
    }
    const isAngi = this.props.brandingDetails.isAngi

    const buttonFullCss = classNames('button', 'button--full', 'button--tall',
            {  'angi': isAngi }
        )
    const buttonMediumCss = classNames('button', 'button--tall', 'custom_btn_width',
        {  'angi': isAngi,
          'custom_angi_btn_width': isAngi
        })

    const completeBooking = classNames(
      'payment-page-form-section',
      'padding-top-2em',
      'payment-btn',
      {
        angi: isAngi
      }
    )


    const { errors, extras, user, editingCCInfo, isFormSubmitted } = this.state
    const { handleAddExtra, membership, paymentAfterComplete, enablePlanEngagement, membershipDiscount, membershipDecoupledPricing, isMember } = this.props;

    const enableNewFormLayout = document.documentElement.dataset.enableNewFormLayout === 'true';
    const externalProviderId = cookies.get('external_provider_id');
    const hasProId = externalProviderId && externalProviderId.length > 0;
    const displayEnableRebookMyProUI = !!hasProId;

    const newFormApplies = enableNewFormLayout

    const showExtras = () => (enableExtras && extras.length > 0);
    const errorClass = classNames(
      'error',
      'invalid-field',
      'pb-2',
      { 'angi-mobile': isAngi }
    )
    const h3font = classNames(
      { 'angi': isAngi }
    )
    const paymentErrors = !_.isEmpty(errors) && errors.map((e, i) => {
      let error;
      if (_.isArray(e)) {
        error = _.isUndefined(e[0].message) ? e[0] : e[0].message
      } else {
        error = _.isUndefined(e.message) ? e : e.message
      }
      const errorMessage = _.isString(error)
        ? error
        : `Unexpected error occurred (Code 237): ${JSON.stringify(error)}`
      return <div className={errorClass} key={i} >{errorMessage}</div>
    })

    if (isFormSubmitted) {
      const bookingId = _.get(this.state, 'booking.external_id');
      cookies.set('bookingId', bookingId);
      cookies.set('bookingStartDate', _.get(this.props, 'quote.starts_at'));
      cookies.remove('quoteWorkflowParams');
      cookies.remove('serviceInfo');

      const stateParams = _.pick(this.state, ['booking', 'redirectUrl', 'quote'])
      stateParams.couponInfo = this.props.couponInfo
      return (<Redirect to={{
        pathname: '/confirmation?booking_id=' + bookingId,
        state: stateParams
      }} />);
    } else {
      return (
        <ValidForm className='payment-form' submit={this.handleSubmit}>
          <CardSection brandingDetails={this.props.brandingDetails} >
            <div className='margin-bottom-1em'>
              {this.props.frequencyOptionsComponent}
            </div>
            { newFormApplies ?
              <YourAddress
                h3font={h3font}
                enablePlanEngagement={enablePlanEngagement}
                handleInputChange={this.handleInputChange}
                getInputPlaceholder={this.getInputPlaceholder}
                brandingDetails={this.props.brandingDetails}
                user={user}
              />
              :
              <HAYourAddress
                h3font={h3font}
                enablePlanEngagement={enablePlanEngagement}
                handleInputChange={this.handleInputChange}
                getInputPlaceholder={this.getInputPlaceholder}
                brandingDetails={this.props.brandingDetails}
                user={user}
              />
            }
            {displayEnableRebookMyProUI &&
              <div className="payment-page-form-section margin-top-2">
                <YourPro />
              </div>
            }
            {showExtras() && (
              <div className='payment-page-form-section padding-top-2em'>
                <h3 className={h3font}>Add Extras</h3>

                <div className='payment-page-form-row extras-container'>
                  {
                    extras.map((extra, index) => {
                      const extraFound = extrasMapping[extra.machine_name];
                      if (!_.isUndefined(extraFound)) {
                        return (
                          <div className='extra-holder' key={index}>
                            <Extra
                              extraMachineName={extra.machine_name}
                              extra={extraFound}
                              handleAddExtra={handleAddExtra}
                            />
                          </div>
                        );
                      }
                    })
                  }
                </div>
              </div>
            )}
          </CardSection>

          {!membershipDecoupledPricing &&
          <CardSection brandingDetails={this.props.brandingDetails} >
            <div className='payment-page-form-section'>
              <h3 className={h3font}>{ enablePlanEngagement ? 'Payment information' : 'Payment Information' }</h3>
              {membership &&
                <MembershipSelection
                  membership={membership}
                  onMembershipSelectionChange={this.props.onMembershipSelectionChange}
                  quote={this.props.quote}
                  paymentAfterComplete={paymentAfterComplete}
                  brandingDetails={this.props.brandingDetails}
                  enablePlanEngagementVariant={this.props.enablePlanEngagementVariant}
                  membershipDiscount={membershipDiscount}
                />
              }
              {paymentAfterComplete &&
                 <PaymentAfterComplete/>
              }

              {!this.showFullCardForm() &&
                <PrefilledCardInfo user={user} handleEditClick={this.toggleEditCCInfo} />
              }

              <BillingInfo
                membership={membership}
                showCancelEdit={!_.isNull(user.ccLast4) && user.ccLast4 !== '0'}
                onCancelEditClick={this.toggleEditCCInfo}
                style={isAngi ? angiBillingStyle : {}}
                isAngi={this.props.brandingDetails.isAngi}
              />
            </div>

            <div className='payment-page-form-section'>
              <div className={'terms-container'}>
                <CheckboxSection
                  handleChange={this.toggleTermsCheckbox}
                  isChecked={this.state.termsChecked}
                  content={this.props.termsContent}
                  brandingDetails={this.props.brandingDetails}
                />
              </div>
            </div>

            <div className='show-for-small-only'>
              <div className='payment-page-form-section padding-top-2em'>
                {paymentErrors}
                <button type='submit' className={buttonFullCss} disabled={this.state.loading}>
                  <Spinner content='Complete Booking' loading={this.state.loading} />
                </button>
              </div>
            </div>

            <div className='show-for-medium'>
              <div className={completeBooking}>
                {paymentErrors}
                <button type='submit' className={buttonMediumCss} disabled={this.state.loading}>
                  <Spinner content='Complete Booking' loading={this.state.loading} />
                </button>
              </div>
            </div>

          </CardSection>
          }

          {membershipDecoupledPricing &&
          <CardSection brandingDetails={this.props.brandingDetails} >
            <div className='payment-page-form-section'>
              <h3 className={h3font}>{ !isMember ? 'Choose your service price' : 'Payment Information' }</h3>
              {membership &&
                <MembershipSelectionV2
                  membership={membership}
                  onMembershipSelectionChange={this.props.onMembershipSelectionChange}
                  quote={this.props.quote}
                  membershipDiscount={membershipDiscount}
                  isAngi={this.props.brandingDetails.isAngi}
                />
              }
            </div>

            {!this.showFullCardForm() &&
              <PrefilledCardInfo user={user} handleEditClick={this.toggleEditCCInfo} />
            }

            <BillingInfoV2
              membership={membership}
              showCancelEdit={!_.isNull(user.ccLast4) && user.ccLast4 !== '0'}
              onCancelEditClick={this.toggleEditCCInfo}
              style={isAngi ? angiBillingStyle : {}}
              isAngi={this.props.brandingDetails.isAngi}
              paymentAfterComplete={paymentAfterComplete}
            />

            <div className='payment-page-form-section'>
              <div className={'terms-container-v2'}>
                <CheckboxSection
                  handleChange={this.toggleTermsCheckbox}
                  isChecked={this.state.termsChecked}
                  content={this.props.termsContent}
                  brandingDetails={this.props.brandingDetails}
                />
              </div>
            </div>

            <div className='show-for-small-only'>
              <div className='payment-page-form-section padding-top-2em'>
                {paymentErrors}
                <button type='submit' className={buttonFullCss} disabled={this.state.loading}>
                  <Spinner content='Complete Booking' loading={this.state.loading} />
                </button>
              </div>
            </div>

            <div className='show-for-medium'>
              <div className={completeBooking}>
                {paymentErrors}
                <button type='submit' className={buttonMediumCss} disabled={this.state.loading}>
                  <Spinner content='Complete Booking' loading={this.state.loading} />
                </button>
              </div>
            </div>

          </CardSection>
          }

        </ValidForm>
      )
    }
  }
}

export default connect(mapStateToProps)(injectStripe(PaymentForm));
