import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText } from '@material-ui/core';
import { Select } from '#/components/shared';
import Label from '#/components/cart/task/taskForm/Label';

export default function Duration({ id, options, value, onChange, recommendedDurationText }) {
  const labelId = `${id}-label`;

  return (
    <FormControl fullWidth>
      <Label id={labelId}>How many hours would you like to book?</Label>
      <Select
        id={id}
        labelId={labelId}
        onChange={onChange}
        options={options}
        value={value}
      />
      <FormHelperText id={`${id}-helper-text`}>
        {recommendedDurationText}
      </FormHelperText>
    </FormControl>
  );
}

Duration.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    text: PropTypes.string,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  recommendedDurationText: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};
