import React from 'react';
import Cookies from 'universal-cookie';
import classNames from 'classnames';
import Moment from 'moment';
import { Omniture } from '../../utils/analytics';
import EntryInformationSection from './EntryInformationSection';
import WhatHappensNextSection from './WhatHappensNextSection';
import brandingContent from '../shared/BrandingContent.jsx';
import ConfirmationMsg from './ConfirmationMsg';
import BundlesServicesConfirmation from '../bundle/BundlesServicesConfirmation';

const cookies = new Cookies()

const omniture = new Omniture()

class BookingConfirmation extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            bookingStartDate: cookies.get('bookingStartDate'),
            bookingId: cookies.get('bookingId'),
            quote: this.props.location.state.quote,
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'srID': this.state.bookingId,
        });
    }

    formattedDateStart() {
        var date = this.state.bookingStartDate || '2018-09-20 20:22';
        return Moment(date, 'YYYY-MM-DD HH:mm').format('MMM D, YYYY [at] hh:mm a')
    }

    render() {
        const redirectUrl = brandingContent.getLink(this.props.brandingDetails.domainBrand, "account")
        const brandingName = this.props.brandingDetails.displayName
        const confirmationSectionCss = classNames(
            'grid-x grid-margin-x confirmation-section text-center align-center',
            { 'angi': this.props.brandingDetails.isAngi }
        )
        const entryInfoContainerCss = classNames('entry-info-container', { 'angi': this.props.brandingDetails.isAngi })
        const confirmationContainerCss = classNames('confirmation-container new', { 'angi': this.props.brandingDetails.isAngi })
        const confirmationHeaderText = classNames(
            'confirmation-header-text',
            {'angi': this.props.brandingDetails.isAngi}
        )
        const confirmationHeaderSubText = classNames(
            'confirmation-header-text',
            {'angi': this.props.brandingDetails.isAngi }
        )

    const externalProviderId = cookies.get('external_provider_id');
    const isSeasonalBundleFeatureEnabled = document.documentElement.dataset.enableSeasonalBundles === 'true';
    const hasProId = externalProviderId && externalProviderId.length > 0;
    const displayEnableRebookMyProUI = !!hasProId;
    const { quote } = this.state;
        return (
            <div className={confirmationContainerCss}>
                <div className='grid-container'>
                    {displayEnableRebookMyProUI && <ConfirmationMsg quoteDate={quote.starts_at}/>}
                    {!displayEnableRebookMyProUI
                    &&<div className={confirmationSectionCss}>
                        <div className='cell small-12 medium-12 large-8 small-centered'>
                            {isSeasonalBundleFeatureEnabled ? <BundlesServicesConfirmation />
                              :
                              <div>
                                  <h2 className="confirmation-header-text">
                                      Your booking is confirmed!
                                  </h2>
                                  <p className="confirmation-content-text">
                                      Thanks for booking your Fixed-Price service with {brandingName}.
                                      We've sent you a confirmation email with full booking details.
                                  </p>
                              </div>}
                        </div>
                    </div>}
                    <div className='grid-x grid-margin-x payment-header-section align-center'>
                        <EntryInformationSection
                            brandingDetails={this.props.brandingDetails}
                            bookingId={this.state.bookingId}
                            bookingStartDate={this.state.bookingStartDate}
                            userEmail={this.props.location.state.quote.email}
                        />
                        <div className='cell medium-12 small-12 large-4 small-centered entry-info-cell whats-next-cell'>
                            <div className={entryInfoContainerCss}>
                                <h3>
                                    What's next?
                                </h3>
                                <br />
                                <div className='what-happens-next-text'>
                                    You've just purchased a fixed price service through {brandingName}! Your pro will arrive at the address you provided at your scheduled date and time to complete your home service. <br /><br />Please note that it's possible that other companies may still contact you with price quotes for your project. These pros are not affiliated with the fixed price booking you have just made.
                                    <br />
                                    <br />
                                    <a href={redirectUrl}>Go to My Account</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <WhatHappensNextSection
                        redirectUrl={redirectUrl}
                        brandingDetails = { this.props.brandingDetails }
                    />
                </div>
            </div>
        )
    }
}

export default BookingConfirmation
