import React from 'react';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { useTheme } from '@material-ui/core/styles';
import ProDetailsPresenter from './ProDetails';
import { useGetProviderByExternalIdQuery } from '#/apiServices';

const cookies = new Cookies();

function ProDetails() {
  const { name: themeName } = useTheme();
  const isAngi = themeName === 'angi';
  const externalId = cookies.get('external_provider_id');
  useGetProviderByExternalIdQuery({ externalId });
  const provider = useSelector(({ providers: { entities } }) => entities[externalId]);

  if (!provider) return null;

  const {
    firstName,
    lastName,
    profilePhotoUrl: proAvatar,
  } = provider;

  const name = `${firstName} ${lastName}`;

  return (
    <ProDetailsPresenter
      name={name}
      proAvatar={proAvatar}
      isAngi={isAngi}
    />
  );
}

export default ProDetails;
