import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { receiptLineItemsSelector, receiptCurrencySelector } from '#/selectors';
import useStyles from './styles';
import ReceiptBreakdownItem from './ReceiptBreakdownItem';

export default function ReceiptBreakdown() {
  const classes = useStyles();
  const lineItems = useSelector(receiptLineItemsSelector);
  const currencySymbol = useSelector(receiptCurrencySelector);

  return (
    <>
      {lineItems.length > 0 && <div className={classes.receiptBreak} />}
      <Box pt={2}>
        <ul className={classes.list}>
          {lineItems.map(
            ({ amount, category, displayName, tooltipContent }) => (
              <li key={displayName}>
                <ReceiptBreakdownItem
                  amount={amount}
                  category={category}
                  currency={currencySymbol}
                  displayName={displayName}
                  tooltip={tooltipContent}
                />
              </li>
            )
          )}
        </ul>
      </Box>
    </>
  );
}
