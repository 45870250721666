import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Tooltip } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { formatCurrency } from "#/utils";
import useStyles from "./styles";
import InfoIcon from "@material-ui/icons/Info";
export default function ReceiptBreakdownItem({
  amount,
  category,
  currency,
  displayName,
  tooltip,
}) {
  const { name: theme } = useTheme();
  const classes = useStyles({ theme });
  const formattedAmount = formatCurrency(currency, amount);

  if (category === "membership_discount" || category === "membership_fee") {
    return null;
  }

  let boldClass = classes.lightFont;
  // This values are coming from Handybook API. We didn't want to update on the handybook API since it could be used in multiple places.
  // Base Price --> Subtotal
  // Trust & Support --> Trust and Support
  if (displayName === "Base Price") {
    displayName = "Subtotal";
    boldClass = classes.brightFont;
  } else if (displayName === "Trust & Support") {
    displayName = "Trust and Support";
  }

  return (
    <div className={`${classes.breakdownSpacedItems} ${boldClass}`}>
      <Typography
        variant="body1"
        component="div"
        className={`${classes.serviceFont} ${classes.iconAlign}`}
      >
        {displayName}
        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoIcon className={classes.iconSpacing} fontSize="small" />
          </Tooltip>
        )}
      </Typography>
      <Typography
        variant="body1"
        component="div"
        className={classes.serviceFont}
      >
        {formattedAmount}
      </Typography>
    </div>
  );
}

ReceiptBreakdownItem.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  category: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
};
