import { makeStyles } from '@material-ui/core';
import scssVariables from '#/styles/_vars.scss';

const {
  haBreakColor,
  haCouponGreen,
  angiCouponGreen,
  haKeyLogoColor,
  angiKeyLogoColor,
  angiPriceTextColor,
  supportingTextColor,
} = scssVariables;

const useStyles = makeStyles(({ name, breakpoints }) => {
  const isAngi = name === "angi";

  return {
    root: {
      marginLeft: "20px",
      width: "304px",
      padding: "24px",
      [breakpoints.down("sm")]: {
        width: "100%",
        marginLeft: "0px",
        paddingTop: "0px",
        borderRadius: "0px",
        boxShadow: "none",
        textAlign: "center",
      },
    },
    header: {
      textAlign: "center",
      paddingBottom: "16px",
      [breakpoints.down("sm")]: {
        display: "none",
      },
    },
    headerText: {
      fontWeight: "bold",
      lineHeight: "1.2rem",
      fontSize: "20px",
    },
    alwaysReceiptBreak: {
      borderBottom: "1px solid",
      borderBottomColor: haBreakColor,
    },
    receiptBreak: {
      borderBottom: "1px solid",
      borderBottomColor: haBreakColor,
      [breakpoints.down("sm")]: {
        display: "none",
      },
    },
    alwaysList: {
      paddingBottom: "0px",
      listStyle: "none",
      [breakpoints.down("sm")]: {
        paddingTop: "16px",
      },
    },
    list: {
      paddingBottom: "0px",
      listStyle: "none",
      [breakpoints.down("sm")]: {
        display: "none",
      },
    },
    listItem: {
      paddingTop: "16px",
      lineHeight: "16px",
      [breakpoints.down("sm")]: {
        paddingTop: "0px",
      },
    },
    spacedItems: {
      display: "flex",
      paddingBottom: "1rem",
      justifyContent: "space-between",
      alignItems: "flex-start",
      fontWeight: "800",
    },
    total: {
      fontWeight: "800",
      paddingBottom: "0px",
      fontSize: "16px",
    },
    breakdownSpacedItems: {
      paddingBottom: "16px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    membership: {
      fontWeight: "bold",
      color: isAngi ? angiCouponGreen : haCouponGreen,
      fontSize: "16px",
    },
    bottomPadding: {
      paddingBottom: "16px",
    },
    biggerFont: {
      lineHeight: "20px",
      fontWeight: "lighter",
    },
    lineWrap: {
      lineHeight: "20px",
      fontWeight: isAngi ? "bold" : "inherit",
      width: "130px",
      whiteSpace: "pre-wrap",
    },
    keyLineItem: {
      display: "flex",
      alignItems: "center",
    },
    keyLogo: {
      marginRight: "8px",
      color: isAngi ? angiKeyLogoColor : haKeyLogoColor,
    },
    serviceFont: {
      lineHeight: "20px",
    },
    lightFont: {
      fontWeight: "lighter",
      paddingBottom: "0.3rem",
    },
    serviceName: {
      fontSize: "16px",
      color: angiPriceTextColor,
    },
    supportingText: {
      fontSize: "0.9rem",
      color: supportingTextColor,
    },
  };
});

export default useStyles;
