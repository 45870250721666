import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createSelectServiceById } from '#/selectors';
import { formatDate } from "#/utils";
export default function BundleServiceLineItem({ serviceName }) {
  const service = useSelector(createSelectServiceById(serviceName));
  const {
    name,
    shortDisplayName,
    businessHours: { value: startsAt },
  } = service;
  const date = formatDate(startsAt);

  const title = name.length > 25 ? shortDisplayName : name;
  return (
    <p>
      {title} is confirmed for {date}
    </p>
  );
}
BundleServiceLineItem.propTypes = {
  serviceName: PropTypes.string.isRequired,
};
