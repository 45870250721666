import HomeCleaningBackgroundImage from '../images/home-cleaning-background.png'
import OtherHandymanServiceBackgroundImage from '../images/other-handyman-service-background.png'
import AngiHomeCleaningBackgroundImage from "../images/angi-home-cleaning-background-image.png"
import Moment from 'moment'

const defaultServiceMachineName = 'other_handyman_service'

const frequencyOptions = [
  {
    display_name: "once",
    machine_name: "one_time"
  },
  {
    display_name: "weekly",
    machine_name: "weekly"
  },
  {
    display_name: "biweekly",
    machine_name: "biweekly"
  },
  {
    display_name: "monthly",
    machine_name: "monthly"
  }
]


const frequencyMachineNames = {
  ha_lawnmowing_trimming: 'biweekly',
  home_cleaning: "weekly",
  other_handyman_service: "one_time",
};

function frequencyMachineName(serviceMachineName, defaultToWeekly = false) {
  if (defaultToWeekly) {
    return 'weekly'
  }

  return frequencyMachineNames[serviceMachineName] || frequencyMachineNames[defaultServiceMachineName]
}

function defaultResponses(questions) {
  let responses = {}
  questions.forEach(question => {
    let defaultValue = ''
    const { options } = question

    if (options.length > 0) {
      const defaultOption = _.find(options, { 'default': true });
      defaultValue = defaultOption.value
    }

    responses[question.machine_name] = defaultValue
  })

  return responses
}
function responsesMatchQuestions(responses, questions) {
  const responseKeys = Object.keys(responses)

  if (questions.length !== responseKeys.length) return false

  questions.forEach(question => {
    const questionMachineName = question.machine_name

    if (!responseKeys.includes(questionMachineName)) {
      return false
    }
  })

  return true
}

function haBackgroundImageMapping (serviceMachineName) {
  switch (serviceMachineName) {
    case 'home_cleaning': return HomeCleaningBackgroundImage
    case 'ha_one_time_cleaning': return HomeCleaningBackgroundImage
    case 'other_handyman_service': return OtherHandymanServiceBackgroundImage
    default: return OtherHandymanServiceBackgroundImage
  }
}

function angiBackgroundImageMapping (serviceMachineName) {
  switch (serviceMachineName) {
    case 'home_cleaning': return AngiHomeCleaningBackgroundImage
    case 'ha_one_time_cleaning': return AngiHomeCleaningBackgroundImage
    case 'other_handyman_service': return OtherHandymanServiceBackgroundImage
    default: return OtherHandymanServiceBackgroundImage
  }
}

function whenPageBackgroundImage (serviceMachineName, brandingDetails) {
  return brandingDetails.isAngi ? angiBackgroundImageMapping(serviceMachineName) : haBackgroundImageMapping(serviceMachineName)
}

function initialDate () {
  // to prevent booking window error for customers
  const daysInFuture = Math.floor(Math.random() * 3) + 4;
  const initialDate = new Date(Moment().add(daysInFuture, 'day'));
  const initialHour = Math.floor(Math.random() * 6) + 10;
  initialDate.setHours(initialHour, 0, 0, 0);
  return new Date(initialDate);
}

export {
  defaultResponses,
  frequencyOptions,
  frequencyMachineName,
  initialDate,
  responsesMatchQuestions,
  whenPageBackgroundImage,
};
