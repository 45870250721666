import React from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatAccessFee, formatDiscountAmount } from '../../utils/memberships';
import AngiKeyMinimal from '../shared/icons/AngiKeyMinimal';
import RadioTile from '../shared/RadioTile';

const useOptionPricingStyles = makeStyles({
  keyIcon: {
    marginRight: '6px',
  },
});

export const Member = ({ checked, membership, name, onChange, price }) => {
  const classes = useOptionPricingStyles({});

  return (
    <RadioTile
      checked={checked}
      label="Key member price"
      labelSecondary={(
        <>
          Become a member for {formatAccessFee(membership)}/yr. Cancel&nbsp;anytime
        </>
      )}
      name={name}
      onChange={onChange}
      price={price}
      tagContent={(
        <>
          <AngiKeyMinimal className={classes.keyIcon} />
          Save&nbsp;
          {formatDiscountAmount(membership)}
        </>
      )}
      value
    />
  );
};

Member.propTypes = {
  checked: PropTypes.bool.isRequired,
  membership: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export const Regular = ({ checked, name, onChange, price }) => (
  <RadioTile
    checked={checked}
    label="Regular price"
    name={name}
    onChange={onChange}
    price={price}
    value={false}
  />
);

Regular.propTypes = {
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
