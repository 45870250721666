const Footer = () => {
  return (
    <div className="footer-term-links">
      <ul className="footer-links-ul">
      </ul>
    </div>
  )
}

export default Footer
