import { combineReducers } from '@reduxjs/toolkit';
import { checkoutApi } from '#/apiServices';
import addToCartPage from './addToCartPage';
import appInfo from './appInfo';
import brandingDetails from './brandingDetails';
import cart from './cart';
import bundles from './bundles';
import quotes from './quotes';
import providers from './providers';
import services from './services';
import whenForm from './whenForm';
import bookingRequestErrorReducer from './bookingRequestError';

export default combineReducers({
  addToCartPage,
  appInfo,
  brandingDetails,
  bundles,
  cart,
  checkoutApi: checkoutApi.reducer,
  quotes,
  providers,
  services,
  whenForm,
  bookingRequestErrorReducer,
});
