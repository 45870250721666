import React from 'react';
import ServiceLink from './ServiceLink';

const serviceLinksLeftCol = [
  'additionsAndRemodeling',
  'windowsAndDoors',
  'flooringAndCarpet',
  'heatingAndAirConditioning',
  'painting',
];

const serviceLinksRightCol = [
  'roofingAndSiding',
  'electrical',
  'handymanServices',
  'landscaping',
  'plumbing',
];

class ServiceLinkSection extends React.PureComponent {
  render() {
    return (
      <div className="cell medium-8 small-centered service-links-container">
        <h2 className="service-links-header">
          Have another project you'd like to talk to Pros about?
        </h2>
        <div className="medium-6 columns service-link-content">
          {
            serviceLinksLeftCol.map((variation, i) => {
              return (
                <ServiceLink
                  variation={variation}
                  key={i}
                />
              );
            })
          }
        </div>
        <div className="medium-6 columns service-link-content">
        {
          serviceLinksRightCol.map((variation, i) => {
            return (
              <ServiceLink
                variation={variation}
                key={i}
              />
            );
          })
        }
        </div>
      </div>
    );
  }
}

export default ServiceLinkSection;
