import { createMuiTheme } from '@material-ui/core';
import baseTheme from './base';
import scssVariables from '#/styles/_vars.scss';

const {
  angiBorderRadius,
  angiButtonBlue,
  angiOrange,
  angiPriceTextColor,
  baseWhite,
  angiDarkGrayBackground2,
  angiDarkGrayBackground3,
  angiGray200,
  angiGray500,
  angiPurple500,
  angiGrayTextSecundary,
  angiKeyLogoColor,
} = scssVariables;

const angiTheme = createMuiTheme({
  name: 'angi',
  customPalette: {
    angiGray200,
    angiGray500,
    angiPurple500,
    angiButtonBlue,
    angiDarkGrayBackground2,
    angiDarkGrayBackground3,
    angiKeyLogoColor,
    white: baseWhite,
  },
  palette: {
    primary: {
      light: angiPriceTextColor,
      main: angiPriceTextColor,
      dark: angiPriceTextColor,
      contrastText: baseWhite,
    },
    secondary: {
      light: angiOrange,
      main: angiOrange,
      dark: angiOrange,
      contrastText: baseWhite,
    },
    success: {
      main: '#00C785',
    },
    text: {
      primary: angiPriceTextColor,
      secondary: angiGrayTextSecundary,
      base: angiPriceTextColor,
    },
    shape: {
      borderRadius: angiBorderRadius,
    },
  },
  typography: {
    fontFamily: 'national2Regular, Montserrat, sans-serif',
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: angiPriceTextColor,
      },
    },
    MuiOutlinedInput: {
      root: {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px',
        },
      },
    },
  },
}, baseTheme);

export default angiTheme;
