import { CardNumberElement, CardExpiryElement, CardCVCElement, CardElement} from 'react-stripe-elements'
import { mobileMaxWidthSize } from '../../utils'
import classnames from 'classnames'

class BillingInfo extends React.Component {
  
  render() {
    const { membership, style, showCancelEdit, onCancelEditClick } = this.props
    const paymentPageFormRow = classnames(
      'payment-page-form-row',
      'padding-bottom-1em',
      {angi: this.props.isAngi}
    )

    const paymentPageCardFieldLock = classnames(
      'far',
      'fa-credit-card',
      'card-field-lock',
      {'angi': this.props.isAngi}
    )

    const paymentPageCardField = classnames(
      'form-input',
      'form-input--full',
      'card-field',
      {'angi': this.props.isAngi}
    )

    const isMobile = window.screen.width <= mobileMaxWidthSize; 
    return(
      <React.Fragment>
        <div className={paymentPageFormRow}>
          <div className='grid-x grid-margin-x'>
            <div className='cell small-12'>
              {membership &&
                <h4 className='padding-bottom-1em'>Billing</h4>
              }
              <label>
                <div className='grid-x grid-margin-x'>
                  <div className='cell auto'>
                    <div className={paymentPageCardField}>
                      <CardNumberElement
                        className='billing-cc'
                        style={style}
                        placeholder={'Card number'}
                      />
                      <i className={paymentPageCardFieldLock}></i>
                    </div>
                  </div>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div className='payment-page-form-row padding-bottom-1em'>
          <div className='grid-x grid-margin-x'>
            { isMobile &&
              <div className='cell small-6 '>
                <label>
                  <div className='grid-x grid-margin-x'>
                    <div className='cell small-10'>
                      <div className={paymentPageCardField}>
                        <CardExpiryElement
                          className='billing-input'
                          style={style}
                          placeholder={'Expiration'}
                        />
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            }
            
            { !isMobile &&
              <div className='cell small-12 medium-6'>
                <label>
                  <div className='grid-x grid-margin-x'>
                    <div className='cell large-12 '>
                      <div className={paymentPageCardField}>
                        <CardExpiryElement
                          className='billing-input'
                          style={style}
                          placeholder={'Expiration'}
                        />
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            }

            { isMobile &&
              <div className='cell small-6 '>
                <label>
                  <div className='grid-x grid-margin-x'>
                    <div className='cell small-12'>
                      <div className={paymentPageCardField}>
                        <CardCVCElement
                          className='billing-input'
                          style={style}
                          placeholder={'CVC'}
                        />
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            }
            { !isMobile &&
              <div className='cell small-12 medium-6'>
                <label>
                  <div className='grid-x grid-margin-x'>
                    <div className='cell large-12'>
                      <div className={paymentPageCardField}>
                        <CardCVCElement
                          className='billing-input'
                          style={style}
                          placeholder={'CVC'}
                        />
                      </div>
                    </div>
                  </div>
                </label>
              </div>
            }
          </div>
        </div>
        {showCancelEdit &&
          <div className='payment-page-form-row padding-bottom-1em'>
            <a onClick={onCancelEditClick}>Use my saved card</a>
          </div>
        }
      </React.Fragment>
    )
  }
}

export default BillingInfo
