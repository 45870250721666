import includes from 'lodash/includes';

const initialState = {
  quoteWorkflowParams: {
    quoteRequest: {},
  },
  isHydrated: false,
  isFormLoading: false,
};

export default function whenFormReducer(state = initialState, action) {
  switch (action.type) {
    case 'WHEN_FORM_IS_HYDRATED':
      return { ...state, isHydrated: true };
    case 'SET_WHEN_FORM_DATA':
      return { ...state, ...action.payload };
    case 'SET_EXTERNAL_PROVIDER_ID':
      return { ...state, externalProviderId: action.payload };
    case 'CLEAR_WHEN_FORM_DATA':
      return { ...initialState, isHydrated: true };
    case 'bundles/setContactInfo':
      return { ...state, ...action.payload };
    case 'bundles/clearServicesNotInBundle': {
      const maybePrimaryService = state.quoteWorkflowParams.quoteRequest?.serviceName;
      const newServices = action.payload;
      const newServicesContainPrimary = includes(newServices, maybePrimaryService);

      if (newServicesContainPrimary) return state;

      return {
        ...state,
        quoteWorkflowParams: {
          ...state.quoteWorkflowParams,
          quoteRequest: {},
        },
      };
    }
    case 'bundles/removePendingServiceFromCart': {
      const maybePrimaryService = state.quoteWorkflowParams.quoteRequest?.serviceName;
      const pendingService = action.payload;
      const isPendingServicePrimary = maybePrimaryService === pendingService;

      if (!isPendingServicePrimary) return state;

      return {
        ...state,
        quoteWorkflowParams: {
          ...state.quoteWorkflowParams,
          quoteRequest: {},
        },
      };
    }
    case 'bundles/updateWhenFormQuoteWorkflowParams':
      return { ...state, quoteWorkflowParams: action.payload };
    case 'bundles/removeFromCartAsPrimaryService':
      return {
        ...state,
        quoteWorkflowParams: {
          ...state.quoteWorkflowParams,
          quoteRequest: {},
        },
      };
    case 'UPDATE_MEMBERSHIP_PLAN':
      return {
        ...state,
        quoteWorkflowParams: {
          ...state.quoteWorkflowParams,
          quoteRequest: {
            ...state.quoteWorkflowParams?.quoteRequest,
            purchasePlanTemplateId: action.payload,
          },
        },
      };
    case 'FORM_IS_LOADING':
      return {
        ...state,
        isFormLoading: action.payload,
      };
    default:
      return state;
  }
}
