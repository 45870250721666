import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state.brandingDetails;

export const brandingDetailsSelector = selectSelf;

export const siteBrandKeySelector = createSelector(
  selectSelf,
  ({ siteBrandKey }) => siteBrandKey,
);
