import React, { Fragment } from 'react'
import { axios } from '../../utils/request'
import { connect } from 'react-redux'
import Spinner from '../shared/Spinner'
import ValidForm from '../shared/ValidForm'
import Moment from 'moment'
import { serverErrorHandler } from '../../utils'
import { trackNoThrottle } from './../../utils/mixpanel'
import classNames from 'classnames'
import brandingContent from '../shared/BrandingContent.jsx'
import experiments from '../../utils/experiments'
import { Omniture } from '../../utils/analytics'
import Cookies from 'universal-cookie'

const omniture = new Omniture();
const cookies = new Cookies();

class EntryInformationSection extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            loading: true,
            loadingError: false,
            submitting: false,
            entryMethods: [],
            activeEntryMethod: 0,
            submitErrors: false,
            submitSuccess: false,
            entry_instructions: '',
            additional_job_info: '',
            showProjectNotes: false,
            taskOid: cookies.get('taskoid'),
            projectNotesRedirectUrl: ''
        }
    }

    componentDidMount () {
        this.loadAvailableEntryMethods()
        this.getProjectNotesExperimentValue()
        this.setProjectNotesLink()
    }

    getProjectNotesExperimentValue () {
        experiments.fetchIsVariant('allow_qa_edits', this.props.userEmail).then((res) => {
            // Need to exclude 14 typeform services based on taskOid
            this.setState({
                ...this.state,
                showProjectNotes: res.data.variant
            });
        })
    }

    loadAvailableEntryMethods () {
        axios.get(
            `/api/v1/bookings/${this.props.bookingId}/available_entry_methods`,
            this.props.brandingDetails.partnerId
        ).then(response => {
                const available = _.get(response, 'data.available', [])
                this.setState({
                    entryMethods: available,
                    loadingError: false
                })

                const params = {
                    methods: _.map(available, 'display_name'),
                    booking_id: this.props.bookingId,
                    collects_entry_info: true,
                };

                trackNoThrottle('post_booking_entry_methods_shown', params)
            }).catch(() => {
            this.setState({
                loadingError: true
            })
        }).finally(() => {
            this.setState({
                loading: false
            })
        })
    }

    selectEntryMethod (e, i) {
        e.preventDefault()
        this.setState({
            activeEntryMethod: i
        })
    }

    activeEntryMethod () {
        return this.state.entryMethods[this.state.activeEntryMethod]
    }

    numRecommendedEntryMethods() {
        return this.state.entryMethods.length === 4 ? 2 : 1;
    }

    handleChange = (event) => {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    handleProjectNotesButtonClick = () => {
        omniture.track_link(`Subdomain FPP - Confirmation Page`);
        window.location.href = this.state.projectNotesRedirectUrl
    }

    handleSubmit = () => {
        const bookingId = this.props.bookingId
        const activeEntryMethod = this.activeEntryMethod() || {}
        const notesToPro = this.state.additional_job_info;

        this.setState({
            submitting: true
        });

        var params = {
            entry_method: activeEntryMethod.machine_name,
            booking_id: bookingId,
            notes_to_pro: notesToPro.length != 0,
        };

        trackNoThrottle('entry_method_info_submitted', params);

        axios.post(
            `/api/v1/bookings/${bookingId}/instructions`, {
                booking_id: bookingId,
                entry_method: {
                    type: activeEntryMethod.machine_name,
                    description: this.state.entry_instructions
                },
                note_to_pro: {
                    description: notesToPro
                },
            }, this.props.brandingDetails.partnerId
        ).then(response => {
            trackNoThrottle('info_submit_success', {booking_id: bookingId});
            this.setState({
                submitting: false,
                submitSuccess: true,
                submitErrors: false
            });
        }).catch(serverErrorHandler)
            .then((errors = []) => {
                if (errors.length) {
                    var allErrors = errors.map(error => error.message).join('. ');
                    trackNoThrottle('info_submit_error', {error_info: allErrors, booking_id: bookingId});
                    this.setState({
                        submitting: false,
                        submitErrors: true,
                        submitSuccess: false
                    })
                }
            })
    };

    setProjectNotesLink() {
        const enableProjectNotesMagicLink = document.documentElement.dataset.enableProjectNotesMagicLink === 'true';
        const projectNotesRedirectUrl = `${brandingContent.getLink(this.props.brandingDetails.domainBrand, "project_notes")}${this.props.bookingId}`;
        if(enableProjectNotesMagicLink){
            axios.post(
                '/api/v1/magic_links',
                 {
                    params: {
                        brand_name: this.props.brandingDetails.isAngi ? "ANGI" : "HOMEADVISOR",
                        redirect_url: projectNotesRedirectUrl
                    }
                }
            ).then(response => {
                if(response.status === 201) {
                    this.setState({
                        projectNotesRedirectUrl: response.data.magic_link
                     });
                }
            });
        }
        this.setState({ projectNotesRedirectUrl, });

    }

    render () {
        const {
            loading,
            submitting,
            activeEntryMethod,
            entry_instructions,
            additional_job_info,
            submitErrors,
            submitSuccess,
            loadingError
        } = this.state

        const recommendedLabelCss = classNames('recommended-label', 'label-green', { 'angi': this.props.brandingDetails.isAngi })
        const paymentPageFormSectionCss = classNames('payment-page-form-section', 'show-for-medium',
            { 'angi': this.props.brandingDetails.isAngi }
        )
        const buttonFullCss = classNames('button', 'button--full', 'button--tall',
            {
                'angi': this.props.brandingDetails.isAngi,
                'button--left': this.state.showProjectNotes
            }
        )
        const buttonMediumCss = classNames('button', 'button--medium', 'button--tall',
            {   'angi': this.props.brandingDetails.isAngi,
                'custom_angi_btn_width': this.props.brandingDetails.isAngi,
                'button--left': this.state.showProjectNotes
            }
        )
        const btnEntryMethodSelectedCss = (idx) => {
            return classNames('button entry-method-btn',
                { 'selected': (activeEntryMethod == idx),
                  'angi': this.props.brandingDetails.isAngi
                }
            )
        }
        const projectNotesButtonCss = classNames('button', 'button--medium', 'button--tall', 'button--left', 'button--secondary',
            {
                'angi': this.props.brandingDetails.isAngi,
                'custom_angi_btn_width': this.props.brandingDetails.isAngi
            }
        )
        const entryMethodBtnsCss = classNames('entry-methods-btns',
            {
                'angi': this.props.brandingDetails.isAngi,
                'entry-method-btns-space': this.state.showProjectNotes
            }
        )
        const entryInfoContainerCss = classNames('entry-info-container', { 'angi': this.props.brandingDetails.isAngi })
        const entryInfoSectionsCSS= classNames('entry-info-sections', { 'angi': this.props.brandingDetails.isAngi })

        const projectsUrl = brandingContent.getLink(this.props.brandingDetails.domainBrand, "projects")

        return (
            <div className='cell large-8 medium-12 small-12 small-centered entry-info-cell'>
                <div className={entryInfoContainerCss}>
                    <ValidForm submit={this.handleSubmit}>
                        <div>
                            <h3 className='entry-info-header'>
                                {this.state.showProjectNotes ? 'Choose Entry Information' : 'Entry Information'}
                            </h3>
                        </div>
                        <div className='text-center'>
                            <Spinner content='' color='black' loading={loading} />
                        </div>
                        {loadingError &&
                        <div className='red-text'>Something went wrong with your request.</div>
                        }
                        {!loading && !submitSuccess && !loadingError &&
                        <div>
                            <div className={entryMethodBtnsCss}>
                                {this.state.entryMethods.map((method, idx) =>
                                    <div className='entry-method-btn-container'>
                                        <button
                                            className={btnEntryMethodSelectedCss(idx)}
                                            onClick={(e) => this.selectEntryMethod(e, idx)}
                                        >
                                            {method.display_name}
                                        </button>
                                    </div>
                                )}
                            </div>
                            {this.state.showProjectNotes ?
                                null :
                                <Fragment>
                                    <div className='recommended-wrapper'>
                                        <div
                                            className={recommendedLabelCss}
                                            style={{
                                                borderTopWidth: 0,
                                                width: (this.numRecommendedEntryMethods() == 2 ? '46%' : '31%')
                                            }}
                                        />
                                    </div>
                                    <div className={entryInfoSectionsCSS}>
                                        {this.activeEntryMethod().requires_description &&
                                        <div>
                                            <h5 className='question-label'>Add Entry Instructions</h5>
                                            <textarea name='entry_instructions' className='form-input form-input--full form-control details-text-input'
                                                    value={entry_instructions} onChange={this.handleChange}
                                                    required
                                                    placeholder='Describe how your professional will get in. Please provide any information on building access codes, lock box code, or parking information if applicable.' />
                                            <span className='invalid-field' />
                                        </div>
                                        }
                                        <div>
                                            <h3>Additional Job Information</h3>
                                            <h5 className='question-label'>Leave Your Pro a Note</h5>
                                            <textarea name='additional_job_info' className='form-input form-input--full form-control details-text-input'
                                                    value={additional_job_info} onChange={this.handleChange}
                                                    rows='5'
                                                    placeholder='Is there anything else that your provider should know? Providing more details will help your pro do the best job possible!' />
                                            <span className='invalid-field' />
                                        </div>
                                    </div>
                                </Fragment>
                            }

                            {submitErrors &&
                            <div className='red-text'>Something went wrong with your request.</div>
                            }

                            <div className='show-for-small-only'>
                                <button type='submit' className={buttonFullCss} disabled={submitting}>
                                    <Spinner content={this.state.showProjectNotes ? 'Save Entry Information' : 'Save Details'} loading={submitting} />
                                </button>
                            </div>
                            <div className={paymentPageFormSectionCss}>
                                <button type='submit' className={buttonMediumCss} disabled={submitting}>
                                    <Spinner content={this.state.showProjectNotes ? 'Save Entry Information' : 'Save Details'} loading={submitting} />
                                </button>
                            </div>
                        </div>
                        }

                        {submitSuccess &&
                        <div className='entry-info-view-container'>
                            <div className='entry-info-view-section'>
                                {this.activeEntryMethod().display_name}
                            </div>
                            <div className='entry-info-view-section'>
                                {entry_instructions}
                            </div>
                            { this.state.showProjectNotes ? null :
                                <div className='entry-info-view-section'>
                                    {additional_job_info}
                                </div>
                            }
                            <div className='entry-info-view-section'>
                                <span className='fa fa-check-circle green-check' /> Saved
                            </div>
                        </div>
                        }

                    </ValidForm>

                    {/* MEOW-13 - Add entry point to add project details */}
                    {this.state.showProjectNotes ?
                        <div>
                            <div className="horizontal-separator" />
                            <div>
                                <h3 className='entry-info-header'>
                                Add Project Details and Photos
                                </h3>
                            </div>
                            <div className="project-notes-content">
                                Adding more details and photos to your project helps your pro to come prepared.
                            </div>

                            <div className='show-for-small-only'>
                                <button
                                    className={ projectNotesButtonCss }
                                    onClick={ this.handleProjectNotesButtonClick }>
                                    Add Project Details
                                </button>
                            </div>
                            <div className={paymentPageFormSectionCss}>
                                <button
                                    className={ projectNotesButtonCss }
                                    onClick={ this.handleProjectNotesButtonClick }>
                                    Add Project Details
                                </button>
                            </div>

                            <div className="project-notes-sub-text">
                                You can also add details to your booking from your <a className="project-notes-link" href={ projectsUrl }>projects page</a>.
                            </div>
                        </div> : null
                    }
                </div>
            </div>
        )
    }
}

export default EntryInformationSection
