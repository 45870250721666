import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, styled } from '@material-ui/core';
import noop from 'lodash/noop';
import SelectInput from './Select';
import { Caret } from '../icons';

const StyledMenuItem = styled(MenuItem)({
  fontSize: '0.875rem',
  padding: '1rem',
});

export default function Select({ id, options, onChange, value: currentValue }) {
  const handleOnChange = ({ target: { value } }) => { onChange(value); };
  return (
    <SelectInput
      IconComponent={Caret}
      id={id}
      labelWidth={0}
      onChange={handleOnChange}
      value={currentValue}
      variant="outlined"
    >
      {options.map(({ value, text, disabled = false }) => (
        <StyledMenuItem key={value} value={value} disabled={disabled}>
          {text}
        </StyledMenuItem>
      ))}
    </SelectInput>
  );
}

Select.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
  })).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Select.defaultProps = {
  id: undefined,
  onChange: noop,
  value: '',
};
