
import LabelMaker from '../../shared/LabelMaker'
import PhoneInputSection from '../../shared/PhoneInputSection'

class HAYourAddress extends React.Component {
    render() {
        const { h3font, enablePlanEngagement, handleInputChange, getInputPlaceholder, user, brandingDetails } = this.props

        return (
            <div className='payment-page-form-section'>
                <h3 className={h3font}>{enablePlanEngagement ? 'Your address' : 'Your Address'}</h3>

                <div className='payment-page-form-row'>
                    <div className='grid-x grid-margin-x'>
                        <div className='cell small-12 medium-6 padding-bottom-1em'>
                            <LabelMaker
                                name='First Name'
                                handler={handleInputChange}
                                placeholderHandler={getInputPlaceholder}
                                value={user.firstName} />
                        </div>

                        <div className='cell small-12 medium-6 padding-bottom-1em'>
                            <LabelMaker
                                name='Last Name'
                                handler={handleInputChange}
                                placeholderHandler={getInputPlaceholder}
                                value={user.lastName} />
                        </div>
                    </div>
                </div>

                <div className='payment-page-form-row'>
                    <div className='grid-x grid-margin-x'>
                        <div className='cell small-12'>
                            <LabelMaker
                                name='Street Address'
                                handler={handleInputChange}
                                placeholderHandler={getInputPlaceholder}
                                size='full'
                                value={user.streetAddress} />
                        </div>
                    </div>
                </div>

                <div className='payment-page-form-row'>
                    <div className='grid-x grid-margin-x'>
                        <div className='cell small-12'>
                            <label>
                                <div className='grid-x grid-margin-x'>
                                    <div className='cell large-3 show-for-large input-label required padding-top-1em'>
                                        <span>Mobile Phone</span>
                                    </div>
                                    <div className='cell small-12 large-9 padding-top-1em'>
                                        <PhoneInputSection
                                            name='mobilePhone'
                                            placeholder={getInputPlaceholder('Mobile Phone')}
                                            handleChange={handleInputChange}
                                            value={user.mobilePhone}
                                            required={true}
                                            brandingDetails={brandingDetails}
                                        />
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HAYourAddress
