import { Button, styled } from '@material-ui/core';

const RadioButton = styled(Button)(({ theme: { breakpoints, palette, customPalette, name } }) => {
  const buttonColor = name === 'angi' ? customPalette.angiButtonBlue : palette.primary.main;
  return ({
    height: '48px',
    color: 'primary',
    width: '120px',
    [breakpoints.down('xs')]: {
      maxWidth: '92px',
      fontSize: '12px',
    },
    '&[aria-checked="true"]': {
      // The previously selected component will transition, but selection will be instant.
      // Since we're flipping white/blue this prevents the button from looking like it's flashing.
      transition: 'none',
      backgroundColor: buttonColor,
      color: palette.primary.contrastText,
      borderColor: buttonColor,
      pointerEvents: 'none',
    },
  });
});

export default RadioButton;
