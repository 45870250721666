import _ from 'lodash';
import Cookies from 'universal-cookie';
import { buildBookingParams } from '.';
import { axios } from './request';

const cookies = new Cookies();

export async function createTokenResult(user, stripe, createStripeToken) {
  if (createStripeToken) {
    return await stripe.createToken({ name: 'handy-checkout-flow' });
  } else {
    return { token: { id: null } };
  }
};

export default function makeBookingRequest(
  user,
  quote,
  membership,
  serviceMachineName,
  stripe,
  partnerId = 'home_advisor',
  extrasAdded = [],
  additionalParams = {},
  paymentAfterComplete = false,
  couponCode = null,
  primaryBookingExternalId = null,
  createStripeToken = false,
) {
  const purchasePlanTemplate = membership && membership.isSelected ?
    { plan_template_id: membership.planTemplateId } :
    {};
  const bookingPromise = createTokenResult(user, stripe, createStripeToken).then((result) => {
    if (result.error) {
      throw { response: { data: { message: result.error.message } } };
    } else {
      user.tokenId = result.token.id;
    }
    return user;
  }).then((user) => {
    const bookingPayload = buildBookingParams(
      quote,
      user,
      couponCode,
      serviceMachineName,
      additionalParams,
      extrasAdded,
      purchasePlanTemplate,
      paymentAfterComplete,
      partnerId,
      primaryBookingExternalId,
      cookies.get('external_provider_id'),
    );
    return axios.post('/api/v1/bookings', bookingPayload, {}, partnerId);
  })

  return bookingPromise;
}
