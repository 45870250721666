import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { startCase } from 'lodash'
import { axios } from '../../utils/request'
import { getCustomerRecommendations, whenPageCopy } from '../../utils/copy'
import { enablePlanEngagement, isExperiment } from '../../utils/config'
import { isMember } from '../../utils/memberships'
import { whenPageBackgroundImage } from '../../utils/content'
import { buildUserFromApiResponse } from '../../utils/user'
import { fakeApiCall, couponData } from '../../utils'
import { Omniture } from "../../utils/analytics"
import { track } from './../../utils/mixpanel'
import Cookies from 'universal-cookie'
import WhenForm from './Form'
import WhatsIncluded from './WhatsIncluded'
import CouponBanner from '../shared/CouponBanner'
import PlanEngagementBanner from '../shared/PlanEngagementBanner'
import Stars from '../shared/Stars'
import CustomerRecommendations from './CustomerRecommendations'
import HowItWorks from './HowItWorks';
import FaqSection from './FaqSection';
import apiCaller from '../../utils/apiCaller';
import URLSearchParams from '@ungap/url-search-params'
import brandingContent from '../shared/BrandingContent.jsx'
import classnames from 'classnames'
import experiments from '../../utils/experiments'
import { getRecommendedRatingsText } from '../../utils/copy'
import { setExternalProviderId, setFormLoadState } from '#/actions/whenForm';
import DescriptionSection from './DescriptionSection';
import React from 'react';
import DescriptionChecklist from './DescriptionChecklist';

const cookies = new Cookies()
const omniture = new Omniture()
const enableCoupons = document.documentElement.dataset.enableCoupons === 'true'
const customerRecommendationsEnabled = document.documentElement.dataset.enableCustomerRecommendations === 'true'

class WhenPage extends React.Component {
  constructor (props) {
    super(props)
    const formAttribute = cookies.get('formAttribute')
    const prefilledAttribute = _.pick(
      formAttribute,
      [
        'zipcode',
        'dateStart',
        'timeStart',
        'email',
        'responses',
        'phone',
        'firstName',
        'lastName',
      ]
    )
    const { location, updateExternalProviderId, setFormLoadState } = props;

    const qs = new URLSearchParams(location.search);
    let taskoid = qs.get('taskoid');
    let couponCode = qs.get('coupon');
    const zipcode = qs.get('zip') || '';
    const email = qs.get('email') || this.initialEmail(prefilledAttribute) || cookies.get('email') || '';
    const entryPointId = qs.get('entry_point_id') || '';
    const extFulTrack = qs.get('extFulTrack') || '';
    const utm_source = qs.get('utm_source');
    const utm_medium = qs.get('utm_medium');
    const utm_campaign = qs.get('utm_campaign');
    const utm_content = qs.get('utm_content');
    const utm_term = qs.get('utm_term');
    const cjevent = qs.get('cjevent');
    const external_provider_id = qs.get('external_provider_id') || '';

    const pageName = `FixedPriceHandy: ${startCase(utm_source || 'direct')} Capture`;

    const reason = qs.get('reason') || ''

    cookies.set('entryPointId', entryPointId, { path: '/' });
    cookies.set('extFulTrack', extFulTrack, { path: '/' });
    cookies.set('cjevent', cjevent, { path: '/' });
    cookies.set('external_provider_id', external_provider_id, { path: '/' });
    updateExternalProviderId(external_provider_id);
    setFormLoadState(true);
    const taskoidName = cookies.get('taskoidName');
    const serviceMachineName = cookies.get('serviceMachineName');

    const defaultTitle = `About Fixed Price ${taskoidName}`;
    const defaultFormTitle = `Fixed Price ${taskoidName}`;

    omniture.track({
      serviceMachineName,
      pageName,
      entryPointId,
      extFulTrack,
      taskoid,
      zipcode,
      prop58: 'FixedPriceHandy: When Form',
      catchall: external_provider_id,
      utm_source,
      utm_campaign,
    });

    this.recommendationRef = React.createRef()
    this.whenFormRef = React.createRef()

    this.state = {
      title: defaultTitle,
      formTitle: defaultFormTitle,
      taskoid,
      taskoidName,
      taskMappingEnabled: props.taskMappingEnabled,
      serviceMachineName,
      couponCode,
      zipcode,
      email,
      quoteCreated: false,
      errors: [],
      customerRecommendations: {},
      entryPointId,
      reason,
      reviews: [],
      serviceMetadata: {},
      reviewCount: 0,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_term,
      isMember: false,
      enablePlanEngagement: false,
      enablePlanEngagementVariant: "default",
    }
  }

  componentDidMount = () => {
    if (this.props.location.state && this.props.location.state.errors) {
      const whenPageErrors = this.props.location.state.errors
      this.setState({
        errors: whenPageErrors
      })

      this.props.history.replace({
        pathname: this.props.location.pathname,
        state: {}
      })
    }

    const { serviceMachineName, taskoidName, taskoid } = this.state

    if (this.state.taskMappingEnabled){
      this.fetchTaskContent(taskoid)
    }

    const recommendationLink = brandingContent.brandingLinks[this.props.brandingDetails.domainBrand]["recommendations"]
    Promise.all([
      axios.get(`/api/v1/services/${serviceMachineName}`),
      fakeApiCall(getCustomerRecommendations(serviceMachineName, taskoidName, recommendationLink)),
      axios.get(`/api/v1/services/${serviceMachineName}/provider_reviews`),
      axios.get(`/api/v1/services/${serviceMachineName}/metadata`),
    ])
      .then(results => {
        const serviceInfo = results[0]
        const customerRecommendations = results[1]
        const reviewsResponse = results[2]
        const serviceMetadataResponse = results[3]

        cookies.set('serviceInfo', serviceInfo.data, { path: '/' })

        this.setState({
          serviceInfo: serviceInfo.data,
          customerRecommendations,
          reviews: reviewsResponse.data.reviews,
          serviceMetadata: serviceMetadataResponse.data.metadata,
        })

        this.reviewCount()
      })

    if (enablePlanEngagement(serviceMachineName) && !this.props.brandingDetails.isAngi) {
      if (!!this.state.email) {
        this.setMembershipFromState()
      } else {
        this.setMembershipFromSession()
      }

      experiments.fetchIsVariant('checkout_plan_engagement', this.state.email).then(({ data }) => {
        this.setState({
          enablePlanEngagement: isExperiment(
            serviceMachineName,
            this.props.brandingDetails,
            this.state.isMember,
            data.variant
          )
        });
        this.setState({
          enablePlanEngagementVariant: this.state.enablePlanEngagement ? "experiment" : "default",
        });
      }).catch(error => {
        console.error('Error fetching experiment variant', error);
      });
    }

    this.validateCoupon()
  }

  // Use email from state to retrieve membership status
  setMembershipFromState = () => {
    isMember(this.state.email, this.state.serviceMachineName, this.props.brandingDetails.siteBrandKey).then((result) => {
      cookies.set('isMember', result.data.is_member, { path: '/' });
      this.setState({
        isMember: result.data.is_member
      })
    });
  }

  // Use current user session to retrieve email and membership status
  setMembershipFromSession = () => {
    buildUserFromApiResponse().then((user) => {
      cookies.set('isMember', user.isMember, { path: '/' })
      this.setState({
        email: user.email,
        zipcode: user.zipcode,
        isMember: user.isMember
      })
    })
  }

  initialEmail = prefilledAttribute => {
    return _.get(prefilledAttribute, 'email', '')
  }

  setStateFromChild = (child_attrs) => {
    this.setState(child_attrs);
    this.setState({
      enablePlanEngagementVariant: this.state.enablePlanEngagement ? "experiment" : "default",
    });
  }

  updateWhenPage = (redirectUrl, is_external_redirect, quoteWorkflowParams) => {
    cookies.set('quoteWorkflowParams', quoteWorkflowParams, { path: '/' });

    const newState = Object.assign({}, this.state, {
      quoteCreated: true,
      redirectUrl,
      is_external_redirect,
    });
    this.setState(newState);
  }

  fetchTaskContent = (taskoid) => {
    axios.get(`/api/v1/tasks/${taskoid}`)
    .then(response => {
      const { title, description, form_title } = response.data.task

      this.setState({
        formTitle: form_title,
        description,
        title,
      })
    })
  }

  scrollTo = ref => {
    window.scrollTo({
      top: this[ref].current.offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  }

  trackAndScroll = (ref, eventId) => {
    const entryPointId = cookies.get('entryPointId')
    const extFulTrack = cookies.get('extFulTrack')
    const serviceMachineName = cookies.get('serviceMachineName')
    const pageName = 'FixedPriceHandy: Email Capture'

    omniture.track({ serviceMachineName, pageName, entryPointId, extFulTrack, events: eventId })
    this.scrollTo(ref);
  }

  bannerDisplay = () => {
    if (this.state.enablePlanEngagement) {
      return <PlanEngagementBanner body='Your 20% member discount will be applied at checkout' hasCheckMark='true' />
    } else {
      return this.couponDisplay();
    }
  }

  couponDisplay = () => {
    const couponInfo = couponData(cookies);
    if (this.state.couponError) {
      return <CouponBanner body={this.state.couponError} brandingDetails={this.props.brandingDetails} error={true} />
    } else if (couponInfo && couponInfo.value) {
      if (enableCoupons && couponInfo.showBanner) {
        return <CouponBanner body={couponInfo.bannerDescription} brandingDetails={this.props.brandingDetails} />
      }
    }
  }

  validateCoupon = () => {
    this.setState({ couponError: null })
    if (!enableCoupons) { return }

    const { serviceMachineName } = this.state
    let { couponCode } = this.state

    if (!couponCode) {
      if (couponData(cookies)) {
        cookies.remove('couponInfo')
      }
      return
    }

    const {
      email,
      frequency,
      zipcode
    } = this.state

    apiCaller.postCouponValidation(couponCode, serviceMachineName, zipcode, frequency, email)
      .then(response => {
        const couponValidation = response.data.coupon_validation
        const { coupon, banner } = couponValidation

        if (couponValidation.is_valid) {
          const couponInfo = {
            couponCode: coupon.code,
            showBanner: banner.is_visible,
            bannerDescription: banner.description,
            value: coupon.value,
            type: coupon.type,
            stackable: coupon.stackable,
          };

          cookies.set('couponInfo', couponInfo, { path: '/' })
          this.forceUpdate()
        } else {
          this.setCouponError()
        }
      })
      .catch(() => {
        track('coupon_error', {
          coupon_code: couponCode,
          step: 'when',
          checkout_plan_engagement_variant: this.state.enablePlanEngagementVariant,
        })
        this.setCouponError()
      })
  }

  showFaqSection = () => {
    const servicesWithFaq = ['home_cleaning', 'other_handyman_service']
    const { serviceMachineName } = this.state

    return _.includes(servicesWithFaq, serviceMachineName)
  }

  setCouponError = () => {
    this.setState({
      couponError: 'Coupon could not be applied',
      couponInfo: null
    })
    cookies.remove('couponInfo');
  }

  reviewCount = () => {
    let { serviceMetadata, reviewCount } = this.state

    if (serviceMetadata.reviews_with_min_rating) {
      reviewCount = serviceMetadata.reviews_with_min_rating.toLocaleString()
    } else if (serviceMetadata.reviews_gtet_four_starts) {
      reviewCount = serviceMetadata.reviews_gtet_four_starts.toLocaleString()
    }

    this.setState({ reviewCount })
  }

  // xxx: hack work-around-- cannot set errors on WhenForm easily
  setErrors = (errors) => {
    if  (!_.isEmpty(errors)) {
      this.setState({ errors })
    }
  }

  renderRatingStars = (reviewCount, average_rating) => {
    return (parseInt(reviewCount) > 0 && parseFloat(average_rating) >= 4)
  }

  render() {
    const {
      quoteCreated,
      is_external_redirect,
      redirectUrl,
      taskoid,
      taskoidName,
      customerRecommendations,
      serviceInfo,
      zipcode,
      serviceMachineName,
      email,
      errors,
      entryPointId,
      reason,
      reviews,
      serviceMetadata,
      reviewCount,
      formTitle,
      description,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_term,
      couponCode,
    } = this.state;

    const { brandingDetails, isFormLoading } = this.props;

    if (quoteCreated) {
      window.location = redirectUrl;
      return null;
    }

    const isInvisible = isFormLoading ? 'invisible' : '';
    const faqBrandingLinks = brandingContent.brandingLinks[this.props.brandingDetails.domainBrand]
    const whenCopy = whenPageCopy(serviceMachineName, faqBrandingLinks, this.props.brandingDetails)
    const whatsIncluded = (taskoidName === "Home Cleaning")
      ? <WhatsIncluded scrollToWhenFormRef={() => this.scrollTo('whenFormRef')} brandingDetails = { brandingDetails } />
      : null
    const screenedProfesionalLink = brandingContent.getLink(this.props.brandingDetails.domainBrand, "screened_profesional")
    const fixPriceGuaranteeLink = brandingContent.getLink(this.props.brandingDetails.domainBrand, "fix_price_guarantee")
    const whenPageContainerCSS = classnames('when-page-container', isInvisible, { 'angi': this.props.brandingDetails.isAngi })
    const faCheckCss = classnames('fa fa-check txt-large green padding-right-0-5em', { 'angi': this.props.brandingDetails.isAngi })
    const angiLinkColor = classnames( 'unstyled-list', { 'angi': this.props.brandingDetails.isAngi });

    const angiFontBold = classnames(
      {'h1angi-bold-font': this.props.brandingDetails.isAngi}
    )

    const recommendedRatingsText = getRecommendedRatingsText(reviewCount)
    return (
      <div className={whenPageContainerCSS}>
        {this.bannerDisplay()}
        <div className="show-for-small-only when-form-top-padding" />
        <img src={whenPageBackgroundImage(serviceMachineName,brandingDetails)} className="when-background show-for-medium" />
        <div className="when-row">
          <div className="grid-container">
            <div className='grid-x grid-margin-x' ref={this.whenFormRef}>
              <WhenForm
                title={formTitle}
                updateWhenPage={this.updateWhenPage}
                serviceInfo={serviceInfo}
                setErrors={this.setErrors}
                zipcode={zipcode}
                email={email}
                scrollToRecommendationRef={() => this.scrollTo('recommendationRef')}
                serviceMachineName={serviceMachineName}
                errors={errors}
                customerRecommendations={customerRecommendations}
                serviceMetadata={serviceMetadata}
                reviewCount={reviewCount}
                reason={reason}
                taskoid={taskoid}
                utm_source={utm_source}
                utm_medium={utm_medium}
                utm_campaign={utm_campaign}
                utm_content={utm_content}
                utm_term={utm_term}
                couponCode={couponCode}
                brandingDetails={ brandingDetails }
                isMember={this.state.isMember}
                enablePlanEngagement={this.state.enablePlanEngagement}
                setParentState={this.setStateFromChild}
                enablePlanEngagementVariant={this.state.enablePlanEngagementVariant}
                customerRecommendationsEnabled={customerRecommendationsEnabled}
              />
              <div className="how-to-book cell medium-8 small-12 medium-order-1 small-order-2">
                <h1 className={angiFontBold}>{this.state.title}</h1>
                {
                  this.renderRatingStars(reviewCount, serviceMetadata.average_rating) ?
                    (<div className='average-star-rating-container show-for-medium'>
                      <Stars starRating={serviceMetadata.average_rating} brandingDetails={brandingDetails}/>
                      {
                        customerRecommendationsEnabled ?
                          (<a className='average-star-rating-description' onClick={() => this.trackAndScroll('recommendationRef', 'event420')}>
                              {recommendedRatingsText}
                          </a>)
                        : (<span className='average-star-rating-description'>
                              {recommendedRatingsText}
                          </span>)
                      }
                    </div>)
                  : null
                }
                <div className='how-to-book-section'>
                  <DescriptionSection description={description || whenCopy.mainDescription} />
                </div>
                <DescriptionChecklist
                  brandingDisplayName={this.props.brandingDetails.displayName}
                  screenedProfessionalLink={screenedProfesionalLink}
                  fixPriceGuaranteeLink={fixPriceGuaranteeLink}
                />
              </div>
            </div>
          </div>
        </div>
        <HowItWorks brandingDetails = {this.props.brandingDetails} serviceMachineName={serviceMachineName} />
        { whatsIncluded }
        {this.showFaqSection() && <FaqSection
          header={whenCopy.faqHeader}
          accordionContent={whenCopy.accordionContent}
          classNames={whenCopy.faqHeaderClassNames}
          brandingDetails = { this.props.brandingDetails }
        />}
        { customerRecommendationsEnabled &&
          <div ref={this.recommendationRef}>
            <CustomerRecommendations
              recommendations={customerRecommendations}
              reviews={reviews}
              serviceMetadata={serviceMetadata}
              reviewCount={reviewCount}
              taskoidName={taskoidName}
              scrollToWhenFormRef={() => this.trackAndScroll('whenFormRef', 'event419')}
              brandingDetails = { this.props.brandingDetails }/>
          </div>
        }
      </div>
    )
  }
}
const updateExternalProviderId = (id) => setExternalProviderId(id);
const mapDispatchToProps = { updateExternalProviderId, setFormLoadState };
const mapStateToProps = (state) => {
  const { isFormLoading } = state.whenForm
  return {
    isFormLoading,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WhenPage);
