import { servicesActions } from '#/reducers/services';

export const {
  serviceAdded,
  servicesLoading,
  servicesReceived,
  serviceUpdated,
  serviceRemoved,
} = servicesActions;

function updateBookingDateBase(serviceName, newValue) {
  return (dispatch, getState) => {
    const { businessHours } = getState().services.entities[serviceName];
    return dispatch(serviceUpdated({
      id: serviceName,
      changes: {
        businessHours: {
          ...businessHours,
          value: newValue,
        },
      },
    }));
  };
}

export function updateBookingDate(serviceName, momentDate, opts) {
  const { setDay, setHour } = { setDay: true, setHour: true, ...opts };
  return (dispatch, getState) => {
    const { businessHours: { value } } = getState().services.entities[serviceName];
    const [currentDay, currenHour] = value.split(' ');

    const newHour = momentDate.clone().format('HH:mm');
    const newDay = momentDate.clone().format('YYYY-MM-DD');

    const day = setDay ? newDay : currentDay;
    const hour = setHour ? newHour : currenHour;

    dispatch(updateBookingDateBase(serviceName, `${day} ${hour}`));
  };
}

export function updateServiceFormErrors(serviceName, errors) {
  return function serviceFormErrorUpdater(dispatch) {
    return dispatch(serviceUpdated({
      id: serviceName,
      changes: { errors },
    }));
  };
}

export function clearServices() {
  return { type: 'CLEAR_SERVICES' };
}
