import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import noop from 'lodash/noop';
import RadioButton from './RadioButton';

export default function ButtonRadio({ options, onChange, value: currentValue }) {
  const onClick = (value) => () => onChange(value);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      maxWidth="504px"
      role="radiogroup"
    >
      {options.map(
        ({ text, value }) => (
          <RadioButton
            key={value}
            role="radio"
            aria-checked={value === currentValue}
            variant={value === currentValue ? 'contained' : 'outlined'}
            onClick={onClick(value)}
            color="primary"
          >
            {text}
          </RadioButton>
        ),
      )}
    </Box>
  );
}

ButtonRadio.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

ButtonRadio.defaultProps = {
  onChange: noop,
  value: '',
};
