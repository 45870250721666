import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useTheme } from '@material-ui/core/styles';
import useStyles from '../../cart/receipt/styles';

export default function AngiKey({ style }) {
  const { name: theme } = useTheme();
  const classes = useStyles({ theme });

  return (
    <SvgIcon style={style} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.keyLogo}>
      <rect y="4" width="32" height="24" rx="5.44391" color="primary" />
      <rect x="19.4258" y="16.8672" width="3.12112" height="2.52674" fill="white"/>
      <path d="M12.1041 15.6907C12.1223 15.7913 12.1321 15.8942 12.1321 16C12.1321 16.9638 11.3429 17.7482 10.3732 17.7482C9.40358 17.7482 8.61517 16.9638 8.61517 16C8.61517 15.0362 9.40434 14.2518 10.374 14.2518C11.176 14.2518 11.8542 14.7885 12.0649 15.5196C12.0739 15.5301 12.0837 15.5406 12.0936 15.5511C12.0785 15.5196 12.0626 15.4881 12.0475 15.4558L13.6107 13.6281C12.8767 12.641 11.6994 12 10.374 12C8.15451 12 6.34961 13.794 6.34961 16C6.34961 18.2053 8.15451 20 10.374 20C11.7016 20 12.879 19.356 13.6122 18.3674L13.613 18.3689C13.613 18.3689 13.6258 18.3494 13.6492 18.3164C13.6696 18.2879 13.6908 18.2601 13.7104 18.2316C13.7142 18.2263 13.718 18.2211 13.7225 18.2158C12.9394 17.5403 12.3843 16.6575 12.1041 15.6907Z" fill="white"/>
      <path d="M12.0488 15.4844C12.0663 15.5539 12.0861 15.6233 12.1066 15.6921C12.0959 15.6339 12.083 15.5765 12.0663 15.5199L12.0655 15.5191L12.0488 15.4844Z" fill="#DAD8D3"/>
      <path d="M14.2358 17.1183C14.2358 17.1183 12.8569 17.1176 12.0941 15.5511C12.0843 15.5405 12.0745 15.5308 12.0654 15.5195C12.0813 15.5758 12.161 15.8209 12.1174 16.1738C12.3975 17.1406 12.9407 17.5402 13.7238 18.2142C13.9723 17.8825 14.6127 17.1258 15.2795 17.1258H16.4531L16.4584 17.1183H14.2358Z" fill="white"/>
      <path d="M15.2783 14.8673C14.4242 14.8673 13.6131 13.625 13.6131 13.625L13.6101 13.628L12.0469 15.4557C12.062 15.4888 12.0771 15.5195 12.0929 15.5511C12.8557 17.1176 14.2347 17.1183 14.2347 17.1183H16.4572H23.4035C24.643 17.1183 25.6478 16.1135 25.6478 14.874C25.6478 14.8712 25.6484 14.8665 25.6456 14.8665C25.4848 14.8673 16.5118 14.8673 15.2783 14.8673Z" fill="white"/>
    </SvgIcon>
  );
}

AngiKey.propTypes = {
  style: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
};

AngiKey.defaultProps = {
  style: {
    height: '32px',
    width: '32px',
  },
}
