import React from 'react';
import PropTypes from 'prop-types';
import { styled, Box, CircularProgress } from '@material-ui/core';
import Question from '../../../cart/task/taskForm/question';
import Frequency from '../../../cart/task/taskForm/frequency';
import Duration from '../../../cart/task/taskForm/duration';
import DateTime from '../../../cart/task/taskForm/dateTime';
import Errors from '../../../cart/task/taskForm/errors';
import { CtaButton } from '#/components/shared';
import DrawerCard from '../../../shared/DrawerCard';

const Progress = styled(CircularProgress)(({ theme: { palette: { primary: { main } } } }) => ({
  color: main,
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
}));

const StyledBox = styled(Box)(({ theme: { breakpoints } }) => ({
  display: 'block',
  justifyContent: 'center',
  marginTop: '24px',
  position: 'relative',
  [breakpoints.up('md')]: {
    width: '100%',
    textAlign: 'center',
  },
}));

const Form = styled('form')(({ padding: '24px' }));

function BundlePageTaskForm({
  hasDuration,
  hasError,
  hasFrequency,
  isLoading,
  id,
  questions,
  addToCart,
  removeFromCart,
  isLoadingQuote,
}) {
  return (
    <DrawerCard isBlurred={isLoading}>
      <Form>
        {!isLoading && <DateTime id={id}/>}
        {hasFrequency && !isLoading && <Frequency id={id}/>}
        {questions.map(({
          serviceMachineName,
          machineName,
        }) => (
          <Question
            key={machineName}
            serviceMachineName={serviceMachineName}
            machineName={machineName}
          />
        ))}
        {hasDuration && <Duration id={id} />}
        {hasError && <Errors id={id} />}
        <StyledBox>
          <div>
            <CtaButton
              disabled={isLoading || isLoadingQuote}
              variant="outlined"
              color="primary"
              style={{ borderWidth: '2px' }}
              onClick={addToCart}
            >
              Next
            </CtaButton>
            {isLoadingQuote && <Progress size={24} />}
            <CtaButton
              disabled={isLoading || isLoadingQuote}
              variant="text"
              color="secondary"
              style={{ background: 'none' }}
              onClick={removeFromCart}
            >
              Remove service
            </CtaButton>
          </div>
        </StyledBox>
      </Form>
    </DrawerCard>
  );
}

BundlePageTaskForm.propTypes = {
  addToCart: PropTypes.func.isRequired,
  hasDuration: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  hasFrequency: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingQuote: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    serviceMachineName: PropTypes.string,
    machineName: PropTypes.string,
  })).isRequired,
  removeFromCart: PropTypes.func.isRequired,
};

export default React.memo(BundlePageTaskForm);
