import React from 'react';
import PropTypes from 'prop-types';
import { Card, styled, Box, CircularProgress } from '@material-ui/core';
import Question from './question';
import Frequency from './frequency';
import Duration from './duration';
import DateTime from './dateTime';
import Errors from './errors';
import { CtaButton } from '#/components/shared';
import DrawerCard from '../../../shared/DrawerCard';

const Progress = styled(CircularProgress)(({ theme: { palette: { primary: { main } } } }) => ({
  color: main,
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
}));

const Form = styled('form')(({ padding: '24px' }));

function TaskForm({
  hasDuration,
  hasError,
  hasFrequency,
  isLoading,
  id,
  questions,
  isInCart,
  addToCart,
  removeFromCart,
  isLoadingQuote,
}) {
  return (
    <DrawerCard isBlurred={isLoading}>
      <Form>
        {!isLoading && <DateTime id={id} />}
        {hasFrequency && !isLoading && <Frequency id={id} />}
        {questions.map(({ serviceMachineName, machineName }) => (
          <Question
            key={machineName}
            serviceMachineName={serviceMachineName}
            machineName={machineName}
          />
        ))}
        {hasDuration && <Duration id={id} />}
        {hasError && <Errors id={id} />}
        <Box display="flex" justifyContent="center" mt="24px" position="relative">
          {
            isInCart
              ? (
                <CtaButton
                  variant="outlined"
                  color="secondary"
                  onClick={removeFromCart}
                >
                  Remove From Cart
                </CtaButton>
              )
              : (
                <CtaButton
                  disabled={isLoading || isLoadingQuote}
                  variant="outlined"
                  color="primary"
                  onClick={addToCart}
                >
                  Add to Cart
                </CtaButton>
              )
          }
          {isLoadingQuote && <Progress size={24} />}
        </Box>
      </Form>
    </DrawerCard>
  );
}

TaskForm.propTypes = {
  addToCart: PropTypes.func.isRequired,
  hasDuration: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  hasFrequency: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  isInCart: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingQuote: PropTypes.bool.isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({
    serviceMachineName: PropTypes.string,
    machineName: PropTypes.string,
  })).isRequired,
  removeFromCart: PropTypes.func.isRequired,
};

export default React.memo(TaskForm);
