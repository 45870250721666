import React from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { HappinessGuarantee as HappinessGuaranteeIcon } from '#/components/shared/icons';
import brandingContent from '#/components/shared/BrandingContent';
import scssVariables from "#/styles/_vars.scss";

const hrefFor = (brand) =>
  brandingContent.getLink(brand, "happiness_guarantee");

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px 16px 0px 40px",
    borderRadius: "8px",
    fontSize: "14px",
    lineHeight: "20px",
    [theme.breakpoints.down("sm")]: { fontSize: "13px" },
    [theme.breakpoints.down("xs")]: { fontSize: "12px" },
  },
  happinessIcon: {
    paddingLeft: "10px",
    height: "56px",
    width: "56px",
    marginRight: "-28px",
    zIndex: "1",
  },
  mainContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: "8px",
    backgroundColor: scssVariables.angiBackgroundColor,
  },
  angiText: {
    color: scssVariables.angiPriceTextColor,
  },
}));

export default function HappinessGuarantee() {
  const classes = useStyles();
  const { name: theme } = useTheme();
  const isAngi = theme === "angi";
  const HomeAdvisorBody = () => (
    <Grid>
      <Typography variant="body2" className={classes.root}>
        Keep your project protected. Always pay through HomeAdvisor and we've
        got you covered with our Happiness Guarantee.
      </Typography>
      <Box component="span" className={classes.root}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={hrefFor("homeadvisor")}
        >
          Learn more
        </a>
      </Box>
    </Grid>
  );
  const AngiBody = () => (
    <Grid>
      <Typography
        variant="body2"
        className={`${classes.root} ${classes.angiText}`}
      >
        Keep your project protected. Always pay through Angi and we've got you
        covered with our Happiness Guarantee.
      </Typography>
      <Box component="span" className={classes.root}>
        <a target="_blank" rel="noopener noreferrer" href={hrefFor("angi")}>
          Learn more
        </a>
      </Box>
    </Grid>
  );
  return (
    <Box pb={1} boxShadow={3} className={classes.mainContainer}>
      <HappinessGuaranteeIcon className={classes.happinessIcon} />
      {isAngi ? <AngiBody /> : <HomeAdvisorBody />}
    </Box>
  );
}
