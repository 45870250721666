import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Card, Typography, Box } from '@material-ui/core';
import { useTheme, styled } from '@material-ui/core/styles';
import { map, flatten, findIndex } from 'lodash';
import { selectIsServiceCartError } from '#/selectors';
import { formatCurrency } from '../../../utils';
import ReceiptBreakdown from '../../shared/ReceiptBreakdown';
import ReceiptServiceList from '../../shared/ReceiptServiceList';
import CtaButton from '../../shared/CtaButton';
import useStyles from './styles';

const getTotals = (services) => {
  const convertAmount = (qli) => ({ ...qli, amount: parseInt(qli.amount, 10) });
  const quoteLineItems = map(flatten(map(services, 'quoteLineItems')), convertAmount);
  const sumQliAmtsByCategory = (sumQlis, qli) => {
    const idx = findIndex(sumQlis, (q) => q.category === qli.category);
    if (idx === -1) return [...sumQlis, qli];
    const currentAmount = sumQlis[idx].amount;
    const newQlis = [...sumQlis];
    // Do no sum membership fees for display, after a primary booking is made with a membership
    // the rest of the quotes will not be charged a membership fee
    const amount = qli.category === 'membership_fee' ? qli.amount : currentAmount + qli.amount;
    newQlis[idx] = { ...qli, amount };
    return newQlis;
  }
  const totaledQlis = quoteLineItems.reduce(sumQliAmtsByCategory, []);
  return totaledQlis;
}

function Receipt({ services, currency, history }) {
  const { name: theme } = useTheme();
  const classes = useStyles({ theme });
  const totalQuoteLineItems = getTotals(services);
  const checkoutButtonDisabled = useSelector(selectIsServiceCartError());

  const total = totalQuoteLineItems.reduce((a, b) => {
    return { amount: a.amount + b.amount }
  }, { amount: 0 })

  const formattedTotal = formatCurrency(currency, total.amount)

  const onClick = () => history.push('/payment');

  return (
    <StyledBox>
      <Card className={classes.root} elevation={5}>
        <div className={classes.header}>
          <Typography variant='h6' component='h3' className={classes.headerText}>Your services</Typography>
        </div>
        {services.length > 0 && (
          <>
            <div className={classes.receiptBreak} />
            <ul className={classes.list}>
              <ReceiptServiceList services={services} currency={currency} />
            </ul>
            <ul className={classes.list}>
              <ReceiptBreakdown
                quoteLineItems={totalQuoteLineItems}
                currency={currency}
              />
            </ul>
          </>
        )}
        <div className={classes.alwaysReceiptBreak} />
        <ul className={classes.alwaysList}>
          <li className={classes.listItem}>
            <div className={classes.spacedItems}>
              <Typography variant='body1' component='div' className={classes.total}>Total</Typography>
              <Typography variant='body1' component='div' className={classes.total}>{formattedTotal}</Typography>
            </div>
          </li>
        </ul>
        <div className={classes.alwaysReceiptBreak} />
        <ul className={classes.alwaysList}>
          <li className={classes.listItem}>
            <CtaButton variant='contained' color='secondary' onClick={onClick} disabled={checkoutButtonDisabled}>
              Checkout
            </CtaButton>
          </li>
        </ul>
      </Card>
    </StyledBox>
  );
}

Receipt.propTypes = {
  services: PropTypes.arrayOf(PropTypes.shape({
    basePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    duration: PropTypes.string,
    frequency: PropTypes.string,
    date: PropTypes.string,
    quoteLineItems: PropTypes.arrayOf(PropTypes.shape({
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      category: PropTypes.string,
      display_name: PropTypes.string,
    })),
  })).isRequired,
  currency: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

Receipt.defaultProps = {
};

const StyledBox = styled(Box)(({ theme: { breakpoints } }) => ({
  [breakpoints.up('sm')]: {
    position: 'sticky',
    top: '16px',
  },
  [breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: '632px',
  },
}));

export default withRouter(Receipt);
