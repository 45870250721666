import scssVariables from '#/styles/_vars.scss';

const {
  baseWhite,
  haBodyBackground,
  haGrayText,
} = scssVariables;

const baseTheme = {
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette: {
    background: {
      default: haBodyBackground,
    },
    action: {
      active: haGrayText,
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        '&.Mui-focused': {
          color: 'inherit',
        },
      },
      formControl: {
        position: 'inherit',
        transform: 'none',
      },
      animated: {
        transition: 'none',
      },
      shrink: {
        transform: 'none',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        backgroundColor: baseWhite,
      },
    },
    MuiCardActionArea: {
      root: {
        '&:hover $focusHighlight': {
          opacity: 0,
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: '18.5px 16px',
      },
    },
    MuiSelect: {
      iconOutlined: {
        right: '16px',
      },
      icon: {
        right: '16px',
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: baseWhite,
      },
    },
    MuiSvgIcon: {
      colorDisabled: {
        color: '#bfbfbf',
      },
    },
    MuiTypography: {
      h1: {
        fontSize: '2rem',
        fontWeight: 'bold',
      },
      h2: {
        fontSize: '1.75rem',
      },
      h3: {
        fontSize: '1.5rem',
      },
      h4: {
        fontSize: '1.25rem',
      },
      h5: {
        fontSize: '1rem',
      },
      h6: {
        fontSize: '0.875rem',
      },
      body1: {
        fontWeight: 'inherit',
      },
    },
  },
};

export default baseTheme;
