import React from "react";
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import MobileWebDatePicker from './MobileWebDatePicker';
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash'

class DateTimePicker extends React.Component {
  formatTime = time => {
    return Moment(time, "HH:mm").format("h:mm A");
  };

  selectedTime = time => {
    return Moment(time, "HH:mm").format("H:mm");
  }

  generateTimes = (min, max) => {
    let times = null;
    if (Number.isInteger(min) && Number.isInteger(max) && min < max) {
      times = [];
      while (min < max) {
        times.push(`${min}:00`);
        times.push(`${min}:30`);
        min += 1;
      }
      if (min === max) {
        times.push(`${min}:00`);
      }
    } else {
      times = this.getDefaultTimes();
    }
    return times;
  }

  getDefaultTimes = () => (
    ['7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30',
      '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00',
      '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30',
      '20:00', '21:00', '21:30', '22:00', '22:30', '23:00']
  )

  getValidationClass = (errorField) => {
    let selectClass = 'show-for-medium datepicker form-input';
    if (errorField !== undefined) {
      selectClass += ' input-error';
    }
    return selectClass;
  }

  render () {
    const {
      className,
      dateStart,
      timeStart,
      minDateWithProduct,
      handleDateChange,
      handleTimeChange,
      isDynamicDate,
      minHour,
      maxHour,
      minDaysInFuture,
      errorField,
    } = this.props;

    const minDate = !isDynamicDate ?
      new Date(Date.now() + (_.isNil(minDaysInFuture) ? 0 : minDaysInFuture * 24*60*60*1000))
      : dateStart;
    const times = this.generateTimes(minHour, maxHour);

    return (
      <div className={className}>
        <div className='form-row'>
          <MobileWebDatePicker
            dateStart={dateStart}
            handleDateChange={handleDateChange}
            minDaysInFuture={minDaysInFuture}
          />
          <DatePicker
            selected={dateStart}
            dateFormat="EEE MMM dd"
            minDate={(minDateWithProduct) ? minDateWithProduct : minDate}
            onChange={handleDateChange}
            className={this.getValidationClass(errorField)}
          />
        </div>
        { errorField }
        <div className='form-row'>
          <div className='select-wrapper sas'>
            <select value={this.selectedTime(timeStart)} className="form-input" onChange={handleTimeChange} >
              {times.map(time => {
                return <option key={time} value={time}>
                  {this.formatTime(time)}
                </option>;
              })}
            </select>
          </div>
        </div>
      </div>
    )
  }
}

DateTimePicker.propTypes = {
  className: PropTypes.string,
  dateStart: PropTypes.instanceOf(Date).isRequired,
  timeStart: PropTypes.instanceOf(Date).isRequired,
  minHour: PropTypes.number,
  maxHour: PropTypes.number,
  handleDateChange: PropTypes.func.isRequired,
  handleTimeChange: PropTypes.func.isRequired,
  errorField: PropTypes.element,
  isDynamicDate: PropTypes.bool.isRequired,
}

DateTimePicker.defaultProps = {
  className: '',
  minHour: 7,
  maxHour: 23,
  errorField: undefined,
}

export default DateTimePicker;
