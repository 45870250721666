import React from 'react';
import { styled, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

const StyledTypographyDescription = styled(Typography)(({ theme: { palette, breakpoints } }) => ({
  color: palette.text.base,
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: '400',
  maxWidth: '88%',
  [breakpoints.down('md')]: {
    maxWidth: '100%',
  },
}));

function DescriptionSection({ description }) {
  return (
    <div>
      <StyledTypographyDescription data-testid="description-section" component="p">
        { description }
      </StyledTypographyDescription>
    </div>
  );
}
DescriptionSection.propTypes = {
  description: PropTypes.string,
};

DescriptionSection.defaultProps = {
  description: null,
};
export default DescriptionSection;
