import React from 'react';
import PropTypes from 'prop-types'
import AdditionsAndRemodeling from '../../images/additions-and-remodeling';
import Electrical from '../../images/electrical';
import FlooringAndCarpet from '../../images/flooring-and-carpet';
import HandymanServices from '../../images/handyman-services';
import HeatingAndAirConditioning from '../../images/heating-and-air-conditioning';
import Landscaping from '../../images/landscaping';
import Painting from '../../images/painting';
import Plumbing from '../../images/plumbing';
import RoofingAndSiding from '../../images/roofing-and-siding';
import WindowsAndDoors from '../../images/windows-and-doors';

const serviceMapping = {
  additionsAndRemodeling: {
    displayText: "Additions & Remodeling",
    link: "http://www.homeadvisor.com/category.Additions-Remodeling.12001.html?entry_point_id=33919408",
    imageSource: AdditionsAndRemodeling,
  },
  windowsAndDoors: {
    displayText: "Windows & Doors",
    link: "http://www.homeadvisor.com/category.Windows-Doors.10220.html?entry_point_id=33919408",
    imageSource: WindowsAndDoors,
  },
  flooringAndCarpet: {
    displayText: "Flooring & Carpet",
    link: "http://www.homeadvisor.com/category.Flooring.10208.html?entry_point_id=33919408",
    imageSource: FlooringAndCarpet,
  },
  heatingAndAirConditioning: {
    displayText: "Heating & Air Conditioning",
    link: "http://www.homeadvisor.com/category.Heating-Cooling.10211.html?entry_point_id=33919408",
    imageSource: HeatingAndAirConditioning,
  },
  painting: {
    displayText: "Painting",
    link: "http://www.homeadvisor.com/category.Painting-Staining.10215.html?entry_point_id=33919408",
    imageSource: Painting,
  },
  roofingAndSiding: {
    displayText: "Roofing & Siding",
    link: "http://www.homeadvisor.com/category.Roofing-Siding-Gutters.10217.html?entry_point_id=33919408",
    imageSource: RoofingAndSiding,
  },
  electrical: {
    displayText: "Electrical",
    link: "http://www.homeadvisor.com/category.Electrical-Telephone-Computers.10207.html?entry_point_id=33919408",
    imageSource: Electrical,
  },
  handymanServices: {
    displayText: "Handyman Services",
    link: "http://www.homeadvisor.com/task.Handyman-for-Multiple-Small-Projects.39794.html?entry_point_id=33919408",
    imageSource: HandymanServices,
  },
  landscaping: {
    displayText: "Landscaping",
    link: "http://www.homeadvisor.com/category.Landscape-Decks-Fences.10212.html?entry_point_id=33919408",
    imageSource: Landscaping,
  },
  plumbing: {
    displayText: "Plumbing",
    link: "http://www.homeadvisor.com/category.Plumbing.10216.html?entry_point_id=33919408",
    imageSource: Plumbing,
  },
};

class ServiceLink extends React.PureComponent {
  render() {
    const {
      variation
    } = this.props;

    const service = serviceMapping[variation];

    return (
      <a href={service.link}>
        <div className="cell service-link">
          <span className="service-link-image">
            <img src={service.imageSource} />
          </span>
          <span className="service-link-display-text">
            {service.displayText}
          </span>
        </div>
      </a>
    );
  }
}

ServiceLink.propTypes = {
  variation: PropTypes.string.isRequired,
};

export default ServiceLink;
