import { Box, styled } from '@material-ui/core';

const StyledBox = styled(Box)(({ theme: { breakpoints } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  [breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  [breakpoints.up('md')]: {
    marginTop: '64px',
  },
}));

export default StyledBox;
