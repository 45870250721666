import PropTypes from 'prop-types'
import Stepper from './Stepper'
import WarningText from './WarningText';

import { question } from './prop-types'
import classnames from 'classnames'

const Question = props => {
  const warningText = () => {
    const currentValue = String(props.value)
    const { options } = props.question
    let warningText = ''

    _.forEach(options, option => {
      if (option.value === currentValue) {
        warningText = option.warning_text
      }
    })

    return warningText
  }

  const mapInputType = () => {
    const { type, subtype } = props.question

    if (type === 'radio' && subtype === 'stepper') {
      return 'stepper'
    }

    if (type === 'radio' && subtype === 'dropdown') {
      return 'select'
    }

    if (type === 'custom' && subtype === 'textarea') {
      return 'textarea'
    }

    if (type === 'custom' && subtype === 'text') {
      return 'text'
    }
  }

  const { question, handleChange, value, isAngi } = props
  const type = mapInputType()

  const warningTextValue = warningText();
  const params = {
    name: question.machine_name,
    value: value,
    onChange: handleChange,
    className: 'form-input form-input--full form-control details-text-input',
    required: question.is_required,
  }
  const questionLabel = classnames(
    'question-label',
    {angi: isAngi}
  )

  switch (type) {
    case 'stepper':
      params.question = question
      return (<Stepper isAngi={isAngi} warningTextValue={warningTextValue} {...params} />)
    case 'select':
      return (<div className='form-row'>
        <div className={questionLabel}>{!!question.label && question.label}</div>
        <div className='select-wrapper'>
          <select {...params}>
            {question.options.map((option, j) => (
              <option key={`${option.display}:${j}`} name={option.display} value={option.value}>
                {option.display}
              </option>
            ))}
          </select>
        </div>
        <WarningText value={warningTextValue} />
      </div>)

    case 'textarea':
      return (<div className='form-row'>
        <div className={questionLabel}>{!!question.label && question.label}</div>
        <textarea {...params} placeholder={question.placeholder} />
        <span className='invalid-field' />
      </div>)
    case 'text':
      return (<div className='form-row'>
        <div className={questionLabel}>{!!question.label && question.label}</div>
        <input {...params} placeholder={question.placeholder} />
        <span className='invalid-field' />
      </div>)

    default:
      return (<input {...params} placeholder={question.placeholder} />)
  }
}

Question.propTypes = {
  question: question,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleChange: PropTypes.func.isRequired
}

export default Question
