import React from 'react';
import { useSelector } from 'react-redux';
import ErrorBanner from '../shared/errorBanner/ErrorBanner';
import { useGetServiceInfoByServiceMachineNameQuery } from '#/apiServices/checkout';

const serviceDisplayName = (machineName) => {
  const { data, error, isLoading } = useGetServiceInfoByServiceMachineNameQuery(machineName);
  const displayName = error ? machineName : isLoading ? undefined : data.name;
  if (!displayName) return '';
  return displayName;
}

const formatServiceNames = (services) => {
  const formattedServiceMachineNames = services.map((service) => (serviceDisplayName(service)))

  let serviceString = formattedServiceMachineNames[0];
  const numServices = formattedServiceMachineNames.length;
  
  if (numServices === 2) {
    serviceString += ` & ${formattedServiceMachineNames[1]}`;
  } else if (numServices > 2) {
    for (let i = 1; i < numServices; i++) {
      serviceString += ', ';
      if (i === (numServices - 1)) {
        serviceString += '& ';
      }
      serviceString += formattedServiceMachineNames[i];
    }
  }
  let endOfString = ' Booking';
  if (numServices > 1) endOfString += 's';
  return serviceString + endOfString;
}

const errorMessage = () => {
  const bookingCreationErrors = useSelector(state => state.bookingRequestErrorReducer);
  const serviceMachineNames = bookingCreationErrors.errors.map((item) => (item.service));
  const failedServices = formatServiceNames(serviceMachineNames);
  const errorText = `There was an issue creating your ${failedServices}.`
  return errorText;
}

export default function AdditionalBookingErrorBanner() {
  const message = errorMessage();
  return (
    <ErrorBanner message={message}/>
  )
}
