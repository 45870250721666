function Stars(props) {
  const { starRating, brandingDetails } = props;
  const angiCss = brandingDetails.isAngi ? 'angi' : '';
  const maxStars = 5

  let ratingRemaining = parseFloat(starRating).toFixed(2)
  let newStars = 0
  const stars = [];

  while (ratingRemaining > 0) {
    if (ratingRemaining > 1) {
      stars.push(wholeStars(newStars, true, angiCss))
      ratingRemaining--
    } else {
      stars.push(partialStar(ratingRemaining, angiCss))
      ratingRemaining = 0
    }
    newStars++
  }
  while (newStars < maxStars) {
    stars.push(wholeStars(newStars, false, angiCss));
    newStars++;
  }

  return <span className='star-container'>{stars}</span>
}

function wholeStars(key, fill, angiCss) {
  return <div key={key} className={`${fill ? 'fa' : 'far'} fa-star ${angiCss}`} />
}

function partialStar(decimalRating, angiCss) {
  const faStarClassCss = (decimalRating < 0.75) ? 'fa-star-half-alt' : 'fa-star'

  const starClassName = `${(decimalRating < 0.25) ? 'far' : 'fa'} ${faStarClassCss} ${angiCss}`
  return <div className={starClassName} />
}

export default Stars
