import Cookies from 'universal-cookie'
import { axios } from './request'

const cookies = new Cookies()
const API_PATH = 'api/v1/current_user'

const defaultUserObject = {
  firstName: "",
  lastName: "",
  mobilePhone: "",
  streetAddress: "",
  tokenId: null,
  ccLast4: null,
  email: "",
  isMember: false,
  zipcode: ""
}

export function defaultUser() {
  return _.isUndefined(cookies.get('payment'))
    ? defaultUserObject
    : Object.assign({}, defaultUserObject, cookies.get('payment'))
}

export function setUserCookie(user) {
  cookies.set('payment',
    Object.assign({}, defaultUserObject, user, { tokenId: null, ccLast4: null }),
    { path: '/' }
  )
}

export function fetchCurrentUser() {
  return axios.get(API_PATH)
}

export async function buildUserFromApiResponse() {
  try {
    const response = await fetchCurrentUser()

    if (_.isEmpty(response.data)) {
      return defaultUser()
    }

    const { first_name, last_name, address, phone_str, payment_information, email, membership } = response.data
    const street_address = _.get(address, "address1", "")
    const zipcode = _.get(address, "zipcode", "")
    const cc_last_4 = _.get(payment_information, "cc_last_4", null)
    const membershipId = _.get(membership, "id", null)

    return Object.assign({}, defaultUser(), {
      firstName: first_name,
      lastName: last_name,
      streetAddress: street_address,
      mobilePhone: phone_str,
      ccLast4: cc_last_4,
      isMember: !!membershipId,
      zipcode,
      email
    })
  } catch (error) {
      return defaultUser()
  }
}

export async function fetchCurrentUserType() {
  const response = await buildUserFromApiResponse();
  let userTypeLabel = 'unknown-user';
  let angiKeyMember = false;
  if (response.email) {
    userTypeLabel = 'known-user';
    if (response.isMember) {
      angiKeyMember = true;
    }
  }
  return { angiKeyMember,
    userTypeLabel };
}
