import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { createSelectServiceById } from '#/selectors';

function ErrorsWithState({ id }) {
  const { errors } = useSelector(createSelectServiceById(id));

  if (!errors) return null;

  return (
    errors.map(({ detail }, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <Typography key={idx} variant="caption" component="p" color="error">
        {detail}
      </Typography>
    ))
  );
}

ErrorsWithState.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ErrorsWithState;
