import CalIcon from '../../images/calendar-icon.svg'
import CardIcon from '../../images/card-icon.svg'
import ProIcon from '../../images/pro-icon'
import classnames from 'classnames'

class HowItWorks extends React.Component {
  render () {
    const isAngi = this.props.brandingDetails.isAngi
    const containerClass = classnames(
      'when-row',
      'how-it-works-container',
      {
        'angi': isAngi,
        
      }
    )
    const whiteCircleCss = classnames(
      'white-circle',
      { 'angi' : isAngi }
    )
    const angiFontBoldH2 = classnames(
      'how-it-works-header',
      {'h2angi-bold-font': isAngi}
    )

    const angiFontBoldH3 = classnames(
      {'h3angi-bold-font': isAngi}
    )
    return (
      <div className = { containerClass }>
        <div className='grid-container'>
          <h2 className={angiFontBoldH2}>How it Works</h2>
          <div className='how-it-works-items'>
            <div className='columns text-center end xlarge-4'>
                <div className={whiteCircleCss}>
                  <img className='how-it-works-icon lazy-loading calendar-icon' src={CalIcon}/>
                </div>
              <h3 className={angiFontBoldH3}>{ isAngi ? '1. ' : '' }Pick a time</h3>
              <p>No more delivery windows! Select the exact day and time for your service to get instant, affordable pricing.</p>
            </div>
            <div className='columns text-center end xlarge-4'>
              <div className={whiteCircleCss}>
                <img className='how-it-works-icon lazy-loading card-icon' src={CardIcon}/>
              </div>
              <h3 className={angiFontBoldH3}>{ isAngi ? '2. ' : '' }Book instantly</h3>
              <p>We'll confirm your appointment and take care of payment electronically and securely.</p>
            </div>
            <div className='columns text-center end xlarge-4'>
              <div className={whiteCircleCss}>
                <img className='how-it-works-icon lazy-loading'src={ProIcon}/>
              </div>
              <h3 className={angiFontBoldH3}>{ isAngi ? '3. ' : '' }Your pro arrives</h3>
              <p>An experienced, fully-equipped professional will show up on time at your door!</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HowItWorks;
