import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Hidden, Box, Typography, styled } from '@material-ui/core';
import Cookies from 'universal-cookie';
import { startCase } from 'lodash';
import { Omniture } from '../../utils/analytics';
import HappinessGuarantee from '#/components/shared/HappinessGuarantee';
import { whenPageBackgroundImage } from '#/utils/content';
import apiCaller from '../../utils/apiCaller';
import deepCamelCase, { deepSnakeCase } from '../../utils/deepCamelCase';
import { fetchCurrentUserType } from '../../utils/user';
import CartWrapperCard from './CartWrapper';
import BoxWrapper from './BoxWrapper';
import Tasks from './tasks';
import Receipt from './receipt';
import { selectQuoteWorkflowParams } from '#/selectors';


const cookies = new Cookies();
const omniture = new Omniture();
export default function Cart({ brandingDetails }) {
  const cartItems = useSelector(({ cart: { serviceIds } }) => serviceIds);
  const quotes = useSelector(({ quotes }) => quotes);
  const additionalServices = useSelector(({ services }) => services);
  const whenFormParams = useSelector(selectQuoteWorkflowParams);

  
  const { quoteRequest } = whenFormParams;
  const [mainService, setMainService] = useState(null);
  const [services, setServices] = useState([]);
  const taskoid = cookies.get('taskoid');
  
  const date = (dateString) => {
    const formattedDateString = dateString ? dateString.replace(/-/g, '/') : null;
    const date = new Date(formattedDateString);
    const opts = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', hour12: true,
    };

    return date.toLocaleTimeString('en-US', opts).split(',').join(' at');
  };

  const quoteBreakDown = (qlis) => qlis.map((item) =>({
    amount: item.amount,
    category: item.category,
    display_name: item.displayName,
  }))

  const currency = cartItems.length ? quotes.entities[quotes.ids[0]].currency.symbol : '$';

  const formulateServiceHash = (qlis, name, durationMinutes, frequency, startsAt) => ({
    basePrice: qlis.find((qli) => qli.category === 'base').amount,
    name: name,
    duration: `${durationMinutes/60} Hours`,
    frequency: startCase(frequency),
    date: date(startsAt),
    quoteLineItems: qlis,
  })

  const addOnServices = () => Object.keys(cartItems).map((cartItem) => {
    const quote = quotes.entities[cartItem];
    const quoteLineItems = quoteBreakDown(quote.quoteBreakdown);
    const serviceForm = additionalServices.entities[cartItem];

    return formulateServiceHash(
      quoteLineItems,
      quote.serviceName,
      quote.recommendedDurationMinutes,
      serviceForm.frequency.selected,
      serviceForm.businessHours.value,
    );
  });

  const serviceMachineName = cookies.get('serviceMachineName');
  const entryPointId = cookies.get('entryPointId');
  const extFulTrack = cookies.get('extFulTrack');
  const pageName = 'FixedPriceHandy: Cart';

  const trackPage = (angiKeyMember, userTypeLabel) => {
    omniture.track({ pageName,
      serviceMachineName,
      entryPointId,
      extFulTrack,
      taskoid,
      angiKeyMember,
      userTypeLabel });
  };

  useEffect(() => {
    fetchCurrentUserType().then((user) => {
      trackPage(user.angiKeyMember, user.userTypeLabel);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function fetchMainService () {
      const { data } = await apiCaller.getQuote(JSON.parse(JSON.stringify(deepSnakeCase(whenFormParams))));
      const quoteLineItems = quoteBreakDown(deepCamelCase(data.quote.quote_breakdown));
      const whenPageService = formulateServiceHash(
        quoteLineItems,
        quoteRequest.serviceName,
        data.quote.recommended_duration_minutes,
        quoteRequest.frequency,
        quoteRequest.startsAt,
      );
      setMainService(whenPageService);

      const addOns = addOnServices();
      setServices([whenPageService, ...addOns]);
    };

    fetchMainService();
  }, []);

  useEffect(() => {
    if (mainService) {
      setServices([mainService, ...addOnServices()]);
    };
  }, [cartItems, quotes]);

  return (
    <>
      <StyledBanner
        src={whenPageBackgroundImage(serviceMachineName, brandingDetails)}
        className="when-background show-for-large"
      />
      <BoxWrapper>
        <CartWrapperCard elevation={2}>
          <Typography variant="h1" gutterBottom>
            Add more services.
            <br />
            Get more done.
          </Typography>
          <Typography variant="subtitle1">
            Services frequently booked together
          </Typography>
          <Tasks />
        </CartWrapperCard>
        <Hidden mdUp>
          <Box maxWidth="632px" bgcolor="background.paper" p="16px" width="100%" display="flex" justifyContent="center">
            <HappinessGuarantee brandingDetails={brandingDetails} mobile />
          </Box>
        </Hidden>
        <Receipt services={services} currency={currency} />
      </BoxWrapper>
      <Box margin="auto" width="100%" display="flex" justifyContent="center" marginTop="24px">
        <Hidden smDown>
          <Box maxWidth="632px">
            <HappinessGuarantee brandingDetails={brandingDetails} mobile />
          </Box>
        </Hidden>
        <Hidden smDown>
          <Box width="100%" maxWidth="332px" />
        </Hidden>
      </Box>
    </>
  );
}

const StyledBanner = styled('img')({
  position: 'absolute',
  zIndex: '-1',
});
