import PropTypes from 'prop-types'

export const option = PropTypes.shape({
  default: PropTypes.bool,
  display: PropTypes.string,
  value: PropTypes.string,
  warning_text: PropTypes.string
})

export const question = PropTypes.shape({
  affects_duration: PropTypes.bool,
  is_required: PropTypes.bool,
  label: PropTypes.string,
  machine_name: PropTypes.string,
  options: PropTypes.arrayOf(option),
  placeholder: PropTypes.string,
  step: PropTypes.string,
  subtype: PropTypes.oneOf(['stepper', 'dropdown', 'textarea']),
  type: PropTypes.oneOf(['radio', 'custom'])
}).isRequired
