import React from 'react';
import { Grid, styled, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import AvatarName from '../../shared/AvatarName/AvatarName';

const StyledTypographyTitle = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.text.base,
  marginBottom: '8px',
  fontSize: '24px',
  lineHeight: '30px',
  fontWeight: '700',
}));
const StyledTypographySubTitle = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.text.base,
  marginBottom: '15px',
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: '400',
}));
const StyledTypographyDescription = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.text.secondary,
  marginTop: '4px',
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: '400',
  maxWidth: '368px',
  marginBottom: '40px',
}));

function ConfirmationMsg({ quoteDate, name, profilePhotoUrl }) {
  return (
    <div>
      <Grid
        container
        direction="column"
        justifycontent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <StyledTypographyTitle data-testid="confirmationMsg-title" component="h2" align="center">
            Your booking is confirmed!
          </StyledTypographyTitle>
        </Grid>
        <Grid item>
          <StyledTypographySubTitle data-testid="confirmationMsg-subTitle" component="div" align="center">
            Your Fixed-Price service is confirmed for
            {' '}
            { quoteDate }
          </StyledTypographySubTitle>
        </Grid>
        <Grid item>
          <AvatarName
            name={name}
            srcImg={profilePhotoUrl}
          />
        </Grid>
        <Grid item>
          <StyledTypographyDescription data-testid="confirmationMsg-footer" component="div" align="center">
            If your pro isn’t available at the requested time,
            we will let you know and send another top-rated pro.
          </StyledTypographyDescription>
        </Grid>
      </Grid>
    </div>
  );
}
ConfirmationMsg.propTypes = {
  quoteDate: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  profilePhotoUrl: PropTypes.string,
};
ConfirmationMsg.defaultProps = {
  profilePhotoUrl: null,
};
const StyledConfirmationMsg = styled(ConfirmationMsg)((
  { theme:
        { breakpoints,
          customPalette } },
) => ({
  [breakpoints.down('sm')]: {
    background: customPalette.angiDarkGrayBackground2,
  },
}));
export default StyledConfirmationMsg;
