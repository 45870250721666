import { InputLabel, styled } from '@material-ui/core';

const StyledLabel = styled(InputLabel)({
  fontSize: '14px',
  lineHeight: '18px',
  paddingBottom: '8px',
  marginTop: '16px',
  fontWeight: 'bold',
});

export default StyledLabel;
