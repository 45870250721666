import React from 'react';
import { Typography, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { receiptPendingLineItemsSelector } from '#/selectors';
import useStyles from './styles';
import { formatCurrency, formatDate } from "#/utils";

export default function PendingServiceLineItems() {
  const { name: theme } = useTheme();
  const classes = useStyles({ theme });

  const pendingServiceLineItems = useSelector(receiptPendingLineItemsSelector);

  return (
    <ul className={classes.list}>
      {pendingServiceLineItems.map((serviceLineItem) => (
        <li className={classes.listItem} key={serviceLineItem.machineName}>
          <div className={`${classes.spacedItems} ${classes.lightText}`}>
            <Typography
              className={`${classes.serviceFont} ${classes.serviceName}`}
            >
              {serviceLineItem.displayName}
            </Typography>
            <Typography className={classes.serviceFont}>
              {formatCurrency("$", serviceLineItem.priceCents)}
            </Typography>
          </div>
          <Typography
            className={`${classes.serviceFont} ${classes.lightText} ${classes.supportingText}`}
          >
            {formatDate(serviceLineItem.bookingTime)}
          </Typography>
          <Typography
            className={`${classes.serviceFont} ${classes.lightText} ${classes.supportingText}`}
          >
            Up to {serviceLineItem.duration} hours{" "}
            {serviceLineItem.frequencyMsg}
          </Typography>
        </li>
      ))}
    </ul>
  );
}
