import { createSelector } from '@reduxjs/toolkit';

const selectSelf = (state) => state.cart;

const cartServicesSelector = createSelector(
  selectSelf,
  ({ serviceIds }) => serviceIds,
);

const cartPendingServicesSelector = createSelector(
  selectSelf,
  ({ pendingServiceIds }) => pendingServiceIds,
);

const cartPendingServicesNamesSelector = createSelector(
  selectSelf,
  ({ pendingServiceIds }) => Object.keys(pendingServiceIds),
);

const cartServicesNamesSelector = createSelector(
  cartServicesSelector,
  (serviceIds) => Object.keys(serviceIds),
);

const createSelectIsInCartByServiceId = (serviceMachineName) => createSelector(
  (state) => state.cart,
  ({ serviceIds }) => serviceIds && serviceIds[serviceMachineName],
);

export {
  cartServicesSelector,
  cartServicesNamesSelector,
  createSelectIsInCartByServiceId,
  cartPendingServicesNamesSelector,
  cartPendingServicesSelector,
};
