import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import AngiGuarantee from './AngiGuarantee';
import HomeAdvisorGuarantee from './HomeAdvisorGuarantee';

export default function HappinessGuarantee({ className, style }) {
  const { name: theme } = useTheme();

  if (theme === 'angi') {
    return <AngiGuarantee className={className} style={style} />;
  }

  return <HomeAdvisorGuarantee className={className} style={style} />;
}

HappinessGuarantee.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object]),
};

HappinessGuarantee.defaultProps = {
  className: '',
  style: {},
};
