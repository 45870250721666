import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useTheme } from '@material-ui/core/styles';

const paths = {
  chevron: {
    up: 'M5.293 15.707a1 1 0 001.414 0L12 10.414l5.293 5.293a1 1 0 101.414-1.414l-6-6a1 1 0 00-1.414 0l-6 6a1 1 0 000 1.414z',
    down: 'M5.293 8.293a1 1 0 011.414 0L12 13.586l5.293-5.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414z',
  },
  triangle: {
    up: 'M11.204 8.748a.996.996 0 011.592 0l3.983 5.31a.996.996 0 01-.797 1.593H8.018a.996.996 0 01-.797-1.593z',
    down: 'M11.204 15.252a.996.996 0 001.592 0l3.983-5.31a.996.996 0 00-.797-1.593H8.018a.996.996 0 00-.797 1.593z',
  },
};

export default function Caret({ style, color, up: isUp, type: specifiedType, className }) {
  const { name: theme } = useTheme();
  const themeType = theme === 'angi' ? 'chevron' : 'triangle';
  const type = specifiedType || themeType;
  const { up, down } = paths[type];

  const d = isUp ? up : down;
  return (
    <SvgIcon
      className={className}
      style={style}
      color={color}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={d}
      />
    </SvgIcon>
  );
}

Caret.propTypes = {
  /** [Material SvgIcon Color Options](https://material-ui.com/api/svg-icon/#props) */
  color: PropTypes.oneOf(['action', 'disabled', 'error', 'inherit', 'primary', 'secondary']),
  up: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object]),
  /** HA will default to `triangle` and Angi to `chevron`, this will override the theme  */
  type: PropTypes.oneOf(['triangle', 'chevron']),
  className: PropTypes.string,
};

Caret.defaultProps = {
  type: null,
  color: 'action',
  up: false,
  style: {},
  className: '',
};
