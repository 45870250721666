import Moment from 'moment'

const brightTagId = document.documentElement.dataset.brightTagId;

function serverErrorHandler(e) {
  let message
  if (_.has(e, 'response.data.errors.data')) message = e.response.data.errors.data
  else if (_.has(e, 'response.data.errors')) message = e.response.data.errors
  else if (_.has(e, 'response.data.message')) message = e.response.data.message
  else message = 'Oops, something went wrong! Sorry, please try again later.'
  const messages = _.isArray(message) ? message : [message]

  return _.compact(messages)
    .map(x => _.isUndefined(x.detail) ? { message: x } : { message: x.detail })
}

function buildBookingParams(
  quote = {},
  user = {},
  couponCode = null,
  serviceMachineName,
  params = {},
  extrasAdded = [],
  purchasePlanTemplate = {},
  paymentAfterComplete = false,
  partnerId = "home_advisor",
  primaryBookingExternalId = null,
  externalProId = '',
) {
  const bookingParams = {
    partner_id: partnerId,
    booking: {
      service: serviceMachineName,
      date_start: quote.starts_at,
      frequency: quote.frequency,
      base_minutes: quote.base_duration_minutes,
      commitment: 'no_commitment',
      responses: params.responses,
      description: params.description,
      extras: extrasAdded,
      coupon_code: couponCode,
      payment_after_complete: paymentAfterComplete,
      brand_name: partnerId,
      booking_source: partnerId,
    },
    user: {
      first_name: user.firstName,
      last_name: user.lastName,
      email: quote.email,
      phone_number: user.mobilePhone,
      stripe_token: user.tokenId,
      partner_id: partnerId,
      brand_name: partnerId,
    },
    address: {
      address1: user.streetAddress,
      city: user.city,
      zipcode: quote.zipcode,
      state: user.state,
    },
    purchase_plan_template: purchasePlanTemplate,
    primary_booking_external_id: primaryBookingExternalId,
  };
  if (externalProId) {
    bookingParams.external_provider_id = externalProId;
  }
  return bookingParams;
}

function getUpdatedBookingInfo(starts_at, quoteWorkflowParams) {
  const email = quoteWorkflowParams.email
  const zipcode = quoteWorkflowParams.quote_request.zipcode
  const frequency = quoteWorkflowParams.quote_request.frequency

  return {email, zipcode, starts_at, frequency}
}

function couponData(cookies) {
  return cookies.get('couponInfo')
}

function setDefaultService(cookies) {
  cookies.set('taskoidName', 'Handyman Services', { path: '/' })
  cookies.set('serviceMachineName', 'other_handyman_service', { path: '/' })
}

function setStaticTaskMapping(taskoid, cookies) {
  const taskoidMapping = JSON.parse(document.documentElement.dataset.taskoidMapping)
  const serviceData = taskoidMapping[taskoid]
  let taskoidName, serviceMachineName

  if (typeof taskoid === 'string' && typeof serviceData === 'object') {
    taskoidName = serviceData['name']
    serviceMachineName = serviceData['serviceMachineName']
  } else {
    taskoidName = 'Handyman Services'
    serviceMachineName = 'other_handyman_service'
  }

  cookies.set('taskoidName', taskoidName, { path: '/' })
  cookies.set('serviceMachineName', serviceMachineName, { path: '/' })
}

function defaultDuration(serviceMachineName) {
  if (serviceMachineName === 'home_cleaning') {
    return ''
  } else {
    return '180'
  }
}

function fakeApiCall(data) {
  return Promise.resolve(data)
}


function formatFrequencyInWeeks(frequency) {
  return frequencyInWeeksMap[frequency] || ''
}

const frequencyInWeeksMap = {
  weekly: '1',
  biweekly: '2',
  monthly: '4',
};

function formatCurrency(currencySymbol, fullAmount) {
  fullAmount = String(fullAmount)
  const cents = formatCents(fullAmount.slice(-2))
  let dollars

  if (isNegative(fullAmount)) {
    dollars = formatDollars(fullAmount.slice(1, -2))
    return '-' + currencySymbol + dollars + '.' + cents
  }

  dollars = formatDollars(fullAmount.slice(0, -2))
  return currencySymbol + dollars + '.' + cents
}

function formatCents(amount) {
  while (amount.length < 2 ) {
    amount = '0' + amount
  }

  return amount
}

function formatDollars(amount) {
  while (amount.length < 1 ) {
    amount = '0' + amount
  }

  return amount
}

function isNegative(amount) {
  return parseInt(amount) < 0
}

const mobileMaxWidthSize = 600

function getDeviceType() {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
}

function formatDate(dateString) {
  const formattedDateString = dateString ? dateString.replace(/-/g, "/") : null;
  const date = new Date(formattedDateString);
  const opts = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  return date.toLocaleTimeString("en-US", opts).split(",").join(" at");
}

function timeToDisplay(service_datetime, service_date, shortMonth) {
  const formatString = shortMonth ? 'MMM D, YYYY [at] LT' : 'MMMM D, YYYY [at] LT'

  if(service_datetime) {
    return Moment(service_datetime).format(formatString)
  } else if(service_date) {
    return Moment(service_date).format(formatString)
  }
}

export {
  fakeApiCall,
  brightTagId,
  serverErrorHandler,
  couponData,
  buildBookingParams,
  getUpdatedBookingInfo,
  defaultDuration,
  setDefaultService,
  setStaticTaskMapping,
  formatCurrency,
  formatFrequencyInWeeks,
  mobileMaxWidthSize,
  getDeviceType,
  formatDate,
  timeToDisplay,
};
