import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { currentBundleSelector } from '#/selectors/bundles';
import BundleShowPage from './bundle/BundleShowPage';
import BundleEditPage from './bundle/BundleEditPage';

const isSeasonalBundleFeatureEnabled = document.documentElement.dataset.enableSeasonalBundles === 'true';

export default function SeasonalBundleRoutes({ location, match: { path } }) {
  const { id: bundleId, tasks: [{ taskoid }] } = useSelector(currentBundleSelector);
  const RedirectToBundlesHome = () => <Redirect to={{ ...location, pathname: `/bundles/${bundleId}` }} />;

  if (!isSeasonalBundleFeatureEnabled) {
    return (
      <Route
        component={() => (
          <Redirect to={{ ...location, pathname: `/when?taskoid=${taskoid}&reason=2` }} />
        )}
      />
    );
  }

  return (
    <Switch>
      <Route exact path={path} component={RedirectToBundlesHome} />
      <Route path="/bundles/:id/edit" component={BundleEditPage} />
      <Route path="/bundles/:id" component={BundleShowPage} />
    </Switch>
  );
}

SeasonalBundleRoutes.propTypes = PropTypes.shape({
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
    path: PropTypes.string.isRequired,
  }),
}).isRequired;
