import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import every from 'lodash/every';
import { useGetBundleServicesByZipcodeQuery } from '#/apiServices/checkout';
import { currentTasksServiceMachineNamesSelector, selectServiceIds, selectWhenForm, serviceEntitiesSelector } from '#/selectors';
import { clearServicesNotInBundle } from '#/actions';
import TaskLoading from '#/components/cart/skeletons/Task';
import images from '#/images/recommendations';
import Task from '#/components/cart/task/Task';
import { isEmpty } from 'lodash';

export default function BundleTasks() {
  const dispatch = useDispatch();
  const [openTask, setOpentask] = useState(0);
  const openNextTask = (indexToOpen) => {
    setOpentask(indexToOpen);
  };
  const { zipcode } = useSelector(selectWhenForm);
  const servicesToLoad = useSelector(currentTasksServiceMachineNamesSelector);
  const serviceEntities = useSelector(serviceEntitiesSelector);

  const bundleOrder = (a, b) => servicesToLoad.indexOf(a) - servicesToLoad.indexOf(b);
  const loadedServices = useSelector((state) => sortBy(selectServiceIds(state), bundleOrder));

  const areSameServices = isEqual(servicesToLoad, loadedServices);
  const hasForm = ({ form, businessHours }) => !isEmpty(form?.questions) && !isEmpty(businessHours);
  const areFormsLoaded = every(serviceEntities, hasForm);

  const { isLoading } = useGetBundleServicesByZipcodeQuery(
    { zipcode, serviceMachineNames: servicesToLoad }, { skip: areSameServices && areFormsLoaded },
  );

  useEffect(() => {
    if (loadedServices.length === 0) return;
    // Clear out any cookie based services from prior submissions
    dispatch(clearServicesNotInBundle(loadedServices));
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadingImages = ['home_cleaning', 'exterior_door_installation', 'faucets_replacement_ha'];

  if (isLoading) {
    return loadingImages.map((machineName) => (
      <TaskLoading key={machineName} image={images[machineName]} />
    ));
  }

  return loadedServices.map((serviceMachineName, index) => (
    <Task
      key={serviceMachineName}
      id={serviceMachineName}
      index={index}
      openNextTask={openNextTask}
      openTask={openTask}
    />
  ));
}
