import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useTrackLink, useAddToCartByServiceNameMutation, useRemoveFromCart } from '#/hooks';
import { createSelectQuestionsByServiceId, createSelectHasDurationByServiceId, createSelectHasFrequencyByServiceId, createSelectErrorsByServiceId, createSelectBusinessHoursByServiceId } from '#/selectors/services';
import { useGetCartServiceFormByServiceNameQuery } from '#/apiServices';
import TaskForm from './TaskForm';
import BundlePageTaskForm from '../../../bundle/BundleEditPage/task/BundlePageTaskForm';

function TaskFormWithState({ id, location: { pathname }, handleTaskFormActions }) {
  const dispatch = useDispatch();
  const selectBusinessHoursByServiceId = createSelectBusinessHoursByServiceId(id);
  const selectQuestionsByServiceId = createSelectQuestionsByServiceId(id);
  const selectHasDurationByServiceId = createSelectHasDurationByServiceId(id);
  const selectHasFrequencyByServiceId = createSelectHasFrequencyByServiceId(id);
  const removeFromCart = useRemoveFromCart(id);
  const [addQuote, { isLoading: isLoadingQuote, isError }] = useAddToCartByServiceNameMutation(id);

  const isBundlePath = pathname.includes('/bundles/');
  const trackAddToCart = useTrackLink(`FPP Sub: Added to Cart: ${id}`);
  const trackRemoveFromCart = useTrackLink(`FPP Sub: Removed from Cart: ${id}`);

  const handleAddClick = async () => {
    trackAddToCart();
    const { error } = await addQuote({ serviceName: id, shouldAddToCart: true });
    if (isBundlePath && !error?.data) { handleTaskFormActions(); }
  };
  const handleRemoveClick = () => {
    trackRemoveFromCart();
    dispatch(removeFromCart(id));
    if (isBundlePath) { handleTaskFormActions(); }
  };

  const errors = useSelector(createSelectErrorsByServiceId(id));
  const questions = useSelector(selectQuestionsByServiceId);
  const businessHours = useSelector(selectBusinessHoursByServiceId);
  const hasDuration = useSelector(selectHasDurationByServiceId);
  const hasFrequency = useSelector(selectHasFrequencyByServiceId);
  const isInCart = useSelector(({ cart: { serviceIds } }) => !!serviceIds[id]);
  const skip = !!questions.length && !!businessHours?.value;

  const { isLoading } = useGetCartServiceFormByServiceNameQuery(
    { serviceName: id, isBundle: isBundlePath }, { skip },
  );

  const hasError = isError || errors.length > 0;

  const Form = isBundlePath ? BundlePageTaskForm : TaskForm;

  return (
    <Form
      id={id}
      addToCart={handleAddClick}
      hasDuration={hasDuration}
      hasError={hasError}
      hasFrequency={hasFrequency}
      isInCart={isInCart}
      isLoading={isLoading}
      isLoadingQuote={isLoadingQuote}
      questions={questions}
      removeFromCart={handleRemoveClick}
    />
  );
}

TaskFormWithState.propTypes = {
  id: PropTypes.string.isRequired,
  handleTaskFormActions: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(withRouter(TaskFormWithState));
