import React from 'react'
import Spinner from '../shared/Spinner'
import brandingContent from '../shared/BrandingContent.jsx'
import classNames from 'classnames'

class WhatHappensNextSection extends React.PureComponent {

    render () {
        const entryInfoContainer = classNames('entry-info-container',
            { 'angi': this.props.brandingDetails.isAngi,
              'light-gray': this.props.brandingDetails.isAngi }
        )
        const buttonMediumCss = classNames('button', 'button--medium', 'button--tall', { 'angi': this.props.brandingDetails.isAngi })
        const buttonFullCss = classNames('button', 'button--full', 'button--tall', { 'angi': this.props.brandingDetails.isAngi })
        const brandingAcronym = this.props.brandingDetails.acronym
        const brandingName = this.props.brandingDetails.displayName
        const question = classNames('question', { 'angi': this.props.brandingDetails.isAngi } )
        const answer = classNames('answer', { 'angi': this.props.brandingDetails.isAngi } )

        const containerCss = classNames('grid-x', 'grid-margin-x', 'confirmation-section')
        const whatHappensContainerCss = classNames('cell', 'small-centered', 'entry-info-cell', 'medium-8')
        const whatHeader = classNames('what-happens-next-header', { 'angi': this.props.brandingDetails.isAngi } )
        const myAccountBtn = classNames('show-for-medium', 'my-account-btn')

        return (
            <div className={ containerCss }>
                <div className={ whatHappensContainerCss }>
                    <div className={ entryInfoContainer }>
                        <h3 className={ whatHeader }>
                            Frequently Asked Questions
                        </h3>

                        <div>
                            <div className={question}>
                                I need to reschedule or cancel - how can I make changes to my booking?
                            </div>
                            <div className={answer}>
                                You can directly manage your booking on your {brandingAcronym} account page and also on the <a href='https://xy59r.app.goo.gl/iVg1'>{brandingName} mobile app.</a>
                            </div>
                            <div className={question}>
                                What is your cancellation policy?
                            </div>
                            <div className={answer}>
                                You can reschedule or cancel any booking without any penalty, so long as you do so at least 24 hours ahead of the scheduled start time. (Within 24 hours, cancellation fees may apply.)<br/>

                                <a href='https://homeadvisor.zendesk.com/hc/en-us/articles/360015445834-What-s-the-cancellation-policy-' target='_blank'>See more details here.</a>
                            </div>
                            <div className={question}>
                                When should I expect my professional to get in touch with me?
                            </div>
                            <div className={answer}>
                                We will send you the contact information for your professional the day before your booking if it is available.
                            </div>
                            <div className={question}>
                                Will the professional bring their own tools?
                            </div>
                            <div className={answer}>
                                Your professional will come with all required tools for your job based on the information you’ve provided. However, if there’s something in particular you’re unsure of or just want to check on, make sure to leave a note to your pro above in the Additional Job Information section!
                            </div>
                            <div className={question}>
                                I need more help.
                            </div>
                            <div className={answer}>
                                <a 
                                    href={brandingContent.getLink(this.props.brandingDetails.domainBrand, "help_center")}
                                    target='_blank'>Please visit our help center.
                                </a>
                            </div>
                            <div className='show-for-small-only'>
                                <form action={this.props.redirectUrl} method='get'>
                                    <button type='submit' className={buttonFullCss} disabled={false}>
                                        My Account
                                    </button>
                                </form>
                            </div>
                            <div className={ myAccountBtn }>
                                <form action={this.props.redirectUrl} method='get'>
                                    <button type='submit' className={buttonMediumCss} disabled={false}>
                                        My Account
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WhatHappensNextSection
