import Logo from "../images/ha-logo.svg"
import logoAngi from "../images/angi-logo.svg"
import classNames from 'classnames'

class NavBar extends React.Component {
  constructor(props) {
    super(props)
  }

  refCallback = element => {
    if (element && this.props.getSize) {
      this.props.getSize(element.getBoundingClientRect())
    }
  }
  render () {
    const header = classNames('header', { angi: this.props.brandingDetails.isAngi });
    const logoImage = this.props.brandingDetails.isAngi ? logoAngi : Logo
    const headerLogo = classNames('header-logo', { angi: this.props.brandingDetails.isAngi });
    return (
      <div className={header} ref={this.refCallback}>
        <div className="grid-container">
          <div className="header-inner">
            <img src={logoImage} className={headerLogo} />
          </div>
        </div>
      </div>
    )
  }
}

export default NavBar
