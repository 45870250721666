import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTheme, styled } from '@material-ui/core/styles';
import { Card, Typography, Box } from '@material-ui/core';
import { receiptLineItemsSelector, cartPendingServicesNamesSelector } from '#/selectors';
import PendingServiceLineItems from './PendingServiceLineItems';
import AngiKeyReceiptPromo from './AngiKeyReceiptPromo';
import ReceiptBreakdown from './ReceiptBreakdown';
import ReceiptServiceList from './ReceiptServiceList';
import CtaButton from '#/components/shared/CtaButton';
import useStyles from './styles';

function Receipt({ onClick, isDisabled, total }) {
  const { name: theme } = useTheme();
  const classes = useStyles({ theme });
  const receiptLineItems = useSelector(receiptLineItemsSelector);
  const pendingServices = useSelector(cartPendingServicesNamesSelector);
  const areItemsPending = pendingServices.length > 0;
  const areItemsInCartOrPending = receiptLineItems.length > 0 || areItemsPending;

  const TotalView = () => (
    <ul className={classes.alwaysList}>
      <li className={classes.listItem}>
        <div className={classes.spacedItems}>
          <Typography className={classes.total}>Total</Typography>
          <Typography className={classes.total}>{total}</Typography>
        </div>
      </li>
    </ul>
  );

  return (
    <StyledBox>
      <Card className={classes.root} elevation={5}>
        <div className={classes.header}>
          <Typography
            className={`${classes.headerText} ${classes.orderSummary}`}
          >
            Order Summary
          </Typography>
        </div>

        {areItemsInCartOrPending && (
          <>
            <ReceiptServiceList />
            <PendingServiceLineItems />
            <ReceiptBreakdown />
          </>
        )}

        <div className={classes.alwaysReceiptBreak} />
        <TotalView />
        <div className={classes.alwaysReceiptBreak} />

        <AngiKeyReceiptPromo />
        <ul className={classes.alwaysList}>
          <li className={classes.listItem}>
            <CtaButton
              variant="contained"
              color="secondary"
              onClick={onClick}
              disabled={isDisabled || areItemsPending}
            >
              Checkout
            </CtaButton>
          </li>
        </ul>
      </Card>
    </StyledBox>
  );
}

Receipt.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  total: PropTypes.string.isRequired,
};

const StyledBox = styled(Box)(({ theme: { breakpoints } }) => ({
  [breakpoints.up('sm')]: {
    position: 'sticky',
    top: '16px',
  },
  [breakpoints.down('sm')]: {
    width: '100%',
    maxWidth: '632px',
  },
}));

export default Receipt;
