import React from 'react';
import PropTypes from 'prop-types';

class ContactInformation extends React.Component {
  render () {
    const {
      firstName,
      lastName,
      handleChange,
    } = this.props;

    return (
      <React.Fragment>
        <p>Contact Info</p>
        <div className='form-row'>
          <input
            required
            name='first_name'
            type='text'
            value={firstName}
            onChange={handleChange}
            className='form-control form-input form-input--full'
            placeholder='First Name*' />
          <span className='invalid-field' />
        </div>
        <div className='form-row'>
          <input
            required
            name='last_name'
            type='text'
            value={lastName}
            onChange={handleChange}
            className='form-control form-input form-input--full'
            placeholder='Last Name*' />
          <span className='invalid-field' />
        </div>
      </React.Fragment>
    )
  }
}

ContactInformation.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

ContactInformation.defaultProps = {
  firstName: '',
  lastName: '',
};

export default ContactInformation;
