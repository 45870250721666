import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardMedia, CardContent, Typography, Box, styled, withStyles } from '@material-ui/core';
import { PlusMinus } from '#/components/shared/icons';
import useStyles from '#/components/cart/task/styles';
import images from '#/images/recommendations';

const Blurred = styled(Box)({
  filter: 'blur(2px)',
  pointerEvents: 'none',
});

const FlowingBackgroundComponent = styled(Card)({
  '&::before': {
    pointerEvents: 'none',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    content: '""',
    position: 'absolute',
    animation: 'skeleton-wave 3s linear 0.5s infinite',
    transform: 'translateX(-100%)',
    background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.75), transparent)',
  },
});

const FlowingBackground = withStyles({
  '@global @keyframes skeleton-wave': {
    '0%': {
      transform: 'translateX(-100%)',
    },
    '50%': {
      /* +0.5s of delay between each loop */
      transform: 'translateX(100%)',
    },
    '100%': {
      transform: 'translateX(100%)',
    },
  },
})(FlowingBackgroundComponent);

function Task({ title, price, image }) {
  const classes = useStyles();

  return (
    <Box mb="16px" position="relative">
      <FlowingBackground className={classes.root} elevation={5}>
        <Box>
          <CardMedia className={classes.media} image={image} title={title} />
        </Box>
        <CardContent>
          <Typography variant="h6" component="h3">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            from&nbsp;
            <Typography variant="body2" color="textPrimary" component="span">
              ${price}
            </Typography>
          </Typography>
        </CardContent>
        <Box ml="auto" mr="16px" display="flex" alignItems="center">
          <PlusMinus />
        </Box>
      </FlowingBackground>
    </Box>
  );
}

Task.propTypes = {
  image: PropTypes.string,
  price: PropTypes.number,
  title: PropTypes.string,
};

Task.defaultProps = {
  title: 'Fetching recommendations...',
  price: 25,
  image: images.home_cleaning,
};

export default React.memo(Task);
