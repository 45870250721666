import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const TuneUpFaucetsReplacement = ({ style }) => {
  // Brand information
  const brandingDetails = (useSelector((state) => state.brandingDetails));
  const { isAngi } = brandingDetails;

  return (
    <div>
      { isAngi
        ? (
          <svg style={style} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 8C12 6.89543 12.8954 6 14 6H34C35.1046 6 36 6.89543 36 8V39H12V8Z" fill="#FF6153" />
            <rect x="14" y="8" width="20" height="30" fill="#A03027" />
            <rect x="16" y="10" width="16" height="10" fill="#FF6153" />
            <rect x="16" y="22" width="16" height="14" fill="#FF6153" />
            <path d="M24.8918 33.9002C24.4592 31.2712 22.7454 29.5408 20.0998 29.1082C19.9667 29.0915 19.9667 28.9085 20.0998 28.8918C22.7288 28.4592 24.4592 26.7454 24.8918 24.0998C24.9085 23.9667 25.0915 23.9667 25.1082 24.0998C25.5408 26.7288 27.2546 28.4592 29.9002 28.8918C30.0333 28.9085 30.0333 29.0915 29.9002 29.1082C27.2712 29.5408 25.5408 31.2546 25.1082 33.9002C25.0749 34.0333 24.9085 34.0333 24.8918 33.9002Z" fill="#A03027" />
            <path d="M20.9351 17.9401C20.6755 16.3627 19.6473 15.3245 18.0599 15.0649C17.98 15.0549 17.98 14.9451 18.0599 14.9351C19.6373 14.6755 20.6755 13.6473 20.9351 12.0599C20.9451 11.98 21.0549 11.98 21.0649 12.0599C21.3245 13.6373 22.3527 14.6755 23.9401 14.9351C24.02 14.9451 24.02 15.0549 23.9401 15.0649C22.3627 15.3245 21.3245 16.3527 21.0649 17.9401C21.0449 18.02 20.9451 18.02 20.9351 17.9401Z" fill="#A03027" />
            <path d="M12 39H36C36 40.6569 34.6569 42 33 42H15C13.3431 42 12 40.6569 12 39Z" fill="#A03027" />
            <path d="M10 38H38V40H10V38Z" fill="#FF6153" />
          </svg>
        )

        : (
          <svg style={style} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M27.8887 24.9648H20.6177V21.3298C20.6177 15.3168 25.5097 10.4238 31.5247 10.4238H40.0067V17.6938H31.5247C29.5197 17.6938 27.8887 19.3248 27.8887 21.3298V24.9648Z" fill="#D5DBF1" />
            <path fillRule="evenodd" clipRule="evenodd" d="M27.8887 24.9648H20.6177V21.3298C20.6177 15.3168 25.5097 10.4238 31.5247 10.4238H40.0067V17.6938H31.5247C29.5197 17.6938 27.8887 19.3248 27.8887 21.3298V24.9648Z" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.9824 39.5068H8.50043V32.2358H16.9824C18.9864 32.2358 20.6184 30.6048 20.6184 28.6008V24.9648H27.8884V28.6008C27.8884 34.6138 22.9964 39.5068 16.9824 39.5068Z" fill="#D5DBF1" />
            <path fillRule="evenodd" clipRule="evenodd" d="M16.9824 39.5068H8.50043V32.2358H16.9824C18.9864 32.2358 20.6184 30.6048 20.6184 28.6008V24.9648H27.8884V28.6008C27.8884 34.6138 22.9964 39.5068 16.9824 39.5068Z" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M40.0069 20.118V8" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path d="M8.5 29.8125V41.9295" stroke="#10103F" strokeWidth="0.5" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20.632 26.7014H27.771V23.2354H20.632V26.7014Z" fill="#10103F" />
          </svg>
        )}
    </div>

  );
};
TuneUpFaucetsReplacement.propTypes = {
  style: PropTypes.shape({
    height: PropTypes.string,
    width: PropTypes.string,
  }),
};

TuneUpFaucetsReplacement.defaultProps = {
  style: {
    height: '22.6px',
    width: '23px',
  },
};

export default TuneUpFaucetsReplacement;
