import Loader from 'react-loader-spinner';

class Spinner extends React.Component {
  render() {
    if (this.props.loading) {
      return (
        <div className='spinner' >
          <Loader
            type='TailSpin'
            color={this.props.color || 'white'}
            height={this.props.height || '16'}
            width={this.props.width || '16'}
          />
        </div>
      )
    } else return this.props.content
  }
}

export default Spinner;
