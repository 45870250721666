import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AvatarName from '../../shared/AvatarName/AvatarName';
import AngiKeyIcon from '../../shared/icons/AngiKeyIcon';

function ProDetails({
  name,
  proAvatar,
  isAngi,
}) {
  const angiKeyBrandingDiv = classnames(
    'rebook-myPro-div',
    { 'angi': isAngi },
  );
  return (
    <div data-testid="rebookMyProDiv" className={angiKeyBrandingDiv}>
      <AvatarName
        name={name}
        srcImg={proAvatar}
      />
      <div className="angi-key-copy">
        <div className="angi-key-logo-div">
          <AngiKeyIcon />
        </div>
        <div className="angi-key-copy-div" data-testid="angiKeyCopy">As a Key member, you get priority rebookings with your
          favorite pros.
        </div>
      </div>
    </div>
  );
}

ProDetails.propTypes = {
  name: PropTypes.string.isRequired,
  proAvatar: PropTypes.string,
  isAngi: PropTypes.bool,
};

ProDetails.defaultProps = {
  proAvatar: '',
  isAngi: false,
};

export default ProDetails;
