const ADD_ERROR = 'ADD_ERROR';
const defaultState = { errors: [] };

export function addError(service, errors) {
  return {
    type: ADD_ERROR,
    service,
    errors,
  };
}

function bookingRequestError(state, action) {
  if (action.type === ADD_ERROR) {
    const serviceCreationError = { service: action.service, serviceErrors: action.errors };
    return { ...state, errors: [...state.errors, serviceCreationError] }
  }
  return state;
}

export default function bookingRequestErrorReducer(state = defaultState, action) {
  return bookingRequestError(state, action);
}
