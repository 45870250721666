import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import URLSearchParams from '@ungap/url-search-params';
import Cookies from 'universal-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentBundleId } from '#/actions';
import { currentBundleSelector } from '#/selectors/bundles';
import { useTrackPageView } from '#/hooks';
import BundleShowPageComponent from './BundleShowPageComponent';

const cookies = new Cookies();

export default function BundleShowPage(props) {
  const { location, match: { params: { id: bundleId } } } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentBundleId(bundleId));
  }, [dispatch, bundleId]);

  const currentBundle = useSelector(currentBundleSelector);
  const brandingDetails = useSelector((state) => state.brandingDetails);

  const qs = new URLSearchParams(location.search);
  const utm_source = qs.get('utm_source');
  const utm_campaign = qs.get('utm_campaign');

  const entryPointId = cookies.get('entryPointId');
  const extFulTrack = cookies.get('extFulTrack');
  const pageName = 'FixedPriceHandy: Bundles';
  const trackBundlePageLoaded = useTrackPageView({
    pageName,
    serviceMachineName: 'Contact Info',
    entryPointId,
    extFulTrack,
    prop58: pageName,
    catchall: bundleId,
    utm_source,
    utm_campaign,
  });

  useEffect(() => {
    trackBundlePageLoaded();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <BundleShowPageComponent
        currentBundle={currentBundle}
        brandingDetails={brandingDetails}
        location={location}
      />
    </div>
  );
}

BundleShowPage.propTypes = PropTypes.shape({
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }),
}).isRequired;
