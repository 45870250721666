import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Tooltip } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { formatCurrency } from '../../utils';
import useStyles from '../cart/receipt/styles';
import MemberDiscountLineItem from './MemberDiscountLineItem';
import InfoIcon from '@material-ui/icons/Info';

const useComponentStyles = makeStyles(() => ({
  brightFont: {
    fontWeight: "800",
  },
  iconAlign: {
    display: "flex",
  },
  iconSpacing: {
    marginLeft: "5px",
  },
}));

export default function ReceiptBreakdownItem({ quoteItem, currency }) {
  const { name: theme } = useTheme();
  const classes = useStyles({ theme });
  const componentClasses = useComponentStyles();
  const formattedAmount = formatCurrency(currency, quoteItem.amount);
  const itemColor =
    quoteItem.category === "membership_discount"
      ? classes.membership
      : classes.biggerFont;

  if (quoteItem.category === "membership_discount") {
    return <MemberDiscountLineItem amount={formattedAmount} />;
  }

  let displayName = quoteItem.displayName
    ? quoteItem.displayName
    : quoteItem.display_name;
  let boldClass = "";
  // This values are coming from Handybook API. We didn't want to update on the handybook API since it could be used in multiple places.
  // Base Price --> Subtotal
  // Trust & Support --> Trust and Support
  if (displayName === "Base Price") {
    displayName = "Subtotal";
    boldClass = componentClasses.brightFont;
  } else if (displayName === "Trust & Support") {
    displayName = "Trust and Support";
  }
  const tooltip = quoteItem.tooltipContent
    ? quoteItem.tooltipContent
    : quoteItem.tooltip_content;

  if (quoteItem.category === "discount") {
    return (
      <div className={classes.breakdownSpacedItems}>
        <Typography
          variant="body1"
          component="div"
          className={classes.lineWrap}
        >
          {displayName}
        </Typography>
        <Typography variant="body1" component="div" className={itemColor}>
          {formattedAmount}
        </Typography>
      </div>
    );
  }

  return (
    <div className={`${classes.breakdownSpacedItems} ${boldClass}`}>
      <Typography
        variant="body1"
        component="div"
        className={`${itemColor} ${componentClasses.iconAlign}`}
      >
        {displayName}
        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoIcon
              className={componentClasses.iconSpacing}
              fontSize="small"
            />
          </Tooltip>
        )}
      </Typography>
      <Typography variant="body1" component="div" className={itemColor}>
        {formattedAmount}
      </Typography>
    </div>
  );
}

ReceiptBreakdownItem.propTypes = {
  quoteItem: PropTypes.shape({
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    category: PropTypes.string,
    display_name: PropTypes.string,
  }).isRequired,
  currency: PropTypes.string.isRequired,
};
