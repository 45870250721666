import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'universal-cookie';
import { isEmpty } from 'lodash';
import { setWhenFormData } from '#/actions/whenForm';
import { selectQuoteWorkflowParams, selectWhenFormIsHydrated } from '#/selectors';
import { whenFormIsHydrated } from '#/actions';
import deepCamelCase from '#/utils/deepCamelCase';

function useHydratedWhenFormStateFromCookies() {
  const dispatch = useDispatch();
  const reduxWhenFormParams = useSelector(selectQuoteWorkflowParams);
  const isHydrated = useSelector(selectWhenFormIsHydrated);
  const needsHydration = isEmpty(reduxWhenFormParams);

  useEffect(() => {
    if (needsHydration) {
      const cookies = new Cookies();

      const quoteWorkflowParams = cookies.get('quoteWorkflowParams');

      const taskoid = cookies.get('taskoid');
      const { zipcode, phone, email, isSubscribe } = cookies.get('formAttribute');
      const couponCode = cookies.get('couponInfo')?.couponCode || null;

      const whenFormData = deepCamelCase({
        couponCode,
        email,
        isSubscribe,
        phone,
        quoteWorkflowParams,
        taskoid,
        zipcode,
      });
      dispatch(setWhenFormData(whenFormData));
    }
    dispatch(whenFormIsHydrated());
  }, [needsHydration, dispatch]);

  return isHydrated;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  useHydratedWhenFormStateFromCookies,
};
