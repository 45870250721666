import { createSelector } from '@reduxjs/toolkit';
import findIndex from 'lodash/findIndex';
import compact from 'lodash/compact';
import { whenFormServiceNameSelector } from './whenForm';
import { cartServicesNamesSelector, cartPendingServicesNamesSelector } from './cart';
import { serviceEntitiesSelector } from './services';
import { selectQuoteEntities } from './quotes';
import { currentTasksServiceMachineNamesSelector } from './bundles';

const receiptPendingLineItemsSelector = createSelector(
  cartPendingServicesNamesSelector,
  serviceEntitiesSelector,
  currentTasksServiceMachineNamesSelector,
  (pendingNames, services, bundleServices) => pendingNames.map((serviceName) => {
    const { shortDisplayName, name: fullName, priceCents, businessHours, frequency, recurrence } = services[serviceName];
    const displayName = fullName.length > 25 ? shortDisplayName : fullName;
    let frequencyMsg;
    if (frequency.selected !== 'one_time' && frequency.options.length > 0) {
      frequencyMsg = `- ${frequency.options[0].displayName}`;
    }
    // Round it to the nearest hour
    let duration;
    if (recurrence?.minimumDurationMinutes) {
      duration = Math.ceil(recurrence.minimumDurationMinutes / 60);
    }
    let bookingTime;
    if (businessHours?.value) {
      bookingTime = businessHours.value
    }
    return (
      { machineName: serviceName, displayName, priceCents, bookingTime, frequencyMsg, duration }
    );
  }).sort((a, b) => bundleServices.indexOf(a.machineName) - bundleServices.indexOf(b.machineName)),
);

const receiptServiceNamesSelector = createSelector(
  cartServicesNamesSelector,
  whenFormServiceNameSelector,
  currentTasksServiceMachineNamesSelector,
  (
    cartServicesNames,
    primaryServiceName,
    bundleServices,
  ) => compact(
    [...cartServicesNames, primaryServiceName],
  ).sort((a, b) => bundleServices.indexOf(a) - bundleServices.indexOf(b)),
);

const receiptQuotesSelector = createSelector(
  receiptServiceNamesSelector,
  selectQuoteEntities,
  (serviceNames, quotes) => compact(serviceNames.map((serviceName) => quotes[serviceName])),
);

const receiptLineItemsSelector = createSelector(
  receiptQuotesSelector,
  (receiptQuotes) => {
    const quoteBreakDowns = receiptQuotes.reduce(
      (breakdowns, { quoteBreakdown }) => [...breakdowns, ...quoteBreakdown],
      [],
    );

    return quoteBreakDowns.reduce(
      (breakdownCategories, breakdown) => {
        const findCurrentCategory = ({ category }) => category === breakdown.category;
        const categoryIdx = findIndex(breakdownCategories, findCurrentCategory);
        const isNewCategory = categoryIdx === -1;

        if (isNewCategory) return [...breakdownCategories, breakdown];

        const existingBreakdown = breakdownCategories[categoryIdx];
        const isDuplicateMembershipFee = existingBreakdown.category === 'membership_fee';

        if (isDuplicateMembershipFee) return breakdownCategories;

        const sum = existingBreakdown.amount + breakdown.amount;
        const newBreakdownCategories = [...breakdownCategories];
        newBreakdownCategories[categoryIdx] = { ...existingBreakdown, amount: sum };

        return newBreakdownCategories;
      },
      [],
    );
  },
);

const receiptCurrencySelector = createSelector(
  receiptQuotesSelector,
  (receiptQuotes) => (receiptQuotes[0] ? receiptQuotes[0].currency.symbol : '$'),
);

export {
  receiptServiceNamesSelector,
  receiptQuotesSelector,
  receiptLineItemsSelector,
  receiptCurrencySelector,
  receiptPendingLineItemsSelector,
};
