import { defaultDuration } from '../../utils'

class DurationOverride extends React.Component {
  constructor(props) {
    super(props);
    const defaultValue = defaultDuration(this.props.serviceMachineName)

    this.state = {
      defaultValue,
      selectedValue: defaultValue,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate (prevProps) {
    const prevRecommendedDuration = prevProps.recommendedDuration
    const currentRecommendedDuration = this.props.recommendedDuration
    if (prevRecommendedDuration !== currentRecommendedDuration) {
      this.setState({
        selectedValue: currentRecommendedDuration
      })
    }
  }

  handleChange(event) {
    this.setState({ selectedValue: event.target.value });
    this.props.handleChange(event);
  }

  getDurationInHours = minutes => (minutes / 60)

  getRecommendedDuration = () => {
    const { recommendedDuration } = this.props
    const { defaultValue } = this.state
    return recommendedDuration || defaultValue
  }

  isSelectedDurationLessThanRecommended = () => {
    const { selectedValue } = this.state
    const {
      recommendedDuration,
      enableRecommendedDuration,
    } = this.props
    return enableRecommendedDuration && selectedValue < recommendedDuration
  }

  renderWeRecommendSection = () => {
    const { enableRecommendedDuration } = this.props
    if (enableRecommendedDuration) {
      const recommendedDuration = this.getRecommendedDuration()
      const recommendedDurationInHours = this.getDurationInHours(recommendedDuration)
      return <p>We recommend {recommendedDurationInHours} hours</p>
    } else {
      return null
    }
  }

  render() {
    const {
      overrideOptions,
    } = this.props
    const props = {
      name: 'duration',
      onChange: this.handleChange,
      className: 'form-input form-input--full',
    }

    return (
      <div>
        <h3 className='subheader question-label'>How many hours would you like to book?</h3>
        {this.renderWeRecommendSection()}
        <div className='form-row'>
          <div className='select-wrapper'>
            <select value={this.state.selectedValue} {...props} required>
              {overrideOptions.map((option, key) => (
                <option key={key} name={option.label} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        {this.isSelectedDurationLessThanRecommended() && (
          <p className='less-hours-warning'>
            Because you are selecting less time than recommended, we may not be able to finish the job
          </p>
        )}
      </div>
    )
  }
}

export default DurationOverride
