import { transform, set } from 'lodash';
import { isArray, isObjectLike, isPlainObject, map, camelCase, snakeCase } from 'lodash/fp';

function createIteratee(converter, self) {
  return (result, value, key) => (
    set(result, converter(key), isObjectLike(value) ? self(value) : value)
  );
}

export function createConverter(keyConverter) {
  return function converter(node) {
    if (isArray(node)) return map(converter, node);
    if (isPlainObject(node)) return transform(node, createIteratee(keyConverter, converter));
    return node;
  };
}

export const deepSnakeCase = createConverter(snakeCase);

export default createConverter(camelCase);
