import { pick, intersection } from 'lodash';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

const quotesAdapter = createEntityAdapter({
  selectId: ({ serviceName }) => serviceName,
});

const quotesSlice = createSlice({
  name: 'quotes',
  initialState: quotesAdapter.getInitialState(),
  reducers: {
    quoteHasBeenSet: quotesAdapter.setOne,
    quoteUpdated: quotesAdapter.updateOne,
  },
  extraReducers: {
    'bundles/clearServicesNotInBundle': (state, action) => {
      const idsToSave = action.payload;
      const entities = pick(state.entities, idsToSave);
      const ids = intersection(state.ids, idsToSave);
      return { entities, ids };
    },
  },
});

export const quotesActions = quotesSlice.actions;
export const quotesReducer = quotesSlice.reducer;
export const quotesSelectors = quotesAdapter.getSelectors((state) => state.quotes);

export default quotesReducer;
